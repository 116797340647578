import styled from "styled-components";

export const Wrapper = styled.div`
width: 100%;
height: 100vh;

p{
        font-size: 8px;
        font-weight: 400;
        color: #fff;
    }

.header{
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 6rem;
    background-color: var(--primary-color);

    img{
        width: 80px;
        height: 16px;
    }


}


.content{
    padding: 20px 6rem 6rem;

    @media screen and (max-width: 1100px){
        padding: 1.2rem;
    }

    @media screen and (min-width: 2160px){
        padding-left:25rem;
        padding-right: 25rem;
    }

    h6{
font-size: 8px;
line-height: 16px;
font-weight: 400;
color:#9a9a9a;
}
    .order-details{
    .horizontal-line{
        display: inline-block;
        width: 100%;
        height: 1px;
        background: #EDEDED;
    }

    .address-container{
        margin-top: 20px;
        margin-bottom: 20px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;

        h4{
            font-size: 12px;
            font-weight: 500;
        }

        .address{
margin-top: 10px;
padding: 16px;
border-radius: 12px;
border: 1px solid var(--Lilac, #F1EEFC);
background: var(--White, #FCFCFC);
display: flex;
justify-content: space-between;

.left-container{
    display: flex;
    flex-direction: column;
    gap: 10px;

    h4,h5{
        font-size: 12px;
        color:#000;
        font-weight: 500;
    }
}


span{
        border-radius: 12px;
        width: 90px;
        height: 28px;
        background:#FFF2E5;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        gap: 8px;
        cursor: pointer;
        color:var(--primary-color);
    }
}
.right-container{
    display: block;
}



}

.items-container{
    margin-bottom: 20px;
    align-items: unset;
    .item-header{
        display: flex;
        justify-content: space-between;
        align-items: center;

        h4{
            font-size: 12px;
            font-weight: 500;
        }

        span{
        border-radius: 12px;
        width: max-content;
        padding: 14px 16px;
        height: 40px;
        background:#FFF2E5;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        gap: 8px;
        cursor: pointer;
        color:var(--primary-color);
    }
    }

.items-wrapper{
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 10px;

.item{
padding: 16px;
border-radius: 20px;
border: 1px solid #F1EEFC;
background:#FCFCFC;
display: flex;
flex-direction: column;
gap: 16px;

h4{
    color:#3F1BD0;
}

.item-details{
    display: grid;
    grid-template-columns: 60% auto auto;
    gap: 20px;
    align-items: center;

    h4{
        color:#000;
        font-weight: 400;
    }

.left-container{
    display: flex;
    gap:64px;
}

.right-container{
    justify-self: end;
    display: flex;
    flex-direction: column;
    gap: 2px;

    h4{
        color: #000;
        font-weight: 500;
    }

    h6{
        align-self:flex-end;
    }
}


}
}
}
}

.summary{
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
border-radius: 20px;
border: 1px solid #F1EEFC;
background:#FCFCFC;

.summary-header{
    width: 100%;
    display: flex;
    justify-content: space-between;

    h5{
        font-size: 14px;
        color:#6C6A73;
        font-weight: 400;
    }

    h5:nth-child(even){
        color:#000;
    }
}

.summary-details{
    display: flex;
    justify-content: space-between;

    h5{
        font-size: 14px;
        color:#6C6A73;
        font-weight: 400;
    }

    .left-container,.right-container{
        display: flex;
        flex-direction: column;
        gap: 5px;
    }
}

}



}
}


`