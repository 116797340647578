import React, {useContext} from 'react'
import "./style.scss"
import {AuthContext} from "../../../context/auth";
import { useNavigate } from 'react-router-dom';
import { removeLocalStorage } from '../../../helpers/localStorage';



const Index = () => {
    const {setCompleteOrderModal, setCompletedOrderData, completedOrderData, setCartData, tempDisable} = useContext(AuthContext)
    const navigate = useNavigate()

  return (
    <div className="small-modal-wrapper">
        <div className="small-modal-content" style={{padding:'20px'}}>
        <div style={{ position: 'relative', justifyContent: 'center', display: 'flex', width: '100%' }}>
        <i className='fas fa-times close' style={{ color: '#9a9a9a', position: 'absolute', right: '0px', top: '0px', cursor: 'pointer', zIndex: 9, fontSize: '20px' }} onClick={() => {
          setCompleteOrderModal(false)
          setCompletedOrderData(null)
          setCartData(null)
          removeLocalStorage('cartId')
          navigate('/')
        }}></i>
        <span style={{ width: '44px', height: '44px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '12px', background: '#33C25E', color: 'var(--white)' }}>
            <i class="far fa-check"></i>
            </span>
            </div>
            <h2 style={{marginBottom:'5px'}}>Thank you for your Order! </h2>
           {tempDisable && <h5 style={{marginBottom:'12px'}}>Order <span style={{fontWeight:'700'}}>#{completedOrderData}</span></h5>}
            <p style={{marginBottom:'20px'}}>Lorem ipsum dolor sit amet consectetur. Eget at erat ultrices odio at massa.</p>
            <button className='btn' onClick={() => {
              setCompleteOrderModal(false)
              setCompletedOrderData(null)
              setCartData(null)
              removeLocalStorage('cartId')
              navigate('/')
            }}>Continue Shopping</button>
            <button className='btn transparent' onClick={() => {
              setCompleteOrderModal(false)
              setCompletedOrderData(null)
              setCartData(null)
              removeLocalStorage('cartId')
                navigate('/profile/orders')
            }}>View Order</button>
        </div>
    </div>
  )
}

export default Index