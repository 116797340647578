import styled from "styled-components";

export const Wrapper = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.28);
    backdrop-filter: blur(5px);
    z-index: 100 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;


    .small-modal-content {
        position: relative;
        height: auto;
        width: 600px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        background-color: #ffffff;
        border-radius: 20px;
        padding: 20px 20px;

        @media screen and (max-width: 1000px) {
            width: 95vw !important;
            flex-direction: column;
            height: 95vh;
            overflow-y: auto;
            justify-content: start;
        }

        h2 {
            /* margin-top: 12px; */
            margin-bottom: 24px;
            text-align: left;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 28px;
            color:#1e1e1e;
        }   

        h4{
            text-align: center;
            font-size: 16px;
            font-weight: 500;
        }

        p{
            text-align: center;
        }

        .btn{
            width: 100%;
            /* margin-bottom: 8px; */
        }

        .close, .back{
            position: absolute;
            top: 18px;
            right: 25px;
            cursor: pointer;
            color: #1e1e1e;
        }

        .back{
            left:25px;
            right: unset;
            color:var(--primary-color);
        }

        label{
            font-size: 14px;
        }


        .two-column {
                display: flex;
                justify-content: space-between;
                gap: 20px;
                margin-bottom: 20px;

                @media (max-width: 1000px) {
                    flex-direction: column;
                }


                .input-container{
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                    width: 100%;
                }

            }

            .three-column {
                display: flex;
                justify-content: space-between;
                gap: 20px;
                margin-bottom: 20px;

                @media screen and (max-width: 1000px) {
                    flex-direction: column;
                }

                .input-container{
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                    width: 100%;
                }

                .phone-number-div {
                    width: 50%;
                    transform: translateY(-5px);

                    @media screen and (max-width: 1000px) {
                        width: 100%;
                    }
                }

                .two-column {
                    display: flex;
                    width: 50%;
                    justify-content: space-between;
                    gap: 20px;
                    margin-bottom: 0px !important;

                    @media (max-width: 1000px) {
                        flex-direction: column;
                        width: 100%;
                    }

                    .input-container{
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                    width: 100%;
                }
                }
            }




    }

`