import React, {useState, useEffect} from 'react'
import { Wrapper } from './style'
import { toAbsoluteUrl } from '../../utils/assetHelper'

const Index = ({ reference, selectedProduct, productCategory }) => {

    return (
        <Wrapper ref={reference}>
            <div className="header">
                <img src={toAbsoluteUrl('/media/logowhite.png')} alt="" />
                <p>© Identifyn™ 2023</p>
            </div>
         {selectedProduct &&   <div className="content">
                <h5>Certificate of Analysis</h5>
                <div className="product-title-container">
                    <div className="two-column">
                        <h6 className="gray">Catalog Number</h6>
                        <h6 className='dark'>{selectedProduct?.mid_code} {selectedProduct?.material}</h6>
                    </div>
                    <div className="two-column">
                        <h6 className="gray">Batch Number(s):</h6>
                        <h6 className='dark'>{selectedProduct?.metadata?.batch_number.filter((item) => item).map((item) => item).join(", ")}</h6>
                    </div>
                    <div className="two-column">
                        <h6 className="gray">Product Name</h6>
                        <h6 className="dark" style={{width:'70%', textAlign:'right'}}>{selectedProduct?.title ?? '-'}</h6>
                    </div>
                </div>
                <div className="product-description-container">
                    <h6 className="gray" style={{ marginBottom: '8px' }}>PRODUCT SPECIFICATION</h6>
                   <div className="two-column gray-card">
                        <h6 className="gray">Host</h6>
                        <h6 className="dark">{selectedProduct?.host ? selectedProduct?.host : '-'}</h6>
                    </div>
                   {productCategory !== 'SA' ?
                    <div className="two-column white-card">
                        <h6 className="gray">Colonality</h6>
                        <h6 className="dark">{selectedProduct?.clonality ? selectedProduct?.clonality : '-'}</h6>
                    </div>
                    :
                    <div className="two-column white-card">
                    <h6 className="gray">Fragment and Chain Specificity</h6>
                    <h6 className="dark">{selectedProduct?.chain_specification} {selectedProduct?.binding_specification.map(spec => spec.replace("(Subclasses 1+2a+2b+3)", "(Subclasses 1+2a+2b+3), Fcy Fragment Specificity")).join(" ")}</h6>
                    </div>
                    }
                   {
                    productCategory !== 'SA' ?
                    <div className="two-column gray-card">
                        <h6 className="gray">Subclass</h6>
                        <h6 className="dark">{selectedProduct?.metadata?.subclass ? selectedProduct?.metadata?.subclass : '-'}</h6>
                    </div>
                    :
                    <div className="two-column gray-card">
                        <h6 className="gray">Species Reactivity</h6>
                        <h6 className="dark">{selectedProduct?.species_reactivity ? selectedProduct?.species_reactivity : '-'}</h6>
                    </div>
                   }
                  {productCategory !== 'SA' &&  <div className="two-column white-card">
                        <h6 className="gray">Immunogen</h6>
                        <h6 className="dark">{selectedProduct?.immunogen ? selectedProduct?.immunogen : '-'}</h6>
                    </div>
                    }
                  <div className="two-column white-card">
                        <h6 className="gray">Conjugate</h6>
                        <h6 className="dark">{productCategory === 'PA' ? 'Unconjugated' : `Identifyn™ SRM Alexa Fluor™ ${selectedProduct?.dyes}`}</h6>
                    </div>
                    <div className="two-column gray-card">
                        <h6 className="gray">Form</h6>
                        <h6 className="dark">Liquid</h6>
                    </div>
                    <div className="two-column white-card">
                        <h6 className="gray">Concentration</h6>
                        <h6 className="dark">{selectedProduct?.concentration ? `${selectedProduct?.concentration} mg/mL` : '-'}</h6>
                    </div>
                    <div className="two-column gray-card">
                        <h6 className="gray">Purification</h6>
                        <h6 className="dark">Affinity chromatography</h6>
                    </div>
                    <div className="two-column white-card">
                        <h6 className="gray">Storage Buffer</h6>
                        <h6 className="dark">{selectedProduct?.buffer ? selectedProduct?.buffer : '-'} Saline, {selectedProduct?.buffer_molarity ? `${selectedProduct?.buffer_molarity}mM` : '-'}, pH {selectedProduct?.ph ? selectedProduct?.ph : '-'}</h6>
                    </div>
                    <div className="two-column gray-card">
                        <h6 className="gray">Preservative</h6>
                        <h6 className="dark">{selectedProduct?.preservative ? selectedProduct?.preservative : '-'}</h6>
                    </div>
                    <div className="two-column white-card">
                        <h6 className="gray">Cryopreservative</h6>
                        <h6 className="dark">{selectedProduct?.cyropreservative ? selectedProduct?.cyropreservative : '-'}</h6>
                    </div>
                    <div className="two-column gray-card">
                        <h6 className="gray">Storage conditions</h6>
                        <h6 className="dark">{selectedProduct?.storage?.map((item) => item + "°C").join(", ")}. Avoid freeze/thaw cycles</h6>
                    </div>
                </div>
       {productCategory !== 'PA' &&  <div className="product-description-container" style={{marginTop:'8px'}}>
                    <h6 className="gray" style={{ marginBottom: '8px' }}>DYE CHARACTERSTICS</h6>
                    <div className="two-column gray-card">
                        <h6 className="gray">Excitation Wavelength</h6>
                        <h6 className="dark">{selectedProduct?.excitation_wavelength ? `${selectedProduct?.excitation_wavelength} nm` : '-'}</h6>
                    </div>
                    <div className="two-column white-card">
                        <h6 className="gray">Emission Wavelength</h6>
                        <h6 className="dark">{selectedProduct?.emission_wavelength ? `${selectedProduct?.emission_wavelength} nm` : '-'}</h6>
                    </div>
                    <div className="two-column gray-card">
                        <h6 className="gray">Extinction Coefficient</h6>
                        <h6 className="dark">{selectedProduct?.extinction_coefficient ? Number(selectedProduct?.extinction_coefficient)?.toLocaleString('en-US') : '-'}</h6>
                    </div>
                    <div className="two-column white-card">
                        <h6 className="gray">Molecular Weight</h6>
                        <h6 className="dark">{selectedProduct?.molecular_weight ? `${selectedProduct?.molecular_weight} Da` : '-'}</h6>
                    </div>
                    <div className="two-column gray-card">
                        <h6 className="gray">Emission Color</h6>
                        <h6 className="dark">{selectedProduct?.emission_color ? selectedProduct?.emission_color : '-'}</h6>
                    </div>
                </div>
}
                <div className="product-description-container" style={{marginTop:'8px'}}>
                    <h6 className="gray" style={{ marginBottom: '8px' }}>APPLICATIONS</h6>
                    <div className="two-column gray-card">
                        <h6 className="gray">Western Blotting</h6>
                        <h6 className="dark">{selectedProduct?.western_bloating ? selectedProduct?.western_bloating : '-'}</h6>
                    </div>
                    <div className="two-column white-card">
                        <h6 className="gray">Immunocytochemistry / Microscopy</h6>
                        <h6 className="dark">{selectedProduct?.dillusion_microscopy ? selectedProduct?.dillusion_microscopy : '-'}</h6>
                    </div>
                </div>
             {productCategory !== 'SA' &&   <div className="product-description-container">
                    <h6 className="gray" style={{ marginBottom: '8px' }}>
                        PRODUCT DESCRIPTION
                    </h6>
                    <p className="description-card">
                    {selectedProduct?.description ? selectedProduct?.description : selectedProduct?.metadata?.description ? selectedProduct?.metadata?.description : 'No Description Available'}
                    </p>
                </div>
}
            </div>
}
            <div className="footer">
            <div className="two-column" style={{marginTop:'auto'}}>
                    <p style={{color:'#1e1e1e'}}>For Research Use Only. Not for use in diagnostic procedures. Not for resale without express authorization.
                    </p>
                    {/* <p style={{color:'var(--primary-color)'}}>1 of 1 </p> */}
                </div>
            </div>
        </Wrapper>
    )
}

export default Index