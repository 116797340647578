import React, {useEffect, useState, useRef} from 'react'
import "./style.scss"
import Input from "../Input"
 
const Index = ({
    value,
    onChange,
    searchable=false,
    options=[],
    label,
    style,
    optionType='array',
    clear=false,
    onClear = () => {},

}) => {
const dropdownRef = useRef(null)
const [isDropdownOpen, setIsDropdownOpen] = useState(false);
const [search, setSearch] = useState('')
const [tempOptions, setTempOptions] = useState([])

useEffect(() => {
    if(searchable && search){
        const filteredOptions = options.filter((option) => {
            return option.toLowerCase().includes(search.toLowerCase())
        })
        setTempOptions(filteredOptions)
    }else{
        setTempOptions(options)
    }

},[search, searchable, options])


useEffect(() => {
  const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setIsDropdownOpen(false)
      }
  };

  document.addEventListener('mousedown', handleClickOutside);

  return () => {
      document.removeEventListener('mousedown', handleClickOutside);
  };

}, []);



  return (
    <div style={{position:'relative'}} className='protein-dropdown' ref={dropdownRef}>
    {clear && value && <span className='clear-dropdown' onClick={() => onClear()}><i className='fal fa-times'></i></span>}
    <div className="selected-protein" style={style} onClick={() => !searchable && setIsDropdownOpen(!isDropdownOpen)}>
     {searchable && value ? <span className='protein-chip'>{value}
      <i className='fas fa-times' style={{marginLeft:'15px'}} onClick={() => onChange('')}></i>
      </span>
      :
      <span style={{color: !value ? '#9a9a9a': '', fontSize:'14px'}}>{value ? value : label}</span>
      }
      <span style={{color:'var(--primary-color)', fontWeight:300}}  onClick={() => setIsDropdownOpen(!isDropdownOpen)}>{isDropdownOpen ? <i className='fas fa-angle-up'></i> : <i className='fas fa-angle-down'></i>}</span>
    </div>
  {isDropdownOpen &&   <div className="dropdown-container">
    {searchable &&  <Input type="text" search style={{ height: '40px', paddingLeft: '30px' }} placeholder={'Search Protein'} value={search} onChange={(e) => setSearch(e.target.value)} /> }
    {tempOptions && optionType === 'array' ? tempOptions?.map((protein) => {
      return (
        <div className="dropdown-item" style={{color:protein === value ? 'var(--primary-color)' : ''}}  onClick={() => {
            onChange(protein)
            setIsDropdownOpen(false)
            setSearch('')
            }}>
          <span>{protein}</span>
        </div>
      )
    })
  : tempOptions?.sort((a, b) => a.value - b.value).map((item) => {
      return (
        <div className="dropdown-item"  style={{color:item.label === value ? 'var(--primary-color)' : ''}} onClick={() => {
            onChange(item)
            setIsDropdownOpen(false)
            }}>
          <span>{item.label}</span>
        </div>
      )
    })
  }
    </div>
}
    </div>
  )
}

export default Index