import React, {useContext} from 'react'
import "./style.scss"
import {AuthContext} from "../../../context/auth";

const Index = () => {
    const {setLogOutModal, logout} = useContext(AuthContext)
  return (
    <div className="small-modal-wrapper">
        <div className="small-modal-content pathway-modal">
        <div style={{position:'relative',justifyContent:'center', display:'flex', width:'100%'}}>
        <i className='fal fa-times close' style={{ color: '#9a9a9a', position: 'absolute', right: '0px', top: '0px', cursor: 'pointer', zIndex: 9, fontSize: '20px' }} onClick={() => {
          setLogOutModal(false)
        }}></i>
        <span style={{ width: '44px', height: '44px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '12px', background: '#DC1E00', color: 'var(--white)' }}>
            <i class="fal fa-sign-out"></i>
            </span>
        </div>
            <h2>Are You Sure? </h2>
            <p style={{marginBottom:'20px'}}>You will be logged out of <span>Identifyn.</span></p>
            <button className='btn' onClick={() => {
              logout()
                setLogOutModal(false)
            }}>Log Out</button>
            <button className='btn transparent' onClick={() => {
                setLogOutModal(false)
            }}>Cancel</button>
        </div>
    </div>
  )
}

export default Index