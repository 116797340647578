import React from 'react'
import Layout from "../../../components/layout"
import "./style.scss"
import { toAbsoluteUrl } from "../../../utils/assetHelper"
import {Footer} from "../../../components/Footer"

const Index = () => {
    return (
        <Layout>
            <div className="container title-container">
                <img src={toAbsoluteUrl("/media/partners/bruker.png")} alt="" />
            </div>
            <div className="container azure-container">
                <div className="left-container">
                    <div className="solutions-text">
                        <h2>Bruker Vutara VXL</h2>
                        <p style={{ marginBottom: '20px' }}>
                            Identifyn™ deploys the power of the Vutara VXL, initially designed and commercialized by our chief scientific officer, Dr. Brian T. Bennett*, the VXL <span>S</span>uper <span>R</span>esolution <span>M</span>icroscope (<span>SRM</span>) empowers <span>S</span>ingle-<span>M</span>olecule <span>L</span>ocalization Microscopy (<span>SMLM</span>) with resolutions to approximately 20 nm in <span>P</span>hoto<span>A</span>ctivated <span>L</span>ocalization <span>M</span>icroscopy (<span>PALM</span>), <span>S</span>tochastic <span>O</span>ptical <span>R</span>econstruction <span>M</span>icroscopy (<span>STORM</span>) and <span>P</span>oint <span>A</span>ccumulation for <span>I</span>maging in <span>N</span>anoscale <span>T</span>opography (<span>PAINT</span>).
                        </p>
                        <small style={{ fontStyle: 'italic', fontWeight: 400, color: '#1e1e1e' }}>*2013 Annual Report - Technology and Venture Commercialization, University of Utah.</small>
                    </div>
                </div>
                <div className="right-container" style={{position:'relative'}}>
                    <img src={toAbsoluteUrl("/media/bruker/bruker1.png")} className='bruker-hero-img' alt="" />
                    <small style={{ font:'Instrument Sans', fontWeight: 400, fontSize:'10px', position:'absolute', bottom:'-50px', right:'5%' }}>Image Courtesy Bruker</small>
                </div>
            </div>
            <div className="container azure-section-third-container">
                <div className="card1">
                    <h3>Technical Capabilities </h3>
                    <div className="points-container">
                        <div className="points">
                            <img src={toAbsoluteUrl("/media/pointbullet.svg")} alt="" />
                            <p>Super-resolution <span>Single Molecule Localization Microscopy</span> (SMLM),</p>
                        </div>
                        <div className="points">
                            <img src={toAbsoluteUrl("/media/pointbullet.svg")} alt="" />
                            <p>SMLM <span>with high z-resolution for STORM</span>, PALM, PAINT</p>
                        </div>
                        <div className="points">
                            <img src={toAbsoluteUrl("/media/pointbullet.svg")} alt="" />
                            <p>Super-resolution Localization Microscopy <span>with large FOV</span> (200 µm x 200 µm: multiplexed localization and widefield; 50 µm x 50 µm for SMLM and 3D biplane detection)</p>
                        </div>
                        <div className="points">
                            <img src={toAbsoluteUrl("/media/pointbullet.svg")} alt="" />
                            <p>Widefield microscopy, Epi-fluorescence microscopy with large FOV, Transmitted light microscopy with large FOV</p>
                        </div>
                        <div className="points">
                            <img src={toAbsoluteUrl("/media/pointbullet.svg")} alt="" />
                            <p><span>Excitation lasers</span> (nominal laser power at diode) - <span>405 nm</span> (120 mW), <span>488 nm</span> (2000 mW), <span>555 nm</span> (2000 mW), <span>638 nm</span> (1200 mW), <span>750 nm</span> (1500 mW)</p>
                        </div>
                        <div className="points">
                            <img src={toAbsoluteUrl("/media/pointbullet.svg")} alt="" />
                            <p>Multicolor acquisition – <span>up to 5 colors sequentially or 2 colors simultaneously in biplane</span> (2 camera) mode</p>
                        </div>
                    </div>
                </div>
                <div className="card2">
                    <h3>Identifyn™ Applications</h3>
                    <div className="points-container">
                        <div className="points">
                            <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
                            <p>Identifyn's™ <span>Vutara VXL</span> is uniquely equipped with two cameras, enabling <span>simultaneous two-channel imaging</span> at <span>single molecule super-resolution</span>. Further, Identifyn's VXL has both <span>live cell capability</span> and Bruker's PlexFlo fluidics system, allowing <span>multiplex application</span> and oncological <span>assay development</span></p>
                        </div>
                        <img src={toAbsoluteUrl("/media/bruker/bruker5.png")} alt="" className='bruker-application-img' />
                    </div>
                </div>
            </div>
            <div className="container azure-container" style={{ marginBottom: '32px',marginTop:'32px' }}>
                <div className="left-container">
                    <div className="solutions-text">
                        <h3><span>Identifyn™ SRM Alexa Fluor™ 647</span></h3>
                        <p style={{marginBottom:'10px'}}>3D STORM revealing 'twist and turn' of TOMM20 on mitochondrial fragment visualized with Identifyn™ SRM 647 Alexa Fluor™ Secondary Antibody
                        </p>
                        {/* <p>Image by Identifyn™ using Vutara VXL.</p> */}
                        <small style={{ fontStyle: 'italic', fontWeight: 400, color: '#1e1e1e' }}>Image and Rendering by Dr. Shreyasi S. Thakur, Microscopy Director, Identifyn™</small>
                    </div>
                </div>
                <div className="right-container bruker-image-grid">
                    <img src={toAbsoluteUrl("/media/bruker/bruker7.jpg")} alt=""  />
                    <div className='image-grid-column'>
                    <img src={toAbsoluteUrl("/media/bruker/bruker8.gif")} alt=""  />
                    <img src={toAbsoluteUrl("/media/bruker/bruker9.gif")} alt=""  />
                    </div>
                </div>
            </div>
            <Footer />
        </Layout >
    )
}

export default Index