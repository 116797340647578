import React, { createContext, useState } from 'react';
import { removeLocalStorage } from '../helpers/localStorage';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [width, setWidth] = useState(window.innerWidth);
  const [isLoading, setIsLoading] = useState(false);
  const [notification, setNotification] = useState(null);
  const [signInModal, setSignInModal] = useState(false);
  const [signUpModal, setSignUpModal] = useState(false);
  const [completeModal, setCompleteModal] = useState(false);
  const [restoreModal, setRestoreModal] = useState(false);
  const [changePasswordRestoreModal, setChangePasswordRestoreModal] = useState(null);
  const [changePasswordModal, setChangePasswordModal] = useState(null);
  const [changeEmailModal, setChangeEmailModal] = useState(false);
  const [logOutModal, setLogOutModal] = useState(false);
  const [deleteProfileModal, setDeleteProfileModal] = useState(false);
  const [completeOrderModal, setCompleteOrderModal] = useState(false);
  const [teamModal, setTeamModal] = useState(false);
  const [shippingAddressModal, setShippingAddressModal] = useState(false);
  const [billingAddressModal, setBillingAddressModal] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [user, setUser] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [cartData, setCartData] = useState(null);
  const [completedOrderData, setCompletedOrderData] = useState(null);
  const [regions, setRegions] = useState([]);
  const [tempDisable, setTempDisable] = useState(true)
  const [openItemPreviewModal, setOpenItemPreviewModal] = useState(false)
  const [selectedProtein, setSelectedProtein] = useState(null)
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [mapViewActive, setMapViewActive] = useState(false)

  const login = (userData) => {
    // Perform login logic and set the user state
    setUser(userData);
    setIsLoggedIn(true);
  };

  const logout = () => {
    // Perform logout logic and clear the user state
    setUser(null);
    setIsLoggedIn(false);
    removeLocalStorage('identifyntoken');
    removeLocalStorage('cartId');
    setCartData(null);
  };

  return (
    <AuthContext.Provider value={{isLoading, setIsLoading, setUser, user, login, logout, isLoggedIn, signInModal, setSignInModal, signUpModal, setSignUpModal, completeModal, setCompleteModal, restoreModal, setRestoreModal, changePasswordRestoreModal, setChangePasswordRestoreModal, changePasswordModal, setChangePasswordModal, changeEmailModal, setChangeEmailModal,  notification, setNotification, teamModal, setTeamModal, logOutModal, setLogOutModal, deleteProfileModal, setDeleteProfileModal, shippingAddressModal, setShippingAddressModal, billingAddressModal, setBillingAddressModal, setSelectedAddress, selectedAddress, setCompleteOrderModal, completeOrderModal, width, setWidth,cartData, setCartData, completedOrderData, setCompletedOrderData, regions, setRegions, tempDisable, setTempDisable, openItemPreviewModal, setOpenItemPreviewModal, selectedProtein, setSelectedProtein, selectedProduct, setSelectedProduct, mapViewActive, setMapViewActive }}>
      {children}
    </AuthContext.Provider>
  );
};