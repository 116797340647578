import styled from "styled-components"


export const AboutHeader = styled.div`
width: 100%;
display: flex;
justify-content: center;
align-items: center;
background: linear-gradient(102deg, rgba(63, 27, 208, 0.05) 4.47%, rgba(215, 76, 16, 0.05) 54.56%);
padding: 164px 6rem 90px 6rem;


@media (max-width: 1100px) {
  padding: 140px 1.2rem 50px 1.2rem;
}

@media screen and (min-width: 2160px) {
    padding-left:  25rem !important;
    padding-right: 25rem !important;
}


h1{
    font-size: 48px;
    font-weight: 800;
    margin-bottom: 16px;
    text-align: center;

    span{
        color: var(--primary-color);
    }
}

`


export const Content = styled.div`
padding: 60px 6rem 60px 6rem;
@media (max-width: 1100px) {
  padding: 1.2rem;
}

@media screen and (min-width: 2160px) {
    padding-left:  25rem !important;
    padding-right: 25rem !important;
}

p{
color:#1e1e1e;    
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px;
margin-bottom: 12px;

span{
    font-weight: 700;
}

a{
    color:var(--primary-color);
    text-decoration:underline;
}

}

h4{
color:var(--primary-color);
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 28px;
margin-bottom: 12px;
margin-top: 20px;
}

h5{
color:#1e1e1e;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 24px;
margin-bottom: 12px;
}

ul{
    list-style: disc;
    li{
        margin-bottom: 8px;
        font-size: 16px;
        line-height: 24px;
    }
    li::marker{
        color: #1e1e1e;
    }

    .orange::marker{
        color: var(--primary-color)
    }
}



`