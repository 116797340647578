import React, {useContext} from 'react'
import Layout from '../../../components/layout'
import { AboutHeader, AboutContent } from './style'
import { toAbsoluteUrl } from '../../../utils/assetHelper'
import { Footer } from '../../../components/Footer'
import { AuthContext } from '../../../context/auth'

const Index = () => {
  const { width } = useContext(AuthContext)
  return (
    <Layout>
      <div className="container title-container bio-container">
        <span style={{ width: '44px', height: '44px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '12px', background: '#fff2e5', color: 'var(--primary-color)' }}>
          <i class="fal fa-brain"></i>
        </span>
        <AboutHeader>
          <h1>Identifyn™ Intellectual Property</h1>
        </AboutHeader>
      </div>
      <AboutContent>
        <div className="container azure-container" style={{ marginBottom: '32px' }}>
          <div className="left-container">
            <div className="solutions-text">
              <h2>Identifyn™ IP</h2>
              <p>At Identifyn™, we <span>actively protect rights in our advancements through intellectual property registration.</span> Identifyn’s™ copyrighted materials and patent pending <span>advancements focus on improvements</span> to our five main research pathways to <span>enhance our products and elevate our industry impact.</span></p>
            </div>
          </div>
          {width > 1100 &&  <div className="right-container">
            <figure>
              <img src={toAbsoluteUrl("/media/intellectual/logo.png")} alt="" width={'100%'} style={{ height: '350px' }} />
            </figure>
          </div>
          }
        </div>
        <div className="container services-section-two-container thermo-second-container">
          <div className="left-container">
            <div className="card1" style={{ height: '238px' }}>
              <h3>Software Applications</h3>
              <p>Methods for Native App Development with Embedded Artificial Intelligence Focused on Image Acquisition and Analysis Improvements.</p>
            </div>
            <div className="card2" style={{ height: '210px' }}>
              <h3>Novel Protein Chemistry</h3>
              <p>Methods for Conjugation Chemistry, Reagent Formulation and Protein Characterization.</p>
            </div>
          </div>
          <div className="right-container">
            <div className="card2" style={{ height: '210px' }}>
              <h3>Novel Protein Development</h3>
              <p>Methods for De Novo Antibody and Recombinant Protein Production</p>
            </div>
            <div className="card1" style={{ height: '238px' }}>
              <h3>Novel Immunofluorescent Applications</h3>
              <p>Application-Specific Image Collection via Novel Methods in Sample Preparation</p>
            </div>
          </div>
        </div>
        <div className="container azure-section-four-container" style={{ background: 'rgba(237, 237, 237, 0.20)', marginBottom: '0px' }}>
          <h3>Advanced Microscopy Methodology</h3>
          <p>Methods for examining protein-protein interactions or protein-chemical reagent dosimetry interaction across a defined optical resolution range and duration in determining functional efficacies directly and indirectly.</p>
        </div>
        <div className="container azure-section-four-container" style={{ background: 'rgba(237, 237, 237, 0.20)', marginBottom: '0px' }}>
          <h3 style={{ color: '#1e1e1e' }}>Identifyn™ maintains valuable copyright, trademark, and other intellectual property rights, and any use of Identifyn’s™ trademarks or transfer of the data and materials contained herein without prior authorization is strictly prohibited. All rights are reserved.</h3>
          <div className="ip-information-container">
            <p style={{ textAlign: 'left' }}>Intellectual Property maintained by:</p>
            <div className="two-column">
              <p style={{textAlign:'left'}}>Shumaker, Loop & Kendrick LLP
                Bank of America Plaza, 101 E Kennedy Blvd Suite 2800,
                Tampa, FL 33602</p>
              <p style={{textAlign:'left'}}>Thorpe North & Western
                8180 S 700 E #350
                Sandy, UT 84070
              </p>
            </div>
            <p style={{textAlign:'left'}}>For questions regarding intellectual property use: <a style={{color:'#0099FF'}} href='mailto:contact@identifyn.com'>contact@identifyn.com</a></p>
          </div>
        </div>
      </AboutContent>
      <Footer />
    </Layout>
  )
}

export default Index