import React, { useEffect, useRef, useState } from 'react'
import "./style.scss"
const Index = ({
  type = 'text',
  placeholder,
  label,
  value,
  className,
  onChange,
  style,
  optional,
  required = false,
}) => {
  const [showPlaceHolder, setShowPlaceHolder] = useState(required)
  const [showPassword, setShowPassword] = useState(false)

  useEffect(() => {
    if (value) {
      setShowPlaceHolder(false)
    }
  }, [value])


  return (
    <>
      <div style={{ position: 'relative', width: '100%' }}>
        {showPlaceHolder && <div className="placeholder">
          <label>{label}</label>
          <span style={{ color: 'rgba(220, 30, 0, 1)' }}> *</span>
        </div>
        }
        <input type={showPassword ? 'text' : type} value={value} placeholder={placeholder} className={className} onChange={onChange} style={style} autocomplete='off' readOnly onBlur={() => !value && required ? setShowPlaceHolder(true) : setShowPlaceHolder(false)} onFocus={(e) => {
          e.target.readOnly = false
        }}
          onTouchStart={(e) => {
            e.target.readOnly = false
          }} />
        {type === 'password' && <span className='show-password' onClick={() => setShowPassword(!showPassword)}>{showPassword ? <i className='fas fa-eye-slash'></i> : <i className='fas fa-eye'></i>}</span>}
        {optional && <span className='optional' >Optional</span>}
      </div>
    </>
  )
}

export default Index