import { toAbsoluteUrl } from '../../utils/assetHelper'
import css from './footer.module.scss'
import { Link } from 'react-router-dom'

export const Footer = () => {
    return (
        <>
            <footer className={css.footer}>
                <div className={css.content}>
                    <div className={css.companyInfo}>
                        <Link to='/' className={css.logo}>
                            <img src={toAbsoluteUrl('/media/logowhite.png')} alt="" />
                        </Link>
                        <div className={`${css.text}`}>Delivering unprecedented power, speed, and access to multimodal super resolution microscopy, reagents, applications, and discovery.</div>
                        <div className={`${css.text}`} style={{fontSize:'12px'}}>© Copyright 2023-2024 Identifyn™ LLC & GMD12™ LLC, All rights reserved</div>
                        <Link className="nav-link" to="/b2b-opportunities" style={{height:'48px',padding:'12px 20px', display:'flex', justifyContent:'center', alignItems:'center', background:'#FFF2E5', color:'var(--primary-color)', width:'181px', borderRadius:'12px', marginTop:'24px'}}>B2B Opportunities</Link>
                    </div>
                    {/* <div className={css.links}>
                        <h3>Account</h3>
                        <Link to='/profile/account'>My Account</Link>
                        <Link to='/contact?support=Account Support'>Account Support</Link>
                        <Link to='/contact?support=Order Support'>Order Support</Link>
                        <Link to='/contact?support=General Inquiry'>General Inquiries </Link>
                    </div> */}
                    <div className={css.links}>
                        <h3>Technical Support</h3>
                        <Link to='/storm-calibration'>STORM Calibration</Link>
                        <Link to='/microscopy-methods'>Microscopy Methods</Link>
                        <Link to='/protein-chemistry-methods'>Protein Chemistry Methods</Link>
                        {/* <Link to='/'>Contract Services</Link> */}
                    </div>
                    <div className={css.links}>
                        <h3>Legal</h3>
                        <Link to='/terms-and-conditions-of-use'>Terms and Conditions of Use</Link>
                        <Link to='/terms-and-conditions-of-sale'>Terms and Conditions of Sale</Link>
                        <Link to='/privacy-policy'>Privacy Policy</Link>
                        <Link to='/cookie-policy'>Cookie Policy</Link>
                        <Link to='/intellectual-property'>Intellectual Property</Link>
                    </div>
                </div>
            </footer>
            <footer className={css.footer1}>
                <div className={css.content}>
                    <div className={css.companyInfo}>
                        <h4>Stay Tuned</h4>
                        <div className={css.icons}>
                            <a href="https://www.instagram.com/identifyn_super_resolution/" target='_blank' rel='noreferrer'>
                            <svg xmlns="http://www.w3.org/2000/svg" height="24" width="21" viewBox="0 0 448 512"><path fill="#ffffff" d="M194.4 211.7a53.3 53.3 0 1 0 59.3 88.7 53.3 53.3 0 1 0 -59.3-88.7zm142.3-68.4c-5.2-5.2-11.5-9.3-18.4-12c-18.1-7.1-57.6-6.8-83.1-6.5c-4.1 0-7.9 .1-11.2 .1c-3.3 0-7.2 0-11.4-.1c-25.5-.3-64.8-.7-82.9 6.5c-6.9 2.7-13.1 6.8-18.4 12s-9.3 11.5-12 18.4c-7.1 18.1-6.7 57.7-6.5 83.2c0 4.1 .1 7.9 .1 11.1s0 7-.1 11.1c-.2 25.5-.6 65.1 6.5 83.2c2.7 6.9 6.8 13.1 12 18.4s11.5 9.3 18.4 12c18.1 7.1 57.6 6.8 83.1 6.5c4.1 0 7.9-.1 11.2-.1c3.3 0 7.2 0 11.4 .1c25.5 .3 64.8 .7 82.9-6.5c6.9-2.7 13.1-6.8 18.4-12s9.3-11.5 12-18.4c7.2-18 6.8-57.4 6.5-83c0-4.2-.1-8.1-.1-11.4s0-7.1 .1-11.4c.3-25.5 .7-64.9-6.5-83l0 0c-2.7-6.9-6.8-13.1-12-18.4zm-67.1 44.5A82 82 0 1 1 178.4 324.2a82 82 0 1 1 91.1-136.4zm29.2-1.3c-3.1-2.1-5.6-5.1-7.1-8.6s-1.8-7.3-1.1-11.1s2.6-7.1 5.2-9.8s6.1-4.5 9.8-5.2s7.6-.4 11.1 1.1s6.5 3.9 8.6 7s3.2 6.8 3.2 10.6c0 2.5-.5 5-1.4 7.3s-2.4 4.4-4.1 6.2s-3.9 3.2-6.2 4.2s-4.8 1.5-7.3 1.5l0 0c-3.8 0-7.5-1.1-10.6-3.2zM448 96c0-35.3-28.7-64-64-64H64C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96zM357 389c-18.7 18.7-41.4 24.6-67 25.9c-26.4 1.5-105.6 1.5-132 0c-25.6-1.3-48.3-7.2-67-25.9s-24.6-41.4-25.8-67c-1.5-26.4-1.5-105.6 0-132c1.3-25.6 7.1-48.3 25.8-67s41.5-24.6 67-25.8c26.4-1.5 105.6-1.5 132 0c25.6 1.3 48.3 7.1 67 25.8s24.6 41.4 25.8 67c1.5 26.3 1.5 105.4 0 131.9c-1.3 25.6-7.1 48.3-25.8 67z"/></svg>
                            </a>
                            <a href="https://twitter.com/IdentifynLLC" target='_blank' rel='noreferrer'>
                            <svg xmlns="http://www.w3.org/2000/svg" height="24" width="21" viewBox="0 0 448 512"><path fill="#ffffff" d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm297.1 84L257.3 234.6 379.4 396H283.8L209 298.1 123.3 396H75.8l111-126.9L69.7 116h98l67.7 89.5L313.6 116h47.5zM323.3 367.6L153.4 142.9H125.1L296.9 367.6h26.3z"/></svg>
                            </a>
                            <a href="https://www.linkedin.com/company/identifyn" target='_blank' rel='noreferrer'>
                            <svg xmlns="http://www.w3.org/2000/svg" height="24" width="21" viewBox="0 0 448 512"><path fill="#ffffff" d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"/></svg>
                            </a>
                        </div>
                        <div className={css.storelinks}>
                        <a href='https://apps.apple.com/us/app/identifyn/id6504354303' target='_blank'><img src={toAbsoluteUrl('/media/app_store.svg')} /></a>
                        <a href='https://play.google.com/store/apps/details?id=com.identifyn.app&hl=en_US' target='_blank'><img src={toAbsoluteUrl('/media/google_play.svg')} /></a>
                        </div>
                    </div>
                    <div className={css.links1}>
                        <h4>IDENTiFYN™ LLC</h4>
                        <Link to='/'>941.529.0647</Link>
                        <Link to='/'>contact@identifyn.com</Link>
                    </div>
                    <div className={css.links1}>
                        <h4>Corporate Address</h4>
                        <p>2055 Wood Street, Suite 206<br/> Sarasota, FL 34237</p>
                    </div>
                    <div className={css.links1}>
                        <h4>Laboratory Address</h4>
                        <p>2055 Wood Street, Suite 102<br/> Sarasota, FL 34237</p>
                    </div>
                </div>
            </footer>
        </>
    )
}
