import styled from "styled-components";

export const AboutHeader = styled.div`
margin-top: 20px;
h1{
    font-size: 48px;
    font-weight: 800;
    margin-bottom: 16px;
    text-align: center;

    span{
        color: var(--primary-color);
    }
}
`

export const AboutContent = styled.div`
    margin-top: 32px;
    margin-bottom: 32px;

.azure-section-four-container{
background: linear-gradient(102deg, rgba(63, 27, 208, 0.05) 4.47%, rgba(215, 76, 16, 0.05) 54.56%);

h2{
font-size: 48px;
font-style: normal;
font-weight: 800;
line-height: 60px;
text-align: center;
}

p{
margin-top: 16px;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 28px;
text-align: center;

span{
    color: var(--primary-color);
    font-weight: 700;
}

}

}


.left-container > .solutions-text > p{
margin-top: 16px;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 28px;
width: 100%;

span{
    color: var(--primary-color);
    font-weight: 700;
}
}

.card1,.card2 > p{
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 28px;

span{
    font-weight: 700;
    color: var(--primary-color);
}

.purple{
    color: #3F1BD0;
}

}

@media screen and (min-width:2160px) {
    .background-image{
        height: 800px !important;
    }
}



.services-section-two-container {
    margin: 0px 0 32px 0 ;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 32px;
    padding-left: 1rem;
    padding-right: 1rem;
    align-items: start;
    padding-top: 0;
    padding-bottom: 0;

    @media screen and (max-width:1100px) {
        grid-template-columns: 1fr;
        padding-left: 1.2rem;
        padding-right: 1.2rem;
    }


    .left-container,
    .right-container {
        display: flex;
        flex-direction: column;
        gap: 32px;
    }

    .card1,
    .card2 {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: linear-gradient(0deg, rgba(241, 238, 252, 0.48) 0%, rgba(241, 238, 252, 0.48) 100%), #FFF;
        border-radius: 12px;
        padding: 44px 96px;
        justify-content: center;
        height: max-content;

        @media screen and (max-width:1100px) {
            padding: 44px 1.2rem;
        }



        h3 {
            font-size: 34px;
            line-height: 50px;
            color: #3F1BD0;
            text-align: center;
        }

        .points-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 12px;
            width: 100%;
        }

        .points {
            display: flex;
            align-items: start;
            gap: 12px;
            position: relative;
            width: 100%;


            img {
                width: 12px;
                height: 12px;
                transform: translateY(5px);
            }

            p {
                font-size: 16px;
                line-height: 24px;
                width: 70%;

                span {
                    color: #3F1BD0;
                    font-weight: 600
                }
            }
        }
    }

    .card2 {
        background: linear-gradient(0deg, rgba(255, 242, 229, 0.48) 0%, rgba(255, 242, 229, 0.48) 100%), #FFF;

        h3 {
            color: var(--primary-color);
        }

        .points {
            p {
                span {
                    color: var(--primary-color)
                }
            }
        }
    }

}

.thermo-second-container{
.left-container, .right-container {
    .card1,.card2{
    @media screen and (max-width:1100px) {
        height: max-content !important;
        h3{
            font-size: 30px;
        }
    }

    @media screen and (max-width:490px) {
        height: max-content !important;
        h3{
            font-size: 26px;
        }
    }
}     
} 
}

`