import React, { useState } from 'react'
import Layout from '../../../components/layout'
import { AboutHeader, AboutContent } from './style'
import { toAbsoluteUrl } from '../../../utils/assetHelper'
import Graph from './graph'
import { useNavigate } from 'react-router-dom'
import {Footer} from '../../../components/Footer'

const Index = () => {
    const [tab, setTab] = useState("alexaflour")
    const [dyeBtns, setDyebtns] = useState()
    const navigate = useNavigate()


    const applications = [
        {
            name: 'Widefield',
            subName: 'Identifyn™ SRM Alexa Fluor™ 594',
            image:`${toAbsoluteUrl('/media/applications/widefield.png')}`,
        },
        {
            name: 'Widefield SIM',
            subName: 'Identifyn™ SRM Alexa Fluor™ 488 & 647',
            image:`${toAbsoluteUrl('/media/applications/widefieldsim.png')}`,
        },
        {
            name: 'Confocal',
            subName: 'Identifyn™ SRM Alexa Fluor™ 488 & 555',
            image:`${toAbsoluteUrl('/media/applications/confocal.png')}`,
        },
        {
            name: 'Super Resolution Airyscan​',
            subName: 'Identifyn™ SRM Alexa Fluor™ 555',
            image:`${toAbsoluteUrl('/media/applications/airyscan.png')}`,
        },
        {
            name: 'Super Resolution SIM²​',
            subName: 'Identifyn™ SRM Alexa Fluor™ 647',
            image:`${toAbsoluteUrl('/media/applications/sim2.png')}`,
        },
        {
            name: 'Super Resolution Single Molecule​ STORM​',
            subName: 'Identifyn™ SRM Alexa Fluor™ 647',
            image:`${toAbsoluteUrl('/media/applications/storm.png')}`,
        }
    ]





    return (
        <Layout>
            <div className="container title-container bio-container">
            <span style={{ width: '44px', height: '44px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '12px', background: '#fff2e5', color: 'var(--primary-color)' }}>
              <img src={toAbsoluteUrl("/media/antibody.png")} alt="" style={{width:'20px'}} />
            </span>
                <AboutHeader>
                    <h1>Identifyn™ SRM Alexa Fluor™  Conjugates</h1>
                </AboutHeader>
            </div>
            <AboutContent>
                {/* alexaflour */}
                {tab === "alexaflour" ? <>
                    <div className="container azure-container" style={{ marginBottom: '32px' }}>
                        <div className="left-container">
                            <div className="solutions-text">
                                <h2><span>Alexa Fluor™ Fluorescence Dyes</span></h2>
                                <p>Identifyn™ currently incorporates <span>Alexa Fluor™ dyes</span> with their <span>highly specific</span> and <span>sensitive affinity reagents</span> for developing <span>high-brightness, photostable</span>, and <span>high-specificity antibody conjugates</span> specific to Identifyn's™ commercial applications of <span>immunocytochemistry for complete oncological cellular pathways</span> in biopharmaceutical space.</p>
                                <p><span>Alexa Fluor™ dyes</span> offer <span>high Extinction coefficients</span> (strong absorption), <span>greater Quantum yields</span> (more emitted light), <span>larger Stoke's shift</span> (better excitation and emission separation), and <span>comparatively amplified brightness with superior photostability</span> covering the <span>complete spectrum</span> with their excitation and emission <span>wavelengths between 350 nm to 850nm</span>. Alexa Fluor™ dyes are strongly <span>suited for microscopy, immunocytochemistry, and other high-sensitivity protein detection methods</span>, such as labeling and biomolecule localization within or on the cells, due to their <span>high solubility in water and chemical stability in various solvents and buffers</span> within a <span>wide range of pHs</span>.</p>
                                <p>Identifyn's™ <span>proprietary conjugation</span> to <span>highly cross-absorbed and affinity-purified secondary antibodies</span> ensures our Alexa Fluor™ products are <span>specifically adapted</span> to provide the highest <span>quality imaging</span> in all forms of microscopy from <span>widefield to confocal to super resolution applications</span>.</p>
                                <p><span>Identifyn's™ Alexa Fluor™ proprietary conjugates</span> have also been <span>validated in STORM applications</span> for photostability and "blinking" across the spectrum (405 - 750nm), using <span>Identifyn's™ STORM-esB™ Buffers</span>, and have shown to be <span>robust</span> in their <span>photostability</span> at increased laser excitation levels necessary for this application with <span>extended working time and decreased photosensitivity</span>.</p>
                            </div>
                        </div>
                        <div className="right-container">
                            <figure>
                                <img src={toAbsoluteUrl("/media/thermo.png")} alt="" className='bruker-hero-img' />
                            </figure>
                        </div>
                    </div>
                    <div className="container acquifer-section-two-container thermo-second-container">
                        <div className="left-container">
                            <div className="card2" style={{ height: '238px' }}>
                                <h3>Identifyn™ SRM Alexa Fluor™ Proprietary Conjugates Excel in Super Resolution Microscopy</h3>
                            </div>
                            <div className="card1" style={{ height: '438px' }}>
                                <h3>Identifyn™ SRM Alexa Fluor™ Provides High Brightness – High Contrast Visualization of Oncological Proteins on and within Cells  </h3>
                            </div>
                        </div>
                        <div className="right-container">
                            <div className="card1" style={{ height: '338px' }}>
                                <h3>Identifyn™ SRM Alexa Fluor™ Offers Comparatively Higher Chemical Stability to Solvents, Buffers, and pHs  </h3>
                            </div>
                            <div className="card2" style={{ height: '338px' }}>
                                <h3>Identifyn™ SRM Alexa Fluor™ Enables Multiplex Detection and Colocalization of Proteins with Larger Stoke’s Shift </h3>
                            </div>
                        </div>
                    </div>
                    <div className="cards-container">
                        <h3>Identifyn™ SRM Alexa Fluor™ Secondary Antibody Conjugates
                                Excel in Optical Applications!</h3>
                        <div className="cards-wrapper">
                            {applications.map((item) => {
                                return <div className="card">
                                    <h5>{item.name}</h5>
                                    <div className="img-container">
                                        <img src={item.image} alt="" width={'100%'} height={'100%'} style={{borderRadius:'12px'}} />
                                    </div>
                                    <h6>{item.subName}</h6>
                                </div>
                            })}
                        </div>
                    </div>
                    <div className="graphs-container">
                        <h3>Identifyn™ SRM Alexa Fluor™ Dyes</h3>
                        <div className="graphs-wrapper">
                            {/* <img src={toAbsoluteUrl("/media/flourescence/Default.svg")} alt="" /> */}
                            <Graph data={dyeBtns} />
                            <div className="button-container">
                                <button className={`btn blue ${dyeBtns === 405 && 'active-blue active'}`} onClick={() => setDyebtns(405)}>405</button>
                                <button className={`btn green ${dyeBtns === 488 && 'active-green active'}`} onClick={() => setDyebtns(488)}>488</button>
                                <button className={`btn yellow ${dyeBtns === 532 && 'active-yellow active'}`} onClick={() => setDyebtns(532)}>532</button>
                                <button className={`btn orange ${dyeBtns === 555 && 'active-orange active'}`} onClick={() => setDyebtns(555)}>555</button>
                                <button className={`btn coral ${dyeBtns === 594 && 'active-coral active'}`} onClick={() => setDyebtns(594)}>594</button>
                                <button className={`btn pink ${dyeBtns === 647 && 'active-pink active'}`} onClick={() => setDyebtns(647)}>647</button>
                                <button className={`btn red ${dyeBtns === 680 && 'active-red active'}`} onClick={() => setDyebtns(680)}>680</button>
                                <button className={`btn dark-red ${dyeBtns === 750 && 'active-dark-red active'}`} onClick={() => setDyebtns(750)}>750</button>
                            </div>
                        </div>
                    </div>
                    <div className="dye-list-container">
                        <div className="dye-list-wrapper">
                            <img src={toAbsoluteUrl("/media/dyesList.png")} alt="" width={"100%"} height={'424px'} />
                            <h4>Identifyn™ Offers Whole IgG (H+L), F(ab')<sub>2</sub>, IgG Subclasses and Fc Specific Selections Across all our Fluorescent Products</h4>
                        <button className={`btn primary-btn`} onClick={() => {
                            navigate('/product/alexa-fluor-secondary')
                        }}>Go To Identifyn™ SRM Alexa Fluor™ Products </button>
                        </div>
                    </div>
                    <div className="container acquifer-section-four-container">
                        <h3>Identifyn™ ongoing commitment to improving fluorescence imaging applications employing the capabilities of Alexa Fluor™ dyes ensures the visualization and quantification of biomolecules with the lowest expressions in your targeted application, continuing our ongoing commitment to excellence for our academic and industry customers. </h3>
                    </div>
                </>
                    :
                    <>
                        <div className="container azure-container" style={{ marginBottom: '32px' }}>
                            <div className="left-container">
                                <div className="solutions-text">
                                    <h2>Antibody Development</h2>
                                    <p>Identifyn™ partners with ThermoFisher in developing highly target-specific and functionally stable Monoclonal and Recombinant antibody affinity reagents achieving high sensitivity detection of biomolecules in oncological cellular pathways. Validation via Identifyn's™ core expertise around microscopy-based immunocytochemistry for biopharmaceutical applications. Identifyn™ antibodies provide selective speciation in immunocytochemistry applications along with Multiplex Immunoassays, Fluorescence Immunoassays, and STORM assays.
                                        Identifyn™ is continually producing, validating, and bringing to market De Novo antibodies within complete pathways centered around more than 300 protein targets!
                                    </p>
                                </div>
                            </div>
                            <div className="right-container">
                                <figure>
                                    <img src={toAbsoluteUrl("/media/thermo.png")} alt="" />
                                    <figcaption style={{ fontSize: '12px', marginTop: '12px' }}>
                                        METHOD: Identifyn’s™ Alexa Fluor™ Goat anti-Mouse 647. Primary Antibody Monoclonal Against Mitochondria. Zeiss LSM 900 AiryScan Image By J. K. Bennett 2023.
                                    </figcaption>
                                </figure>
                            </div>
                        </div>
                        <div className="container acquifer-section-two-container">
                            <div className="left-container">
                                <div className="card1" style={{ height: '238px' }}>
                                    <h3 style={{ textAlign: 'center' }}>Mouse Monoclonal: Hybridoma-Based Monoclonal Antibodies</h3>
                                </div>
                                <div className="card2" style={{ height: '238px' }}>
                                    <h3 style={{ textAlign: 'center' }}>Adapted for Microscopy-based immunocytochemistry</h3>
                                </div>
                                <div className="card1" style={{ height: '388px' }}>
                                    <h3 style={{ textAlign: 'center' }}>Covers All the Proteins in Oncological Cellular Pathways DNA Damage & Repair, Cell Cycle, Cell Death, Mitochondria, and Cancer Growth & Spread</h3>
                                </div>
                            </div>
                            <div className="right-container">
                                <div className="card2" style={{ height: '928px' }}>
                                    <h3 style={{ textAlign: 'center' }}>Mouse Recombinant: mouse monoclonal antibodies engineered to possess a proprietary backbone from ThermoFisher™ for enhanced structural stability and minimized functional cross-reactivity with a choice of chimeric antibodies containing Fc regions from different species</h3>
                                </div>
                            </div>
                        </div>
                        <div className="cards-container">
                            <h3>Identifyn™ SRM Alexa Fluor™ Secondary Antibody Conjugates
                                Excel in Optical Applications!</h3>
                            <div className="cards-wrapper">
                                {applications.map((item) => {
                                    return <div className="card">
                                        <h5>{item.name}</h5>
                                        <div className="img-container"></div>
                                        <h6>{item.subName}</h6>
                                    </div>
                                })}
                            </div>
                        </div>
                        <div className="container acquifer-section-four-container">
                            <h3>Identifyn™ is committed to developing specific reagents for detecting biomolecules with the lowest expressions in the targeted oncological pathways towards our ongoing commitment to excellence for our academic and industry customers.</h3>
                        </div>
                    </>

                }
            </AboutContent>
            <Footer />
        </Layout>
    )
}

export default Index