import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { path } from "./path";

import React from 'react'

const Index = ({ meta }) => {
    const { pathname } = useLocation()
    if (meta) {
        const { title } = meta
        return (
            <Helmet>
                <title>{title} | Identifyn™️</title>
            </Helmet>
        )
    }
    const { title, description, author, robots } = path[pathname]
    return (
        <Helmet>
            <title>{title} | Identifyn™️</title>
            <meta name="description" content={description ? description : 'Identifyn™️'} />
            <meta property='og:title' content={`${title} | Identifyn™️`} />
            <meta property='og:description' content={description ? description : 'Identifyn™️'} />
            <meta name='author' content={author ? author : 'Identifyn™️'} />
            {robots && (
                <meta name="robots" content={robots} />
            )}
        </Helmet>
    )
}

export default Index
