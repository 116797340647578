export const wrapBoxWithColorClass  = (html) => {
    let bodyContent = html;

    const colorEndRegex = /\[startbox (\d+)\](.*?)\[endbox\]/gs;
    const startEndBoxRegex = /\[startbox\](.*?)\[endbox\]/gs;

    bodyContent = bodyContent.replace(colorEndRegex, (match, colorCode, content) => {
        return `<span class="color-${colorCode}">${content}</span>`;
    });

   bodyContent = bodyContent.replace(startEndBoxRegex, function(match, content) {
        return `<div class="wrapped-box" style="border: 1px solid #b2b2b2; padding: 12px; margin-bottom: 10px;"><p>${content}</p></div>`;
    });

    return bodyContent;
}

export const unwrapBoxWithColorClass = (html) => {
    let bodyContent = html;

    // Regex to match the <span class="color-<colorCode>"> and convert it back to the original [startcolor_<colorCode>]
    const colorSpanRegex = /<span class="color-(\d+)">(.*?)<\/span>/gs;
    
    bodyContent = bodyContent.replace(colorSpanRegex, (match, colorCode, content) => {
        return `[startbox ${colorCode}]${content}[endbox]`;
    });

    // Regex to match the <div class="wrapped-box"> and convert it back to [startbox]
    const boxDivRegex = /<div class="wrapped-box"[^>]*>(.*?)<\/div>/gs;

    bodyContent = bodyContent.replace(boxDivRegex, (match, content) => {
        // Strip out the <p><br></p> tags if present, assuming they were cleaned earlier.
        const cleanedContent = content.replace(/<p><br><\/p>/g, '');
        return `[startbox]${cleanedContent}[endbox]`;
    });

    return bodyContent;
};
