import React, { useContext } from 'react'
import Layout from '../../../components/layout'
import { AboutHeader, AboutContent } from './style'
import { toAbsoluteUrl } from '../../../utils/assetHelper'
import { Footer } from '../../../components/Footer'
import { AuthContext } from '../../../context/auth'
import { downloadPdf } from '../../../utils/downloader'
import stormcalibration from "./stormcalibration.pdf"

const Index = () => {
    const { width } = useContext(AuthContext)
    return (
        <Layout>
            <div className="container title-container bio-container">
                <span style={{ width: '44px', height: '44px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '12px', background: '#fff2e5', color: 'var(--primary-color)' }}>
                    <i class="fal fa-microscope"></i>
                </span>
                <AboutHeader>
                    <h1>STORM Calibration</h1>
                </AboutHeader>
                <button className='btn' style={{ background: '#FFF2E5', color: '#DC4405' }}
                    onClick={() => downloadPdf(stormcalibration, 'storm_calibration.pdf')}
                ><i className='fal fa-arrow-to-bottom' /> Download PDF</button>
            </div>
            <AboutContent>
                <div class="container acquifer-section-four-container">
                    <h3>Identifyn™ STORM Calibration Standard Operating Procedure (SOP) for Vutara VXL Dual Camera System
                    </h3>
                </div>
                <div class="container azure-section-four-container">
                    <h3 style={{ color: '#3f1bd0' }}>Calibration</h3>
                    <p>Identifyn's™ Bruker™ Vutara VXL Bi-plane with dual camera alignment and PSF (Point Spread Function) calibration are meticulously performed in an <a href="https://ibidi.com/chambered-coverslips/252--slide-8-well-high-glass-bottom.html" target='_blank'>8-well Ibidi chamber</a> using 100nm <a href="https://www.thermofisher.com/order/catalog/product/T7279" target='_blank'>TetraSpeck Beads</a> in PBS, ensuring the highest level of precision.</p>
                </div>
                <div class="container azure-section-four-container calibration-section-container">
                    <span style={{ width: '32px', height: '32px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '12px', background: '#FFF2E5', color: '#DC1E00', marginBottom: '16px' }}>
                        1
                    </span>
                    <h3>Select <span>Calibration WorkFlow</span> from the home page of the <span>SRX Software</span></h3>
                    <p>We selected the Configure Tab under Calibration WorkFlow, and the following parameters were chosen for each section:</p>
                </div>
                <div className="container services-section-two-container">
                    <div className="left-container">
                        <div className="card1" style={{height:'408px'}}>
                            <h4>Capture Configuration</h4>
                            <div className="points-container">
                                <div className="points">
                                    <img src={toAbsoluteUrl("/media/pointbullet.svg")} alt="" />
                                    <p>Number of Probes: 1 Selected</p>
                                </div>
                                <div className="points">
                                    <img src={toAbsoluteUrl("/media/pointbullet.svg")} alt="" />
                                    <p>Number of Simultaneous Probes: 1 Default</p>
                                </div>
                                <div className="points">
                                    <img src={toAbsoluteUrl("/media/pointbullet.svg")} alt="" />
                                    <p>Camera: Both Cameras Default</p>
                                </div>
                                <div className="points">
                                    <img src={toAbsoluteUrl("/media/pointbullet.svg")} alt="" />
                                    <p>Channel: Both channels Default</p>
                                </div>
                                <div className="points">
                                    <img src={toAbsoluteUrl("/media/pointbullet.svg")} alt="" />
                                    <p>Probe Capture Mode: Sequential Selected</p>
                                </div>
                                <div className="points">
                                    <img src={toAbsoluteUrl("/media/pointbullet.svg")} alt="" />
                                    <p>Z -Stack Capture Mode: Sequential Default</p>
                                </div>
                                <div className="points">
                                    <img src={toAbsoluteUrl("/media/pointbullet.svg")} alt="" />
                                    <p>Autofocus Drift Correction: On</p>
                                </div>
                            </div>
                        </div>
                        <div className="card2" style={{height:'384px'}}>
                            <h4>Camera Configuration</h4>
                            <div className="points-container">
                                <div className="points">
                                    <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
                                    <p>Recording Vertical Field of View: 100% Default</p>
                                </div>
                                <div className="points">
                                    <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
                                    <p>Widefield Camera Exposure Time: 100ms Selected</p>
                                </div>
                                <div className="points">
                                    <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
                                    <p>Super Resolution Camera Exposure Time: 20ms Selected</p>
                                </div>
                                <div className="points">
                                    <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
                                    <p>Widefield Camera Binning: 1 Default</p>
                                </div>
                                <div className="points">
                                    <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
                                    <p>Per-Probe Exposure Time: Selected</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="right-container">
                        <div className="card2" style={{height:'408px'}}>
                            <h4>Microscope Configuration</h4>
                            <div className="points-container">
                                <div className="points">
                                    <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
                                    <p>Bi-Plane Module: 635nm dichroic Selected</p>
                                </div>
                                <div className="points">
                                    <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
                                    <p>Objective: Silicon Selected</p>
                                </div>
                                <div className="points">
                                    <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
                                    <p>Stage Insert: Rectangular Selected</p>
                                </div>
                                <div className="points">
                                    <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
                                    <p>Course Focus: 8-well chamber Selected</p>
                                </div>
                                <div className="points">
                                    <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
                                    <p>Dichroic: SuperRes Selected</p>
                                </div>
                            </div>
                        </div>
                        <div className="card1"  style={{height:'384px'}}>
                            <h4>Probe Configuration</h4>
                            <div className="points-container">
                                <div className="points">
                                    <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
                                    <p>Predefined Probe: Calibration Probe 640nm (635nm Dichroic) Selected</p>
                                </div>
                                <div className="points">
                                    <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
                                    <p>Photoactivation/Imaging Laser: 0% Selected</p>
                                </div>
                                <div className="points">
                                    <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
                                    <p>Exposure Time: 20ms Selected</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container azure-section-four-container calibration-section-container">
                    <span style={{ width: '32px', height: '32px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '12px', background: '#FFF2E5', color: '#DC1E00', marginBottom: '16px' }}>
                        2
                    </span>
                    <h3>Select <span>Align Select</span> Tab under <span>Calibration WorkFlow</span></h3>
                </div>
                <div className="container services-section-two-container" style={{ gridTemplateColumns: '1fr' }}>
                    <div className="card2">
                        <div className="points-container">
                            <div className="points">
                                <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
                                <p>The sample is focused on the Widefield View Mode</p>
                            </div>
                            <div className="points">
                                <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
                                <p>Widefield 640nm: 0.1X Neutral Density Filter is selected at a power of 0.5%</p>
                            </div>
                            <div className="points">
                                <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
                                <p>Exposure Time: 100ms Selected</p>
                            </div>
                            <div className="points">
                                <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
                                <p>SML View Mode: Selected</p>
                            </div>
                            <div className="points">
                                <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
                                <p>SuperRes (640nm): 0.1X Neutral Density Filter is selected at a power of 7%</p>
                            </div>
                            <div className="points">
                                <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
                                <p>Exposure Time: 20ms Selected</p>
                            </div>
                            <div className="points">
                                <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
                                <p>The Advanced Tool remains at Default Values for both the Widefield and SML View Modes</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container azure-section-four-container calibration-section-container">
                    <span style={{ width: '32px', height: '32px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '12px', background: '#FFF2E5', color: '#DC1E00', marginBottom: '16px' }}>
                        3
                    </span>
                    <h3>Select the <span>Align Record</span> Tab under <span>Calibration WorkFlow</span></h3>
                </div>
                <div className="container services-section-two-container" style={{ gridTemplateColumns: '1fr' }}>
                    <div className="card1">
                        <h4>Capture Parameters</h4>
                        <div className="points-container">
                            <div className="points">
                                <img src={toAbsoluteUrl("/media/pointbullet.svg")} alt="" />
                                <p>Frames: 30 Default</p>
                            </div>
                            <div className="points">
                                <img src={toAbsoluteUrl("/media/pointbullet.svg")} alt="" />
                                <p>Z Positions: 1 using Steps: 0.1um Default</p>
                            </div>
                            <div className="points">
                                <img src={toAbsoluteUrl("/media/pointbullet.svg")} alt="" />
                                <p>Laser Power: From Select</p>
                            </div>
                        </div>
                        <p>We start the Recording via the "play" button.</p>
                    </div>
                </div>
                <div class="container azure-section-four-container calibration-section-container">
                    <span style={{ width: '32px', height: '32px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '12px', background: '#FFF2E5', color: '#DC1E00', marginBottom: '16px' }}>
                        4
                    </span>
                    <h3>Select the <span>Align Planes</span> Tab under <span>Calibration WorkFlow</span></h3>
                </div>
                <div className="container services-section-two-container" style={{ gridTemplateColumns: '1fr' }}>
                    <div className="card2">
                        <p><span>Select AutoAlign:</span> We, as the knowledgeable operator, select the tab for PSF and the appropriate color from the drop-down menu, depending on the Identifyn™ SRM Alexa Fluor™ dye we intend to image. The reference plane is selected by default and is the first probe and the first plane. We followed by selecting plane 2, which we auto aligned with the reference plane.</p>
                        <p>If calibration of more than one dye is needed, we select planes 1 and 2 and auto-align them to the reference plane, which is dye one, plane one.</p>
                        <p><span>The SRX software plays a crucial role in determining alignment quality, categorizing it as Poor, Fair, or Good. This automated feature ensures that the system is calibrated to the highest possible standard, enhancing the accuracy of the imaging results.</span></p>
                        <p>Identifyn™ calibrates to "Good" alignment quality whenever possible but will not accept "Poor." Some dyes will yield "Fair" due to their wavelength and other chemistries, which are acceptable under certain conditions.</p>
                    </div>
                </div>
                <div class="container azure-section-four-container calibration-section-container">
                    <span style={{ width: '32px', height: '32px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '12px', background: '#FFF2E5', color: '#DC1E00', marginBottom: '16px' }}>
                        5
                    </span>
                    <h3>Select <span>PSF Select</span> Tab under <span>Calibration WorkFlow</span></h3>
                </div>
                <div className="container services-section-two-container" style={{ gridTemplateColumns: '1fr' }}>
                    <div className="card1">
                        <p style={{ marginBottom: '16px' }}>We examine the field of view in X and Y for nonoverlapping, well-separated 4-10 beads.</p>
                        <div className="points-container">
                            <div className="points">
                                <img src={toAbsoluteUrl("/media/pointbullet.svg")} alt="" />
                                <p>SuperRes (640nm): 0.1X Neutral Density Filter is Selected at a Power of 7%</p>
                            </div>
                            <div className="points">
                                <img src={toAbsoluteUrl("/media/pointbullet.svg")} alt="" />
                                <p>Exposure Time: 20ms Selected</p>
                            </div>
                            <div className="points">
                                <img src={toAbsoluteUrl("/media/pointbullet.svg")} alt="" />
                                <p>The Advanced tool remains at default values.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container azure-section-four-container calibration-section-container">
                    <span style={{ width: '32px', height: '32px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '12px', background: '#FFF2E5', color: '#DC1E00', marginBottom: '16px' }}>
                        6
                    </span>
                    <h3>Select the <span>PSF Record</span> Tab under <span>Calibration WorkFlow</span></h3>
                </div>
                <div className="container services-section-two-container" style={{ gridTemplateColumns: '1fr' }}>
                    <div className="card2">
                        <h4>Capture Parameters:</h4>
                        <div className="points-container">
                            <div className="points">
                                <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
                                <p>Frames: 30 Default</p>
                            </div>
                            <div className="points">
                                <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
                                <p>Z positions: 1 using Steps: 0.1um Default</p>
                            </div>
                            <div className="points">
                                <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
                                <p>Laser Power: "From Select"</p>
                            </div>
                        </div>
                        <p>We start the Recording via the "play" button.</p>
                    </div>
                </div>
                <div class="container azure-section-four-container calibration-section-container">
                    <span style={{ width: '32px', height: '32px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '12px', background: '#FFF2E5', color: '#DC1E00', marginBottom: '16px' }}>
                        7
                    </span>
                    <h3>Select the <span>PSF Isolate</span> Tab under <span>Calibration WorkFlow</span></h3>
                </div>
                <div className="container services-section-two-container" style={{ gridTemplateColumns: '1fr' }}>
                    <div className="card1">
                        <p style={{ marginBottom: '16px', color: '#1e1e1e', fontSize: '20px' }}>We select at least 4 nonoverlapping, well-spaced beads to provide maximum measurements without interference from proximal signals.</p>
                    </div>
                </div>
                <div class="container azure-section-four-container calibration-section-container">
                    <span style={{ width: '32px', height: '32px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '12px', background: '#FFF2E5', color: '#DC1E00', marginBottom: '16px' }}>
                        8
                    </span>
                    <h3>Select the <span>PSF Save</span> Tab under <span>Calibration WorkFlow</span></h3>
                </div>
                <div className="container services-section-two-container" style={{ gridTemplateColumns: '1fr' }}>
                    <div className="card2">
                        <p>We Start the PSF Measurements via the "Play" Button</p>
                        <p><span>The SRX software plays a crucial role in determining PSF quality, categorizing it as Poor, Fair, or Good. This automated feature ensures that the system is calibrated to the highest possible standard, enhancing the accuracy of the imaging results.</span></p>
                        <p>Identifyn™ calibrates to "Good" PSF values whenever possible.</p>
                        <p>but will not accept "Poor." Some dyes will yield "Fair" due to their wavelength and other chemistries, which are acceptable under certain conditions.</p>
                        <p>Measured PSF Parameter Values will be Displayed as Follows: The values shown here are from a measurement taken as an example and are variable for each calibration based on the TetraSpec Bead calibration sample, based on the intended dyes imaged by Identifyn™.</p>
                    </div>
                </div>
                <div class="container azure-section-four-container">
                <h4 style={{ color: '#3f1bd0' }}>Example PSF Values for Identifyn™ SRM Alexa Fluor™ 647</h4>
                <div className="calibration-table-container">
                    <div className="column">
                        <h5></h5>
                        <h5>ZFWHM</h5>
                        <h5>Tilt</h5>
                        <h5>S/N</h5>
                    </div>
                    <div className="column">
                        <h5>Plane 1</h5>
                        <h5><span>816nm</span></h5>
                        <h5><span>0.5px</span></h5>
                        <h5><span>553</span></h5>
                    </div>
                    <div className="column">
                        <h5>Plane 2</h5>
                        <h5><span>806nm</span></h5>
                        <h5><span>0.9px</span></h5>
                        <h5><span>731</span></h5>
                    </div>
                </div>
                </div>
                <div class="container acquifer-section-four-container">
                    <h3 style={{ fontSize: '24px' }}>SRX Software Rendered a “Good” PSF Measurement
                    </h3>
                </div>
                <div className="container services-section-two-container" style={{ gridTemplateColumns: '1fr' }}>
                    <div className="card2 card3">
                        <p><span style={{ color: '#1e1e1e' }}>Disclaimer:</span> Due to the high variability in microscopy equipment and applications, the following method serves as both the Identifyn™ internal Standard Operating Procedures, may serve as a general guideline for other similar users, and is not representative of the totality of Identifyn's™ microscopy methods.</p>
                        <p>© Copyright 2023-2024 Identifyn™ LLC & GMD12™ LLC, All rights reserved
                        </p>
                    </div>
                </div>
            </AboutContent>
            <Footer />
        </Layout>
    )
}

export default Index