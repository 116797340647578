import React, { useContext, useEffect, useState } from 'react'
import "./style.scss"
import { toAbsoluteUrl } from '../../../utils/assetHelper';
import { useNavigate, useLocation } from 'react-router-dom';

const Index = ({setPathwayModal, pathwayModal}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [doublePathwayModal, setDoublePathwayModal] = useState(false)


  useEffect(() => {
    if (location.state && location.state.dsb) {
      setDoublePathwayModal(true)
    }
  },[])



if(doublePathwayModal){
  return (
    <div className="small-modal-wrapper">
      <div className="small-modal-content pathway-modal" style={{width:'728px'}}>
      <div style={{position:'relative',justifyContent:'center', display:'flex', width:'100%'}}>
        <span style={{color:'#9a9a9a', top:0, right:0}} className='close' onClick={() => setPathwayModal(false)}><i className="fas fa-times" style={{fontSize:'20px'}}></i></span>
        <span className='back' onClick={() => setDoublePathwayModal(false)}><i className="fal fa-angle-left" style={{fontSize:'20px'}}></i></span>
        <h2 style={{marginTop:'0px', marginBottom:'0px'}}>Which Double Strand Break Pathway <br/> Are You Interested In?</h2>
        </div>
        <div className="pathways-wrapper">
        <div className="pathway" style={{backgroundImage: `url(${toAbsoluteUrl('/media/pathways/hr.png')})`, backgroundSize:'cover', cursor: 'pointer'}} 
        onClick={() => {
          navigate("/product/pathways/dsb-hr")
        }}
        >
                <div className="img-container">
                <img src={toAbsoluteUrl('/media/pathways/hr.png')} alt="" />
                </div>
                <div className="text-content">
                  <h4>Homologous Recombination (HR)</h4>
                </div>
              </div>
              <div className="pathway" style={{backgroundImage: `url(${toAbsoluteUrl('/media/pathways/nhej.png')})`, backgroundSize:'cover', cursor: 'pointer'}}
                   onClick={() => {
                    navigate("/product/pathways/dsb-nh")
                  }}
              >
                <div className="img-container">
                <img src={toAbsoluteUrl('/media/pathways/nhej.png')} alt="" />
                </div>
                <div className="text-content">
                  <h4>Non-Homologous End Joining (NHEJ)</h4>
                </div>
              </div>
        </div>
      </div>
    </div>
  )
  }

  return (
    <div className="small-modal-wrapper">
      <div className="small-modal-content pathway-modal" style={{width:'728px', padding:'32px'}}>
        <div style={{position:'relative',justifyContent:'center', display:'flex', width:'100%'}}>
        <span style={{color:'#9a9a9a', top:0, right:0}}  className='close' onClick={() => setPathwayModal(false)}><i className="fas fa-times" style={{fontSize:'20px'}}></i></span>
        <h2 style={{marginTop:0, marginBottom:0, width:'90%'}}>Which DNA Damage Pathway Are You Interested In?</h2>
        </div>
        <div className="pathways-wrapper">
        <div className="pathway" style={{backgroundImage: `url(${toAbsoluteUrl('/media/pathways/ssb.png')})`, backgroundSize:'cover', cursor: 'pointer'}}
          onClick={() => setDoublePathwayModal(true)}
        >
                <div className="img-container">
                <img src={toAbsoluteUrl('/media/pathways/dsb.png')} alt="" />
                </div>
                <div className="text-content">
                  <h4>Double Strand Breaks (DSBs)</h4>
                </div>
              </div>
              <div className="pathway" style={{backgroundImage: `url(${toAbsoluteUrl('/media/pathways/ssb.png')})`, backgroundSize:'cover', cursor: 'pointer'}} 
              onClick={() => navigate("/product/pathways/ssb")}
              >
                <div className="img-container">
                <img src={toAbsoluteUrl('/media/pathways/ssb.png')} alt="" />
                </div>
                <div className="text-content">
                  <h4>Single Strand Breaks(SSBs)</h4>
                </div>
              </div>
        </div>
      </div>
    </div>
  )
}

export default Index