import React, { useState, useEffect, useContext, useRef } from 'react'
import "./style.scss"
import { toAbsoluteUrl } from '../../../utils/assetHelper';
import {isVideoFile, getMp4Url, isGif} from '../../../utils/videoHelper'
import { wrapBoxWithColorClass } from '../../../utils/htmlConversion';
import 'react-quill/dist/quill.snow.css';
import { AuthContext } from "../../../context/auth";
import { getProductFromListApi } from '../../../api'
import {
  Magnifier,
  GlassMagnifier,
  MOUSE_ACTIVATION,
  TOUCH_ACTIVATION
} from "@vanyapr/react-image-magnifiers";
import ImageViewer from "awesome-image-viewer";



const Index = ({ setOpenMethodologyModal, selectedProduct, setSelectedProduct, modalName, setModalName, galleryView, setPreviewProduct, setOpenItemPreviewModal }) => {
  const { width, setIsLoading, isLoading } = useContext(AuthContext)
  const [methodologyData, setMethodologyData] = useState(null)


  useEffect(() => {
    if (selectedProduct && modalName) {
      if (selectedProduct?.metadata?.images?.find((item) => modalName === 'Multiplexed Protein Expression Testing' ? item?.name === 'Multiplexed Protein Expression and Host Cell Protein' : modalName === 'Wide Field' ? item.name === 'Widefield' : modalName === 'Super Resolution / Single Molecule - STORM' ? item.name === 'Super Resolution - Single Molecule STORM' : modalName === 'Super Resolution PALM' ? item.name === 'Super Resolution - PALM' : modalName === 'Super Resolution SIM' ? item.name === 'Super Resolution - SIM' : modalName === 'Super Resolution SIM²' ? item.name === 'Super Resolution - SIM²' : modalName === 'Super Resolution Airyscan' ? item.name === 'Super Resolution - Airyscan' : item.name === modalName)) {
        setMethodologyData(selectedProduct?.metadata?.images?.find((item) => modalName === 'Multiplexed Protein Expression Testing' ? item?.name === 'Multiplexed Protein Expression and Host Cell Protein' : modalName === 'Wide Field' ? item.name === 'Widefield' : modalName === 'Super Resolution / Single Molecule - STORM' ? item.name === 'Super Resolution - Single Molecule STORM' : modalName === 'Super Resolution PALM' ? item.name === 'Super Resolution - PALM' : modalName === 'Super Resolution SIM' ? item.name === 'Super Resolution - SIM' : modalName === 'Super Resolution SIM²' ? item.name === 'Super Resolution - SIM²' : modalName === 'Super Resolution Airyscan' ? item.name === 'Super Resolution - Airyscan' : item.name === modalName))
      }
    }

  }, [modalName])

  const props = { zoomPosition: 'left', zoomStyle: 'background-color: white;', scale: 1, img: methodologyData?.url || toAbsoluteUrl("/media/methodologyplaceholder.png") }

  const getProductFromList = (id) => {
    setIsLoading(true)
    getProductFromListApi(id).then(res => {
      if (res && res.data) {
        setPreviewProduct(res?.data?.products[0])
        setIsLoading(false)
        setOpenItemPreviewModal(true)
      }
      setIsLoading(false)
    }).catch(err => {
      console.error(err)
      setIsLoading(false)
    })
  }


  useEffect(() => {
    if (methodologyData?.url) {
      setIsLoading(true)
    }
  }, [methodologyData])


  return (
    <div className="small-modal-wrapper">
      <div className={`small-modal-content methodology-modal-content`}>
        <span className='close close-modal-btn' onClick={() => {
          setOpenMethodologyModal(false)
          setModalName(null)
          // setSelectedProduct(null)
        }} style={{ color: 'var(--primary-color)' }} ><i className="fas fa-times"></i></span>
        <div className="img-container">
          {isLoading && <h4>Loading...</h4>}
          {
          isVideoFile(methodologyData) ?
          <video src={getMp4Url(methodologyData?.url)} controls loop autoPlay={true} muted={true} playsInline
          style={{width:'100%'}}
          onLoadedData={() => {
            setIsLoading(false)
          }}
         />
          :
          isGif(methodologyData) ?
          <img src={methodologyData?.url} alt="methodology" style={{width:'100%'}} 
          onLoad={() => {
            setIsLoading(false)
          }}
          />
          :
          width > 1200 ? <GlassMagnifier
            className="input-position"
            style={{ visibility: isLoading ? 'hidden' : 'visible' }}
            imageSrc={methodologyData && methodologyData?.url ? `${process.env.REACT_APP_GALLERY_URL}800:850:${methodologyData?.url?.split('.com/')[1]}` : toAbsoluteUrl("/media/methodologyplaceholder.png")}
            largeImageSrc={methodologyData && methodologyData?.url ? `${process.env.REACT_APP_GALLERY_URL}1200:1250:${methodologyData?.url?.split('.com/')[1]}` : toAbsoluteUrl("/media/methodologyplaceholder.png")}
            allowOverflow={false}
            magnifierSize={'20%'}
            magnifierBorderSize={3}
            magnifierBorderColor={'rgba(255, 255, 255, .5)'}
            square={true}
            onImageLoad={() => {
              setIsLoading(false)
            }}
          />
            //  <ReactImageZoom {...props} />
            :
            <Magnifier
              className="input-position"
              style={{ visibility: isLoading ? 'hidden' : 'visible' }}
              imageSrc={methodologyData?.url ? `${process.env.REACT_APP_GALLERY_URL}800:850:${methodologyData?.url?.split('.com/')[1]}` : toAbsoluteUrl("/media/methodologyplaceholder.png")}
              largeImageSrc={methodologyData?.url ? `${process.env.REACT_APP_GALLERY_URL}1600:1650:${methodologyData?.url?.split('.com/')[1]}` : toAbsoluteUrl("/media/methodologyplaceholder.png")}
              mouseActivation={MOUSE_ACTIVATION.CLICK}
              touchActivation={TOUCH_ACTIVATION.CLICK}
              dragToMove={true}
              onImageLoad={() => {
                setIsLoading(false)
              }}
            />
            // <img src={methodologyData?.url || toAbsoluteUrl("/media/methodologyplaceholder.png")} alt="" />
          }
          {galleryView && <button className='btn view-product' style={{ margin: 0, width: 'max-content', padding: '6px 10px', maxHeight: 'max-content' }} onClick={() => {
            getProductFromList(methodologyData?.product_id)
          }}><i className='fas fa-eye'></i> View Product</button>
          }
         {!isVideoFile(methodologyData) && <button className='btn view-full-image' style={{ margin: 0, width: 'max-content', padding: '6px 10px', maxHeight: 'max-content' }} onClick={() => {
            new ImageViewer({
              images: [{
                mainUrl: methodologyData?.url
              }],
              closeOnEsc: true
            })
          }}
          >View Image</button>
        }
        </div>
        <div className="methods-text">
          <h3>{modalName ? modalName : ""}
          </h3>
          <div style={{ overflowY: 'auto', height: `${methodologyData?.methods ? 'auto' : width < 490 ? '220px' : '350px'}` }} className='ql-snow'>
            {methodologyData?.methods ? <p className='method ql-editor' style={{ height: ((methodologyData?.name !== 'Multiplexed Protein Expression and Host Cell Protein') && (methodologyData?.name !== 'Multiplexed Protein Expression Testing') && (methodologyData?.name !== 'Super Resolution - Single Molecule STORM')) && '380px' }} dangerouslySetInnerHTML={{ __html: methodologyData?.methods ? wrapBoxWithColorClass(methodologyData?.methods) : "No Methodology Available" }}></p>
              : <p style={{ padding: '12px 10px', background: '#FFF2E5', borderRadius: '12px', color: '#DC4405', fontWeight: 700 }}>Data Collection In Progress</p>
            }
          </div>
          <button className='btn transparent' onClick={() => {
            setOpenMethodologyModal(false)
            setModalName("")
            // setSelectedProduct(null)
          }}>Close</button>
        </div>
      </div>

    </div>
  )
}

export default Index
