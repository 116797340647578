import styled from "styled-components";


export const Wrapper = styled.div`
display: flex;
flex-direction: column;
gap: 20px;
padding: 110px 6rem 60px 6rem;


@media screen and (max-width:1100px){
    padding: 100px 1.2rem;
}


@media screen and (min-width:2160px){
padding-left: 25rem;
padding-right: 25rem;
}




.no-orders{
    width: 100%;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap:20px;

    span{
        width: 120px;
        height: 120px;
        font-size: 35px;
    }
    p{
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 28px;
text-align: center;
    }
}





h3{
        font-size: 20px;
        font-weight: 700;
        line-height: 28px;
        color:#1e1e1e;
    }


    h4{
font-size: 16px;
font-weight: 500;
line-height: 24px;
color:#1e1e1e;
}

h5{
font-size: 14px;
font-weight: 500;
line-height: 20px;
color:#1e1e1e;
}

h6{
font-size: 12px;
line-height: 16px;
font-weight: 400;
color:#9a9a9a;
}





.content{
    display: flex;
    justify-content: space-between;
    gap: 30px;
 
    @media screen and (max-width:1100px){
    flex-direction: column;
    justify-content: start;
}




.left-container{
  width: 67%;
  @media screen and (max-width:1100px){
    width: 100%;
}

.checkout-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;

.checkout-header{
    width: 100%;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: start;
}

.cards-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.card{
    width: 100%;
    border-radius: 20px;
background: #FFF;
box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.05);
display: flex;
padding: 20px;
gap: 20px;
flex-direction: column;

.header{

.header-btn-container{
    display: flex;
    align-items: center;
    gap: 20px;

    button{
        padding: 0;
    }
}

}


.address-container{
    display: flex;
    flex-direction: column;
    gap: 16px;

.address-card{
border-radius: 12px;
border: 1px solid #F1EEFC;
background:#FCFCFC;
padding: 16px;
display: flex;
justify-content: space-between;
gap: 10px;

.card-left-container{
    display: flex;
    gap: 8px;
    cursor: pointer;

.first-container{
    display: flex;
    padding: 6px;
    .selected-check{
        position: relative;
        width:24px;
        height: 24px;
        border-radius: 50%;
        border:2px solid #9a9a9a;
    }

    .selected-check-active{
        border:2px solid var(--primary-color);

        &::before{
            content: "";
            position: absolute;
            top: 4px;
            left: 4px;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background: var(--primary-color);
        }
    }
}    


.second-container{
    display: flex;
    flex-direction: column;
    gap: 8px;
}    

    h5,p{
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        color: #6c6a73;
    }

h5{
    font-weight: 500;
    color:#1E1E1E;
    display: flex;
    align-items: center;
    gap: 8px;
    span{
padding: 4px 8px;
display: flex;
align-items: center;
justify-content: center;
border-radius: 8px;
background: linear-gradient(0deg, rgba(63, 27, 208, 0.12) 0%, rgba(63, 27, 208, 0.12) 100%), #FFF;
color: #3f1bd0;
    }

@media screen and (max-width:1100px){ 
    flex-wrap: wrap;
}    

}

}

.card-right-container{

    .transparent{
        padding:0;
    }
}

}

.active-card{
    background-color: #F8F7FE;
    border:1px solid var(--primary-color)
}

.item{
width: 100%;
display: flex;
gap: 40px;
justify-content: space-between;
align-items: center;
padding: 16px;
border-radius: 20px;
background: #FFF;
box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.05);

@media screen and (max-width:1100px){
    flex-wrap: wrap;
}


.price-content{
    display: flex;
    flex-direction: column;
    gap: 2px;
    align-items: center;
}

.quantity-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2px;

.quantity{
border-radius: 12px;
border: 1px solid #F1EEFC;
background: #FCFCFC;
backdrop-filter: blur(19px);
padding: 10px 12px;
width: 70px;
text-align: center;
height: 40px;
font-size: 16px;
color: #1e1e1e;
font-weight: 400;
line-height: 28px;
display: flex;
justify-content: center;
align-items: center;
}

}

.item-content{
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 40%;

    @media screen and (max-width: 1000px) {
           width: 100%;
        }

    h4{
        color: #3f1bd0;
    }

    .item-details{
        display: flex;
        gap: 40px;
        align-items: center;


.details{
    display: flex;
    flex-direction: column;
    gap: 4px;
}

    }

}



}
}


hr{
    width: 100%;
    height: 1px;
    background-color: #EDEDED;
    border: none;
}


.three-column{
    display: flex;
    justify-content: space-between;
    gap: 20px;
    align-items: center;

@media screen and (max-width:1100px) {
    flex-direction: column;

    .more-detail{
        text-align: center;
    }
}

.more-detail-card{
width: 100%;
border-radius: 12px;
border: 1px solid #F1EEFC;
background: #FCFCFC;
backdrop-filter: blur(19px);
display: flex;
padding: 12px 16px;
flex-direction: column;
justify-content: center;
align-items: flex-start;
gap: 2px;


h5{
    color:#6c6a73;
    font-weight: 400;
}

h4{
    font-weight: 400;
}
}

.more-detail{
    h5{
    color:#6c6a73;
    font-weight: 400;
}

h4{
    font-weight: 400;
} 
}




}

.input-container{
    display: flex;
    flex-direction: column;
    gap:5px;
    width: 100%;

    label{
        font-size: 12px;
        color:#9a9a9a;
    }

    h4{
        font-size: 16px;
        font-weight: 700;
        line-height: 28px;
    }

input {
    height: 48px;
    width: 100%;
}

.select-option{
                padding: 5px 16px;
                border-radius: 12px;
                border: 2px solid #F1EEFC;
                background: #FCFCFC;
                width: -webkit-fill-available;

            .select-active{
            border: 2px solid #DC4405;

           .containers > span{
                color:var(--primary-color) !important;
                font-weight: 700;
            }
            }

            .containers {
                font-weight: 500;
                display: block;
                position: relative;
                padding-left: 35px;
                padding-top: 10px;
                margin-bottom: 12px;
                cursor: pointer;
                font-size: 16px;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                color: #0b161d;
            }

            /* Hide the browser's default radio button */
            .containers input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                left: 0;
                top:0;
                width: 100%;
                z-index: 9;
            }

            span{
                color:#1E1E1E;
                font-size: 16px;
            }

            /* Create a custom radio button */
            .checkmark {
                position: absolute;
                top: 0;
                left: 0;
                height: 21px;
                width: 21px;
                background-color: transparent;
                border-radius: 50%;
                border: 2px solid rgb(215, 76, 16);
                transform: translateY(10px);
            }

            /* On mouse-over, add a grey background color */
            .containers:hover input~.checkmark {
                background-color: var(--primary-color);
            }

            /* When the radio button is checked, add a blue background */
            .containers input:checked~.checkmark {
                background-color: #fff;
            }

            /* Create the indicator (the dot/circle - hidden when not checked) */
            .checkmark:after {
                content: "";
                position: absolute;
                display: none;
            }

            /* Show the indicator (dot/circle) when checked */
            .containers input:checked~.checkmark:after {
                display: block;
            }

            /* Style the indicator (dot/circle) */
            .containers .checkmark:after {
                top: 3.5px;
                left: 3.5px;
                width: 10.5px;
                height: 10.5px;
                border-radius: 50%;
                background: var(--primary-color);
            }

            .containers:hover input:checked~.checkmark:after {
                top: 3.5px;
                left: 3.5px;
                width: 10.5px;
                height: 10.5px;
                border-radius: 50%;
                background: var(--primary-color);
            }


        }

}

.payment-container{
    display: flex;
    flex-direction: column;
    gap: 16px;

.two-column{
    width: 100%;
    display: flex;
    gap:24px;

    @media screen and (max-width:1100px){
        flex-direction: column;
        gap: 16px; 
    }
}

.card-number-element{
border-radius: 12px;
border: 1px solid #F1EEFC;
background: #FCFCFC;
backdrop-filter: blur(19px);
/* display: flex; */
height: 74px;
padding: 25px 16px;
align-items: center;
gap: 8px;
align-self: stretch;
/* color:  #28272E; */
white-space: nowrap;
outline: unset !important;
width: 100%;

&:focus{
    border:1px solid #DC4405;
}

}


}
}
}

}

.right-container{
border-radius: 20px;
background: #FFF;
box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.05);
width: 33%;
padding: 16px 20px 20px 20px;
display: flex;
flex-direction: column;
gap: 20px;
align-items: start;
height: max-content;

@media screen and (max-width:1100px){
    width: 100%;
}

.btn{
    width: 100%;
}

.price-summary{
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;

.price{
    display: flex;
    justify-content: space-between;
}

hr{
    width: 100%;
    height: 1px;
    background-color: #EDEDED;
    border: none;
}


}



}

}

`