import React, { useContext, useState } from 'react'
import "./style.scss"
import { AuthContext } from "../../../context/auth";
import { toAbsoluteUrl } from '../../../utils/assetHelper';

const Index = () => {
  const { setTeamModal, teamModal} = useContext(AuthContext)
  const galleryUrl = `${process.env.REACT_APP_GALLERY_URL}500:550`

  return (
    <div className="small-modal-wrapper">
      <div className={`small-modal-content team-modal-content ${teamModal.team.type}`}>
        <span className='close' onClick={() => setTeamModal(null)}><i className="fas fa-times"></i></span>
        <div className="img-container" >
          <img style={{height: '100%', width:'auto'}} src={teamModal.team.image_layout ?  galleryUrl + `:${teamModal.team.image?.split('.com/')[1]}`  :  !teamModal.team.image_layout && !teamModal.team.image ? toAbsoluteUrl('/media/avatar_blank.png') : galleryUrl + `:${teamModal.team.imageFull?.split('.com/')[1]}`} alt="" />
        </div>  
        <div className="team-text">
          <h2>{teamModal.team.name}</h2>
          <h5>{teamModal.team.position_1} <br/> {teamModal.team.position_2}</h5>
          <div style={{overflowY: 'auto'}}>
          <p style={{textAlign:'left', height: teamModal.team.position_2 && '330px'}}>{teamModal.team.description}</p>
          </div>
          <button className='btn transparent' onClick={() => {  
            setTeamModal(false)
          }}>Close</button>
        </div>
      </div>
    </div>
  )
}

export default Index