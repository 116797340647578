import React from 'react'
import "./styles.scss"

const Shapes = ({
  className='',
  style,
  shapeType = 1,
  onClick = () => {},
  smaller=false,
  bigger = false,
  selectedPhoto
}) => {
  return (
   !smaller && !bigger ?
    ( <>
      <div className={`shape ${className}`} style={style} onClick={onClick}>
        <svg height="0px" width="0px">
          <defs>
            <clipPath id={`svgPath-${shapeType}-text`}>
             {shapeType == 1 ?  <path d="M132.234 97.5959C122.379 158.116 100.748 164.264 70.3215 158.116C39.8946 151.968 0.333496 135.251 0.333496 77.6156C0.333496 27.665 36.7198 1.12679e-05 76.8963 0C117.073 -1.12679e-05 139.765 51.345 132.234 97.5959Z" fill="#C4C4C4"/>
            :shapeType == 2 ?  <path d="M156.718 90.983C127.805 166.85 119.243 163.62 69.6164 156.641C35.8374 151.891 9.98139 163.62 0.806957 90.983C-6.81713 30.6209 40.8994 0 88.3251 0C135.751 0 171.216 52.9393 156.718 90.983Z" fill="#C4C4C4"/>
            : shapeType == 3 ? <path d="M155.778 79.7568C155.778 159.514 132.648 154.982 66.8917 159.514C1.13516 164.045 0.22214 136.451 0.222168 96.0707C0.222193 59.8176 14.8609 0 66.8917 0C118.923 0 155.778 24.4708 155.778 79.7568Z" fill="#C4C4C4"/>
            : shapeType == 4 ? <path d="M154.137 100.285C137.164 142.071 109.669 160 72.7053 160C35.7419 160 21.052 131.177 3.07186 66.8564C-15.6171 3.98498e-06 55.6899 -0.000214847 89.6377 7.70248e-07C123.586 0.000216387 177.983 41.5809 154.137 100.285Z" fill="#C4C4C4"/>
            : shapeType == 5 ? <path d="M151.897 94.2602C136.709 133.538 71.0988 159 38.0239 159C4.94891 159 -16.6902 109.971 16.7234 62.8378C34.8222 37.3075 36.4683 -0.0062466 94.1827 -0.00585937C151.897 -0.00547215 173.235 39.0794 151.897 94.2602Z" fill="#C4C4C4"/>
            : shapeType == 6 ?  <path d="M158.319 90.8731C149.591 159.787 63.7891 143.705 29.3204 143.705C-5.14825 143.705 -11.7325 94.8198 23.089 56.9316C58.6903 18.195 45.2579 0.888672 90.2582 0.888672C135.258 0.888672 167.915 15.0967 158.319 90.8731Z" fill="#C4C4C4"/>
            : shapeType == 7 ? <path d="M131.522 82.4003C154.675 147.613 116.782 149.537 65.287 158.271C13.7925 167.005 0.895905 120.443 0.594822 63.7902C0.421169 31.115 62.3939 -6.15792 91.5111 1.85678C120.628 9.87148 120.399 51.07 131.522 82.4003Z" fill="#C4C4C4"/>
            : shapeType == 8 ? <path d="M159.12 70.4009C156.534 122.143 175.732 149.031 89.9915 157.832C4.25089 166.632 -5.88393 108.979 2.47537 78.6099C12.9231 40.6533 37.89 38.8591 74.937 11.8113C111.984 -15.2365 162.421 4.34883 159.12 70.4009Z" fill="#C4C4C4"/>
            : shapeType == 9 ? <path d="M151.465 77.0717C149.375 118.826 145.305 153.459 86.2342 159.517C27.1634 165.575 -8.29627 113.326 2.31237 74.8129C12.1717 39.0202 63.7809 -11.8548 94.6607 2.48373C125.54 16.8223 153.352 39.3465 151.465 77.0717Z" fill="#C4C4C4"/>
            : shapeType == 10 ? <path d="M154.465 95.8121C141.79 156.994 91.8955 164.089 57.1125 151.758C22.3295 139.427 -15.9813 91.1311 7.62114 35.0806C24.4201 -4.81303 31.9197 -9.58958 85.0928 16.696C138.266 42.9816 172.162 10.3887 154.465 95.8121Z" fill="#C4C4C4"/>
            : shapeType == 11 ? <path d="M151.682 87.7419C143.129 140.215 123.93 160 81.1232 160C55.4655 160 59.7414 143.656 16.9788 104.086C-25.7838 64.5161 20.6527 0 63.4591 0C106.266 0 185.892 7.74194 151.682 87.7419Z" fill="#C4C4C4"/>
            : <path d="M149.109 76.3407C172.678 133.152 159.27 160 96.3557 160C58.6455 160 -26.9534 106.459 8.36499 67.0201C43.6834 27.5812 12.2651 0 75.1794 0C138.094 0 125.54 19.529 149.109 76.3407Z" fill="#C4C4C4"/>
            }
            </clipPath>
          </defs>
        </svg>
      </div>
    </>
    )
    : 
!smaller && bigger ? 
( <>
  <div className={`shape-big ${className}`} style={style} onClick={onClick}>
    <svg height="0px" width="0px">
      <defs>
        <clipPath id={`svgPath-${shapeType}-text`}>
         {shapeType == 1 ?  <path d="M 221.046875 136.632812 C 204.574219 221.363281 168.414062 229.96875 117.550781 221.363281 C 66.691406 212.753906 0.558594 189.351562 0.558594 108.660156 C 0.558594 38.730469 61.382812 0 128.542969 0 C 195.703125 0 233.636719 71.882812 221.046875 136.632812 Z M 221.046875 136.632812" fill="#C4C4C4"/>
        :shapeType == 2 ?  <path d="M 219.40625 127.375 C 178.925781 233.589844 166.941406 229.066406 97.464844 219.296875 C 50.171875 212.648438 13.972656 229.066406 1.128906 127.375 C -9.542969 42.871094 57.257812 0 123.65625 0 C 190.050781 0 239.703125 74.113281 219.40625 127.375 Z M 219.40625 127.375" fill="#C4C4C4"/>
        : shapeType == 3 ? <path d="M 223.679688 111.660156 C 223.679688 223.320312 190.46875 216.976562 96.050781 223.320312 C 1.628906 229.664062 0.320312 191.03125 0.320312 134.5 C 0.320312 83.746094 21.339844 0 96.050781 0 C 170.761719 0 223.679688 34.257812 223.679688 111.660156 Z M 223.679688 111.660156" fill="#C4C4C4"/>
        : shapeType == 4 ? <path  d="M 215.792969 140.398438 C 192.03125 198.898438 153.535156 224 101.789062 224 C 50.039062 224 29.472656 183.648438 4.300781 93.597656 C -21.863281 0 77.964844 0 125.492188 0 C 173.019531 0 249.175781 58.214844 215.792969 140.398438 Z M 215.792969 140.398438" fill="#C4C4C4"/>
        : shapeType == 5 ? <path d="M 212.65625 132.792969 C 191.394531 188.128906 99.539062 224 53.234375 224 C 6.929688 224 -23.367188 154.925781 23.414062 88.527344 C 48.75 52.558594 51.054688 -0.0078125 131.855469 -0.0078125 C 212.65625 -0.0078125 242.527344 55.054688 212.65625 132.792969 Z M 212.65625 132.792969" fill="#C4C4C4"/>
        : shapeType == 6 ?  <path d="M 221.648438 139.421875 C 209.425781 245.152344 89.304688 220.480469 41.046875 220.480469 C -7.207031 220.480469 -16.425781 145.476562 32.324219 87.347656 C 82.167969 27.914062 63.359375 1.363281 126.363281 1.363281 C 189.359375 1.363281 235.082031 23.160156 221.648438 139.421875 Z M 221.648438 139.421875" fill="#C4C4C4"/>
        : shapeType == 7 ? <path d="M 211.949219 116.085938 C 249.261719 207.957031 188.195312 210.667969 105.210938 222.972656 C 22.226562 235.277344 1.445312 169.679688 0.957031 89.867188 C 0.679688 43.835938 100.546875 -8.675781 147.472656 2.617188 C 194.394531 13.90625 194.023438 71.949219 211.949219 116.085938 Z M 211.949219 116.085938" fill="#C4C4C4"/>
        : shapeType == 8 ? <path d="M 222.769531 99.179688 C 219.148438 172.074219 246.023438 209.957031 125.988281 222.355469 C 5.953125 234.753906 -8.238281 153.53125 3.464844 110.746094 C 18.09375 57.273438 53.046875 54.746094 104.910156 16.640625 C 156.777344 -21.464844 227.390625 6.125 222.769531 99.179688 Z M 222.769531 99.179688" fill="#C4C4C4"/>
        : shapeType == 9 ? <path d="M 223.210938 107.898438 C 220.132812 166.355469 214.132812 214.84375 127.082031 223.324219 C 40.03125 231.804688 -12.226562 158.65625 3.40625 104.738281 C 17.9375 54.628906 93.992188 -16.597656 139.5 3.476562 C 185.007812 23.550781 225.992188 55.085938 223.210938 107.898438 Z M 223.210938 107.898438" fill="#C4C4C4"/>
        : shapeType == 10 ? <path d="M 216.25 136.699219 C 198.507812 223.992188 128.652344 234.113281 79.957031 216.519531 C 31.261719 198.925781 -22.375 130.019531 10.667969 50.050781 C 34.1875 -6.867188 44.6875 -13.683594 119.128906 23.820312 C 193.574219 61.324219 241.027344 14.820312 216.25 136.699219 Z M 216.25 136.699219" fill="#C4C4C4"/>
        : shapeType == 11 ? <path d="M 212.355469 122.839844 C 200.378906 196.300781 173.503906 224 113.574219 224 C 77.652344 224 83.636719 201.117188 23.769531 145.71875 C -36.097656 90.324219 28.914062 0 88.84375 0 C 148.773438 0 260.25 10.839844 212.355469 122.839844 Z M 212.355469 122.839844" fill="#C4C4C4"/>
        : <path d="M 208.753906 106.878906 C 241.75 186.414062 222.976562 224 134.898438 224 C 82.105469 224 -37.734375 149.042969 11.710938 93.828125 C 61.15625 38.613281 17.171875 0 105.25 0 C 193.332031 0 175.757812 27.339844 208.753906 106.878906 Z M 208.753906 106.878906" fill="#C4C4C4"/>
        }
        </clipPath>
      </defs>
    </svg>
  </div>
</>
)
:
smaller && !bigger &&    
    <>
    <div className={`shape-small ${className}`} style={style} onClick={onClick}>
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <defs>
    <clipPath id={`svgPath-${shapeType}`}>
  {shapeType == 1 ? <path d="M79.1401 48.798C73.2273 79.058 60.2489 82.1318 41.9928 79.058C23.7367 75.9841 5.47238e-10 67.6255 0 38.8078C-4.74273e-10 13.8325 21.8318 5.63395e-06 45.9377 0C70.0435 -5.63395e-06 83.6588 25.6725 79.1401 48.798Z" fill="#C4C4C4"/>
  :shapeType == 2 ?  <path d="M78.3589 45.4915C63.9027 83.4252 59.6215 81.8098 34.8082 78.3204C17.9187 75.9453 4.99069 81.8098 0.403479 45.4915C-3.40856 15.3105 20.4497 0 44.1626 0C67.8755 0 85.608 26.4696 78.3589 45.4915Z" fill="#C4C4C4"/>
  :shapeType == 3 ? <path d="M78.8891 39.8784C78.8891 79.7568 67.3244 77.491 34.4461 79.7568C1.56782 82.0226 1.11131 68.2253 1.11133 48.0353C1.11134 29.9088 8.4307 0 34.4461 0C60.4615 0 78.8891 12.2354 78.8891 39.8784Z" fill="#C4C4C4"/>
  : shapeType == 4 ? <path d="M77.0686 50.1426C68.5818 71.0354 54.8344 80 36.3527 80C17.871 80 10.526 65.5885 1.53593 33.4282C-7.80857 1.99249e-06 27.8449 -0.000107423 44.8189 3.85124e-07C61.7928 0.000108194 88.9917 20.7905 77.0686 50.1426Z" fill="#C4C4C4"/>
  : shapeType == 5 ? <path d="M75.9486 47.6301C68.3546 67.2689 35.5494 80.0002 19.0119 80.0002C2.47446 80.0002 -8.34509 55.4854 8.36168 31.9189C17.4111 19.1538 18.2341 0.496877 47.0914 0.49707C75.9486 0.497264 86.6174 20.0397 75.9486 47.6301Z" fill="#C4C4C4"/>
  : shapeType == 6 ? <path d="M79.1593 49.7227C74.7955 85.505 31.8946 77.1544 14.6602 77.1544C-2.57413 77.1544 -5.86625 51.7719 11.5445 32.0992C29.3451 11.986 22.629 3 45.1291 3C67.6292 3 83.9577 10.3773 79.1593 49.7227Z" fill="#C4C4C4"/>
  : shapeType == 7 ? <path d="M72.7999 42.3435C86.5239 74.8728 65.8163 75.2094 37.7748 78.6847C9.73326 82.1601 1.92334 58.9929 0.843937 31.0534C0.221376 14.939 33.5368 -2.43915 49.6018 1.98285C65.6668 6.40485 66.2065 26.7155 72.7999 42.3435Z" fill="#C4C4C4"/>
  : shapeType == 8 ? <path d="M79.5604 35.2007C78.2673 61.0715 87.8665 74.5159 44.9962 78.9161C2.12593 83.3164 -2.94148 54.4899 1.23817 39.3052C6.46202 20.3269 18.9455 19.4298 37.469 5.90591C55.9925 -7.618 81.2112 2.17466 79.5604 35.2007Z" fill="#C4C4C4"/>
  : shapeType == 9 ? <path d="M77.7328 38.5359C76.6882 59.4132 74.653 76.7297 45.1176 79.7587C15.5822 82.7876 -2.14765 56.6629 3.15667 37.4065C8.08636 19.5101 33.891 -5.9274 49.3308 1.24186C64.7707 8.41113 78.6766 19.6733 77.7328 38.5359Z" fill="#C4C4C4"/>
  : shapeType == 10 ? <path d="M77.2332 48.906C70.8957 79.497 45.9482 83.0446 28.5567 76.8791C11.1652 70.7136 -7.99018 46.5656 3.81106 18.5403C12.2105 -1.40651 15.9603 -3.79479 42.5469 9.34801C69.1335 22.4908 86.0817 6.19436 77.2332 48.906Z" fill="#C4C4C4"/>
  : shapeType == 11 ? <path d="M75.841 43.871C71.5647 70.1075 61.9648 80 40.5616 80C27.7327 80 29.8707 71.828 8.48941 52.043C-12.8919 32.2581 10.3264 0 31.7296 0C53.1328 0 92.9462 3.87097 75.841 43.871Z" fill="#C4C4C4"/>
  : <path d="M74.5544 38.1704C86.3388 66.5762 79.635 80 48.1779 80C29.3227 80 -13.4767 53.2295 4.18249 33.51C21.8417 13.7906 6.13253 0 37.5897 0C69.0469 0 62.7699 9.76451 74.5544 38.1704Z" fill="#C4C4C4"/>
  }
    </clipPath>
    </defs>
  </svg>
  </div>
    </>
  )
}

export default Shapes