import { useEffect, useContext, lazy, Suspense } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { AuthContext } from '../context/auth'
import TopBarProgress from 'react-topbar-progress-indicator'
import { ProtectedRoute } from './ProtectedRoute'
import Home from '../pages/Home'
import Bio from '../pages/Bio'
import Contact from '../pages/Contact'
import Reagents from '../pages/Reagents'
import Gallery from '../pages/Gallery'
import Pathways from '../pages/Pathways'
import SSB from '../pages/Products/SSB'
import DSBHR from '../pages/Products/DSBHR'
import DSBNH from '../pages/Products/DSBNH'
import AlexaFlourSecondary from '../pages/Products/AlexaFlourSecondary'
import CookiePolicy from '../pages/PrivacyPages/CookiePolicy'
import PrivacyPolicy from '../pages/PrivacyPages/PrivacyPolicy'
import TermsConditionUse from '../pages/PrivacyPages/TermsConditionUse'
import TermsConditionSale from '../pages/PrivacyPages/TermsConditionSale'
import Cart from '../pages/Cart'
import Checkout from '../pages/Checkout'
import ProfileInformation from '../pages/Profile/information'
import Orders from '../pages/Profile/orders'
import TaxExempt from '../pages/Profile/taxExempt'
import ShippingAddress from '../pages/Profile/shippingAddress'
import BillingAddress from '../pages/Profile/billingAddress'
import About from '../pages/About'
import Azure from '../pages/Technologies/Azure'
import Acquifier from '../pages/Technologies/Acquifier'
import Bruker from '../pages/Technologies/Bruker'
import ThermoFisher from '../pages/Technologies/ThermoFisher'
import PrimaryAntibodies from '../pages/Technologies/PrimaryAntibodies'
import Zeiss from '../pages/Technologies/Zeiss'
import AssayDevelopment from '../pages/Technologies/AssayDevelopment'
import Services from '../pages/Technologies/Services'
import Error from '../pages/Error'
import Coa from '../components/coA'
import Invoice from '../components/Invoice'
import B2B from '../pages/Support/B2B'
import Careers from "../pages/Support/Career"
import MicroscopyMethods from "../pages/Support/MicroscopyMethods"
import ProteinChemistryMethods from "../pages/Support/ProteinChemistryMethods"
import IntellectualProperty from "../pages/PrivacyPages/IntellectualProperty"
import StormCalibration from "../pages/Support/StormCalibration"
import { getLocalStorage, removeLocalStorage, setLocalStorage } from "../helpers/localStorage";
import { currentUserApi, getCustomerGroupApi, createCartApi, getCartApi, updateCartApi, getRegionsApi } from '../api'
import { Loader } from "rsuite"



// const SuspensedView = ({ children }) => {
//     const baseColor = '#DC4405'
//     TopBarProgress.config({
//         barColors: {
//             '0': baseColor,
//         },
//         barThickness: 2,
//         shadowBlur: 5,
//     })
//     return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
// }




export const AppRouter = () => {
    const { setWidth, login, logout, setIsLoading, isLoading, setCartData, user, regions, setRegions, setChangePasswordRestoreModal } = useContext(AuthContext)
    const navigate = useNavigate()
    const token = getLocalStorage('identifyntoken')
    const cartId = getLocalStorage('cartId')

    const data = (new URL(document.location).searchParams)
    const tokenId = data.get('token')

    // useEffect(() => {
    //     if(tokenId){
    //         setChangePasswordRestoreModal(true)
    //     }
    // },[])





    // const fetchRegions = () => {
    //     getRegionsApi().then(res => {
    //         if(res && res.data && res.data.regions){
    //             setRegions(res.data.regions)
    //         }
    //     })
    // }


    // useEffect(() => {
    //     if (token) {
    //         setIsLoading(true)
    //         currentUserApi()
    //             .then(res => {
    //                 if (res.data) {
    //                     let data = res.data
    //                     getCustomerGroupApi(data?.customer?.id).then((customerGroup) => {
    //                         data.customer.groups = customerGroup.data?.customerGroups[0]?.groups[0]?.name
    //                     })
    //                     login(data)
    //                     setIsLoading(false)
    //                 }else{
    //                     setIsLoading(false)
    //                     logout()
    //                 }
    //             })
    //             .catch(() => {
    //                 // navigate('/')
    //                 logout()
    //                 setIsLoading(false)
    //             })
    //     } else {
    //         // navigate('/')
    //         setIsLoading(false)
    //     }
    //     fetchRegions()
    // }, [])

    // useEffect(() => {
    //     if(!cartId){
    //       createCartApi().then(res => {
    //         if(res && res.data && res.data.cart){
    //             setLocalStorage('cartId', res.data.cart.id)
    //         }
    //       })  
    //     }else{
    //         getCartApi(cartId).then((res)=>{
    //             if(res && res.data && res.data.cart){
    //               setCartData(res?.data?.cart)
    //             }else{
    //               setCartData(null)
    //               removeLocalStorage('cartId')
    //             }
    //           })
    //     }
    // },[cartId])


    // useEffect(() => {
    //     if(user?.customer?.id && cartId){
    //         updateCartApi(cartId, {
    //             customer_id: user?.customer?.id,
    //             region_id: regions?.find((region) => region.countries?.find((country) => country.name === 'UNITED STATES'))?.id
    //         }).then((res)=>{
    //             if(res && res.data && res.data.cart){
    //               setCartData(res.data.cart)
    //             }else{
    //               setCartData(null)
    //             }
    //           })
    //     }

    // },[user, cartId])


    const updateWindowDimensions = () => {
        const newWidth = window.innerWidth;
        setWidth(newWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', updateWindowDimensions);
    }, []);

    return (
        <>
            {/* {isLoading && (
                <Loader size="lg" backdrop vertical />
            )} */}
            <Routes>
                <Route exact path='/' element={<Home />
                } />
                <Route exact path='/technologies/acquifer' element={<Acquifier />
                } />
                <Route exact path='/technologies/azure' element={<Azure />
                } />
                <Route exact path='/technologies/bruker' element={<Bruker />
                } />
                <Route exact path='/technologies/zeiss' element={<Zeiss />
                } />
                <Route exact path='/technologies/assay-development' element={<AssayDevelopment />
                } />
                <Route exact path='/technologies/services' element={<Services />
                } />
                <Route exact path='/technologies/alexaflour-conjugates' element={<ThermoFisher />
                } />
                <Route exact path='/technologies/primary-antibodies' element={<PrimaryAntibodies />
                } />
                <Route exact path='/b2b-opportunities' element={<B2B />
                } />
                <Route exact path='/intellectual-property' element={<IntellectualProperty />
                } />
                {/* <Route exact path='/careers' element={<Careers />
                } /> */}
                <Route exact path='/storm-calibration' element={<StormCalibration />} />
                <Route exact path='/microscopy-methods' element={<MicroscopyMethods />
                } />
                <Route exact path='/protein-chemistry-methods' element={<ProteinChemistryMethods />
                } />
                <Route exact path='/about' element={<About />
                } />
                <Route exact path='/team' element={<Bio />
                } />
                <Route exact path='/contact' element={
                    <Contact />
                } />
                {/* <Route exact path='reagents' element={<Reagents/>
                } /> */}
                <Route exact path='/gallery' element={<Gallery />
                } />
                <Route exact path='/product/pathways' element={<Pathways />
                } />
                <Route exact path='/product/pathways/ssb' element={<SSB />
                } />
                <Route exact path='/product/pathways/ssb/:to/:wgerId' element={<SSB />
                } />
                <Route exact path='/product/pathways/dsb-hr' element={<DSBHR />
                } />
                <Route exact path='/product/pathways/dsb-nh' element={<DSBNH />
                } />
                <Route exact path='/product/alexa-fluor-secondary' element={<AlexaFlourSecondary />
                } />
                <Route exact path='/cookie-policy' element={<CookiePolicy />
                }
                />
                <Route exact path='/privacy-policy' element={<PrivacyPolicy />
                } />
                <Route exact path='/terms-and-conditions-of-use' element={<TermsConditionUse />
                } />
                <Route exact path='/terms-and-conditions-of-sale' element={<TermsConditionSale />
                } />
                {/* <Route exact path='/cart' element={<Cart />
                } /> */}
                <Route path='*' element={<Error />} />
                {/* <Route exact path='/coa' element={<Coa/>}/> */}
                {/* <Route element={<ProtectedRoute />}>
                    <Route exact path='/profile/orders' element={<Orders />
                    } />
                    <Route exact path='/profile/account' element={<ProfileInformation />
                    } />
                    <Route exact path='/profile/billing-address' element={<BillingAddress />
                    } />
                    <Route exact path='/profile/shipping-address' element={<ShippingAddress />
                    } />
                    <Route exact path='/profile/tax-exempt' element={<TaxExempt />
                    } />
                    <Route exact path='/checkout' element={<Checkout />
                    } />
                </Route> */}
                {/* <Route exact path="/invoice" element={<Invoice />} /> */}
            </Routes>
        </>
    )
}
