import React, { useState, useEffect, useContext } from 'react'
import Layout from '../../../components/layout'
import { AboutHeader, AboutContent } from './style'
import { toAbsoluteUrl } from '../../../utils/assetHelper'
import { AuthContext } from '../../../context/auth'
import { contactApi } from '../../../api'
import Input from "../../../components/Input"
import { CountryDropdown, StateDropdown } from '../../../components/DropDowns'
import axios from 'axios'
import Dropdown from "../../../components/Dropdown"
import { Footer } from '../../../components/Footer'

const Index = () => {
  const { setIsLoading, setNotification, width } = useContext(AuthContext)
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [country, setCountry] = useState('United States');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [topic, setTopic] = useState('Identifyn™ B2B Opportunities');
  const [description, setDescription] = useState('');
  const [cities, setCities] = useState([]);


  useEffect(() => {
    if (state === undefined) return
    if (state) {
      axios.post('https://countriesnow.space/api/v0.1/countries/state/cities', {
        country: country,
        state: state
      }).then((res) => {
        setCities(res.data.data)
      }).catch((err) => {
        console.error(err)
      })
    }
  }, [state])



  const sendContact = async () => {
    setIsLoading(true)
    if (email && RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(email)) {
      const data = {
        first_name: firstName,
        last_name: lastName,
        email,
        company,
        country,
        state,
        city,
        topic,
        message: description,
        email_to:'contact@identifyn.com'
      }
      const response = await contactApi(data)
      if (response?.status === 200) {
        setFirstName('')
        setLastName('')
        setEmail('')
        setCompany('')
        setCountry('United States')
        setState('')
        setCity('')
        setTopic('')
        setDescription('')
        setIsLoading(false)
        setNotification({
          type: 'success',
          message: 'Message sent successfully!'
        })
      } else {
        setIsLoading(false)
        setNotification({
          type: 'red',
          message: 'Something went wrong, please try again!'
        })
      }
    } else {
      setIsLoading(false)
      setNotification({
        type: 'red',
        message: 'Please enter a valid Email Address'
      })
    }
  }






  return (
    <Layout>
      <div className="container title-container bio-container">
        <span style={{ width: '44px', height: '44px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '12px', background: '#fff2e5', color: 'var(--primary-color)' }}>
          <i class="fal fa-briefcase"></i>
        </span>
        <AboutHeader>
          <h1>Identifyn™ B2B Opportunities</h1>
        </AboutHeader>
      </div>
      <AboutContent>
        <div className="container azure-container" style={{ marginBottom: '32px' }}>
          <div className="left-container">
            <div className="solutions-text">
              <h2>Identifyn™ OEM</h2>
              <p>Identifyn™ <span>delivers unprecedented power, speed, and access to multimodal super resolution microscopy (SRM), reagents, applications, and discovery</span> exclusively through our <span>network of selective partnerships.</span></p>
              <p>Identifyn™ places our <span>focus on making vetted reagents</span> and our commitment to develop comprehensively SRM-, SMLM-, and <span>Multiplex-vetted antibodies, Alexa Fluor™ direct and secondary conjugates</span> targeted to oncological cellular pathways.</p>
            </div>
          </div>
        {width > 1100 &&  <div className="right-container">
            <figure>
              <img src={toAbsoluteUrl("/media/intellectual/logo.png")} alt="" width={'100%'} style={{ height:'350px'}} />
            </figure>
          </div>
          }
        </div>
        <div className="container azure-container b2b-service" style={{ marginBottom: '32px', background: 'rgba(237, 237, 237, 0.2)' }}>
          <h4>Identifyn™ provides confidence and a guarantee of vetted products and exclusive data not seen anywhere in science, delivering to our selected OEM distributors:</h4>
          <div className="cards-container">
            <div className="card">
              <span style={{ width: '44px', height: '44px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '12px', background: 'var(--primary-color)', color: '#FFF2E5' }}>
                1
              </span>
              <h4>Premium Experience</h4>
            </div>
            <div className="card">
              <span style={{ width: '44px', height: '44px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '12px', background: 'var(--primary-color)', color: '#FFF2E5' }}>
                2
              </span>
              <h4>Products</h4>
            </div>
            <div className="card">
              <span style={{ width: '44px', height: '44px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '12px', background: 'var(--primary-color)', color: '#FFF2E5' }}>
                3
              </span>
             <h4>Services</h4>
            </div>
          </div>
        </div>
        <div className="container azure-container reverse" style={{ marginBottom: '32px', background: '#F8F7FE' }}>
          <div className="left-container">
            <div className="solutions-text">
              <h2>Identifyn™ Premium Presentation</h2>
              <p>The Identifyn™ experience begins with a <span>product presentation</span> to our mutual end-users via the first-ever <span>sustainable, temperature-tested, custom-designed packaging</span> that accommodates up to <span>10 of our individually packed reagents</span>, plus <span>custom-designed microscopy slide and coverslip storage boxes.</span>
              </p>
            </div>
          </div>
          <div className="right-container">
            <img src={toAbsoluteUrl("/media/presentation.png")} alt="" className='bruker-hero-img' />
          </div>
        </div>
        <div className="container azure-container" style={{ marginBottom: '32px', background: 'rgba(237, 237, 237, 0.2)' }}>
          <div className="left-container">
            <div className="solutions-text">
              <h2>Identifyn™ SRM-vetted Reagents</h2>
              <p>Identifyn™ is committed to <span>developing de novo antibodies across five distinct oncological pathways.</span> Identifyn's™ antibody programs include researching and developing <span>highly specific Rabbit Recombinant and Polyclonal, Mouse Recombinant and Monoclonal, and Fc-switched recombinant antibodies.</span> Identifyn™ <span>does NOT license or outsource antibodies included within our pathway models.</span> Identifyn™ exclusively offers our OEM partners the de novo products that meet our <span>demanding Super Resolution and fluorescent application-specific standards.</span></p>
              <p>
                Identifyn™ holds <span>proprietary conjugation IP</span> that enables highly <span>specific, bright, and stable direct conjugate antibodies. Identifyn™ SRM Alexa Fluor™ primary antibody</span>
                conjugates, <span>enable all forms of microscopy and fluorescent assays,</span> allowing for <span>more experimentation diversity</span> and eliminating speciation cross-reactivity.</p>
              <p>Identifyn™ offers a <span>robust line of secondary antibody products</span> for use in all microscopy and fluorescent assays. Conjugation of <span>Identifyn™ SRM Alexa Fluor™ with secondary antibodies</span> provide <span>higher dye ratios and cleaner matrix</span> that <span>excel</span> under the demands of <span>super resolution applications,</span> as well as <span>traditional microscopy and multiplex applications.</span></p>
            </div>
          </div>
          <div className="right-container">
            <figure>
              <img src={toAbsoluteUrl("/media/reagents.png")} alt="" width={'100%'} style={{ height: '350px' }} />
            </figure>
          </div>
        </div>
        <div className="container azure-container b2b-container">
          <h2>Identifyn™ OEM Services</h2>
          <p>Identifyn™ also <span>provides our B2B partners</span> with the opportunity to <span>further validate their products</span> with our <span>enhanced quality control</span> process via our various <span>super resolution and traditional fluorescent applications.</span></p>
          <p><span style={{ color: '#1e1e1e' }}>Identifyn's™ current laboratory offerings include but are not limited to:</span></p>
          <div className="points-container">
            <div className="points">
              <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
              <p>Multiplex Fluorescent Assays via our two <span>Azure Biosystems Sapphire FL Biomolecular Imaging Platforms (covering 405 nm - NIR).</span></p>
            </div>
            <div className="points">
              <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
              <p><span>Zeiss Primovert HDcam,</span> with labscope, supports a state-of-the-art <span>Tissue Culture facility.</span></p>
            </div>
            <div className="points">
              <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
              <p><span>Zeiss Axioscope Widefield</span> with spectral capabilities from <span>405 nm through ~800 nm (NIR).</span></p>
            </div>
            <div className="points">
              <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
              <p><span>Zeiss Apotome Structured Illumination (SIM) Microscope,</span> providing <span>confocal resolutions and 3D analysis</span> under LED excitation conditions with spectral capabilities from <span>405 nm through ~800 nm (NIR).</span></p>
            </div>
            <div className="points">
              <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
              <p><span>Zeiss LSM, 900 Confocal with Super Resolution AiryScan,</span> provides traditional two- and three-dimensional confocal imaging and <span>super-resolution two- and three-dimensional imaging with a resolution to ~120 nm (X, Y)</span> combining the entire suite of <span>Zeiss Zen analysis tools.</span> The LSM is equipped with an <span>environmental chamber for live cell capabilities.</span></p>
            </div>
            <div className="points">
              <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
              <p><span>Zeiss LSM, 980 Confocal with Super Resolution AiryScan,</span> provides traditional two- and three-dimensional confocal imaging and <span>super-resolution two- and three-dimensional imaging with a resolution to ~120 nm (X, Y)</span> combining the entire suite of <span>Zeiss Zen analysis tools.</span> The LSM is equipped with an <span>environmental chamber for live cell capabilities.</span></p>
            </div>
            <div className="points">
              <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
              <p><span>Identifyn's™ LSM 980 is specially equipped to provide Near-Infrared (NIR) to ~900 nm.</span></p>
            </div>
            <div className="points">
              <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
              <p><span>Zeiss Elyra 7 Super Resolution</span> Lattice-Structured Illumination (SIM) and SIM² provides <span>two- and three-dimensional imaging and resolution to ~60 nm (X, Y)</span> with the entire suite of <span>Zeiss Zen analysis tools.</span> It is also equipped with an <span>environmental chamber for live cell capabilities.</span></p>
            </div>
            <div className="points">
              <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
              <p><span>Bruker Super Resolution Vutara VXL,</span> initially designed and commercialized by our chief scientific officer, Dr. Brian T. Bennett*, the VXL Super Resolution Microscope (SRM) <span>empowers Single-Molecule Localization Microscopy (SMLM) with resolutions to approximately 20 nm</span> in PhotoActivated Localization Microscopy <span>(PALM),</span> Stochastic Optical Reconstruction Microscopy <span>(STORM)</span> and Point Accumulation for Imaging in Nanoscale Topography <span>(PAINT).</span> The VXL is also <span>equipped with fluidics and live cell capabilities.</span></p>
            </div>
            <div className="points" style={{ marginTop: '12px' }}>
              <span></span>
              <small style={{ fontStyle: 'italic', fontWeight: 400, color: '#1e1e1e', textAlign: 'left' }}>*2013 Annual Report - Technology and Venture Commercialization, University of Utah.</small>
            </div>
          </div>
        </div>
        <div className="container acquifer-section-four-container">
          <h3>Identifyn™ provides our exclusive OEM partners with a never-before-seen, highly vetted, specific, bright, and stable line of reagents, custom packaging, and services changing the focus of reagent presentation and product assurance throughout academic and industrial research.</h3>
        </div>
        <div className="container acquifer-section-four-container" style={{ background: 'rgba(241, 238, 252, 0.48)' }}>
          <p style={{ textAlign: 'center', color: '#3F1BD0', fontSize: '20px', lineHeight: '28px' }}>Please inquire if you wish to distribute Identifyn™ Products, require Services, or a need to have your own products validated via our Proprietary Methods. Terms are available under NDA.</p>
        </div>
        <div className="container contact-container" id='contact'>
          <div className="left-container">
            <div className="solutions-text">
              <h2><span>B2B</span> Opportunities</h2>
            </div>
          </div>
          <div className="right-container">
            <div className="form-container">
              <div className="form-wrapper">
                <div
                  style={{ display: "block", width: "100%", marginTop: "20px", marginBottom: '20px' }}
                >
                  <div className="two-column">
                    <Input placeholder="Your First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                    <Input placeholder="Your Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                  </div>
                  <Input placeholder="Your Email" type='email' style={{ marginBottom: '20px' }} value={email} onChange={(e) => setEmail(e.target.value)} />
                  <Input placeholder="Your Company/Institution" style={{ marginBottom: '20px' }} optional value={company} onChange={(e) => setCompany(e.target.value)} />
                  <div className="three-column">
                    <CountryDropdown value={country} onChange={(value) => {
                      setCountry(value)
                    }} />
                    <StateDropdown value={state} country={country} placeholder={"State"} onChange={(value) => {
                      setState(value)
                    }} />
                    <select
                      data-kt-select2='true'
                      data-placeholder='City'
                      data-allow-clear='true'
                      data-kt-user-table-filter='two-step'
                      data-hide-search='true'
                      value={city}
                      onChange={(e) => {
                        setCity(e.target.value)
                      }}
                      placeholder='City'
                    >
                      <option disabled selected>City </option>
                      {cities && cities.length && cities?.map((city) => {
                        return <option key={city} value={city}>{city}</option>
                      })}
                    </select>
                  </div>
                  <Input style={{ marginBottom: '20px', pointerEvents: 'none' }} value={topic} onChange={(e) => setTopic(e.target.value)} />
                  <textarea
                    style={{ resize: "none", borderRadius: "8px" }}
                    placeholder='Write your message here!'
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  ></textarea>
                </div>
                <button class="btn" style={{ float: 'right' }}
                  disabled={!firstName || !lastName || !email || !country || !state || !city || !topic || !description}
                  onClick={sendContact}
                >Get In Touch!</button>
              </div>
            </div>
          </div>
        </div>
      </AboutContent>
      <Footer />
    </Layout>
  )
}

export default Index