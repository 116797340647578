import React, { useEffect, useState, useContext, useMemo, useRef } from 'react'
import { toAbsoluteUrl } from '../../../utils/assetHelper'
import { isValidURL } from '../../../utils/urlHelper'
import "./style.scss"
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useNavigate } from 'react-router-dom';
import { getProductByTagApi } from '../../../api';
import { AuthContext } from '../../../context/auth';
import { getLocalStorage, setLocalStorage } from "../../../helpers/localStorage";
import { createCartApi, addItemsToCartApi } from '../../../api'
import ReactPrint from "react-to-print";
import Dropdown from "../../Dropdown"

const staticDyes = [
    {
        value: 405,
        label: "Dye: 405"
    },
    {
        value: 488,
        label: "Dye: 488"
    },
    {
        value: 532,
        label: "Dye: 532"
    },
    {
        value: 555,
        label: "Dye: 555"
    },
    {
        value: 594,
        label: "Dye: 594"
    },
    {
        value: 647,
        label: "Dye: 647"
    },
    {
        value: 680,
        label: "Dye: 680"
    },
    {
        value: 750,
        label: "Dye: 750"
    },
    {
        value: 430,
        label: "Dye: 430"
    },
    {
        value: 546,
        label: "Dye: 546"
    },
    {
        value: 568,
        label: "Dye: 568"
    },
    {
        value: 610,
        label: "Dye: 610"
    },
    {
        value: 633,
        label: "Dye: 633"
    },
    {
        value: 635,
        label: "Dye: 635"
    },
    {
        value: 660,
        label: "Dye: 660"
    },
    {
        value: 700,
        label: "Dye: 700"
    },
    {
        value: 790,
        label: "Dye: 790"
    }

]

const hosts = [
    {
        name: "Mouse Monoclonal",
    },
    {
        name: 'Mouse Recombinant Monoclonal'
    },
    {
        name: 'Rabbit Polyclonal'
    },
    {
        name: 'Rabbit Recombinant Monoclonal'
    },
    {
        name: 'Rat Monoclonal'
    }
]


const Index = ({ setSelectedProtein, selectedProtein, selectedProduct, setSelectedProduct, proteinId, setProteinId, setOpenMethodologyModal, setModalName, coARef, setOpenFunctionModal, filteredProteinProducts }) => {
    const { setIsLoading, setCartData, setNotification, isLoading, tempDisable, setTempDisable, mapViewActive, setMapViewActive } = useContext(AuthContext)
    const galleryUrl = `${process.env.REACT_APP_GALLERY_URL}250:250`
    const onBeforeGetContentResolve = useRef();
    const cartId = getLocalStorage('cartId')
    const navigate = useNavigate()
    const [productTab, setProductTab] = useState("primary")
    const [selectedHost, setSelectedHost] = useState('Mouse Monoclonal')
    const [dye, setDye] = useState({
        value: 405,
        label: "Dye: 405"
    })
    const [images, setImages] = useState([])
    const [imageSlideIndex, setImageSlideIndex] = useState(0)
    const [productName, setProductName] = useState("")
    const [dyes, setDyes] = useState(staticDyes)
    const [productsList, setProductsList] = useState([])

    // useEffect(() => {
    //     if (selectedProduct?.metadata?.images) {
    //         const arr = selectedProduct?.metadata?.images?.filter((item) => item.url !== '')
    //         const splitArr = Array.from({ length: Math.ceil(arr.length / 4) }, (_, index) =>
    //             arr.slice(index * 4, index * 4 + 4)
    //         );
    //         setImages(splitArr)
    //     } else {
    //         setImages([])
    //     }
    //     if (selectedProduct?.dyes) {
    //         setDye({
    //             value: selectedProduct?.dyes,
    //             label: `Dye: ${selectedProduct?.dyes}`
    //         })
    //     }

    //     if (selectedProduct?.mid_code.search("PA") >= 0) {
    //         setProductTab("primary")
    //     } else if (selectedProduct?.mid_code.search("DC") >= 0) {
    //         setProductTab("alexaflour")
    //     }

    //     if (selectedProduct?.host === 'Mouse' && selectedProduct?.clonality === 'Monoclonal') {
    //         setSelectedHost('Mouse Monoclonal')
    //     }
    //     if (selectedProduct?.host === 'Mouse' && selectedProduct?.clonality === 'Recombinant Monoclonal') {
    //         setSelectedHost('Mouse Recombinant Monoclonal')
    //     }
    //     if (selectedProduct?.host === 'Rabbit' && selectedProduct?.clonality === 'Recombinant Monoclonal') {
    //         setSelectedHost('Rabbit Recombinant Monoclonal')
    //     }
    //     if (selectedProduct?.host === 'Rabbit' && selectedProduct?.clonality === 'Polyclonal') {
    //         setSelectedHost('Rabbit Polyclonal')
    //     }
    //     if (selectedProduct?.host === 'Rat' && selectedProduct?.clonality === 'Monoclonal') {
    //         setSelectedHost('Rat Monoclonal')
    //     }

    // }, [selectedProduct])

    // useEffect(() => {
    //     if (productTab === "primary") {
    //         if (selectedHost === 'Mouse Monoclonal') {
    //             setProductName(`Mouse anti-Human ${selectedProtein} Monoclonal`)
    //         } else if (selectedHost === 'Mouse Recombinant Monoclonal') {
    //             setProductName(`Mouse anti-Human ${selectedProtein} Recombinant Monoclonal`)
    //         } else if (selectedHost === 'Rabbit Polyclonal') {
    //             setProductName(`Rabbit anti-Human ${selectedProtein} Polyclonal`)
    //         } else if (selectedHost === 'Rabbit Recombinant Monoclonal') {
    //             setProductName(`Rabbit anti-Human ${selectedProtein} Recombinant Monoclonal`)
    //         } else if (selectedHost === 'Rat Monoclonal') {
    //             setProductName(`Rat anti-Human ${selectedProtein} Monoclonal`)
    //         }
    //     } else {
    //         if (selectedHost === 'Mouse Monoclonal') {
    //             setProductName(`Mouse anti-Human ${selectedProtein} Monoclonal - Identifyn™ SRM Alexa Fluor™ ${dye.value}`)
    //         } else if (selectedHost === 'Mouse Recombinant Monoclonal') {
    //             setProductName(`Mouse anti-Human ${selectedProtein} Recombinant Monoclonal - Identifyn™ SRM Alexa Fluor™ ${dye.value}`)
    //         } else if (selectedHost === 'Rabbit Polyclonal') {
    //             setProductName(`Rabbit anti-Human ${selectedProtein} Polyclonal - Identifyn™ SRM Alexa Fluor™ ${dye.value}`)
    //         } else if (selectedHost === 'Rabbit Recombinant Monoclonal') {
    //             setProductName(`Rabbit anti-Human ${selectedProtein} Recombinant Monoclonal - Identifyn™ SRM Alexa Fluor™ ${dye.value}`)
    //         } else if (selectedHost === 'Rat Monoclonal') {
    //             setProductName(`Rat anti-Human ${selectedProtein} Monoclonal - Identifyn™ SRM Alexa Fluor™ ${dye.value}`)
    //         }
    //     }

    // }, [productTab, selectedHost, dye, selectedProtein])


    // const fetchProducApi = () => {
    //     setIsLoading(true)
    //     getProductByTagApi(proteinId?.id ?? "", productName).then((res) => {
    //         if (res && res.data && res.data.products) {
    //             setSelectedProduct(res.data.products?.find((item) => item.title === productName) ?? null)
    //             setIsLoading(false)
    //         } else {
    //             setSelectedProduct(null)
    //             setIsLoading(false)
    //         }
    //     })
    // }


    // useMemo(() => {
    //     if (selectedProtein && proteinId && !mapViewActive) {
    //         fetchProducApi()
    //     }
    // }, [productName])


    const AddToCart = () => {
        setIsLoading(true)
        if (!cartId) {
            createCartApi().then(res => {
                setLocalStorage('cartId', res.data.cart.id)
                addItemsToCartApi(res.data.cart.id, {
                    variant_id: selectedProduct?.variants[0]?.id,
                    quantity: 1,
                }).then((res) => {
                    if (res && res.data && res.data?.cart) {
                        setCartData(res.data.cart)
                        setNotification({
                            message: 'Added to cart',
                            type: 'green',
                        })
                        setIsLoading(false)
                    } else {
                        setNotification({
                            message: 'Something went wrong',
                            type: 'red',
                        })
                        setIsLoading(false)
                    }
                })
            })
        } else {
            addItemsToCartApi(cartId, {
                variant_id: selectedProduct?.variants[0]?.id,
                quantity: 1,
            }).then((res) => {
                if (res && res.data && res.data?.cart) {
                    setCartData(res.data.cart)
                    setNotification({
                        message: 'Added to cart',
                        type: 'green',
                    })
                    setIsLoading(false)
                } else {
                    setNotification({
                        message: 'Something went wrong',
                        type: 'red',
                    })
                    setIsLoading(false)
                }
            })
        }
    }

    const BuyProduct = () => {
        setIsLoading(true)
        if (!cartId) {
            createCartApi().then(res => {
                setLocalStorage('cartId', res.data.cart.id)
                addItemsToCartApi(res.data.cart.id, {
                    variant_id: selectedProduct?.variants[0]?.id,
                    quantity: 1,
                }).then((res) => {
                    if (res && res.data && res.data?.cart) {
                        setCartData(res.data.cart)
                        setNotification({
                            message: 'Added to cart',
                            type: 'green',
                        })
                        setIsLoading(false)
                        navigate('/checkout')
                    } else {
                        setNotification({
                            message: 'Something went wrong',
                            type: 'red',
                        })
                        setIsLoading(false)
                    }
                })
            })
        } else {
            addItemsToCartApi(cartId, {
                variant_id: selectedProduct?.variants[0]?.id,
                quantity: 1,
            }).then((res) => {
                if (res && res.data && res.data?.cart) {
                    setCartData(res.data.cart)
                    setNotification({
                        message: 'Added to cart',
                        type: 'green',
                    })
                    setIsLoading(false)
                    navigate('/checkout')
                } else {
                    setNotification({
                        message: 'Something went wrong',
                        type: 'red',
                    })
                    setIsLoading(false)
                }
            })
        }
    }

    useEffect(() => {
        let tempPrimaryProduct = mapViewActive ? filteredProteinProducts?.products?.find((item) => item.id === selectedProduct?.id)?.mid_code.search("PA") >= 0 : filteredProteinProducts?.products?.find((item) => item.mid_code.search("PA") >= 0)
        let tempSecondaryProduct = mapViewActive ? filteredProteinProducts?.products?.find((item) => item.id === selectedProduct?.id)?.mid_code.search("DC") >= 0 : filteredProteinProducts?.products?.find((item) => item.mid_code.search("DC") >= 0)
        if (tempPrimaryProduct) {
            setProductTab("primary")
            if (mapViewActive) {
                setProductsList(filteredProteinProducts?.products?.filter((item) => item.id === selectedProduct?.id))
            } else {
                setProductsList(filteredProteinProducts?.products?.filter((item) => item.mid_code.search("PA") >= 0))
            }
        } else if (!tempPrimaryProduct && tempSecondaryProduct) {
            setProductTab("alexaflour")
            if (mapViewActive) {
                setProductsList(filteredProteinProducts?.products?.filter((item) => item.id === selectedProduct?.id))
            } else {
                setProductsList(filteredProteinProducts?.products?.filter((item) => item.mid_code === "DC"))
            }
        } else {
            setProductTab("primary")
            if (mapViewActive) {
                setProductsList(filteredProteinProducts?.products?.filter((item) => item.id === selectedProduct?.id))
            } else {
                setProductsList(filteredProteinProducts?.products)
            }
        }
        let tempData = staticDyes.filter((item) => {
            return filteredProteinProducts?.products?.some(product => product.dyes == item.value);
        })
        if (mapViewActive) {
            setDye({ value: selectedProduct?.dyes, label: `Dye: ${selectedProduct?.dyes}` })
        } else {
            setDye(tempData[0])
        }
        setDyes(tempData ?? [])
    }, [filteredProteinProducts])


    useEffect(() => {
        if (productTab === "primary") {
            setProductsList(filteredProteinProducts?.products?.filter((item) => item.mid_code.search("PA") >= 0))
        } else {
            setProductsList(filteredProteinProducts?.products?.filter((item) => item.mid_code.search("DC") >= 0 && item.dyes == dye.value))
        }
    }, [productTab, dye])

    const handleOnBeforeGetContent = (business) => {
        return new Promise((resolve) => {
            setIsLoading(true)
            setSelectedProduct(business);
            onBeforeGetContentResolve.current = resolve;

        });
    };

    React.useEffect(() => {
        if (selectedProduct) {
            onBeforeGetContentResolve.current && onBeforeGetContentResolve.current();
        }
    }, [selectedProduct, onBeforeGetContentResolve]);


    return (
        <>
            <div className="header">
                <h2>Anti-Human {selectedProtein}</h2>
                <div className="icons-container"
                    onClick={() => {
                        setSelectedProtein(null)
                        setSelectedProduct(null)
                        setProteinId(null)
                        setMapViewActive(false)
                    }}
                >
                    <i className='fal fa-times'></i>
                </div>
            </div>
            <button className='btn' style={{ width: 'max-content', alignSelf: 'flex-end' }} onClick={() => setOpenFunctionModal(true)}>Read More About {selectedProtein}</button>
            <div className="tab-container">
                <button style={{ borderTopLeftRadius: '12px', borderBottomLeftRadius: '12px', width: '35%' }} className={productTab === "primary" && 'tab-active'}
                    onClick={() => {
                        setProductTab("primary")
                        setMapViewActive(false)
                    }}
                >Primary Antibodies</button>
                <button style={{ borderTopRightRadius: '12px', borderBottomRightRadius: '12px', width: '65%' }} className={productTab === "alexaflour" && 'tab-active'}
                    onClick={() => {
                        setProductTab("alexaflour")
                        setMapViewActive(false)
                    }}
                >Identifyn™ SRM Alexa Fluor™ Conjugates</button>
            </div>
            {productTab === 'alexaflour' && dyes.length > 0 &&
                <Dropdown
                    label="Select Dye"
                    value={dye.label}
                    onChange={(e) => {
                        setDye(e)
                        setMapViewActive(false)
                    }}
                    options={dyes}
                    optionType='object'
                    style={{ width: '100%' }}
                />
            }
            {/* <div className="items-container">
                {
                    hosts.map((item) => {
                        if (filteredProteinProducts?.products?.find((product) => `${product.host} ${product.clonality}` === item.name)) {
                            return (
                                <button
                                    key={item.name}
                                    style={{ borderTopLeftRadius: '12px', borderBottomLeftRadius: '12px' }}
                                    className={selectedHost === item.name ? 'tab-active' : ''}
                                    onClick={() => {
                                        setSelectedHost(item.name);
                                        setMapViewActive(false);
                                    }}
                                >
                                    {item.name}
                                </button>
                            );
                        }
                        return null
                    })
                }
            </div> */}
            {productsList && productsList.length > 0 ?
                <>
                    {productsList?.map((selectedProduct) => {
                        return <div>
                            <div className="item-details-container">
                                <div className="item-detail-header">
                                    <div className="left-container" style={{ width: '85%' }}>
                                        <h4 style={{ color: 'var(--primary-color)' }}>{selectedProduct?.title}, {selectedProduct?.metadata.subclass}</h4>
                                        <h5>Cat ID# - {selectedProduct?.mid_code} {selectedProduct?.material}</h5>
                                    </div>
                                    <ReactPrint
                                        onBeforeGetContent={() => handleOnBeforeGetContent(selectedProduct)}
                                        onAfterPrint={() => {
                                            setIsLoading(false)
                                            setSelectedProduct(null)
                                        }}
                                        trigger={() => <span className='coa btn' style={{ cursor: 'pointer', alignSelf: 'flex-start' }}>
                                            CoA</span>}
                                        content={() => coARef.current}
                                    />
                                </div>
                                <div className="item-content">
                                    <div className="left-container">
                                        <h4>Concentration</h4>
                                        <h4>Buffer</h4>
                                        <h4>pH</h4>
                                        <h4>Storage</h4>
                                        {/* <h4>Applications</h4> */}
                                        {/* <h4>Price</h4> */}
                                    </div>
                                    <div className="right-container">
                                        <h4>{selectedProduct?.concentration} mg/ml</h4>
                                        <h4>{selectedProduct?.buffer_molarity}mM {selectedProduct?.buffer}</h4>
                                        <h4>{selectedProduct?.ph}</h4>
                                    </div>
                                </div>
                                <div className="item-content">
                                    <div className="left-container" style={{ width: '50%' }}>
                                        <h4>Applications</h4>
                                    </div>
                                    <div className="right-container">
                                        <div className="application-container">
                                            {selectedProduct?.metadata?.protein_application?.map((item) => {
                                                return <span onClick={() => {
                                                    setModalName(item.name)
                                                    setOpenMethodologyModal(true)
                                                }} data-tooltip-id="applications" data-tooltip-content={item.name}>{item.abbr}</span>
                                            })
                                            }
                                            {selectedProduct?.metadata?.microscopy_application?.map((item) => {
                                                return <span onClick={() => {
                                                    setModalName(item.name)
                                                    setOpenMethodologyModal(true)
                                                }} data-tooltip-id="applications" data-tooltip-content={item.name}>{item.abbr}</span>
                                            })
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="item-content">
                                    <div className="left-container">
                                        <h4>Price / 50 µL Unit Size</h4>
                                    </div>
                                    <div className="right-container">
                                        <h4>${(selectedProduct?.variants[0]?.prices[0]?.amount / 100)?.toFixed(2)}</h4>
                                    </div>
                                </div>
                                {selectedProduct?.metadata?.reference && selectedProduct?.metadata?.reference?.some((item) => isValidURL(item)) &&
                                    <div className='references-container'>
                                        <h4>References:</h4>
                                        {
                                            selectedProduct?.metadata?.reference?.map((item, index) => {
                                            if (isValidURL(item)){
                                                return <a href={item.includes("https") ? item : `https://${item}`} target="_blank" key={index}>{item}</a>
                                            }
                                            })}
                                    </div>
                                }
                            </div>
                            {/* <div className="item-image-container">
                                {images && images.length > 0 && <div className="images-container">
                                    {images[imageSlideIndex]?.map((item, index) => {
                                        return <div className="image" onClick={() => {
                                            setOpenMethodologyModal(true)
                                            setModalName(item.name)
                                        }} key={index}>
                                            <img src={galleryUrl + `:${item.url?.split('.com/')[1]}` || toAbsoluteUrl('/media/methodologyplaceholder.png')} alt="" />
                                            <h6>{item.name}</h6>
                                        </div>
                                    })}
                                </div>
                                }
                                {images && images.length > 1 && <div className="buttons-container">
                                    <button onClick={() => {
                                        if (imageSlideIndex > 0) {
                                            setImageSlideIndex(imageSlideIndex - 1)
                                        } else {
                                            setImageSlideIndex(0)
                                        }
                                    }}><i className='fas fa-chevron-left'></i></button>
                                    <div className="dots">
                                        {
                                            images.map((item, index) => {
                                                return <span className={imageSlideIndex === index ? "active-dot" : ""} key={index}></span>
                                            })
                                        }
                                    </div>
                                    <button
                                        onClick={() => {
                                            if (imageSlideIndex < images.length - 1) {
                                                setImageSlideIndex(imageSlideIndex + 1)
                                            } else {
                                                setImageSlideIndex(images.length - 1)
                                            }
                                        }}
                                    ><i className='fas fa-chevron-right'></i></button>
                                </div>
                                }
                            </div> */}
                        </div>
                    })}
                    <div className="add-cart-container">
                        <p style={{ padding: '12px 10px', background: '#FFF2E5', borderRadius: '12px', color: '#DC4405', fontWeight: 700, textAlign: 'center', fontSize: '16px', lineHeight: '24px' }}>Identifyn™ Super Resolution Vetted
                            Premium Research Products are Available through
                            our Preferred Industry Partners</p>
                        {/* <button className='btn add-btn'
                        disabled={isLoading}
                        onClick={() => {
                            tempDisable ? setTempDisable(false) :
                            AddToCart()
                            // setSelectedProtein(false)
                            // navigate('/cart')
                        }}
                    >
                        <i className='fal fa-shopping-cart'></i>
                        Add to Cart</button>
                    <button className='btn buy-btn'
                        disabled={isLoading}
                        onClick={() => {
                           tempDisable ? setTempDisable(false) : BuyProduct()
                        }}
                    >Buy Now</button> */}
                    </div>
                </>
                :
                <>
                    <div className="no-orders">
                        <span className='icon'>
                            <i class="far fa-images"></i>
                        </span>
                        <p>No Product available for this Selection.</p>
                    </div>
                </>
            }
            <ReactTooltip
                id="applications"
                place="top"
                variant="light"
            />
        </>
    )
}

export default Index