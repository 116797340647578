import React, { useEffect, useRef, useState } from 'react'
import "./style.scss"
const Index = ({
  type = 'text',
  placeholder,
  label,
  value,
  className,
  onChange,
  style,
  optional,
  required = false,
  search = false,
  radio=false,
  name,
  otherText,
  isDisabled=false,
  defaultValue='',
  checked=false,
  verified,
  readOnly=false,
  onClick,
  onBlur = () => {},
  clear=false,
  onClear = () => {},
  onKeyPress = () => {},
}) => {
  const [showPlaceHolder, setShowPlaceHolder] = useState(required)
  const [showPassword, setShowPassword] = useState(false)

  useEffect(() => {
    if (value) {
      setShowPlaceHolder(false)
    }
  }, [value])


  return (
    <>
     {!radio && <div style={{ position: 'relative', width: '100%' }} className='input-wrapper'>
        {showPlaceHolder && <div className="placeholder" onClick={() => {
          document.querySelector(`.input-wrapper .${className === 'First Name' ? 'First Name' : className === 'Last Name' ? 'Last Name' : className}`)?.focus()
        }}>
          <label style={{cursor: 'text'}} >{label}</label>
          <span style={{ color: 'rgba(220, 30, 0, 1)' }}> *</span>
        </div>
        }
      {!readOnly ?  <input type={showPassword ? 'text' : type} value={value} placeholder={placeholder} disabled={isDisabled} className={className} onChange={onChange} style={style} defaultValue={defaultValue} autoComplete='off' readOnly={readOnly} onBlur={() => !value && required ? setShowPlaceHolder(true) : setShowPlaceHolder(false)} onFocus={(e) => {
          e.target.readOnly = false
        }}
          onTouchStart={(e) => {
            e.target.readOnly = false
          }}
          onClick={onClick}
          onKeyDown={(e) => {
            type === 'password' && onKeyPress(e)
          }}
          />
          :  <input type={showPassword ? 'text' : type} value={value} placeholder={placeholder} disabled={isDisabled} className={className} onChange={onChange} style={style} defaultValue={defaultValue} autoComplete='off' readOnly={readOnly}
            onClick={onClick}
            onKeyDown={(e) => {
              type === 'password' && onKeyPress(e)
            }}
            />
        }
        {type === 'password' && <span className='show-password' onClick={() => setShowPassword(!showPassword)}>{showPassword ? <i className='fal fa-eye-slash'></i> : <i className='fal fa-eye'></i>}</span>}
        {optional && <span className='optional' >Optional</span>}
        {search && <i className='fal fa-search search'></i>}
        {otherText && <span className='other-text'>{otherText}</span>}
        {verified ?  <span className='verified'>Verified <i className="fal fa-check" /></span> : verified === false && <span className='not-verified'>Not Verified <i className="fal fa-times" /></span> }
        {clear ? <span className='clear'
        onClick={onClear}
        ><i className='fal fa-times' /></span> : null}
      </div>
}
    {radio &&  <label class="radio-containers" style={style} className={className}>
        <input type="radio" name={name} value={value} onChange={onChange} checked={checked} />
        <span class="checkmark"></span>
        <span>{value}</span>
      </label>
}
    </>
  )
}

export default Index