import React, {useContext} from 'react'
import { toAbsoluteUrl } from '../../utils/assetHelper'
import "./style.scss"
import { createCartApi, addItemsToCartApi } from '../../api'
import { AuthContext } from '../../context/auth'
import { setLocalStorage, getLocalStorage } from '../../helpers/localStorage'

const Index = ({item, index, className, setSelectedProduct, randomNumber}) => {
  const { setIsLoading, setNotification, setCartData, isLoading, tempDisable, setTempDisable, setOpenItemPreviewModal } = useContext(AuthContext)
  const galleryUrl = `${process.env.REACT_APP_GALLERY_URL}350:350`
  const cartId = getLocalStorage('cartId')
  const AddToCart = (selectedProduct) => {
    setIsLoading(true)
    if (!cartId) {
        createCartApi().then(res => {
            setLocalStorage('cartId', res.data.cart.id)
            addItemsToCartApi(res.data.cart.id, {
                variant_id: selectedProduct?.variants[0]?.id,
                quantity: 1,
            }).then((res) => {
                if (res && res.data && res.data?.cart) {
                    setCartData(res.data.cart)
                    setNotification({
                        message: 'Added to cart',
                        type: 'green',
                    })
                    setIsLoading(false)
                } else {
                    setNotification({
                        message: 'Something went wrong',
                        type: 'red',
                    })
                    setIsLoading(false)
                }
            })
        })
    } else {
        addItemsToCartApi(cartId, {
            variant_id: selectedProduct?.variants[0]?.id,
            quantity: 1,
        }).then((res) => {
            if (res && res.data && res.data?.cart) {
                setCartData(res.data.cart)
                setNotification({
                    message: 'Added to cart',
                    type: 'green',
                })
                setIsLoading(false)
            } else {
                setNotification({
                    message: 'Something went wrong',
                    type: 'red',
                })
                setIsLoading(false)
            }
        })
    }
}


const selectRandomNumber = (length) => {
  return Math.floor(Math.random() * length)
}



  return (
    <article key={index}>
      <div className="price" style={{background: !item.metadata?.images[0]?.url && 'rgba(252, 252, 252, 1)', color: !item.metadata?.images[0]?.url && '#1e1e1e'}}>${(item.variants && item?.variants[0]?.prices[0]?.amount / 100 )?.toFixed(2)}</div>
      {/* <span className='cart-hover' style={{ width: '44px', height: '44px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '12px', background: 'var(--primary-color)', color: 'var(--primary-color)' }}>
      <i class="fal fa-shopping-cart" aria-hidden="true" style={{ fontSize: '16px', color: 'white' }}
      onClick={() => {
        if(!isLoading){
        tempDisable ? setTempDisable(false) : 
          AddToCart(item)
        }
      }}
      ></i>
      </span> */}
      <div className="img-card" style={{cursor:'pointer'}} onClick={() => {
        setSelectedProduct(item)
        setOpenItemPreviewModal(true)
        }}>
         {item?.metadata?.images?.filter((item) => item.url !== '')?.length ? <img src={galleryUrl + `:${item.image?.split('.com/')[1]}`} alt="" loading='lazy' />  : null}   
       {/* {item?.metadata?.images[selectRandomNumber(item?.metadata?.images?.length)]?.url ? <img src={item?.metadata?.images[selectRandomNumber(item?.metadata?.images?.length)]?.url} alt="" loading='lazy' /> : item?.metadata?.images.filter((image) => image.url)?.length  ?  <img src={item?.metadata?.images.filter((image) => image.url)[selectRandomNumber(item?.metadata?.images.filter((image) => image.url).length)]?.url} alt="" loading='lazy' /> : null} */}
      </div>
    <div className="info-container">
    <div className="top-part">
    <h4 style={{cursor:'pointer'}} onClick={() => {
        setSelectedProduct(item)
        setOpenItemPreviewModal(true)
        }}>{item.title}</h4>
    </div>
    </div> 
</article>
  )
}

export default Index