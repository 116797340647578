import React, { useContext } from 'react'
import Layout from '../../../components/layout'
import { AboutHeader, AboutContent } from './style'
import { toAbsoluteUrl } from '../../../utils/assetHelper'
import { Footer } from '../../../components/Footer'
import { AuthContext } from '../../../context/auth'
import { downloadPdf } from '../../../utils/downloader'
import proteinChemistry from "./proteinchemistry.pdf"

const Index = () => {
  const { width } = useContext(AuthContext)
  return (
    <Layout>
      <div className="container title-container bio-container">
        <span style={{ width: '44px', height: '44px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '12px', background: '#fff2e5', color: 'var(--primary-color)' }}>
          <i class="fal fa-microscope"></i>
        </span>
        <AboutHeader>
          <h1>Identifyn™ Protein Chemistry Methods</h1>
        </AboutHeader>
      </div>
      <AboutContent>
        <div className="container azure-container" style={{ marginBottom: '32px' }}>
          <div className="left-container">
            <div className="solutions-text">
              <h2>Identifyn™ Western Blotting Methodology</h2>
              <p>Identifyn™ is committed to using the best products and instrumentation in vetting our de novo antibody products. The following method is used at Identifyn™ to validate antibody specificity. In sharing our methodology, we encourage the implementation of our method in your own research.</p>
            </div>
          </div>
          {width > 1100 &&  <div className="right-container">
            <figure>
              <img src={toAbsoluteUrl("/media/intellectual/logo.png")} alt="" width={'100%'} style={{ height: '350px' }} />
            </figure>
          </div>
          }
        </div>
        <div className="container services-section-two-container">
          <div className="left-container">
            <div className="card2">
              <h3>Identifyn™ Reagents</h3>
              <div className="points-container">
                <div className="points">
                  <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
                  <p>Identifyn™ De Novo Primary Antibody</p>
                </div>
                <div className="points">
                  <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
                  <p>Identifyn™ SRM Alexa Fluor™ De Novo Primary Antibody</p>
                </div>
                <div className="points">
                  <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
                  <p>Identifyn™ SRM Alexa Fluor™ Secondary Antibody</p>
                </div>
              </div>
            </div>
            <div className="card2">
              <h3>Instruments</h3>
              <p style={{ marginBottom: '16px', color: '#6c6a73', fontSize: '16px' }}>Click on an instrument to visit our partner website</p>
              <div className="points-container">
                <div className="points">
                  <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
                  <a href="https://www.thermofisher.com/order/catalog/product/PSC350M?SID=srch-srp-PSC350M" target='_blank'><p>PowerEase™ Touch 350W Power Supply <i className='fal fa-eye' style={{ fontSize: '16px', color: '#DC4405' }} /></p></a>
                </div>
                <div className="points">
                  <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
                  <a href="https://www.thermofisher.com/order/catalog/product/A25977?SID=srch-srp-A25977" target='_blank'><p>Mini Gel Tank <i className='fal fa-eye' style={{ fontSize: '16px', color: '#DC4405' }} /></p></a>
                </div>
                <div className="points">
                  <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
                  <a href="https://www.thermofisher.com/order/catalog/product/IB31001?SID=srch-srp-IB31001" target='_blank'><p>iBlot™ 3 Western Blot Transfer System <i className='fal fa-eye' style={{ fontSize: '16px', color: '#DC4405' }} /></p></a>
                </div>
                <div className="points">
                  <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
                  <a href="https://azurebiosystems.com/azure-sapphire-vs-licor-odyssey/?msclkid=57510b1a6afb1b3be2576ef54621b904&utm_source=bing&utm_medium=cpc&utm_campaign=CAT%20Trade-in-Program&utm_term=licor.biomolecular.imager&utm_content=Licor" target='_blank'><p>Azure Biosystems Sapphire Biomolecular Imager IS4000 <i className='fal fa-eye' style={{ fontSize: '16px', color: '#DC4405' }} /></p></a>
                </div>
              </div>
            </div>
          </div>
          <div className="right-container">
            <div className="card1">
              <h3>General Reagents</h3>
              <p style={{ marginBottom: '16px', color: '#6c6a73', fontSize: '16px' }}>Click on a product to visit our partner website </p>
              <div className="points-container">
                <div className="points">
                  <img src={toAbsoluteUrl("/media/pointbullet.svg")} alt="" />
                  <a href="https://www.thermofisher.com/order/catalog/product/NP0321BOX" target='_blank'><p>NuPAGE™ 4 to 12%, Bis-Tris, 1.0–1.5 mm, Mini Protein Gels <i className='fal fa-eye' style={{ fontSize: '16px', color: '#DC4405' }} /></p></a>
                </div>
                <div className="points">
                  <img src={toAbsoluteUrl("/media/pointbullet.svg")} alt="" />
                  <a href="https://www.thermofisher.com/order/catalog/product/NP000202?SID=srch-srp-NP000202" target='_blank'><p>NuPAGE™ MES SDS Running Buffer (20X) <i className='fal fa-eye' style={{ fontSize: '16px', color: '#DC4405' }} /></p></a>
                </div>
                <div className="points">
                  <img src={toAbsoluteUrl("/media/pointbullet.svg")} alt="" />
                  <a href="https://www.thermofisher.com/order/catalog/product/84788?SID=srch-srp-84788" target='_blank'><p>NuPAGE™ LDS Sample Buffer (4X) <i className='fal fa-eye' style={{ fontSize: '16px', color: '#DC4405' }} /></p></a>
                </div>
                <div className="points">
                  <img src={toAbsoluteUrl("/media/pointbullet.svg")} alt="" />
                  <a href="https://www.thermofisher.com/order/catalog/product/26616?SID=srch-srp-26616" target='_blank'><p>PageRuler™ Prestained Protein Ladder, 10 to 180 kDa <i className='fal fa-eye' style={{ fontSize: '16px', color: '#DC4405' }} /></p></a>
                </div>
                <div className="points">
                  <img src={toAbsoluteUrl("/media/pointbullet.svg")} alt="" />
                  <a href="https://www.thermofisher.com/order/catalog/product/IB34002?SID=srch-srp-IB34002" target='_blank'><p>iBlot™ 3 Transfer Stacks, mini, PVDF <i className='fal fa-eye' style={{ fontSize: '16px', color: '#DC4405' }} /></p></a>
                </div>
                <div className="points">
                  <img src={toAbsoluteUrl("/media/pointbullet.svg")} alt="" />
                  <a href="https://www.thermofisher.com/order/catalog/product/37530?SID=srch-srp-37530" target='_blank'><p>Casein <i className='fal fa-eye' style={{ fontSize: '16px', color: '#DC4405' }} /></p></a>
                </div>
                <div className="points">
                  <img src={toAbsoluteUrl("/media/pointbullet.svg")} alt="" />
                  <p>10 mM PBS, pH 7.4</p>
                </div>
                <div className="points">
                  <img src={toAbsoluteUrl("/media/pointbullet.svg")} alt="" />
                  <p>Deionized Water (~18MΩ)</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container azure-section-four-container" style={{ background: 'rgba(237, 237, 237, 0.20)', marginBottom: '0px', alignItems: 'start', gap: '40px' }}>
          <div className="methods-header">
            <h5>Estimated Time</h5>
            <div className='inner-header'>
              <h5>Method Steps</h5>
              {width > 700 && <button className='btn' style={{ background: '#FFF2E5', color: '#DC4405' }}
                onClick={() => downloadPdf(proteinChemistry, 'proteinChemistry.pdf')}
              ><i className='fal fa-arrow-to-bottom' /> Download PDF</button>}
            </div>
          </div>
          <div className="methods-header">
            <p>30 min</p>
            <p><span>1.</span> Lyse cell pellets using an ultrasonicator/homogenizer; alternatively, use RIPA buffer, multiple freeze-thaw cycles at -80 °C, or a combination of these approaches.</p>
          </div>
          <div className="methods-header">
            <p>15 min</p>
            <p><span>2.</span> Centrifuge at 500xg to pellet out cellular debris</p>
          </div>
          <div className="methods-header">
            <p>15 min</p>
            <p><span>3.</span> Collect supernatant
              Add NuPAGE™ LDS Sample Buffer (4X) in a ratio of 1 part per 3 parts of the sample and heat at 80 °C for 10 min
            </p>
          </div>
          <div className="methods-header">
            <p>10 min</p>
            <p><span>4.</span> While the sample is under treatment, prepare 1X Running Buffer by diluting NuPAGE™ MES SDS Running Buffer (20X) in Deionized Water (DIW; ~18 MΩ)</p>
          </div>
          <div className="methods-header">
            <p>5 min</p>
            <p><span>5.</span> Prepare NuPAGE™ 4 to 12%, Bis-Tris, 1.0–1.5 mm, Mini Protein Gel cassettes</p>
          </div>
          <div className="methods-header">
            <p>30 min</p>
            <p><span>6.</span> Load NuPAGE™ 4%-12% Bis-Tris precast gel cassettes in the Mini Gel Tank
              Fill the tank / s with NuPAGE™ MES SDS Running Buffer (1X)
              Load PageRuler™ Prestained Protein Ladder and samples
              Perform electrophoresis at preset conditions
            </p>
          </div>
          <div className="methods-header">
            <p>5 min</p>
            <p><span>7.</span> Wash gels with Deionized Water
              Transfer the gels to the iBlot™ 3 Transfer Stacks, mini, PVDF membrane</p>
          </div>
          <div className="methods-header">
            <p>10 min</p>
            <p><span>8.</span> Transfer protein to the PVDF membrane using the preset conditions (low MW 3 min transfer)</p>
          </div>
          <div className="methods-header">
            <p>65 min</p>
            <p><span>9.</span> Wash the membrane in PBS for 1 min by Soaking
              and Blocking the membrane with 5% Casein (v/v) in PBS by continuous rocking
              for 60 min</p>
          </div>
          <div className="methods-header">
            <p>15 min</p>
            <p><span>10.</span> Wash 5X with PBS by continuous rocking for 2 min per wash</p>
          </div>
          <div className="methods-header">
            <p>65 min</p>
            <p><span>11a.</span> Incubate with Identifyn™ De Novo primary antibody (unconjugated) by continuously rocking for 60 min <br />
              <br />
              or
            </p>
          </div>
          <div className="methods-header">
            <p></p>
            <p style={{ marginTop: 0 }}>
              <span>11b.</span>Alternatively, incubate with Identifyn™ SRM Alexa Fluor™ De Novo primary antibody by continuously rocking for 60 min
            </p>
          </div>
          <div className="methods-header">
            <p>30 min</p>
            <p><span>12.</span> Wash 5X with PBS by continuously rocking for 5 min per wash (removing excess unbound reagents)
            </p>
          </div>
          <div className="methods-header">
            <p>65 min</p>
            <p><span>13.</span> If using Identifyn™ De Novo primary antibody (unconjugated) from step 11a, incubate with Identifyn™ SRM Alexa Fluor™ secondary antibody conjugate, offered in various host and reactivity combinations, by continuously rocking for 60 min
            </p>
          </div>
          <div className="methods-header">
            <p>~30 min</p>
            <p><span>14.</span> If you used Identifyn™ SRM Alexa Fluor™ De Novo primary antibody conjugate from step 11b, proceed to image acquisition with Azure Biosystems Sapphire Biomolecular imager
              <br /> <br />
              Est Time: ~30 min per membrane read, depending on the selected settings
              <br /> <br />
              Note: if using Identifyn™ SRM Alexa Fluor™ De Novo primary antibody conjugate, the execution of this protocol is complete. If not, continue to the following steps.
            </p>
          </div>
          <div className="methods-header">
            <p>30 min</p>
            <p><span>15.</span> If used Identifyn™ SRM Alexa Fluor™ secondary antibody conjugate from step 13, wash 5X with PBS by continuously rocking for 5 min per wash
            </p>
          </div>
          <div className="methods-header">
            <p>~30 min</p>
            <p><span>16.</span> Proceed to image acquisition with Azure Biosystems Sapphire Biomolecular imager
              <br /> <br />
              Est Time: ~30 min per membrane read, depending on the selected settings
            </p>
          </div>
          {width < 700 && <button className='btn' style={{ background: '#FFF2E5', color: '#DC4405', width: '100%' }}
            onClick={() => downloadPdf(proteinChemistry, 'proteinChemistry.pdf')}
          ><i className='fal fa-arrow-to-bottom' /> Download PDF</button>}
        </div>
      </AboutContent>
      <Footer />
    </Layout>
  )
}

export default Index