import { apiRoutes } from './apiRoutes'
import { apiCall } from './apiService/apiCall'

const loginApi = (userData) => apiCall(apiRoutes.login, 'post', userData)
const checkCurrentUserApi = (data) => apiCall(`${apiRoutes.currentUser}`, 'post', data)
const currentUserApi = () => apiCall(`${apiRoutes.customers}`, 'get')
const updateCustomerApi = (data) => apiCall(apiRoutes.customers, 'post', data)
const deleteCustomerApi = (id) => apiCall(`${apiRoutes.customers}/${id}`, 'delete')
const getCustomerGroupApi = (id) => apiCall(`${apiRoutes.customerGroups}/${id}`, 'get')
const getCustomerGroupsApi = () => apiCall(`${apiRoutes.customerGroups}`, 'get')
const updateCustomerToCustomerGroupApi = (data) => apiCall(`${apiRoutes.customerGroups}`, 'post', data)
const getCustomerOrdersApi = (status, search,date,limit=10, page=0) => apiCall(`${apiRoutes.orders}?expand=billing_address,items,payments,shipping_address,fulfillments&created_at[gt]=${date}&status[]=${status}&limit=${limit}&offset=${page}${search ? `&q=${search}` : ''}`, 'get')
const addCustomerAddressesApi = (data) => apiCall(apiRoutes.address, 'post', data)
const updateCustomerAddressesApi = (id, data) => apiCall(`${apiRoutes.address}/${id}`, 'post', data)
const deleteCustomerAddressesApi = (id) => apiCall(`${apiRoutes.address}/${id}`, 'delete')
const uploadApi = (data) => apiCall(apiRoutes.uploads, 'post', data, 'multipart/form-data')
const checkEmailExist = (email) => apiCall(`${apiRoutes.currentUser}/${email}`, 'get')
const createCustomerApi = (data) => apiCall(apiRoutes.createCustomer, 'post', data)
const createCustomerGroupApi = (data) => apiCall(apiRoutes.addGroup, 'post', data)
const verifyCustomerApi = (email,otp) => apiCall(`${apiRoutes.createCustomer}?email=${email}&otp=${otp}`, 'get')
const resetPasswordApi = (email) => apiCall(`${apiRoutes.resetPassword}`, 'post', email)
const restorePasswordApi = (data) => apiCall(`${apiRoutes.restorePassword}`, 'post', data )
const changeEmailApi = (data) => apiCall(apiRoutes.changeEmail, 'post', data)
const changePasswordApi = (data) => apiCall(apiRoutes.changePassword, 'post', data)
const createCartApi = (data) => apiCall(apiRoutes.carts, 'post', data)
const getCartApi = (id) => apiCall(`${apiRoutes.carts}/${id}`, 'get')
const calculateTaxApi = (id) => apiCall(`${apiRoutes.carts}/${id}/taxes`, 'post')
const updateCartApi = (id, data) => apiCall(`${apiRoutes.carts}/${id}`, 'post', data)
const addItemsToCartApi = (id, data) => apiCall(`${apiRoutes.carts}/${id}/line-items`, 'post', data)
const updateItemsToCartApi = (id, itemId, data) => apiCall(`${apiRoutes.carts}/${id}/line-items/${itemId}`, 'post', data)
const deleteItemsFromCartApi = (id, itemId) => apiCall(`${apiRoutes.carts}/${id}/line-items/${itemId}`, 'delete')
const getShippingOptionsApi = (id) => apiCall(`${apiRoutes.shipping}/${id}`, 'get')
const setShippingMethodsApi = (id, data) => apiCall(`${apiRoutes.carts}/${id}/shipping-methods`, 'post', data)
const createPaymentSessionsApi = (id) => apiCall(`${apiRoutes.carts}/${id}/payment-sessions`, 'post')
const updatePaymentSessionsApi = (id, providerId, data) => apiCall(`${apiRoutes.carts}/${id}/payment-sessions/${providerId}`, 'post', data)
const selectPaymentSessionApi = (id, data) => apiCall(`${apiRoutes.carts}/${id}/payment-session`, 'post', data)
const completeCartApi = (id) => apiCall(`${apiRoutes.carts}/${id}/complete`, 'post')
const getProductCategoriesApi = () => apiCall(`${apiRoutes.productCategories}`, 'get')
const getProductCategorysByIdApi = (id) => apiCall(`${apiRoutes.productCategories}/${id}?expand=products`, 'get')
const getProductsApi = (cat_id, limit=10, page=0, search='', sort='title') => apiCall(`${apiRoutes.products}?category_id[]=${cat_id}&include_category_children=true&limit=${limit}&offset=${page}&q=${search}`, 'get')
const getProductApi = (code, dye, limit, page, host, species, chain) => apiCall(`${apiRoutes.product}?mid_code=${code}&dyes=${dye}&limit=${limit}&offset=${page}${host && `&host=${host}`}${species && `&species_reactivity=${species}`}${chain && `&chain_specification=${chain}`}`, 'get')
const getProductFromListApi = (id) => apiCall(`${apiRoutes.product}?id=${id}`)
const getFeaturedApi = (featured) => apiCall(`${apiRoutes.product}?featured=${featured}&limit=20`, 'get')
const getProductTagsApi = (search='') => apiCall(`${apiRoutes.productTags}?q=${search}`, 'get')
const getProductByTagApi = (tagId,search='',limit=50, page=0) => apiCall(`${apiRoutes.products}?tags[]=${tagId}&offset=${page}&limit=${limit}&q=${search ? search : ''}`, 'get')
const getBestSellingApi = () => apiCall(`${apiRoutes.bestSelling}`, 'get')
const getRegionsApi = () => apiCall(`${apiRoutes.regions}`, 'get')
const getGalleryApi = (limit=30, page=1,search, filter) => apiCall(`${apiRoutes.media}?limit=${limit}&page=${page}&name=${search ? search : ''}${filter}`, 'get')
const contactApi = (data) => apiCall(`${apiRoutes.contact}`, 'post', data)
const getTeamsApi = () => apiCall(`${apiRoutes.teams}`, 'get')


export {
loginApi,
checkCurrentUserApi,
currentUserApi,
updateCustomerApi,
deleteCustomerApi,
getCustomerGroupApi,
getCustomerGroupsApi,
updateCustomerToCustomerGroupApi,
getCustomerOrdersApi,
addCustomerAddressesApi,
updateCustomerAddressesApi,
deleteCustomerAddressesApi,
uploadApi,
checkEmailExist,
createCustomerApi,
createCustomerGroupApi,
verifyCustomerApi,
resetPasswordApi,
restorePasswordApi,
changeEmailApi,
changePasswordApi,
createCartApi,
getCartApi,
calculateTaxApi,
updateCartApi,
addItemsToCartApi,
updateItemsToCartApi,
deleteItemsFromCartApi,
getShippingOptionsApi,
setShippingMethodsApi,
createPaymentSessionsApi,
updatePaymentSessionsApi,
selectPaymentSessionApi,
completeCartApi,
getProductCategoriesApi,
getProductCategorysByIdApi,
getProductsApi,
getProductApi,
getProductFromListApi,
getProductTagsApi,
getProductByTagApi,
getBestSellingApi,
getRegionsApi,
getGalleryApi,
contactApi,
getFeaturedApi,
getTeamsApi
}
