export const getHighlightedText = (text, highlight, color) => {
    const parts = text?.split(new RegExp(`(${highlight})`, 'gi'));
    return (
      <span>
        {parts?.map((part) =>
          part?.toLowerCase() === highlight?.toLowerCase() ? (
            <span style={{ color: color ? color : '#fff', fontWeight: 700 }}>
              {part}
            </span>
          ) : (
            part
          )
        )}
      </span>
    );
  };