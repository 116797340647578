import React, { useEffect, useState, useRef, useContext } from 'react'
import Layout from '../../../components/layout'
import { Wrapper } from './style'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import ProteinMap from './proteinMap'
import SideDrawer from '../../../components/Drawers/slideDrawer/sideDrawer'
import Pagination from '../../../components/pagination'
import Input from '../../../components/Input'
import MethodologyModal from "../../../components/modals/MethodologyModal"
import { Tooltip as ReactTooltip } from "react-tooltip";
import { toAbsoluteUrl } from '../../../utils/assetHelper'
import ReactPrint from "react-to-print";
import Coa from '../../../components/coA'
import { AuthContext } from '../../../context/auth'
import { getProductCategoriesApi, getProductsApi, getProductTagsApi, getProductByTagApi, createCartApi, addItemsToCartApi, getProductFromListApi } from '../../../api'
import { getLocalStorage, setLocalStorage } from "../../../helpers/localStorage";
import { useDebounce } from 'use-debounce';
import { proteinFunctions, proteinMapLink } from '../../../utils/proteinName'
import { getHighlightedText } from '../../../utils/highlighter'
import FeedBackModal from "../../../components/modals/FeedBackModal"
import ProteinFunctionModal from "../../../components/modals/ProteinFunctionModal"

const Index = () => {
    const printRef = useRef(null)
    const coARef = useRef(null)
    const tipRef = useRef(null)
    const onBeforeGetContentResolve = useRef();
    const navigate = useNavigate()
    const location = useLocation()
    const { width, setIsLoading, isLoading, setNotification, setCartData, tempDisable, setTempDisable, selectedProtein, setSelectedProtein, selectedProduct, setSelectedProduct, setMapViewActive, mapViewActive } = useContext(AuthContext)
    const cartId = getLocalStorage('cartId')
    const [tab, setTab] = useState("map")
    // const [selectedProtein, setSelectedProtein] = useState(false)
    const [openMethodologyModal, setOpenMethodologyModal] = useState()
    const [search, setSearch] = useState("")
    const [pageSize, setPageSize] = useState(10)
    const [currentPage, setCurrentPage] = useState(0)
    const [products, setProducts] = useState([])
    const [productCategory, setProductCategory] = useState([])
    const [proteinId, setProteinId] = useState(null)
    // const [selectedProduct, setSelectedProduct] = useState(null)
    const [modalName, setModalName] = useState(null)
    const [debounceSearch] = useDebounce(search, 500);
    const [openFeedBackModal, setOpenFeedBackModal] = useState(false)
    const [openKeyTip, setOpenKeyTip] = useState(false)
    const [openFunctionModal, setOpenFunctionModal] = useState(false)
    const [filteredProteinProducts, setFilteredProteinProducts] = useState([])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (tipRef.current && !tipRef.current.contains(event.target)) {
                setOpenKeyTip(false)
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };

    }, []);


    const handleOnBeforeGetContent = (business) => {
        return new Promise((resolve) => {
            setIsLoading(true)
            setSelectedProduct(business);
            onBeforeGetContentResolve.current = resolve;

        });
    };

    React.useEffect(() => {
        if (selectedProduct) {
            onBeforeGetContentResolve.current && onBeforeGetContentResolve.current();
        }
    }, [selectedProduct, onBeforeGetContentResolve]);

    useEffect(() => {
        if (openMethodologyModal || (selectedProtein && width < 500 && tab === "map")) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "unset";
        }

    }, [openMethodologyModal, selectedProtein, tab, width])

    // useEffect(() => {
    //     if (width > 1200) {
    //         setTab("map")
    //         setTimeout(() => {
    //             setOpenFeedBackModal(true)
    //         }, [5000])
    //     } else {
    //         setTab("list")
    //         setOpenFeedBackModal(false)
    //     }
    // }, [width])

    useEffect(() => {
        setTimeout(() => {
            setOpenFeedBackModal(true)
        }, [5000])
    }, [])

    useEffect(() => {
        if (selectedProtein) {
            setIsLoading(true)
            getProductTagsApi(selectedProtein).then((res) => {
                if (res && res.data && res.data.product_tags && res.data?.product_tags?.length > 0) {
                    setProteinId(res.data.product_tags?.find((item) => item.value === selectedProtein))
                    if (res?.data?.product_tags && res?.data?.product_tags?.length > 0) {
                        getProductByTagApi(res?.data?.product_tags?.find((item) => item.value === selectedProtein).id).then((res) => {
                            if (res && res.data && res.data.products) {
                                setFilteredProteinProducts(res.data)
                                // setSelectedProduct(res.data.products[0])
                                setIsLoading(false)
                            } else {
                                setIsLoading(false)
                                setFilteredProteinProducts([])
                                setSelectedProduct(null)
                            }
                        })
                    }
                    setIsLoading(false)
                } else {
                    setIsLoading(false)
                    setFilteredProteinProducts([])
                    setSelectedProduct(null)
                    setProteinId(null)
                }
            }).catch(() => {
                setIsLoading(false)
                setFilteredProteinProducts([])
                setSelectedProduct(null)
            })
        }
    }, [selectedProtein])


    const fetchProductCategories = () => {
        setIsLoading(true)
        getProductCategoriesApi()
            .then(res => {
                if (res && res.data?.product_categories && res.data?.product_categories.length > 0) {
                    if (res?.data?.product_categories?.find((item) => item?.name == 'Primary Antibodies' || item.name == 'Alexa Flour Primary Antibodies')) {
                        setProductCategory(res?.data?.product_categories?.filter((item) => item?.name == 'Primary Antibodies' || item.name == 'Alexa Flour Primary Antibodies'))
                        setIsLoading(false)
                    } else {
                        setProductCategory(null)
                        setIsLoading(false)
                    }
                } else {
                    setProductCategory(null)
                    setProducts([])
                    setIsLoading(false)
                }
            })
            .catch(err => {
                console.error(err)
                setIsLoading(false)
            })
    }

    useEffect(() => {
        fetchProductCategories()
    }, [])


    const fetchProduct = () => {
        setIsLoading(true)
        getProductsApi(`${productCategory.map(item => item.id).join(",")}`, pageSize, currentPage * pageSize, search).then(res => {
            if (res && res.data) {
                setProducts(res?.data)
                setIsLoading(false)
            }
            setIsLoading(false)
        }).catch(err => {
            console.error(err)
            setIsLoading(false)
        })
    }

    useEffect(() => {
        if (!selectedProtein && productCategory) {
            fetchProduct()
        } else if (proteinId?.id) {
            setIsLoading(true)
            getProductByTagApi(proteinId.id, search, pageSize, currentPage).then((res) => {
                if (res && res.data && res.data.products) {
                    setProducts(res.data)
                    setIsLoading(false)
                } else {
                    setIsLoading(false)
                    setSelectedProduct(null)
                }
            })
        }
    }, [debounceSearch, currentPage, productCategory, pageSize, proteinId])


    const AddToCart = (product) => {
        setIsLoading(true)
        if (!cartId) {
            createCartApi().then(res => {
                setLocalStorage('cartId', res.data.cart.id)
                addItemsToCartApi(res.data.cart.id, {
                    variant_id: product?.variants[0]?.id,
                    quantity: 1,
                }).then((res) => {
                    if (res && res.data && res.data?.cart) {
                        setCartData(res.data.cart)
                        setNotification({
                            message: 'Added to cart',
                            type: 'green',
                        })
                        setIsLoading(false)
                    } else {
                        setNotification({
                            message: 'Something went wrong',
                            type: 'red',
                        })
                        setIsLoading(false)
                    }
                })
            })
        } else {
            addItemsToCartApi(cartId, {
                variant_id: product?.variants[0]?.id,
                quantity: 1,
            }).then((res) => {
                if (res && res.data && res.data?.cart) {
                    setCartData(res.data.cart)
                    setNotification({
                        message: 'Added to cart',
                        type: 'green',
                    })
                    setIsLoading(false)
                } else {
                    setNotification({
                        message: 'Something went wrong',
                        type: 'red',
                    })
                    setIsLoading(false)
                }
            })
        }
    }

    const BuyProduct = (product) => {
        setIsLoading(true)
        if (!cartId) {
            createCartApi().then(res => {
                setLocalStorage('cartId', res.data.cart.id)
                addItemsToCartApi(res.data.cart.id, {
                    variant_id: product?.variants[0]?.id,
                    quantity: 1,
                }).then((res) => {
                    if (res && res.data && res.data?.cart) {
                        setCartData(res.data.cart)
                        setNotification({
                            message: 'Added to cart',
                            type: 'green',
                        })
                        setIsLoading(false)
                        navigate('/checkout')
                    } else {
                        setNotification({
                            message: 'Something went wrong',
                            type: 'red',
                        })
                        setIsLoading(false)
                    }
                })
            })
        } else {
            addItemsToCartApi(cartId, {
                variant_id: product?.variants[0]?.id,
                quantity: 1,
            }).then((res) => {
                if (res && res.data && res.data?.cart) {
                    setCartData(res.data.cart)
                    setNotification({
                        message: 'Added to cart',
                        type: 'green',
                    })
                    setIsLoading(false)
                    navigate('/checkout')
                } else {
                    setNotification({
                        message: 'Something went wrong',
                        type: 'red',
                    })
                    setIsLoading(false)
                }
            })
        }
    }

    const getProductFromList = (data, to, protein, isSameRoute) => {
        setIsLoading(true)
        setSelectedProduct(data)
        setSelectedProtein(protein)
        setMapViewActive(true)
        if (isSameRoute) {
            setTab('map')
        } else {
            navigate(`/product/pathways/${to}`)
        }
        setIsLoading(false)
        // getProductFromListApi(id).then(res => {
        //     if (res && res.data) {
        //         setSelectedProduct(res?.data?.products[0])
        //         setIsLoading(false)
        //         setSelectedProtein(protein)
        //         setMapViewActive(true)
        //         if (isSameRoute) {
        //             setTab('map')
        //         } else {
        //             navigate(`/product/pathways/${to}`)
        //         }
        //     }
        //     setIsLoading(false)
        // }).catch(err => {
        //     console.error(err)
        //     setIsLoading(false)
        //     })
        }






// const businessData = [
//     {

//         cat: "PA-MM-12345",
//         protein_name: "Mouse Monoclonal Anti-Human PARP1",
//         Applications: [
//             {
//                 abbr: 'WB',
//                 name: 'Western Blot'
//             },
//             {
//                 abbr: 'IF',
//                 name: 'Western Blot'
//             },
//             {
//                 abbr: 'SRM',
//                 name: 'Western Blot'
//             }
//         ],
//         concentration: '10mg/ml',
//         price: "$99.99",

//     },
//     {

//         cat: "PA-MM-12345",
//         protein_name: "Mouse Monoclonal Anti-Human PARP1",
//         Applications: [
//             {
//                 abbr: 'WB',
//                 name: 'Western Blot'
//             },
//             {
//                 abbr: 'IF',
//                 name: 'Western Blot'
//             },
//             {
//                 abbr: 'SRM',
//                 name: 'Western Blot'
//             }
//         ],
//         concentration: '10mg/ml',
//         price: "$99.99",

//     },
//     {

//         cat: "PA-MM-12345",
//         protein_name: "Mouse Monoclonal Anti-Human PARP1",
//         Applications: [
//             {
//                 abbr: 'WB',
//                 name: 'Western Blot'
//             },
//             {
//                 abbr: 'IF',
//                 name: 'Western Blot'
//             },
//             {
//                 abbr: 'SRM',
//                 name: 'Western Blot'
//             }
//         ],
//         concentration: '10mg/ml',
//         price: "$99.99",

//     },
//     {

//         cat: "PA-MM-12345",
//         protein_name: "Mouse Monoclonal Anti-Human PARP1",
//         Applications: [
//             {
//                 abbr: 'WB',
//                 name: 'Western Blot'
//             },
//             {
//                 abbr: 'IF',
//                 name: 'Western Blot'
//             },
//             {
//                 abbr: 'SRM',
//                 name: 'Western Blot'
//             }
//         ],
//         concentration: '10mg/ml',
//         price: "$99.99",

//     },
//     {

//         cat: "PA-MM-12345",
//         protein_name: "Mouse Monoclonal Anti-Human PARP1",
//         Applications: [
//             {
//                 abbr: 'WB',
//                 name: 'Western Blot'
//             },
//             {
//                 abbr: 'IF',
//                 name: 'Western Blot'
//             },
//             {
//                 abbr: 'SRM',
//                 name: 'Western Blot'
//             }
//         ],
//         concentration: '10mg/ml',
//         price: "$99.99",

//     },
//     {

//         cat: "PA-MM-12345",
//         protein_name: "Mouse Monoclonal Anti-Human PARP1",
//         Applications: [
//             {
//                 abbr: 'WB',
//                 name: 'Western Blot'
//             },
//             {
//                 abbr: 'IF',
//                 name: 'Western Blot'
//             },
//             {
//                 abbr: 'SRM',
//                 name: 'Western Blot'
//             }
//         ],
//         concentration: '10mg/ml',
//         price: "$99.99",

//     },
//     {

//         cat: "PA-MM-12345",
//         protein_name: "Mouse Monoclonal Anti-Human PARP1",
//         Applications: [
//             {
//                 abbr: 'WB',
//                 name: 'Western Blot'
//             },
//             {
//                 abbr: 'IF',
//                 name: 'Western Blot'
//             },
//             {
//                 abbr: 'SRM',
//                 name: 'Western Blot'
//             }
//         ],
//         concentration: '10mg/ml',
//         price: "$99.99",

//     },
//     {

//         cat: "PA-MM-12345",
//         protein_name: "Mouse Monoclonal Anti-Human PARP1",
//         Applications: [
//             {
//                 abbr: 'WB',
//                 name: 'Western Blot'
//             },
//             {
//                 abbr: 'IF',
//                 name: 'Western Blot'
//             },
//             {
//                 abbr: 'SRM',
//                 name: 'Western Blot'
//             }
//         ],
//         concentration: '10mg/ml',
//         price: "$99.99",

//     },
//     {

//         cat: "PA-MM-12345",
//         protein_name: "Mouse Monoclonal Anti-Human PARP1",
//         Applications: [
//             {
//                 abbr: 'WB',
//                 name: 'Western Blot'
//             },
//             {
//                 abbr: 'IF',
//                 name: 'Western Blot'
//             },
//             {
//                 abbr: 'SRM',
//                 name: 'Western Blot'
//             }
//         ],
//         concentration: '10mg/ml',
//         price: "$99.99",

//     },
//     {

//         cat: "PA-MM-12345",
//         protein_name: "Mouse Monoclonal Anti-Human PARP1",
//         Applications: [
//             {
//                 abbr: 'WB',
//                 name: 'Western Blot'
//             },
//             {
//                 abbr: 'IF',
//                 name: 'Western Blot'
//             },
//             {
//                 abbr: 'SRM',
//                 name: 'Western Blot'
//             }
//         ],
//         concentration: '10mg/ml',
//         price: "$99.99",

//     },
// ];

return (
    <Layout>
        <Wrapper>
            <div className="pathway-header-container">
                <div className="bread-cumbs-container">
                    <div className="tab-container">
                        <button style={{ borderTopLeftRadius: '12px', borderBottomLeftRadius: '12px' }} className={tab === "map" && 'tab-active'} onClick={() => setTab("map")}><i class="fal fa-chart-network"></i></button>
                        <button style={{ borderTopRightRadius: '12px', borderBottomRightRadius: '12px' }} className={tab === "list" && 'tab-active'} onClick={() => setTab("list")}><i class="fal fa-bars"></i></button>
                    </div>
                    {width > 1100 && <Link to="/product/pathways">
                        Pathways
                        <i class="fas fa-chevron-right"></i>
                    </Link>
                    }
                    <Link to="/product/pathways" state={{ ssb: true }}>
                        {/* <i class="fas fa-dna dna-icon"></i> */}
                        <img src={toAbsoluteUrl('/media/dnaIcon.png')} alt="logo" width={20} height={20} />
                        DNA Damage & Repair
                        <i class="fas fa-chevron-right"></i>
                    </Link>
                    <Link to="/product/pathways" state={{ dsb: true }}>
                        Double Strand Break
                        <i class="fas fa-chevron-right"></i>
                    </Link>
                    <Link style={{ color: 'var(--primary-color)', fontWeight: '700', cursor: 'default' }}>
                        Homologous Recombination
                    </Link>
                </div>
                {tab === 'map' ? <>
                    <ReactPrint
                        trigger={() => <span className='print-btn'>
                            <i className='fal fa-eye' />
                            See Full Map</span>}
                        content={() => printRef.current}
                    />
                </>
                    : <>
                        <div className="header-search-container">
                            {
                                proteinId &&
                                <button className='print-btn' style={{ width: '200px' }}
                                    onClick={() => {
                                        setProteinId(null)
                                        setSelectedProduct(null)
                                        setSelectedProtein(null)
                                        setMapViewActive(false)
                                    }}
                                >
                                    View All
                                </button>
                            }
                            <Input type="text" search style={{ height: '40px', paddingLeft: '30px', width: '100%' }} placeholder={'Search'} value={search} onChange={(e) => setSearch(e.target.value)} clear={search} onClear={() => setSearch('')} />
                            {width > 500 && <select
                                data-placeholder='Topic'
                                value={pageSize}
                                placeholder='Select Page Size'
                                onChange={(e) => {
                                    setPageSize(e.target.value)
                                }}
                            >
                                <option value={10} selected>10</option>
                                <option value={20} selected>20</option>
                                <option value={30} selected>30</option>
                            </select>
                            }
                        </div>
                        {/* {
                                width < 1100 &&
                                proteinId &&
                                <button className='print-btn' style={{ width: '200px' }}
                                    onClick={() => {
                                        setProteinId(null)
                                        setSelectedProduct(null)
                                        setSelectedProtein(null)
                                        setMapViewActive(false)
                                    }}
                                >
                                    View All
                                </button>
                            } */}
                    </>
                }
            </div>

            {tab === 'map' ? <div className="map-container">
                <div className={`map-showcase ${selectedProtein ? 'active-map-show' : ''}`}>
                    <ProteinMap setSelectedProtein={setSelectedProtein} selectedProtein={selectedProtein} setMapViewActive={setMapViewActive} />
                    {
                        selectedProtein && width > 1100 &&
                        <div className="function-container">
                            <div className="function-wrapper">
                                <div className="function">
                                    <h4>Function:</h4>
                                    <p className='function-text'>
                                        {proteinFunctions?.find((item) => item.name === selectedProtein) && proteinFunctions?.find((item) => item.name === selectedProtein).preview ? `${proteinFunctions?.find((item) => item.name === selectedProtein).preview?.slice(0, 120)}...` : '-'}{proteinFunctions?.find((item) => item.name === selectedProtein)?.preview?.length > 120 && '...'}
                                        {proteinFunctions?.find((item) => item.name === selectedProtein) && proteinFunctions?.find((item) => item.name === selectedProtein)?.preview?.length > 120 && proteinFunctions?.find((item) => item.name === selectedProtein).preview?.length > 120 && <button className='transparent' style={{ width: 'max-content' }} onClick={() => setOpenFunctionModal(true)}>Read More</button>}
                                    </p>
                                </div>
                                <div className="references">
                                    <h4>References:</h4>
                                    <div className="references-list">
                                        {proteinFunctions?.find((item) => item.name === selectedProtein)?.reference.filter((item) => item !== "").map((item, index) => {
                                            return <a href={item.includes("https") ? item : `https://${item}`} target="_blank" key={index}>[{index + 1}]</a>
                                        })}
                                    </div>
                                </div>
                                <div className="key" onClick={() => setOpenKeyTip(!openKeyTip)}>
                                    <i className='fal fa-question-circle' /> <span>Key</span>
                                </div>
                                {selectedProtein && openKeyTip && <img ref={tipRef} className='tip-img' src={toAbsoluteUrl('/media/tip.png')} alt='tip' />}
                            </div>
                        </div>
                    }
                </div>
                <div className={`product-details ${selectedProtein ? `active-product-show ${width < 1200 && 'active-product-show-mobile'}` : ''}`}>
                    <div className="product-details-wrapper">
                        <SideDrawer setSelectedProtein={setSelectedProtein} selectedProtein={selectedProtein} selectedProduct={selectedProduct} setSelectedProduct={setSelectedProduct} setProteinId={setProteinId} proteinId={proteinId} setOpenMethodologyModal={setOpenMethodologyModal} setModalName={setModalName} coARef={coARef} setOpenFunctionModal={setOpenFunctionModal} filteredProteinProducts={filteredProteinProducts} />
                    </div>
                </div>
            </div>
                :
                products?.count > 0 ?
                    <>
                        <div className="list-container" style={{
                            marginTop: width < 500 &&
                                proteinId ? '270px' : width < 500 && '220px'
                        }}>
                            {width > 1200 ? <table className={"mainTable"}>
                                <thead>
                                    <tr>
                                        <th>Protein Target</th>
                                        <th>Cat ID# </th>
                                        <th>Applications </th>
                                        <th>Concentration</th>
                                        <th>Price</th>
                                        <th>CoA</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {products?.products && products?.products?.map((business) => {
                                        return (
                                            <tr
                                                key={business?.id}
                                            >
                                                <td style={{ width: '30%' }}>
                                                    {business?.title ? getHighlightedText(business?.title, search, "#DC4405") : "-"}
                                                </td>
                                                <td style={{ width: '15%' }}>
                                                    {business?.material ? business?.mid_code + " " + business?.material : "-"}
                                                </td>
                                                <td style={{ width: '25%' }}>
                                                    {(business?.metadata?.protein_application || business?.metadata?.microscopy_application) ?
                                                        <div className="applications">
                                                            {business?.metadata?.protein_application?.map((item) => {
                                                                return <span onClick={() => {
                                                                    setModalName(item.name)
                                                                    setOpenMethodologyModal(true)
                                                                    setSelectedProduct(business)
                                                                }} data-tooltip-id="applications" data-tooltip-content={item.name}>{item.abbr}</span>
                                                            })
                                                            }
                                                            {business?.metadata?.microscopy_application?.map((item) => {
                                                                return <span onClick={() => {
                                                                    setModalName(item.name)
                                                                    setOpenMethodologyModal(true)
                                                                    setSelectedProduct(business)
                                                                }} data-tooltip-id="applications" data-tooltip-content={item.name}>{item.abbr}</span>
                                                            })
                                                            }
                                                        </div>

                                                        : "-"}
                                                </td>
                                                <td style={{ width: '5%' }}>
                                                    {business?.concentration ? business?.concentration + ' ' + 'mg/ml' : "-"}
                                                </td>
                                                <td><span style={{ fontWeight: 600 }}>{`$${(business?.variants[0]?.prices[0]?.amount / 100)?.toFixed(2)}` || "-"}</span></td>
                                                <td style={{ cursor: "pointer" }}>
                                                    <span style={{ display: 'flex', gap: '30px', alignItems: 'center', justifyContent: 'flex-end' }}>
                                                        <button className='btn buy-btn'
                                                            style={{ width: 'max-content' }}
                                                            disabled={isLoading}
                                                            onClick={() => {
                                                                const proteinName = proteinMapLink.find((item) => item.name === business.subtitle)?.maps[0]
                                                                // navigate(`/product/pathways/${proteinName === 'SSB' ? 'ssb' : proteinName === 'HR' ? 'dsb-hr' : 'dsb-nhej'}`)
                                                                // setSelectedProtein(business?.subtitle)
                                                                // setMapViewActive(true)
                                                                getProductFromList(business, proteinName === 'SSB' ? 'ssb' : proteinName === 'HR' ? 'dsb-hr' : 'dsb-nh', business?.subtitle, location.pathname === "/product/pathways/dsb-hr" && proteinName === 'HR' ? true : false)
                                                            }}
                                                        >
                                                            Map View
                                                        </button>
                                                        {/* <button className='btn add-btn'
                                                                disabled={isLoading}
                                                                onClick={() => tempDisable ? setTempDisable(false) : AddToCart(business)}
                                                            >
                                                                <i className='fal fa-shopping-cart'></i>
                                                            </button>
                                                            <button className='btn buy-btn'
                                                                disabled={isLoading}
                                                                onClick={() => tempDisable ? setTempDisable(false) : BuyProduct(business)}
                                                            >Buy Now</button> */}
                                                        <ReactPrint
                                                            onBeforeGetContent={() => handleOnBeforeGetContent(business)}
                                                            onAfterPrint={() => {
                                                                setIsLoading(false)
                                                                setSelectedProduct(null)
                                                            }}
                                                            trigger={() => <span className='coa'>
                                                                CoA</span>}
                                                            content={() => coARef.current}
                                                        />
                                                    </span>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                                :
                                <div className='products-cards-container'>
                                    {products?.products && products?.products?.map((business) => {
                                        return <div className="product-card">
                                            <h4>{business.title ? business.title : '-'}</h4>
                                            <div className="product-double-column">
                                                <div className="label-name">
                                                    <h5>Cat ID#</h5>
                                                    <h4>{business?.material ? business?.mid_code + " " + business?.material : "-"}</h4>
                                                </div>
                                                <div className="label-name">
                                                    <h5>Concentration</h5>
                                                    <h4>{business?.concentration ? business?.concentration + ' ' + 'mg/ml' : "-"}</h4>
                                                </div>
                                                <div className="label-name" style={{ textAlign: 'right' }}>
                                                    <h5>Price</h5>
                                                    <h4><span style={{ color: 'var(--primary-color)', fontWeight: '600' }}>{`$${(business?.variants[0]?.prices[0]?.amount / 100)?.toFixed(2)}` || "-"}</span></h4>
                                                </div>
                                            </div>
                                            {(business?.metadata?.protein_application || business?.metadata?.microscopy_application) ?
                                                <div className="applications" style={{ marginTop: '12px' }}>
                                                    {business?.metadata?.protein_application?.map((item) => {
                                                        return <span onClick={() => {
                                                            setModalName(item.name)
                                                            setOpenMethodologyModal(true)
                                                            setSelectedProduct(business)
                                                        }} data-tooltip-id="applications" data-tooltip-content={item.name}>{item.abbr}</span>
                                                    })
                                                    }
                                                    {business?.metadata?.microscopy_application?.map((item) => {
                                                        return <span onClick={() => {
                                                            setModalName(item.name)
                                                            setOpenMethodologyModal(true)
                                                            setSelectedProduct(business)
                                                        }} data-tooltip-id="applications" data-tooltip-content={item.name}>{item.abbr}</span>
                                                    })
                                                    }
                                                </div>

                                                : "-"}
                                            {/* <button className='btn primary-btn'
                                                    style={{ marginTop: '12px', marginBottom: '2px' }}
                                                    disabled={isLoading}
                                                    onClick={() => tempDisable ? setTempDisable(false) : AddToCart(business)}
                                                >
                                                    <i className='fal fa-shopping-cart'></i> Add to Cart
                                                </button> */}
                                            <ReactPrint
                                                onBeforeGetContent={() => handleOnBeforeGetContent(business)}
                                                onAfterPrint={() => {
                                                    setIsLoading(false)
                                                    setSelectedProduct(null)
                                                }}
                                                trigger={() => <span className='coa'>
                                                    CoA</span>}
                                                content={() => coARef.current}
                                            />
                                        </div>
                                    })}
                                </div>
                            }
                            {products?.count / products.limit > 1 && <Pagination total={products?.count / products.limit} setCurrentPage={setCurrentPage} currentPage={currentPage} />}
                        </div>
                    </>
                    : <div className='no-orders'>
                        <span className='icon'>
                            <i class="fal fa-boxes"></i>
                        </span>
                        <p>No Products Yet.</p>
                    </div>

            }
            <ReactTooltip
                id="applications"
                place="top"
                variant="light"
            />
            <div className="print-map" style={{ display: 'none' }}>
                <img src={toAbsoluteUrl('/media/proteinMap/HR.svg')} alt="" width={'100%'} height={'100%'} ref={printRef} />
            </div>
            <div className="print-map" style={{ display: 'none' }}>
                <Coa reference={coARef} selectedProduct={selectedProduct} productCategory={selectedProduct?.mid_code.search("PA") >= 0 ? 'PA' : selectedProduct?.mid_code.search("SA") >= 0 ? 'SA' : 'DC'} />
            </div>
        </Wrapper>
        {openMethodologyModal && <MethodologyModal setOpenMethodologyModal={setOpenMethodologyModal} selectedProduct={selectedProduct} setSelectedProduct={setSelectedProduct} modalName={modalName} setModalName={setModalName} />}
        {openFeedBackModal && <FeedBackModal setOpenFeedBackModal={setOpenFeedBackModal} />}
        {openFunctionModal && <ProteinFunctionModal selectedProtein={selectedProtein} setOpenFunctionModal={setOpenFunctionModal} proteinFunctions={proteinFunctions} />}
    </Layout>
)
}

export default Index