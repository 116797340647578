import styled from "styled-components";

export const Wrapper = styled.div`
margin: 0px 0px 0 44px;
overflow-y: auto;
height: 100%;
border-radius: 20px;
background: #fff;
box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.05);
padding: 26px 20px;
display: flex;
flex-direction: column;
gap: 20px;

@media screen and (max-width: 1100px) {
    margin: 0;
}

.header{
    h4{
        font-size: 20px;
        line-height: 28px;
        font-weight: 700;
    }
.header-btn-container{
    display: flex;
    align-items: center;
    gap: 20px;

    @media screen and (max-width: 1100px) {
    display: none;
}
}


}

.input-wrapper{
    display: flex;
    gap: 16px;
    align-items: center;
    width: 100%;
    flex-direction: column;

.input-container{
    display: flex;
    flex-direction: column;
    gap:5px;
    width: 100%;

    label{
        font-size: 12px;
        color:#9a9a9a;
    }

    h4{
        font-size: 16px;
        font-weight: 700;
        line-height: 28px;
    }

input {
    height: 48px;
    width: 100%;
}

}


}

.select-option{
                padding: 5px 16px;
                border-radius: 12px;
                border: 1px solid #F1EEFC;
                background: #FCFCFC;
                width: -webkit-fill-available;

            .select-active{
            border: 1px solid #DC4405;
            background-color: #F8F7FE;

           .containers > span{
                color:var(--primary-color) !important;
                font-weight: 700;
            }
            }

            .containers {
                font-weight: 500;
                display: block;
                position: relative;
                padding-left: 35px;
                padding-top: 10px;
                margin-bottom: 12px;
                cursor: pointer;
                font-size: 16px;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                color: #0b161d;
            }

            /* Hide the browser's default radio button */
            .containers input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                left: 0;
                top:0;
                width: 100%;
                z-index: 9;
            }

            span{
                color:#1E1E1E;
                font-size: 16px;
            }

            /* Create a custom radio button */
            .checkmark {
                position: absolute;
                top: 0;
                left: 0;
                height: 21px;
                width: 21px;
                background-color: transparent;
                border-radius: 50%;
                border: 2px solid rgb(215, 76, 16);
                transform: translateY(10px);
            }

            /* On mouse-over, add a grey background color */
            .containers:hover input~.checkmark {
                background-color: var(--primary-color);
            }

            /* When the radio button is checked, add a blue background */
            .containers input:checked~.checkmark {
                background-color: #fff;
            }

            /* Create the indicator (the dot/circle - hidden when not checked) */
            .checkmark:after {
                content: "";
                position: absolute;
                display: none;
            }

            /* Show the indicator (dot/circle) when checked */
            .containers input:checked~.checkmark:after {
                display: block;
            }

            /* Style the indicator (dot/circle) */
            .containers .checkmark:after {
                top: 3.5px;
                left: 3.5px;
                width: 10.5px;
                height: 10.5px;
                border-radius: 50%;
                background: var(--primary-color);
            }

            .containers:hover input:checked~.checkmark:after {
                top: 3.5px;
                left: 3.5px;
                width: 10.5px;
                height: 10.5px;
                border-radius: 50%;
                background: var(--primary-color);
            }


        }


        .button-container{
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 1100px) {
    flex-direction: column;
    gap: 12px;
  }

  button{
    display: flex;
    gap: 10px;
    align-items: center;

    @media screen and (max-width: 1100px) {
    width: 100%;
    justify-content: center;
  }

}
}


`