import React from 'react'
import Layout from '../../components/layout'
import { AboutHeader, AboutContent } from './style'
import { toAbsoluteUrl } from '../../utils/assetHelper'
import { Footer } from '../../components/Footer'

const Index = () => {
  return (
    <Layout>
      <div className="container title-container bio-container">
        <span style={{ width: '44px', height: '44px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '12px', background: '#fff2e5', color: 'var(--primary-color)', marginBottom: '20px' }}>
          <i class="far fa-id-badge"></i>
        </span>
        <AboutHeader>
          <h1>About <span>Us</span></h1>
        </AboutHeader>
      </div>
      <AboutContent>
        <div className="container azure-section-four-container" style={{ marginBottom: '0px' }}>
          <h2>Identifyn™</h2>
          <p><span>Identifyn™️ is the first commercial super resolution Imaging research and reagent manufacturing facility</span>. Identifyn™ focuses on bringing fundamental biological pathway research via highly vetted <span>de novo antibodies and Identifyn's™ primary and secondary super resolution vetted flourescent products</span> in support of academic and pharmaceutical research.</p>
          <p><span>Identifyn™️ has partnered with industry leaders</span> in microscopy, fluorescent chemistry, and protein biology to <span>empower breakthrough research</span> by <span>providing a next-level validation platform</span> for discovery from <span>widefield to single-molecule microscopy, immunocytochemistry, and reagent development</span>.</p>
          <p>Identifyn's™ <span>state-of-the-art super resolution laboratories</span> also offer <span>services and assay development</span> for select projects</p>
        </div>
        <div className="container azure-section-four-container" style={{ background: 'rgba(237, 237, 237, 0.20)', marginBottom: '0px' }} >
          <h2>Early Vision</h2>
          <p>
            The foundational vision for Identifyn™️ <span>was conceptualized in early 2007 when CSO and Co-Founder Dr. Brian T. Bennett developed Immunocellularchemistry (ICC) assays</span> tracking various proteins involved in DNA damage repair via novel microscopy and mathematical analysis techniques. This research path created the need for an even more powerful resolution, leading to Dr. Bennett's development of a novel and easy-to-use single-molecule resolution microscope (PALM) while a research professor at the University of Utah. Dr. Bennett founded and funded a start-up with the U of UT called Scintalla, later to become Vutara, which Bruker acquired and now sells as Vutara VXL. Dr. Bennett left the industry after the U of UT, investors, colleagues, and others actively removed him from his work and contributions to creating applications combining super resolution with biological sciences to further drug discovery that would translate to the clinical space. <span>He would ultimately exit the industry he sought to change for over a decade,</span> leaving his significant work dormant.
          </p>
        </div>
        {/* <div className="container azure-container" style={{ marginBottom: '32px' }}>
          <div className="left-container">
            <div className="solutions-text">
              <h2 style={{ width: '100%' }}>Early Vision</h2>
              <p>
                The foundational vision for Identifyn™️ <span>was conceptualized in early 2007 when CSO and Co-Founder Dr. Brian T. Bennett developed Immunocellularchemistry (ICC) assays</span> tracking various proteins involved in DNA damage repair via novel microscopy and mathematical analysis techniques. This research path created the need for an even more powerful resolution, leading to Dr. Bennett's development of a novel and easy-to-use single-molecule resolution microscope (PALM) while a research professor at the University of Utah. Dr. Bennett founded and funded a start-up with the U of UT called Scintalla, later to become Vutara, which Bruker acquired and now sells as Vutara VXL. Dr. Bennett left the industry after the U of UT, investors, colleagues, and others actively removed him from his work and contributions to creating applications combining super resolution with biological sciences to further drug discovery that would translate to the clinical space. <span>He would ultimately exit the industry he sought to change for over a decade,</span> leaving his significant work dormant.
              </p>
            </div>
          </div>
          <div className="right-container">
            <img src={toAbsoluteUrl("/media/about.jpeg")} alt="" style={{ objectFit: 'cover', backgroundPosition: '10%' }} />
          </div>
        </div> */}
        <div className="container azure-section-four-container" style={{ marginBottom: '0px' }}>
          <h2>An Introduction</h2>
          <p><span>Identifyn™ was created non-traditionally through the friendship</span> of CSO and Co-Founder Dr. Brian T. Bennett and CEO and Co-Founder Todd D. Wyman, who couldn't come from more different backgrounds, during a conversation in Longboat Key, FL, in 2023.</p>
        </div>
        <div className="container azure-section-third-container">
          <div className="card1" style={{ alignItems: 'start' }}>
            <h3>Brian </h3>
            <p>At 29, Brian had what he believed was a defining moment in his life over a conversation with his grandfather about "what we leave behind." What do we do to serve others after we are gone? How will we be remembered for contributing during our lifetime? This conversation prompted Brian to <span className='purple'>pursue a career in medicine that later would become focused on cancer research</span>. Starting his undergraduate studies at 31, <span className='purple'>he finished his Ph.D. at the University of Massachusetts Medical School, Worcester, Massachusetts</span>, at 38. Brian has published in high-impact peer-reviewed journals like Nature and PNAS and holds patents for optical technologies and assays. He has led several biotech companies from the start to commercialization of his work. Brian's decision to seek altruism in which he could humbly impact people's lives has led to this moment where, through Identifyn™, he has found a partner in Todd D. Wyman who aligns with his research goals, allowing him to see his career goals to completion. </p>
          </div>
          <div className="card2" style={{ alignItems: 'start' }}>
            <h3>Todd </h3>
            <p>Todd took a more traditional path, beginning his career at <span>Worcester Polytechnical Institute, earning a B.S. in engineering</span> in the same City, Worcester, Massachusetts, as Brian did his education two decades later.</p>
            <p>He went on to work for two large corporate industrials, General Electric, and Ingersoll Rand. He spent most of his <span>career in manufacturing, sourcing, and distribution</span>, with increasing responsibilities over the years, <span>leading the Global Operations and Supply Chains for GE's Transportation business and Ingersoll Rand. He finished his career with Ingersoll Rand</span>, running a multi-billion dollar P&L.</p>
          </div>
        </div>
        {/* <div style={{ background: `url(${toAbsoluteUrl('/media/About1.jpeg')})`, height: '600px', backgroundSize: 'cover', backgroundPosition: 'center 20%', marginTop:'32px' }} className='background-image'></div> */}
        <div className="container azure-section-four-container" style={{ marginBottom: '0px' }}>
          <h2>The Conversation</h2>
          <p>Brian and Todd had known each other for almost two years. Their casual discussions consisted mainly of the beautiful beaches of Longboat Key, Tom Brady, and the Patriots, Brian's interior design business, and Todd's golf schedule. One particular day, over a beer, Brian noticed Todd reviewing a pitch deck for a biotech company and stated, "Don't do it; it's horrible." to which Todd proclaimed, "What do you know, you remodel homes?" It was at this point the dormant Brian was awakened and <span>shared with his good friend of two years his story. Todd's reaction to Brian's past accomplishments left Todd feeling that Brian had too much to offer to science, and he encouraged him to return</span> with Todd at his side. A trusted friend and partner now supporting and aligning with Brian's vision, Identifyn™ is created. </p>
        </div>
        {/* <div className="container azure-section-four-container" style={{ background: 'rgba(237, 237, 237, 0.20)', marginBottom: '0px' }}>
          <h2>The Missing Piece</h2>
          <p>Brian and Todd could not have imagined the creation of Identifyn™️ less than a year ago. They began meeting and discussing multiple business options in the field of Super Resolution Microscopy and moving the technology toward In Vitro Diagnostics (IVD). Coincidentally, during this same time, <span>Brian met Jess and found the same support and love he had when he made the decision to pursue his career over two decades ago,</span> leading to a marriage and a love story for the ages. Jess, Brian, and Todd formed the team from day one. Jess has been the quiet, observant, do-it-all, and everything for the business, especially keeping Brian and Todd aligned.</p>
        </div> */}
        <div className="container azure-section-four-container">
          <img src={toAbsoluteUrl("/media/logowithtext.png")} alt="" width={320} />
          <p>This is just the beginning. The Co-Founders have great plans for Identifyn™️, but you'll have to stay "focused" for more to come.</p>
        </div>
      </AboutContent>
      <Footer />
    </Layout>
  )
}

export default Index