import styled from "styled-components";


export const Wrapper = styled.div`
display: flex;
flex-direction: column;
gap: 10px;
padding: 110px 6rem 60px 6rem;


@media screen and (max-width:1100px){
    padding: 100px 1.2rem;
}


@media screen and (min-width:2160px){
padding-left: 25rem;
padding-right: 25rem;
}

.no-orders{
    width: 100%;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap:20px;

    span{
        width: 120px;
        height: 120px;
        font-size: 35px;
    }
    p{
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 28px;
text-align: center;
    }
}





h3{
        font-size: 20px;
        font-weight: 700;
        line-height: 28px;
        color:#1e1e1e;
    }


    h4{
font-size: 16px;
font-weight: 700;
line-height: 24px;
color:#1e1e1e;
}

h5{
font-size: 14px;
font-weight: 400;
line-height: 20px;
color:#1e1e1e;
}

h6{
font-size: 12px;
line-height: 16px;
font-weight: 400;
color:#9a9a9a;
}





.content{
    display: flex;
    justify-content: space-between;
    gap: 30px;
 
    @media screen and (max-width:1100px){
    flex-direction: column;
    justify-content: start;
}




.left-container{
  width: 67%;

  @media screen and (max-width:1100px){
    width: 100%;
  }

.items-container{
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;

.item{
width: 100%;
display: flex;
gap: 40px;
justify-content: space-between;
align-items: center;
padding: 16px;
border-radius: 20px;
background: #FFF;
box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.05);

@media screen and (max-width:1100px){ 
    flex-wrap: wrap;

    .delete-item{
        width: 100% !important;
    }
}

.quantity-btn{
        &:hover{
            background-color: #E2E2E2 !important;
        }
    }


.price-content{
    display: flex;
    flex-direction: column;
    gap: 2px;
    align-items: center;
}

.quantity-content{
    display: flex;
    align-items: center;
    gap: 8px;


.quantity{
border-radius: 12px;
border: 1px solid #F1EEFC;
background: #FCFCFC;
backdrop-filter: blur(19px);
padding: 10px 12px;
width: 70px;
text-align: center;
height: 40px;
font-size: 16px;
color: #1e1e1e;
font-weight: 400;
line-height: 28px;
display: flex;
justify-content: center;
align-items: center;
}

}

.item-content{
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 40%;

    h4{
        color: #3f1bd0;
    }

    .item-details{
        display: flex;
        gap: 40px;
        align-items: center;


.details{
    display: flex;
    flex-direction: column;
    gap: 4px;
}

    }

}




}



}

}

.right-container{
border-radius: 20px;
background: #FFF;
box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.05);
width: 33%;
padding: 16px 20px 20px 20px;
display: flex;
flex-direction: column;
gap: 20px;
align-items: start;
height: max-content;

@media screen and (max-width:1100px){
    width: 100%;
}

.btn{
    width: 100%;
}

.price-summary{
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;

.price{
    display: flex;
    justify-content: space-between;
}

hr{
    width: 100%;
    height: 1px;
    background-color: #EDEDED;
    border: none;
}


}



}

}

`