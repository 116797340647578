import React, { useState } from 'react'
import Layout from '../../../components/layout'
import { AboutHeader, AboutContent } from './style'
import { toAbsoluteUrl } from '../../../utils/assetHelper'
import { Footer } from '../../../components/Footer'

const Index = () => {
    const [solutionSlider, setSolutionSlider] = useState(0);

    const Solutions = [
        {
          title: "I - Immunization",
          description: `Identifyn™ ensures host immunization using high-quality whole protein and modified protein immunogens to achieve maximal immune response.`,
        },
        {
          title: "I & S - Isolation and Selection",
          description: `Identifyn™ deploys advanced techniques to isolate and select antigen-specific immune cells to create hybridomas from mice and specific B-cells from Rabbits.`,
        },
    
        {
          title: "H & R - Hybridoma & Recombination",
          description: `Identifyn™ creates stable hybridoma products for robust monoclonal antibody production and plasmid-mediated transfection for high-quality recombinant antibody production, ensuring an uninterrupted supply of highly specific affinity reagents.`,
        },
    
        {
          title: "V - Validation",
          description: `Identifyn™ pressure tests our affinity reagents via immunocytochemistry and bioanalytical protein chemistry, including incremental grades of super resolution microscopy applications.`,
        },
    
    
      ]



    return (
        <Layout>
            <div className="container title-container bio-container">
                <span style={{ width: '44px', height: '44px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '12px', background: '#fff2e5', color: 'var(--primary-color)' }}>
                    <img src={toAbsoluteUrl("/media/antibody.png")} alt="" style={{ width: '20px' }} />
                </span>
                <AboutHeader>
                    <h1>Identifyn™ De Novo Primary Antibodies</h1>
                </AboutHeader>
            </div>
            <AboutContent>
                <>
                    <div className="container azure-container" style={{ marginBottom: '32px' }}>
                        <div className="left-container">
                            <div className="solutions-text">
                                <h2>Antibody Development </h2>
                                <p>Identifyn™ is <span>committed to developing highly target-specific and functionally stable Monoclonal, Polyclonal, and Recombinant antibody</span> affinity reagents, achieving high-sensitivity detection of biomolecules in the oncological cellular pathway. <span>Validation via Identifyn's™ core expertise</span> around microscopy-based immunocytochemistry for biopharmaceutical applications <span>ensures a new standard of confidence</span> in your research.</p>
                                <p>Identifyn™ <span>antibodies provide selective speciation in immunocytochemistry</span> applications along with <span>Multiplex and  Fluorescence Immunoassays</span> and are precisely vetted in <span>super-resolution microscopy</span> techniques.</p>
                                <p>Identifyn™ is <span>continually producing</span>, validating, and bringing to market <span>De Novo antibodies</span> within complete pathways centered around <span>more than 300 protein targets!</span></p>
                            </div>
                        </div>
                        <div className="right-container">
                            <figure>
                                <img src={toAbsoluteUrl("/media/primaryAntibody/primaryantibody1.png")} alt="" width={'100%'} style={{ height: '350px' }} />
                            </figure>
                        </div>
                    </div>
                    <div className="container solutions-container primary-container">
                        <div className="left-container" style={{display:'grid', gap:'16px'}}>
                           {
                            Solutions.map((item, index) => (
                                <div className="solutions-text" key={index}>
                                    <h4 style={{cursor:'pointer'}} onClick={() => setSolutionSlider(index)}>{item.title} <span style={{marginLeft:'5px'}}><i className='fal fa-angle-down' /></span></h4>
                                   {solutionSlider === index && <p dangerouslySetInnerHTML={{ __html: item.description }}></p>}
                                </div>
                            ))
                           } 
                           {/* <div className="solutions-text">
                                <h4>{Solutions[solutionSlider].title}</h4>
                                <p dangerouslySetInnerHTML={{ __html: Solutions[solutionSlider].description }}></p>
                            </div> */}
                        </div>
                        <div className="right-container">
                            {/* <img src={toAbsoluteUrl('/media/solutions.png')} alt="" /> */}
                            <div className="wheel-container">
                                <img src={toAbsoluteUrl("/media/wheel/logo.png")} alt="" className='wheel-logo' />
                                <img src={toAbsoluteUrl("/media/primaryWheel/validation.png")} alt="" className='red' onClick={() => setSolutionSlider(3)} />
                                <img src={toAbsoluteUrl("/media/primaryWheel/activevalidation.png")} alt="" className={`red active-red ${solutionSlider === 3 ? 'active' : ''}`} onClick={() => setSolutionSlider(3)} />
                                <img src={toAbsoluteUrl("/media/primaryWheel/immunization.png")} alt="" className='blue' onClick={() => setSolutionSlider(0)} />
                                <img src={toAbsoluteUrl("/media/primaryWheel/activeimmunization.png")} alt="" className={`blue active-blue ${solutionSlider === 0 ? 'active' : ''}`} onClick={() => setSolutionSlider(0)} />
                                <img src={toAbsoluteUrl("/media/primaryWheel/isolation.png")} alt="" className='green' onClick={() => setSolutionSlider(1)} />
                                <img src={toAbsoluteUrl("/media/primaryWheel/activeisolation.png")} alt="" className={`green active-green  ${solutionSlider === 1 ? 'active' : ''}`} onClick={() => setSolutionSlider(1)} />
                                <img src={toAbsoluteUrl("/media/primaryWheel/hybridoma.png")} alt="" className='orange' onClick={() => setSolutionSlider(2)} />
                                <img src={toAbsoluteUrl("/media/primaryWheel/activehybridoma.png")} alt="" className={`orange active-orange ${solutionSlider === 2 ? 'active' : ''}`} onClick={() => setSolutionSlider(2)} />
                            </div>
                        </div>
                    </div>
                    <div className="container acquifer-section-two-container thermo-second-container">
                        <div className="left-container">
                            <div className="card1" style={{ height: '188px' }}>
                                <h3>Mouse Monoclonal and Recombinant Antibodies</h3>
                            </div>
                            <div className="card2" style={{ height: '388px', alignItems: 'flex-start' }}>
                                <h3 s>Identifyn™ Validation</h3>
                                <div className="points-container">
                                    <div className="points">
                                        <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
                                        <p>IF Western Blot</p>
                                    </div>
                                    <div className="points">
                                        <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
                                        <p>IF Multiplex Assays</p>
                                    </div>
                                    <div className="points">
                                        <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
                                        <p>Conventional Microscopy</p>
                                    </div>
                                    <div className="points">
                                        <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
                                        <p>Super Resolution Microscopy</p>
                                    </div>
                                    <div className="points">
                                        <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
                                        <p>Single Molecule Microscopy</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="right-container">
                            <div className="card2" style={{ height: '388px' }}>
                                <h3>Covers All the Proteins in Oncological Cellular Pathways DNA Damage & Repair, Cell Cycle, Cell Death, Mitochondria, and Cancer Growth & Spread</h3>
                            </div>
                            <div className="card1" style={{ height: '188px' }}>
                                <h3>Rabbit Polyclonal and Recombinant Antibodies</h3>
                            </div>
                        </div>
                    </div>
                    <div className="container azure-container" style={{ marginBottom: '32px' }}>
                        <div className="left-container">
                            <div className="solutions-text">
                                <h3><span>Identifyn™ gamma-H2A.X</span></h3>
                                <p>Identifyn™ Rabbit Recombinant Monoclonal Phosphor-Serine 139 H2A.X with Identifyn™ SRM Alexa Fluor™ 488 in HeLa Cells following Etoposide induced DNA damage.</p>
                                <p>Image by Identifyn™ using Zeiss LSM 900 AiryScan. <br />
                                    <em>J.K.Bennett</em></p>
                            </div>
                        </div>
                        <div className="right-container">
                            <figure>
                                <img src={toAbsoluteUrl("/media/primaryAntibody/primaryantibody2.png")} alt="" className='bruker-hero-img'/>
                            </figure>
                        </div>
                    </div>
                    <div className="container acquifer-section-four-container">
                        <h3>Identifyn™ is committed to developing specific reagents for detecting biomolecules with the lowest expressions in the targeted oncological pathways towards our ongoing commitment to excellence for our academic and industry customers. </h3>
                    </div>
                </>
            </AboutContent>
            <Footer />
        </Layout>
    )
}

export default Index