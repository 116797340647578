import React from 'react'
import Layout from '../../../components/layout'
import { AboutHeader, Content } from './style'
import {Footer} from '../../../components/Footer'

const Index = () => {
    return (
        <Layout>
                <AboutHeader>
                    <h1>Cookie <span>Policy</span></h1>
                </AboutHeader>
                <Content>
                <p style={{color:'#6c6a73'}}>Last Modified Date: December 6, 2023</p>
                <p>The purpose of this Cookie Policy is to inform visitors to the <a href="www.Identifyn™.com">www.Identifyn™.com</a> (“<span>Website</span>”) and the Identifyn™ mobile application (“<span>Mobile Application</span>”) (collectively, “<span>Platform</span>”) about Identifyn’s™ use of cookies.</p>
                <p>References in this Cookie Policy to “you” or “your,” means the individual user of the Identifyn™ Platform. When we refer to “our,” “we,” or “us,” we mean Identifyn™.</p>
                <h4>What are cookies?</h4>
                <p>Cookies are small pieces of data that are transmitted from our hosting server to your computer or mobile device (“computing device”) and stored for use by your internet browser or our Mobile Application. Each time you visit our Website or utilize our Mobile Application, your computing device returns information to us that enables us to identify you as having visited our Website before or used the Mobile Application before. We use cookies to personalize content, facilitate logins to your account, and to analyze Platform usage data in order to optimize your user experience with the Platform. Some of these cookies are deleted when you end your web browsing or Mobile Application session, and other cookies remain on your computing device until you delete them.</p>
                <h4>What information is collected by cookies?</h4>
                <p>The cookies on our Website collect information including a unique ID number, your internet protocol address, internet browser, type of computing device, country, preferred language, internet browsing history, and internet provider.</p>
                <h4>How Does Identifyn™ Use Cookies?</h4>
                <p>Our Platform utilizes cookies to collect information about you while you use the Platform. We use the information we collect from cookies to enhance your user experience when using the Identifyn™ Platform. Cookies enable us to recognize whether you have previously visited our Website or used the Mobile Application and to recall any personal preferences you may have set while previously using the Platform. We use cookies to determine which content is most popular among users, to detect and troubleshoot issues with the Platform, and to optimize your user experience. Cookies are also utilized to keep track of the items in your shopping cart through the Platform. We use the information collected from cookies to compile and analyze data about our Website traffic, Platform usage, and user interactions so that we can offer better experiences using our Platform for users in the future.</p>
                <h4>What kind of cookies are used by the Identifyn™ Platform?</h4>
                <p>The Identifyn™ Platform may use four categories of cookies: (i) Strictly Necessary Cookies; (ii) Functionality Cookies; (iii) Performance/Analytics Cookies; and (iv) Advertising Cookies.</p>
                <h5>Strictly Necessary cookies</h5>
                <p>Strictly Necessary cookies enable you to navigate the Identifyn™ Website and Mobile Application and use the Platform features, such as assisting with your account login. Strictly Necessary cookies do not gather information about you that could be used for marketing or remembering where else you have visited on the internet. Such cookies may provide authentication, cyber security, and help localize the language displayed on Platform user interfaces based on the geography of your visit. This category of cookies cannot be disabled.</p>
                <h5>Functionality cookies</h5>
                <p>These provide functionality that help us deliver products and services. For example, cookies help you log in by pre-filling fields. We may also use cookies and similar technologies to help us provide you and others with customized content and experiences, such as making suggestions to you and others.</p>
                <h5>Performance/Analytics cookies</h5>
                <p>Performance/Analytics cookies help us analyze how you use the Identifyn™ Platform. Performance/Analytics cookies collect and store information such as how you interact with the Platform, errors you encounter when using the Platform, and which features within the Platform you utilize most often. We use the information collected by these cookies to improve the Platform and your user experience.</p>
                <h5>Mobile-Specific</h5>
                <p>These service providers use various technologies to deliver relevant mobile ads, track mobile marketing or mobile ad campaign performance and efficiency.</p>
                <h4>Can I turn off cookies?</h4>
                <p>You have the right to choose whether or not to accept cookies. However, they are an important part of how our Platform works, so you should be aware that if you choose to refuse or remove cookies, this could affect the availability and functionality of the Platform.</p>
                <p>Most internet browsers contain settings, which enable you to disable cookies. For more information about your specific browser and how to toggle any settings in relation to cookies, please consult the browser’s help menu. Please be advised that should you choose to disable cookies, some of the Platform or Website functionality will not work properly.</p>
                <p>For mobile users, you have controls in your device operating system that enables you to choose whether to allow cookies. For information on controlling your mobile choices, you can visit https://www.networkadvertising.org/mobile-choices.</p>
                <h4>Updates to this Policy</h4>
                <p>Identifyn™ reserves the right to update this Cookie Policy from time to time in our sole discretion. All changes to this Cookie Policy are effective and binding on you immediately either upon posting the revised policy to Identifyn’s™ website or making the policy otherwise accessible through the Platform.</p>
                </Content>
            <Footer />
        </Layout>
    )
}

export default Index