import styled from "styled-components";

// export const AboutHeader = styled.div`
// margin-top: 20px;
// .tab-container{
//         width: 400px;
//         height: 40px;
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         background: #EDEDED;
//         border-radius: 12px;

// button{
//     display: block;
//   background-color:#EDEDED;
//   color: #000;
//   text-align: center;
//   padding: 1rem 1.2rem;
//   font-size: 1rem;
//   font-weight: 500;
//   height: 40px;
//   width: 50%;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//     }

//     .tab-active{
//         background-color: var(--primary-color);
//         color: #fff;
//         border-radius: 12px;
//     }

//     }
// `


export const AboutHeader = styled.div`
margin-top: 20px;
h1{
    font-size: 48px;
    font-weight: 800;
    margin-bottom: 16px;
    text-align: center;

    span{
        color: var(--primary-color);
    }
}
`

export const AboutContent = styled.div`
    margin-top: 32px;
    margin-bottom: 32px;
.thermo-second-container{
.left-container, .right-container {
    .card1,.card2{
        h3{
            text-align: center;
            margin-bottom: 0;
        }
    @media screen and (max-width:1100px) {
        height: max-content !important;

        h3{
            font-size: 30px;
        }
    }
}     
} 
}


.cards-container,.graphs-container{
    width: 98vw;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 60px 96px;
    border-radius: 20px;
    background: linear-gradient(102deg, rgba(63, 27, 208, 0.05) 4.47%, rgba(215, 76, 16, 0.05) 64.86%);
    margin-bottom: 32px;

    @media screen and (max-width:1100px) {
        padding: 60px 1.2rem;
        
    }


    h3 {
        font-size: 36px;
        line-height: 50px;
        color: #1E1E1E;
        text-align: center;
    }

.cards-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    gap:20px;
    margin-top: 60px;

    @media screen and (max-width:1100px) {
        flex-direction: column;
        justify-content: unset;     
    }

.card{
    display: grid;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 200px;
    @media screen and (max-width:1100px) {
        justify-content: unset;
    }

    h5{
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 24px;
text-align: center;
height: 38px;
display: flex;
justify-content: center;
align-items: center;
    }

    h6{
height: 32px;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 16px;
text-align: center;
    }

    .img-container{
        width: 200px;
        /* height: 200px; */
        border-radius: 12px;
        background: var(--Light-Grey, #EDEDED);
        margin-top: 8px;
        margin-bottom: 5px;

        @media screen and (max-width:1100px) {
        width: 100%;
    }

    }
}


    @media screen and (max-width:1100px) {
        grid-template-columns: 1fr;
    }
}   
}

.graphs-container{
background: rgba(237, 237, 237, 0.20);

.graphs-wrapper{
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;

    @media screen and (max-width:1100px) {
       svg{
        width: 100%;
        height: 200px;
       }
    }

    .hide-line{
        opacity: 0;
        transition: 0.5s all ease-in-out;
    }
    .active-line{
        opacity: 1;
        transition: 0.5s all ease-in-out;
    }

    .button-container{
        display: flex;
        /* grid-template-columns: repeat(8, 1fr); */
        gap: 8px;

        @media screen and (max-width:1100px) {
            width: 100%;
            flex-direction: column;
            grid-template-columns: 1fr;
            button{
                width: 100% !important;
            }
    }

        button{
            width: 140px;
        }

        .blue{
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.78) 0%, rgba(255, 255, 255, 0.78) 100%), #09F;
            color: #09F;
        }
        .active-blue{
            background: #0099FF;
        }

        .green{
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.80) 0%, rgba(255, 255, 255, 0.80) 100%), #33C25E;
            color: #33C25E;
        }

        .active-green{
            background: #33C25E;
        }

        .yellow{    
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.88) 0%, rgba(255, 255, 255, 0.88) 100%), #FFC224;
        color: #FFC224;
        }

        .active-yellow{
            background: #FFC224;
        }

        .orange{
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.82) 0%, rgba(255, 255, 255, 0.82) 100%), #FF8D24;
        color: #FF8D24;
        }

        .active-orange{
            background: #FF8D24;
        }

        .coral{
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.80) 0%, rgba(255, 255, 255, 0.80) 100%), #FC5555;
        color: #FC5555;
        }

        .active-coral{
            background: #FC5555;
        }

        .pink{
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.78) 0%, rgba(255, 255, 255, 0.78) 100%), #D94949;
        color: #D94949;
        }

        .active-pink{
            background: #D94949;
        }

        .red{
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.76) 0%, rgba(255, 255, 255, 0.76) 100%), #B23C3C;
            color: #B23C3C;
        }

        .active-red{
            background: #B23C3C;
        }

        .dark-red{
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.72) 0%, rgba(255, 255, 255, 0.72) 100%), #993434;
            color: #993434;
        }

        .active-dark-red{
            background: #993434;
        }

        .active{
            color:#fff;
            width: 200px;
        }

    }
}
}


.dye-list-container{
    width: 98vw;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 100px 96px;
    border-radius: 20px;
    background: linear-gradient(102deg, rgba(63, 27, 208, 0.05) 4.47%, rgba(215, 76, 16, 0.05) 64.86%);
    margin-bottom: 32px;

    @media screen and (max-width:1100px) {
        padding: 60px 1.2rem;
        
    }
    .dye-list-wrapper{
    display: flex;
    flex-direction: column;
    gap: 40px;
    align-items: center;

    @media screen and (max-width:1100px) {
       img{
        width: 100%;
        height: 300px;
       }
    }
}
}

`