export const getLocalStorage = (name, parse) => {
    if (parse) {
        return JSON.parse(localStorage.getItem(name))
    } else {
        return localStorage.getItem(name)
    }
}

export const setLocalStorage = (name, value, stringify) => {
    if (stringify) {
        localStorage.setItem(name, JSON.stringify(value))
    } else {
        localStorage.setItem(name, value)
    }
}

export const removeLocalStorage = (name) => {
    localStorage.removeItem(name)
}

export const getSessionStorage = (name) => {
    return sessionStorage.getItem(name)
}

export const setSessionStorage = (name, value) => {
    sessionStorage.setItem(name, value)
}

export const removeSessionStorage = (name) => {
    sessionStorage.removeItem(name)
}
