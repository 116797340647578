import React, { useContext, useState } from 'react'
import "./style.scss"
import { AuthContext } from "../../../context/auth";
import Input from "../../Input Old";
import { restorePasswordApi } from '../../../api';

const Index = () => {
  const { setChangePasswordRestoreModal, setNotification, setRestoreModal, setIsLoading, isLoading } = useContext(AuthContext)
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [error, setError] = useState('')

  const data = (new URL(document.location).searchParams)
  const token = data.get('token')    
  const email = data.get('email')

  const restorePassword = () => {
    if(password !== confirmPassword){
      setNotification({
        type:'red',
        message:"Passwords doesn't match"
      })
    }else{
      setIsLoading(true)
      if (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)) {
      restorePasswordApi({email, password, token}).then((res) => {
        setIsLoading(false)
        setNotification({
          type:'success',
          message:'Password Successfully Changed!'
        })
        setChangePasswordRestoreModal(false)
      }).catch((err) => {
        setIsLoading(false)
        setNotification({
          type: 'red',
          message: 'Something went wrong, please try again!'
        })
      })
    }else{
      setIsLoading(false)
      setNotification({
        type: 'red',
        message: 'Please enter a valid Email Address'
      })
    }
  }
  }


  return (
    <div className="small-modal-wrapper">
      <div className="small-modal-content">
        <span style={{ width: '44px', height: '44px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '12px', background: '#fff2e5', color: 'var(--primary-color)' }}>
          <i class="fal fa-lock"></i>
        </span>
        <span className='close' style={{ color: '#9a9a9a' }} onClick={() => setChangePasswordRestoreModal(false)}><i className="fas fa-times"></i></span>
        <h2>Change Password </h2>
        <div className="form-container" style={{ width: '100%' }}>
          <div style={{ marginTop: '32px' }}>
            <p style={{ marginBottom: '10px', color: '#9a9a9a', opacity: '1', fontSize: '14px', textAlign: 'left' }}>New Password</p>
            <Input type='password' placeholder={"Enter Password"} value={password} onChange={(e) => setPassword(e.target.value)} className={error ? 'error' : ''} />
          </div>
          <div style={{ marginTop: '22px', marginBottom: '22px' }}>
            <p style={{ marginBottom: '10px', color: '#9a9a9a', opacity: '1', fontSize: '14px', textAlign: 'left' }}>Confirm New Password</p>
            <Input type='password' placeholder={"Confirm Password"} value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} className={error ? 'error' : ''} />
          </div>
        </div>
        <div className="password-validations" style={{ width: '100%', marginBottom: '20px' }}>
          <span style={{ color: '#6C6A73' }}>{password.length >= 8 ? <i className="fas fa-check" style={{ color: '#33C25E', marginRight: '8px' }}></i> : <i className="far fa-circle" style={{ color: '#9a9a9a', marginRight: '8px' }}></i>}At least 8 characters</span>
          <span style={{ color: '#6C6A73' }}>{/[A-Z]/.test(password) ? <i className="fas fa-check" style={{ color: '#33C25E', marginRight: '8px' }}></i> : <i className="far fa-circle" style={{ color: '#9a9a9a', marginRight: '8px' }}></i>}At least 1 upper case letter (A-Z).</span>
          <span style={{ color: '#6C6A73' }}>{/\d/.test(password) ? <i className="fas fa-check" style={{ color: '#33C25E', marginRight: '8px' }}></i> : <i className="far fa-circle" style={{ color: '#9a9a9a', marginRight: '8px' }}></i>}At least 1 number (0-9).</span>
        </div>

        {/* <p style={{marginBottom:'28px'}}>Forgot? <span style={{ color: 'var(--primary-color)', cursor: 'pointer' }}
        onClick={() => {
          setRestoreModal(true)
          setChangePasswordRestoreModal(false)
        }}
        >Restore Password</span></p> */}

        <button className='btn' disabled={isLoading} onClick={() => {
          restorePassword()
          // setNotification({ status: true, message: 'Password Successfully Changed!', subMessage: '', type: 'password' })
          // setChangePasswordRestoreModal(false)
        }}>Save</button>
        <button className='btn transparent' onClick={() => {
          setChangePasswordRestoreModal(false)
        }}>Cancel</button>
      </div>
    </div>
  )
}

export default Index