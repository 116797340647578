import React, { useContext } from 'react'
import "./style.scss"
import { AuthContext } from "../../../context/auth";

const Index = () => {
  const { setCompleteModal } = useContext(AuthContext)
  return (
    <div className="small-modal-wrapper">
      <div className="small-modal-content">
        <div style={{ position: 'relative', justifyContent: 'center', display: 'flex', width: '100%' }}>
          <i className='fal fa-times close' style={{ color: '#9a9a9a', position: 'absolute', right: '15px', top: '12px', cursor: 'pointer', zIndex: 9, fontSize: '20px' }} onClick={() => {
            setCompleteModal(false)
          }}></i>
          <span style={{ width: '44px', height: '44px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '12px', background: '#33c25e', color: 'var(--white)' }}>
            <i class="fal fa-check"></i>
          </span>
        </div>
        <h2 style={{ marginTop: '20px', marginBottom: '24px' }}>Thank You for Registering with IDENTiFYN™ </h2>
        <button className='btn' onClick={() => {
          setCompleteModal(false)
        }}>Complete Registration</button>
      </div>
    </div>
  )
}

export default Index