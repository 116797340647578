import React, { useEffect, useRef, useState, useContext } from 'react'
import "./style.css"
import { NavLink, Link, useLocation } from 'react-router-dom'
import { AuthContext } from "../../context/auth.js"
import { toAbsoluteUrl } from '../../utils/assetHelper'
import { checkIsActive } from '../../utils/routeHelper'



const Index = () => {
  const { setSignInModal, setSignUpModal, user, isLoggedIn, setLogOutModal, width, cartData } = useContext(AuthContext)
  const { pathname } = useLocation()
  const dropdownRef = useRef(null)
  const [dropdownOpen, setDropDownOpen] = useState({ name: '', open: false })
  const [hamburgerOpen, setHamburgerOpen] = useState(false)
  const [quantity, setQuantity] = useState(1)

  useEffect(() => {
    if (cartData && cartData?.items?.length > 0) {
      setQuantity(cartData?.items?.reduce((acc, curr) => acc + curr?.quantity, 0))
    }
  }, [cartData])

  // useEffect(() => {
  //   const navbar = document.querySelector('.nav-container');
  //   window.onscroll = () => {
  //     if (window.scrollY > 550) {
  //       navbar.classList.add('nav-white');
  //     } else {
  //       navbar.classList.remove('nav-white');
  //     }
  //   };

  // }, [])




  useEffect(() => {
    if (width > 1200) {
      const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          // Handle outside click
          setDropDownOpen({
            open: false,
            name: ''
          })
        }
      };

      document.addEventListener('mousedown', handleClickOutside);

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [width]);


  return (
    <header id="nav-menu" aria-label="navigation bar">
      <div class="nav-container" style={{ boxShadow: pathname.includes('ssb') || pathname.includes('dsb-hr') || pathname.includes('dsb-nh') ? 'none' : '' }}>
        <div class="nav-start">
          <Link class="logo" to="/">
            <img src={toAbsoluteUrl("/media/logowithtext.png")} width="156" height="32" alt="Inc Logo" />
          </Link>
          <nav class={`menu ${hamburgerOpen ? 'show' : ''}`}>
            <ul class="menu-bar">
              <li><NavLink className="nav-link" to="/">Home</NavLink></li>
              <li>
                <button class="nav-link dropdown-btn" style={{ color: pathname.includes('product') ? 'var(--primary-color)' : '', fontWeight: dropdownOpen.name === 'product' && dropdownOpen.open ? '700' : '500' }} data-dropdown="dropdown1" aria-haspopup="true" aria-expanded="false" aria-label="product"
                  onClick={() => {
                    if (dropdownOpen.name === 'product') {
                      setDropDownOpen({
                        name: '',
                        open: false
                      })
                    } else {
                      setDropDownOpen({
                        name: 'product',
                        open: true
                      })
                    }
                  }}
                >
                  Product
                  {dropdownOpen.name === 'product' && dropdownOpen.open ? <i class="bx bx-chevron-up" aria-hidden="true"></i> : <i class="bx bx-chevron-down" aria-hidden="true"></i>}
                </button>
                <div id="dropdown1" className={`dropdown ${dropdownOpen.name === 'product' && dropdownOpen.open ? 'active' : ''}`} ref={dropdownRef}>
                  <ul role="menu ">
                    <li role="menuitem">
                      <NavLink className="dropdown-link" to="/product/pathways">Primary Antibodies</NavLink>
                    </li>
                    <li role="menuitem">
                      <a class="dropdown-link" href="/product/pathways">Identifyn™ SRM Alexa Fluor™ Primary Antibodies</a>
                    </li>
                    <li role="menuitem">
                      <NavLink className="dropdown-link" to="/product/alexa-fluor-secondary">Identifyn™ SRM Alexa Fluor™ Secondary Antibodies</NavLink>
                    </li>
                    {/* <li role="menuitem">
                      <a class="dropdown-link" href="/">Buffers</a>
                    </li>
                    <li role="menuitem">
                      <a class="dropdown-link" href="/">STORM Microscopy Products</a>
                    </li>
                    <li role="menuitem">
                      <a class="dropdown-link" href="/">Slides, Coverslips & Chambers</a>
                    </li>
                    <li role="menuitem">
                      <a class="dropdown-link" href="/">Mounting Medium</a>
                    </li>
                    <li role="menuitem">
                      <a class="dropdown-link" href="/">Microscopy Kits</a>
                    </li> */}
                  </ul>
                </div>
              </li>
              <li>
                <button class="nav-link dropdown-btn" style={{ color: pathname.includes('technologies') ? 'var(--primary-color)' : '', fontWeight: dropdownOpen.name === 'technologies' && dropdownOpen.open ? '700' : '500' }} data-dropdown="dropdown2" aria-haspopup="true" aria-expanded="false" aria-label="technologies"
                  onClick={() => {
                    if (dropdownOpen.name === 'technologies') {
                      setDropDownOpen({
                        name: '',
                        open: false
                      })
                    } else {
                      setDropDownOpen({
                        name: 'technologies',
                        open: true
                      })
                    }
                  }}
                >
                  Technology
                  {dropdownOpen.name === 'technologies' && dropdownOpen.open ? <i class="bx bx-chevron-up" aria-hidden="true"></i> : <i class="bx bx-chevron-down" aria-hidden="true"></i>}
                </button>
                <div id="dropdown2" className={`dropdown ${dropdownOpen.name === 'technologies' && dropdownOpen.open ? 'active' : ''}`} ref={dropdownRef}>
                  <ul role="menu">
                    <li role="menuitem">
                      <NavLink className="dropdown-link" to="/technologies/zeiss">Zeiss™</NavLink>
                    </li>
                    <li role="menuitem">
                      <NavLink className="dropdown-link" to="/technologies/bruker">Bruker™ - Vutara VXL</NavLink>
                    </li>
                    <li role="menuitem">
                      <NavLink className="dropdown-link" to="/technologies/azure">Azure Biosystems™</NavLink>
                    </li>
                    <li role="menuitem">
                      <NavLink className="dropdown-link" to="/technologies/acquifer">Acquifier™</NavLink>
                    </li>
                    <li role="menuitem">
                      <NavLink className="dropdown-link" to="/technologies/assay-development">Assay Development</NavLink>
                    </li>
                    <li role="menuitem">
                      <NavLink className="dropdown-link" to="/technologies/services">Services</NavLink>
                    </li>
                    {/* <li role="menuitem">
                      <NavLink className="dropdown-link" to="/technologies/thermofisher">ThermoFisher™</NavLink>
                    </li>
                    <li role="menuitem">
                      <NavLink className="dropdown-link" to="/technologies/fortis">Fortis/Bethyl™</NavLink>
                    </li>
                    <li role="menuitem">
                      <NavLink className="dropdown-link" to="/technologies/identify">Identifyn™</NavLink>
                    </li> */}
                    {/* <li role="menuitem">
                      <NavLink className="dropdown-link" to="/technologies/resolution">Understanding Resolution</NavLink>
                    </li> */}
                  </ul>
                </div>
              </li>
              <li><NavLink className="nav-link" to="/about">About Us</NavLink></li>
              <li><NavLink className="nav-link" to="/gallery">Gallery</NavLink></li>
              <li><NavLink className="nav-link" to="/contact">Contact Us</NavLink></li>
              {width < 1100 && user && isLoggedIn && <li><NavLink className="nav-link cart-btn" to={"/cart"}>
                Cart
                <i class="bx bx-cart" aria-hidden="true" style={{ fontSize: '24px', color: '#28272E' }}></i>
                {cartData?.items && cartData?.items?.length > 0 && <span>{quantity}</span>}
              </NavLink>
              </li>
              }
              <div className="auth-container">
                {user && isLoggedIn ?
                  <>
                    <div class="nav-link avatar" style={{ color: pathname.includes('profile') ? 'var(--primary-color)' : '', cursor: 'pointer' }} data-dropdown="dropdown1" aria-haspopup="true" aria-expanded="false" aria-label="product"
                      onClick={() => {
                        if (dropdownOpen.name === 'profile') {
                          setDropDownOpen({
                            name: '',
                            open: false
                          })
                        } else {
                          setDropDownOpen({
                            name: 'profile',
                            open: true
                          })
                        }
                      }}
                    >
                      {user?.customer?.avatar ?
                        <img src={user?.customer?.avatar} alt="product" className='avatar-img' style={{ border: dropdownOpen.name === 'profile' && dropdownOpen.open && '2px solid var(--primary-color)' }} />
                        :
                        <div className="custom-profile" style={{ border: dropdownOpen.name === 'profile' && dropdownOpen.open && '1px solid #F1EEFC' }}>{user?.customer?.first_name[0]?.toUpperCase()}</div>
                      }
                      <span className='avatar-name'>{user?.customer?.first_name} {user.customer?.last_name}</span>
                      {dropdownOpen.name === 'profile' && dropdownOpen.open ? <i class="bx bx-chevron-up" aria-hidden="true"></i> : <i class="bx bx-chevron-down" aria-hidden="true"></i>}
                    </div>
                    <div id="dropdown3" className={`dropdown ${dropdownOpen.name === 'profile' && dropdownOpen.open ? 'active' : ''}`} ref={dropdownRef}>
                      <ul role="menu ">
                        <li role="menuitem">
                          <NavLink class="dropdown-link" to="/profile/account">Account</NavLink>
                        </li>
                        <li role="menuitem">
                          <NavLink className="dropdown-link" to="/profile/orders">Orders</NavLink>
                        </li>
                        <li role="menuitem">
                          <Link class="dropdown-link" to="/contact?support=Accout Support">Support</Link>
                        </li>
                        <li role="menuitem">
                          <button class="dropdown-link" style={{ fontSize: '1rem', color: 'var(--primary-color)' }} onClick={() => setLogOutModal(true)} >Sign Out</button>
                        </li>
                      </ul>
                    </div>
                  </>
                  : <>
                    {/* <Link class="nav-link cart-btn" to={"/cart"}>
                      Cart
                      <i class="fal fa-shopping-cart" aria-hidden="true" style={{ fontSize: '20px', color: '#28272E' }}></i>
                      {cartData?.items && cartData?.items?.length > 0 && <span>{quantity}</span>}
                    </Link>
                    <button class="btn btn-transparent" style={{ marginBottom: '10px' }} onClick={() => setSignInModal(true)}>Sign In</button>
                    <button class="btn btn-primary" onClick={() => setSignUpModal(true)}>Sign Up</button> */}
                  </>
                }
              </div>
            </ul>
          </nav>
        </div>

        <div class="nav-end">
          <div class="right-container">
            {/* <form class="search" role="search">
            <input type="search" name="search" placeholder="Search" />
            <i class="bx bx-search" aria-hidden="true"></i>
          </form>
          <a href="#profile">
            <img src="https://github.com/Evavic44/responsive-navbar-with-dropdown/blob/main/assets/images/user.jpg?raw=true" width="30" height="30" alt="user image" />
          </a> */}
            {/* {user && isLoggedIn ? <>
              <div class="nav-link avatar" style={{ color: pathname.includes('profile') ? 'var(--primary-color)' : '', cursor: 'pointer' }} data-dropdown="dropdown1" aria-haspopup="true" aria-expanded="false" aria-label="product"
                onClick={() => {
                  if (dropdownOpen.name === 'profile') {
                    setDropDownOpen({
                      name: '',
                      open: false
                    })
                  } else {
                    setDropDownOpen({
                      name: 'profile',
                      open: true
                    })
                  }
                }}
              >
                {user?.customer?.avatar ?
                  <img src={user?.customer?.avatar} alt="product" className='avatar-img' style={{ border: dropdownOpen.name === 'profile' && dropdownOpen.open && '2px solid var(--primary-color)' }} />
                  :
                  <div className="custom-profile" style={{ border: dropdownOpen.name === 'profile' && dropdownOpen.open && '1px solid #F1EEFC' }}>{user?.customer?.first_name[0]?.toUpperCase()}</div>
                }
                <span className='avatar-name'>{user.customer?.first_name} {user.customer?.last_name}</span>
                {dropdownOpen.name === 'profile' && dropdownOpen.open ? <i class="bx bx-chevron-up" aria-hidden="true"></i> : <i class="bx bx-chevron-down" aria-hidden="true"></i>}
              </div>
              <div id="dropdown3" className={`dropdown ${dropdownOpen.name === 'profile' && dropdownOpen.open ? 'active' : ''}`} ref={dropdownRef}>
                <ul role="menu ">
                  <li role="menuitem">
                    <NavLink className="dropdown-link" to="/profile/account">Account</NavLink>
                  </li>
                  <li role="menuitem">
                    <NavLink className="dropdown-link" to="/profile/orders">Orders</NavLink>
                  </li>
                  <li role="menuitem">
                    <Link class="dropdown-link" to="/contact?support=Accout Support">Support</Link>
                  </li>
                  <li role="menuitem">
                    <button class="dropdown-link" style={{ color: 'var(--primary-color)' }} onClick={() => setLogOutModal(true)} >Sign Out</button>
                  </li>
                </ul>
              </div>
            </>
              :
              <>
                <button class="btn btn-transparent" onClick={() => setSignInModal(true)}>Sign In</button>
                <button class="btn btn-primary" onClick={() => setSignUpModal(true)}>Sign Up</button>
              </>
            }
            <Link class="nav-link dropdown-btn cart-btn" to={"/cart"}>
              <i class="fal fa-shopping-cart" aria-hidden="true" style={{ fontSize: '20px', color: '#28272E' }}></i>
              {cartData?.items && cartData?.items?.length > 0 && <span>{quantity}</span>}
            </Link> */}
          </div>

          <button id="hamburger" aria-label="hamburger" aria-haspopup="true" aria-expanded="false"
            onClick={() => {
              setHamburgerOpen(!hamburgerOpen)
            }}
          >
            <i class="bx bx-menu" aria-hidden="true"></i>
          </button>
        </div>
      </div>
    </header>
  )
}

export default Index