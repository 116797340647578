export const apiRoutes = {
    login: 'auth/token',
    currentUser:'auth',
    customer:'customer',
    customers: 'customers/me',
    customerGroup: 'customer-group',
    customerGroups: 'customer-groups',
    orders: 'customers/me/orders',
    address: 'customers/me/addresses',
    uploads: 'uploads',
    createCustomer:'auth-otp',
    resetPassword:'customers/password-token',
    restorePassword:'customers/password-reset',
    changeEmail:'change-email',
    changePassword:'change-password',
    carts:'carts',
    shipping:'shipping-options',
    productCategories:'product-categories',
    products:'products',
    product:'product',
    productTags:'product-tags',
    bestSelling:'bestselling',
    regions:'regions',
    media:'media',
    addGroup:'add-group',
    contact:'contact',
    teams:'teams'

}
