import React, {useContext, useState} from 'react'
import "./style.scss"
import {toAbsoluteUrl} from "../../utils/assetHelper"
import Input from "../../components/Input"
import {AuthContext} from "../../context/auth.js"
import {loginApi, currentUserApi, getCustomerGroupApi, updateCartApi} from "../../api"
import { setLocalStorage } from '../../helpers/localStorage.js'

const Index = () => {
    const {setSignInModal, setSignUpModal, setRestoreModal, login, setIsLoading} = useContext(AuthContext)
    const [Email, setEmail] = useState('')
    const [Password, setPassword] = useState('')
    const [error, setError] = useState('')


    const Login = () => {
      setIsLoading(true)
      if (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(Email)) {
        loginApi({ email: Email, password: Password }).then((res) => {
          if (res?.data?.access_token) {
            setLocalStorage('identifyntoken', res?.data?.access_token)
            currentUserApi().then((user) => {
              let data = user.data
              getCustomerGroupApi(data?.customer?.id).then((customerGroup) => {
              data.customer.groups = customerGroup.data?.customerGroups[0]?.groups[0]?.name
              })
              login(data)
              setIsLoading(false)
              setError('')
              setEmail('')
              setPassword('')
              setSignInModal(false)
            })
          } else {
            setError("Invalid Email or Password")
            setIsLoading(false)
          }
        }).catch((err) => {
          setError("Invalid Email or Password")
          setIsLoading(false)
        })
      } else {
        setError('Please enter a valid Email Address')
        setIsLoading(false)
      }
    }





  return (
    <div className="modal-wrapper">
        <div className="signin-modal-content">
          <i className='fal fa-times close'  style={{color:'white', position:'absolute', right:'32px', top:'32px', cursor:'pointer', zIndex:9, fontSize:'20px'}} onClick={() => {
            setSignInModal(false)
          }}></i>  
        <div className="left-container">
        <span style={{ width: '44px', height: '44px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '12px', background: '#fff2e5', color: 'var(--primary-color)' }}>
            <i class="fal fa-sign-in"></i>
            </span>
            <div>
            <h1>Welcome Back to IDENTiFYN™</h1>
            <p>Please sign-in.</p>
            </div>
            <div className="form-container">
            <Input type='email' className={"Email"} style={{ marginBottom: '20px' }} required label={'Email'} value={Email} onChange={(e) => setEmail(e.target.value)} />
            <Input type='password' className={"Password"} required label={'Password'} value={Password} onChange={(e) => setPassword(e.target.value)} onKeyPress={(e) => e.key === 'Enter' && Login()} />
            <h4 style={{textAlign:'right', marginTop:'12px', color:'var(--primary-color)', cursor:'pointer', fontWeight:'500'}}
            onClick={() => {
              setSignInModal(false)
              setRestoreModal(true)
            }}
            >Forgot Password?</h4>
            </div>
            <button className="btn" onClick={() => {
            Login()
            }}>Sign In</button>
            {error && <h4 style={{ color: '#DC1E00', textAlign: 'center', fontWeight: 500 }}>{error}</h4>}
            <p style={{ textAlign: 'center' }}>Don’t have an Account? <span onClick={() =>{
              setSignInModal(false)
              setSignUpModal(true)
            }}>Sign Up</span></p>
        </div>
        <div className="right-container">
            <img src={toAbsoluteUrl('/media/logowhite.png')} alt="" />
        </div>
        </div>
    </div>
  )
}

export default Index