import React, { useContext, useRef, useState, useEffect } from 'react'
import { AuthContext } from '../../context/auth'
import axios from 'axios'
import Layout from '../../components/layout'
import { toAbsoluteUrl } from '../../utils/assetHelper'
import "./style.scss"
import Card from "../../components/card"
import Input from "../../components/Input"
import { CountryDropdown, StateDropdown } from '../../components/DropDowns'
import 'swiper/swiper-bundle.css';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import { useNavigate, Link } from 'react-router-dom'
import { Footer } from '../../components/Footer'
import { getFeaturedApi } from '../../api'
import { contactApi, getTeamsApi } from '../../api'
import Dropdown from "../../components/Dropdown"
import ItemPreviewDrawer from "../../components/Drawers/ItemPreviewDrawer"
import Coa from "../../components/coA"
import Shapes from "../Bio/Shapes/shapes"

const Index = () => {
  const { isLoggedIn, login, logout, setIsLoading, setNotification, openItemPreviewModal } = useContext(AuthContext)
  const galleryUrl = `${process.env.REACT_APP_GALLERY_URL}300:350`
  const navigate = useNavigate();
  const coARef = useRef(null)
  const elementRef = useRef(null);
  const teamRef = useRef(null);
  const [arrowDisable, setArrowDisable] = useState(true);
  const [solutionSlider, setSolutionSlider] = useState(0);
  const [teamsSlider, setTeamsSlider] = useState(0);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [country, setCountry] = useState('United States');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [topic, setTopic] = useState('');
  const [description, setDescription] = useState('');
  const [cities, setCities] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState({})
  const [TeamCards, setTeamCards] = useState([])

  // const TeamCards = [
  //   [
  //     {
  //       type: 'Teams',
  //       title: "Dr. Chandra K. Dixit",
  //       image: toAbsoluteUrl("/media/teams/chandra.png"),
  //       position_1: "Director of protein chemistry",
  //       position_2: ""
  //     },
  //     // {
  //     //   type: 'Teams',
  //     //   title: "Dr. Liqin Ren",
  //     //   image: toAbsoluteUrl("/media/teams/Liquin.svg"),
  //     //   description: "Senior Scientist",
  //     // },
  //     {
  //       type: 'Teams',
  //       title: "Jessica K. Bennett",
  //       image: toAbsoluteUrl("/media/teams/jessica.png"),
  //       position_1: "Microscopy facility manager",
  //       position_2: "microscopist"
  //     },
  //     {
  //       type: 'Teams',
  //       title: "Patrick Amistoso",
  //       image: toAbsoluteUrl("/media/teams/patrick.png"),
  //       position_1: "Materials & procurement manager",
  //       position_2: "Laboratory Technical Support"
  //     },
  //     {
  //       type: 'Teams',
  //       title: "Emily Simon",
  //       image: toAbsoluteUrl("/media/teams/emily.png"),
  //       position_1: "Tissue Culture facility manager",
  //       position_2: "Microscopy Support"
  //     },
  //   ],
  //   [
  //     {
  //       type: 'Leadership',
  //       title: "Todd D. Wyman",
  //       image: toAbsoluteUrl("/media/teams/todd.png"),
  //       position_1: "Chief Executive Officer",
  //       position_2: "CO-FOUNDER"
  //     },
  //     {
  //       type: 'Leadership',
  //       title: "Dr. Brian T. Bennett",
  //       image: toAbsoluteUrl("/media/teams/brian.png"),
  //       position_1: "Chief Scientific Officer",
  //       position_2: "CO-FOUNDER"
  //     }
  //   ],
  //   [
  //     {
  //       type: 'Advisors',
  //       title: "Mike Lamach",
  //       image: toAbsoluteUrl("/media/teams/mike.png"),
  //       position_1: "Advisor",
  //       position_2: ""
  //     },
  //     {
  //       type: 'Advisors',
  //       title: "Kevin Lucey",
  //       image: toAbsoluteUrl("/media/teams/kevin.png"),
  //       position_1: "Advisor",
  //       position_2: ""
  //     },
  //   ]

  // ]

  const Solutions = [
    {
      title: "Primary Antibodies",
      description: `Identifyn™ offers affinity reagents, including <span>de novo primary antibodies</span> and our <span>exclusive primary</span> antibody SRM Alexa Fluor™ conjugates for oncological research in our defined, <span>first-ever complete cellular pathway models</span>, including DNA Damage and Repair, Apoptosis, Cell Cycle, Mitochondrial and Metastatic.`,
      description1: `Identifyn™ affinity reagents are purpose-built and validated in Immunofluorescent Western Blot, Widefield, Widefield SIM, Confocal, <span>Super Resolution Airyscan, Lattice SIM, and Single Molecule Localization Microscopy</span> ensuring <span>no matter the system you work within, Identifyn™ reagents brings your research into focus</span>.`
    },
    {
      title: "Identifyn™ SRM Alexa Fluor™ Conjugates",
      description: `Identifyn™ offers a thorough line of <span>comprehensively vetted Identifyn™ SRM Alexa Fluor™ conjugates</span>. Identifyn SRM conjugates are <span>pressure tested in all fluorescent applications</span>, including <span>Multiplex IF and from Widefield to Super-resolution and Single Molecule Microscopy</span>. This rigorous testing ensures that, no matter the application your research requires, <span>Identifyn™ SRM conjugates guarantee extreme sensitivity and specificity while demonstrating high brightness and photostability</span>. Identifyn's proprietary conjugation chemistry combined with our Super Resolution vetting <span>provides optimal success in your application and research focus</span>.`,
      description1: ""
    },

    {
      title: "Services",
      description: `Identifyn™ <span>provides access to all resolutions of optical microscopy</span>, from conventional to super resolution, and single molecule microscopy resources, multiplex IF, and Identifyn's™ cell and tissue culture facilities and, importantly, <span>offers pre-developed assay support for drug efficacy testing and development of new assays</span>.`,
      description1: ""
    },

    {
      title: "Assay Development",
      description: `Identifyn's™ <span>core competence</span> is their highly sensitive, spatially resolved, single-molecule level detection <span>assays for oncological research and drug dosimetry-specific pharmaceutical applications</span> for cancer and various diseases examining perturbations at <span>cell populations, at single cell and single molecule level</span> via the detection of protein and protein aggregation.`,
      description1: "",
    },


  ]

  // const Products = [
  //   {
  //     image: [`${toAbsoluteUrl("/media/products/product1.png")}`],
  //     title: "PAR Monoclonal Antibody (14B4)",
  //     price: '$499.00',
  //   },
  //   {
  //     image: [`${toAbsoluteUrl("/media/products/product2.png")}`],
  //     title: "RAD51  Monoclonal Antibody (xxxx)",
  //     price: '$599.00',
  //   },
  //   {
  //     image: [`${toAbsoluteUrl("/media/products/product3.png")}`],
  //     title: "BRCA1  Polyclonal Antibody (xxxx)",
  //     price: '$599.00',
  //   },
  //   {
  //     image: [`${toAbsoluteUrl("/media/products/product4.png")}`],
  //     title: "PARP1  Polyclonal Antibody (xxxx)",
  //     price: '$349.00',
  //   },
  //   {
  //     image: [`${toAbsoluteUrl("/media/products/product4.png")}`],
  //     title: "PARP1  Polyclonal Antibodea (xxxx)",
  //     price: '$349.00',
  //   }
  // ]


  const getBestProducts = () => {
    getFeaturedApi(true).then((res) => {
      const temp = res?.data?.products.map((item) => {
        if (item.metadata?.images.filter((_i) => _i.url !== '').length > 0) {
          const images = item?.metadata?.images.filter(({ url }) => url !== '');
          const featuredImages = images.filter(({ featuredImage }) => featuredImage);
          const imageUrl = featuredImages.length ? featuredImages[0]?.url : images[selectRandomNumber(images.length)]?.url;
          return {
            ...item,
            image: imageUrl,
          }
        }
        return item
      })
      setProducts(temp || [])
    })
  }


  const handleHorizantalScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
      if (element.scrollLeft === 0) {
        setArrowDisable(true);
      } else {
        setArrowDisable(false);
      }
    }, speed);
  };


  const handleTeamHorizantalScroll = (element, speed, distance, step, angle) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
        if (angle === 'left') {
          if (teamsSlider > 0) {
            setTeamsSlider(teamsSlider - 1)
          } else {
            setTeamsSlider(2)
          }
        } else {
          if (teamsSlider < 2) {
            setTeamsSlider(teamsSlider + 1)
          } else {
            setTeamsSlider(0)
          }
        }
      }
      if (element.scrollLeft === 0) {
        setArrowDisable(true);
      } else {
        setArrowDisable(false);
      }
    }, speed);
  };



  useEffect(() => {
    if (state === undefined) return
    if (state) {
      axios.post('https://countriesnow.space/api/v0.1/countries/state/cities', {
        country: country,
        state: state
      }).then((res) => {
        setCities(res.data.data)
      }).catch((err) => {
        console.error(err)
      })
    }
  }, [state])

  useEffect(() => {
    getBestProducts()
  }, [])


  const sendContact = async () => {
    setIsLoading(true)
    if (email && RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(email)) {
      const data = {
        first_name: firstName,
        last_name: lastName,
        email,
        company,
        country,
        state,
        city,
        topic,
        message: description,
        email_to: 'contact@identifyn.com'
      }
      const response = await contactApi(data)
      if (response?.status === 200) {
        setFirstName('')
        setLastName('')
        setEmail('')
        setCompany('')
        setCountry('United States')
        setState('')
        setCity('')
        setTopic('')
        setDescription('')
        setIsLoading(false)
        setNotification({
          type: 'success',
          message: 'Message sent successfully!'
        })
      } else {
        setIsLoading(false)
        setNotification({
          type: 'red',
          message: 'Something went wrong, please try again!'
        })
      }
    } else {
      setIsLoading(false)
      setNotification({
        type: 'red',
        message: 'Please enter a valid Email Address'
      })
    }
  }



  // const swiperParams = {
  //   slidesPerView: 'auto',
  //   breakpoints: {
  //     320: {
  //       slidesPerView: 1
  //     },
  //     640: {
  //       slidesPerView: 1
  //     },
  //     768: {
  //       slidesPerView: 2
  //     },
  //     1024: {
  //       slidesPerView: 3
  //     },
  //     1200: {
  //       slidesPerView: 4
  //     },

  //   },
  //   spaceBetween: 30,
  //   // loop: true,
  //   // freeMode: true,
  //   navigation: true,
  //   navigation: {
  //     nextEl: '.right',
  //     prevEl: '.left',
  //   },
  //   parallax: true,
  //   observer: true,
  //   observeParents: true,
  // };
  // useEffect(() => {
  //   const swiper = new Swiper('.mySwiper', {
  //     // configure Swiper to use modules
  //     modules: [FreeMode, Autoplay, Navigation, Parallax],
  //     ...swiperParams,
  //   });

  //   const TeamSwiper = new Swiper('.teamSwiper', {
  //     // configure Swiper to use modules
  //     modules: [FreeMode, Autoplay, Navigation, Parallax],
  //     ...swiperParams,

  //   });

  // }, [products]);


  const resetSwipers = () => {
    const teamSwiper = document.querySelector('.teamSwiper')?.swiper;
    if (teamSwiper) {
      teamSwiper.slideTo(0);
      teamSwiper.update();
    }
  };

  useEffect(() => {
    resetSwipers();
  }, [teamsSlider]);


  const selectRandomNumber = (length) => {
    return Math.floor(Math.random() * length)
  }

  const getTeamsData = () => {
    getTeamsApi().then((res) => {
      if (res.data) {
        const leadershipTeam = res?.data?.roles?.find(role => role.name === 'Leadership')?.teams?.sort((a, b) => a.order - b.order)?.map((item) => {
          return {
            type: 'orange',
            description: item.bio,
            title: item.name,
            ...item
          }
        })
        const advisorTeam = res.data.roles.find(role => role.name === 'Advisors')?.teams?.sort((a, b) => a.order - b.order)?.map((item) => {
          return {
            type: 'gray',
            description: item.bio,
            title: item.name,
            ...item
          }
        })
        const Team = res.data.roles.find(role => role.name === 'Team')?.teams?.sort((a, b) => a.order - b.order)?.map((item) => {
          return {
            type: 'blue',
            description: item.bio,
            title: item.name,
            ...item
          }
        })
        setTeamCards([Team, leadershipTeam, advisorTeam])
      }
    })
  }

  useEffect(() => {
    getTeamsData()
  }, [])




  return (
    <>
      <Layout>
        <div className="featured-container">
        <p><span>IDENTiFYN™</span> featured in Azure Biosystems case study. <a href='https://azurebiosystems.com/blog/case-study-sapphire-fl-delivers-unmatched-imaging-flexibility-for-a-florida-start-ups-success/' target='_blank'>Click here</a> to read</p>
        </div>
        <div className="container hero-container">
          <div className="hero-text">
            <h1>IDENTiFYN™ <br />
              <span>Super Resolution</span><br />
              Applications & Services</h1>
            <p>Delivering unprecedented power, speed, and access to multimodal super resolution microscopy, reagents, applications, and discovery.</p>
          </div>
        </div>
        <div className="container bench-container">
          <div className="left-container">
            <span style={{ width: '44px', height: '44px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '12px', background: '#fff2e5', color: 'var(--primary-color)', marginBottom: '20px' }}>
              <i className="fal fa-vial" aria-hidden="true"></i>
            </span>
            <div className="solutions-text">
              <h2><span>IDENTiFYN™</span> Bench to Body™</h2>
              <p><span>Our Identifyn™ products</span>, which encompass de novo <span>antibodies and fluorescent conjugates</span>, are just the beginning. We offer a <span>comprehensive range</span> of <span>assays</span> and <span>services</span>, all supported by a <span>pioneering</span>, <span>first-ever</span>, <span>multi-model super resolution microscopy and multiplex format</span>. This comprehensive approach is the foundation of our RUO and IVD <span>Bench to Body™ research strategy</span>.</p>
              <br />
              <p><span>Identifyn's™ Fundamental strategy</span> for molecular products and research, <span>meticulously designed</span> with a keen <span>focus on clinical application</span>, is not just a bridge but a <span>transformative pathway</span> between the <span>Bench and the Body</span>. This approach holds <span>immense potential</span> for groundbreaking <span>discoveries and advancements</span> in the field.</p>
            </div>
          </div>
          <div className="right-container">
            <img src={toAbsoluteUrl("/media/wheel/benchwheel.png")} alt="" className='bench-wheel-logo' />
          </div>
        </div>
        <div className="container technologies-container">
          <span style={{ width: '44px', height: '44px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '12px', background: '#fff2e5', color: 'var(--primary-color)' }}>
            <i className="fal fa-microscope" aria-hidden="true"></i>
          </span>
          <div className="technologies-text">
            <h1><span>IDENTiFYN™</span> Technologies & Partners</h1>
            <p>Identifyn™, through its partners in technology, reagents, and applications, delivers the <span>most comprehensive suite of discovery tools for immunofluorescent biological investigation</span> anywhere. From conventional to <span>Super-Resolution Microscopy (SRM), Single Molecule Localization Microscopy (SMLM), and immunofluorescent Multiplex immunocytochemistry,</span> Identifyn™ delivers power and speed at all resolutions. Identifyn™ is committed to the first line of comprehensively <span>SRM-, SMLM-, and Multiplex-vetted antibodies,</span> Alexa Fluor™ direct and secondary conjugates targeted to oncological cellular pathways. Together, Identifyn™ and its partners stand ready to bring <span>a new focus on academic, industry, and Drug Discovery research</span>. </p>
          </div>
          <div className="partners-container">
            <div className="partners">
              <Link to="/technologies/zeiss"><img src={toAbsoluteUrl('/media/partners/zeiss.png')} alt="" width={84} height={84} /></Link>
            </div>
            {/* <div className="partners">
              <img src={toAbsoluteUrl('/media/partners/thermofisher.png')} alt="" width={180} />
            </div> */}
            <div className="partners">
              <Link to="/technologies/bruker"><img src={toAbsoluteUrl('/media/partners/bruker.png')} alt="" width={150} /></Link>
            </div>
            <div className="partners">
              <Link to="/technologies/acquifer"><img src={toAbsoluteUrl('/media/partners/acquifer.png')} alt="" width={150} /></Link>
            </div>
            {/* <div className="partners">
              <img src={toAbsoluteUrl('/media/partners/bethyl.png')} alt="" width={150} />
            </div> */}
            {/* <div className="partners">
              <img src={toAbsoluteUrl('/media/partners/fortis.png')} alt="" width={150} />
            </div> */}
            <div className="partners">
              <Link to="/technologies/azure"><img src={toAbsoluteUrl('/media/partners/azure.png')} alt="" width={150} /></Link>
            </div>
            {/* <div className="partners">
              <img src={toAbsoluteUrl('/media/partners/logowithtext.png')} alt="" />
            </div> */}
          </div>
        </div>
        <div className="container solutions-container">
          <div className="left-container">
            <span style={{ width: '44px', height: '44px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '12px', background: '#fff2e5', color: 'var(--primary-color)', marginBottom: '20px' }}>
              <i className="fal fa-lightbulb-on" aria-hidden="true"></i>
            </span>
            <div className="solutions-text">
              <h2>IDENTiFYN™ Solutions</h2>
              <h3><img src={toAbsoluteUrl('/media/polygon.png')} alt="" style={{ marginRight: '10px' }} />{Solutions[solutionSlider].title}</h3>
              <p dangerouslySetInnerHTML={{ __html: Solutions[solutionSlider].description }}></p>
              {Solutions[solutionSlider].description1 && <p dangerouslySetInnerHTML={{ __html: Solutions[solutionSlider].description1 }} style={{ marginTop: '20px' }}></p>}
            </div>
            <div className="buttons-container">
              <div className="slider-controls">
                <button style={{ width: '44px', height: '44px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '12px', background: 'rgba(255, 255, 255, 0.16', color: 'var(--white)' }}
                  onClick={() => {
                    if (solutionSlider === 0) {
                      setSolutionSlider(Solutions.length - 1)
                    } else {
                      setSolutionSlider(solutionSlider - 1)
                    }
                  }}
                >
                  <i className="fas fa-chevron-left" aria-hidden="true"></i>
                </button>
                <button style={{ width: '44px', height: '44px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '12px', background: 'rgba(255, 255, 255, 0.16', color: 'var(--white)' }}
                  onClick={() => {
                    if (solutionSlider === Solutions.length - 1) {
                      setSolutionSlider(0)
                    } else {
                      setSolutionSlider(solutionSlider + 1)
                    }
                  }}
                >
                  <i className="fas fa-chevron-right" aria-hidden="true"></i>
                </button>
              </div>
              <button class="btn" onClick={() => {
                if (solutionSlider === 0) {
                  navigate('/technologies/primary-antibodies')
                } else if (solutionSlider === 1) {
                  navigate('/technologies/alexaflour-conjugates')
                } else if (solutionSlider === 2) {
                  navigate('/technologies/services')
                } else if (solutionSlider === 3) {
                  navigate('/technologies/assay-development')
                }
              }}>Explore More</button>
            </div>
          </div>
          <div className="right-container">
            {/* <img src={toAbsoluteUrl('/media/solutions.png')} alt="" /> */}
            <div className="wheel-container">
              <img src={toAbsoluteUrl("/media/wheel/logo.png")} alt="" className='wheel-logo' />
              <img src={toAbsoluteUrl("/media/wheel/assay.png")} alt="" className='red' onClick={() => setSolutionSlider(3)} />
              <img src={toAbsoluteUrl("/media/wheel/activeassay.png")} alt="" className={`red active-red ${solutionSlider === 3 ? 'active' : ''}`} onClick={() => setSolutionSlider(3)} />
              <img src={toAbsoluteUrl("/media/wheel/primary.png")} alt="" className='blue' onClick={() => setSolutionSlider(0)} />
              <img src={toAbsoluteUrl("/media/wheel/activeprimary.png")} alt="" className={`blue active-blue ${solutionSlider === 0 ? 'active' : ''}`} onClick={() => setSolutionSlider(0)} />
              <img src={toAbsoluteUrl("/media/wheel/secondary.png")} alt="" className='green' onClick={() => setSolutionSlider(1)} />
              <img src={toAbsoluteUrl("/media/wheel/activesecondary.png")} alt="" className={`green active-green  ${solutionSlider === 1 ? 'active' : ''}`} onClick={() => setSolutionSlider(1)} />
              <img src={toAbsoluteUrl("/media/wheel/service.png")} alt="" className='orange' onClick={() => setSolutionSlider(2)} />
              <img src={toAbsoluteUrl("/media/wheel/activeservice.png")} alt="" className={`orange active-orange ${solutionSlider === 2 ? 'active' : ''}`} onClick={() => setSolutionSlider(2)} />
            </div>
          </div>
        </div>
        <div className="container higlighted-container">
          <div className="technologies-container" style={{ paddingBottom: '48px' }}>
            <span style={{ width: '44px', height: '44px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '12px', background: '#fff2e5', color: 'var(--primary-color)' }}>
              <img src={toAbsoluteUrl("/media/antibody.png")} alt="" style={{ width: '20px' }} />
            </span>
            <div className="technologies-text">
              <h1>Highlighted <span>Products</span></h1>
              <p style={{ width: '100%' }}>Delivering unprecedented power, speed, and access <br /> to multimodal super resolution microscopy, reagents, applications, and discovery. </p>
            </div>
            {/* <div className="slider-container">
            {products?.length >=4 &&  <button
                className='icon left'
                disabled={arrowDisable}
                onClick={() => {
                  handleHorizantalScroll(elementRef.current, 5, 100, -10);
                }}
              >
                <i className="fas fa-chevron-left" aria-hidden="true"></i>
              </button>
              }
          {products?.length >=4 &&   <button
                className='icon right'
                onClick={() => {
                  handleHorizantalScroll(elementRef.current, 5, 100, 10);
                }}
              >
                <i className="fas fa-chevron-right" aria-hidden="true"></i>
              </button>
              }
              <div className="articles-container" ref={elementRef} style={{ justifyContent: 'start' }}>
                {products.map((item, index) => {
                  return <Card index={index} item={item} key={item.id} setSelectedProduct={setSelectedProduct} />
                })}
              </div>
            </div> */}
            <div className="slider-container">
              <div className="articles-container">
                <Swiper
                  slidesPerView={4}
                  spaceBetween={30}
                  navigation={{
                    nextEl: '.right',
                    prevEl: '.left',
                  }}
                  breakpoints={{
                    320: {
                      slidesPerView: 1
                    },
                    640: {
                      slidesPerView: 1
                    },
                    768: {
                      slidesPerView: 2
                    },
                    1024: {
                      slidesPerView: 3
                    },
                    1200: {
                      slidesPerView: 4
                    },
                  }}
                  modules={[Navigation]}
                  className="swiper-container mySwiper"
                >
                  {products.map((item, index) => {
                    return <SwiperSlide>
                      <Card index={index} item={item} key={item.id} setSelectedProduct={setSelectedProduct} />
                    </SwiperSlide>
                  })}
                </Swiper>

                {/* <div class="swiper-container mySwiper">
                  <div class="swiper-wrapper">
                    {products.map((item, index) => {
                      return <div className="swiper-slide" key={index}>
                        <Card index={index} item={item} key={item.id} setSelectedProduct={setSelectedProduct} />
                      </div>
                    })}
                  </div>
                </div> */}
                <button
                  className='icon left'
                >
                  <i className="fas fa-chevron-left" aria-hidden="true"></i>
                </button>

                <button
                  className='icon right'
                >
                  <i className="fas fa-chevron-right" aria-hidden="true"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="container contact-container" id='contact' style={{ marginTop: products && products?.length < 4 ? '48px' : '' }}>
          <div className="left-container">
            <div className="solutions-text">
              <h2>Contact <span>Us</span></h2>
              <p>Please contact Identifyn™ using this form. Select topic from dropdown and briefly describe how we can help.
              </p>
            </div>
          </div>
          <div className="right-container">
            <div className="form-container">
              <div className="form-wrapper">
                <div
                  style={{ display: "block", width: "100%", marginTop: "20px", marginBottom: '20px' }}
                >
                  <div className="two-column">
                    <Input placeholder="Your First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                    <Input placeholder="Your Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                  </div>
                  <Input placeholder="Your Email" type='email' style={{ marginBottom: '20px' }} value={email} onChange={(e) => setEmail(e.target.value)} />
                  <Input placeholder="Your Company/Institution" style={{ marginBottom: '20px' }} optional value={company} onChange={(e) => setCompany(e.target.value)} />
                  <div className="three-column">
                    <CountryDropdown value={country} onChange={(value) => {
                      setCountry(value)
                    }} />
                    <StateDropdown value={state} country={country} placeholder={"State"} onChange={(value) => {
                      setState(value)
                    }} />
                    <select
                      data-kt-select2='true'
                      data-placeholder='City'
                      data-allow-clear='true'
                      data-kt-user-table-filter='two-step'
                      data-hide-search='true'
                      value={city}
                      onChange={(e) => {
                        setCity(e.target.value)
                      }}
                      placeholder='City'
                    >
                      <option disabled selected>City </option>
                      {cities && cities.length && cities?.map((city) => {
                        return <option key={city} value={city}>{city}</option>
                      })}
                    </select>
                  </div>
                  <Dropdown
                    value={topic}
                    onChange={(e) => {
                      setTopic(e)
                    }}
                    style={{ marginBottom: '20px', width: '100%' }}
                    label={'Select Topic'}
                    options={
                      [
                        "Microscopy Support",
                        "Protein Chemistry Support",
                        "Primary Antibody",
                        "Primary Fluorescent Conjugates",
                        "Secondary Fluorescent Conjugates",
                        // "Reagents & Glass",
                        // "STORM Buffers",
                        // "Kits",
                        // "Account Support",
                        // "Order Support",
                        "General Inquiry"
                      ]
                    }
                  />
                  {/* <select
                      data-placeholder='Topic'     
                      value={topic}
                      onChange={(e) => {
                        setTopic(e.target.value)
                      }}
                      placeholder='Select Topic'
                      style={{ marginBottom: '20px' }}
                    >
                      <option disabled selected>Select Topic </option>
                      <option value={"Microscopy Support"}>Microscopy Support</option>
                      <option value={"Protein Chemistry Support"}>Protein Chemistry Support</option>
                      <option value={"Primary Antibody"}>Primary Antibody</option>
                      <option value={"Primary Fluorescent Conjugates"}>Primary Fluorescent Conjugates</option>
                      <option value={"Secondary Fluorescent Conjugates"}>Secondary Fluorescent Conjugates</option>
                      <option value={"Reagents & Glass"}>Reagents & Glass</option>
                      <option value={"STORM Buffers"}>STORM Buffers</option>
                      <option value={"Kits"}>Kits</option>
                      <option value={"Account Support"}>Account Support</option>
                      <option value={"Order Support"}>Order Support</option>
                      <option value={"General Inquiry"}>General Inquiry</option>
                      <option value={"Carrers"}>Careers</option>
                    </select> */}
                  <textarea
                    style={{ resize: "none", borderRadius: "8px" }}
                    placeholder='Write your message here!'
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  ></textarea>
                </div>
                <button class="btn" style={{ float: 'right' }}
                  disabled={!firstName || !lastName || !email || !country || !state || !city || !topic || !description}
                  onClick={sendContact}
                >Get In Touch!</button>
              </div>
            </div>
          </div>
        </div>
        <div className="container higlighted-container teams-container">
          <div className="technologies-container" style={{ paddingBottom: '48px' }}>
            <span style={{ width: '44px', height: '44px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '12px', background: '#fff2e5', color: 'var(--primary-color)' }}>
              <i className="far fa-users"></i>
            </span>
            <div className="team-text">
              <h1>Our <span className={teamsSlider === 0 ? 'team-active' : ''} onClick={() => setTeamsSlider(0)}>Team</span>, <span className={teamsSlider === 1 ? 'team-active' : ''} onClick={() => setTeamsSlider(1)}>Leadership</span> & <span className={teamsSlider === 2 ? 'team-active' : ''} onClick={() => setTeamsSlider(2)}>Advisors</span></h1>
            </div>
          </div>
          {/* <div className="slider-container">
            <button
              className='icon left'
              onClick={() => {
                // if (teamsSlider > 0) {
                //   setTeamsSlider(teamsSlider - 1)
                // } else {
                //   setTeamsSlider(2)
                // }
                handleTeamHorizantalScroll(teamRef.current, 5, 100, 10, 'left');
              }}
            >
              <i className="fas fa-chevron-left" aria-hidden="true"></i>
            </button>
            <button
              className='icon right'
              onClick={() => {
                handleTeamHorizantalScroll(teamRef.current, 5, 100, 10, 'right');
                // if (teamsSlider < 2) {
                //   setTeamsSlider(teamsSlider + 1)
                // } else {
                //   setTeamsSlider(0)
                // }
              }}
            >
              <i className="fas fa-chevron-right" aria-hidden="true"></i>
            </button>
            <div className="articles-container" ref={teamRef}>
              {TeamCards[teamsSlider].map((item, index) => {
                return <article className='team-card' style={{}}>
                  <img src={item.image} alt="" width={'224px'} height={'224px'} />
                  <div className="article-text">
                    <h5>{item.title}</h5>
                    <p>{item.position_1}</p>
                    <p>{item.position_2}</p>
                    <p></p>
                  </div>
                </article>
              })}
            </div>
          </div> */}
          <div className="slider-container">
            <div className="articles-container">
              <Swiper
                slidesPerView={4}
                spaceBetween={30}
                navigation={{
                  nextEl: '.team-right',
                  prevEl: '.team-left',
                }}
                breakpoints={{
                  320: {
                    slidesPerView: 1
                  },
                  640: {
                    slidesPerView: 1
                  },
                  768: {
                    slidesPerView: 2
                  },
                  1024: {
                    slidesPerView: 3
                  },
                  1200: {
                    slidesPerView: 4
                  },
                }}
                modules={[Navigation]}
                className="swiper-container teamSwiper"
                style={{ height: 'max-content' }}
              >
                {TeamCards[teamsSlider]?.map((item, index) => {
                  return <SwiperSlide key={index} className='team-card' style={{ display: 'grid', placeItems: 'center', height: 'max-content' }}>
                    {item.image_layout ? <Shapes key={item.id} shapeType={item.image_layout} style={{ clipPath: `url(#svgPath-${item.image_layout}-text)`, backgroundImage: `url("${galleryUrl}:${item.image?.split('.com/')[1]}")` }} selectedPhoto={item.image} bigger />
                      :
                      <Shapes key={item.id} shapeType={1} style={{ clipPath: `url(#svgPath-${1}-text)`, backgroundImage: `url("${toAbsoluteUrl('/media/teams/blank.png')}")` }} selectedPhoto={`${toAbsoluteUrl('/media/teams/blank.png')}`} bigger />
                      // <img src={item.image ? item.image : `${toAbsoluteUrl('/media/teams/blank.png')}`} alt="" style={{ width: '180px', height: '180px' }} />
                    }
                    {/* <img src={item.image} alt="" width={'224px'} height={'224px'} /> */}
                    <div className="article-text">
                      <h5>{item.title}</h5>
                      <p>{item.position_1}</p>
                      <p>{item.position_2}</p>
                      <p></p>
                    </div>
                  </SwiperSlide>
                })}
              </Swiper>
              {/* <div class="swiper-container teamSwiper">
                <div class="swiper-wrapper">
                  {TeamCards[teamsSlider].map((item, index) => {
                    return <article className='swiper-slide team-card' style={{ display: 'flex' }} key={index}>
                      <img src={item.image} alt="" width={'224px'} height={'224px'} />
                      <div className="article-text">
                        <h5>{item.title}</h5>
                        <p>{item.position_1}</p>
                        <p>{item.position_2}</p>
                        <p></p>
                      </div>
                    </article>
                  })}
                </div>
              </div> */}
              <button
                className='icon left team-left'
              >
                <i className="fas fa-chevron-left" aria-hidden="true"></i>
              </button>

              <button
                className='icon right team-right'
              >
                <i className="fas fa-chevron-right" aria-hidden="true"></i>
              </button>
            </div>
          </div>
          <button className='btn' onClick={() => navigate('/team')}>View All</button>
        </div>
        <Footer />
        <div className="print-map" style={{ display: 'none' }}>
          <Coa reference={coARef} selectedProduct={selectedProduct} productCategory={selectedProduct?.mid_code?.search("PA") >= 0 ? 'PA' : selectedProduct?.mid_code?.search("SA") >= 0 ? 'SA' : 'DC'} />
        </div>
        {openItemPreviewModal && <ItemPreviewDrawer selectedProduct={selectedProduct} setSelectedProduct={setSelectedProduct} coARef={coARef} />}
      </Layout>
    </>
  )
}

export default Index