import React, { useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { AuthContext } from '../../context/auth'
import Navbar from '../Navbar/navbar'
import { Footer } from '../Footer'
import SignIn from "../../pages/SignIn"
import SignUp from "../../pages/SignUp"
import CompleteModal from "../modals/completeModal"
import RestoreModal from "../modals/restorePasswordModal"
import ChangePasswordRestoreModal from "../modals/changePasswordRestoreModal"
import ChangePasswordModal from "../modals/changePasswordModal"
import ChangeEmailModal from "../modals/changeEmailModal"
import LogOutModal from "../modals/LogOutModal"
import DeleteProfileModal from "../modals/DeleteProfileModal"
import TeamModal from '../modals/teamModal'
import ShippingAddressModal from '../modals/shippingAddressModal'
import BillingAddressModal from '../modals/billingAddressModal'
import OrderCompleteModal from '../modals/orderCompleteModal'
import ThankYouModal from '../modals/ThankYouModal'
import Notification from '../Notification'
import Helmet from "../Helmet"


const Index = ({ children, meta }) => {
  const { signInModal, setSignInModal, signUpModal, setSignUpModal, completeModal, setCompleteModal, restoreModal, setRestoreModal, changePasswordModal, setChangePasswordModal, changePasswordRestoreModal, setChangePasswordRestoreModal, notification, setNotification, teamModal, setTeamModal, changeEmailModal, setChangeEmailModal, logOutModal, setLogOutModal, deleteProfileModal, setDeleteProfileModal, shippingAddressModal, setShippingAddressModal, billingAddressModal, setBillingAddressModal, completeOrderModal, setCompleteOrderModal, tempDisable, setTempDisable, openItemPreviewModal, setOpenItemPreviewModal, setSelectedProduct, setSelectedProtein, setMapViewActive } = useContext(AuthContext)
  const location = useLocation()
  useEffect(() => {
    if (signInModal || signUpModal || completeModal || restoreModal || changePasswordModal || changePasswordRestoreModal || teamModal || changeEmailModal || logOutModal || deleteProfileModal || shippingAddressModal || billingAddressModal || completeOrderModal || !tempDisable || openItemPreviewModal) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [signInModal, signUpModal, completeModal, restoreModal, changePasswordModal, changePasswordRestoreModal, teamModal, changeEmailModal, logOutModal, deleteProfileModal, shippingAddressModal, billingAddressModal, completeOrderModal, tempDisable, openItemPreviewModal])


  useEffect(() => {
    if (notification) {
      setTimeout(() => {
        setNotification(null)
      }, 5000)
    }
  }, notification)


  useEffect(() => {

    const handleBackButton = () => {
      setSignInModal(false)
      setSignUpModal(false)
      setCompleteModal(false)
      setRestoreModal(false)
      setChangePasswordModal(false)
      setChangePasswordRestoreModal(false)
      setTeamModal(false)
      setChangeEmailModal(false)
      setLogOutModal(false)
      setDeleteProfileModal(false)
      setShippingAddressModal(false)
      setBillingAddressModal(false)
      setCompleteOrderModal(false)
      setTempDisable(true)
      setOpenItemPreviewModal(false)
      setMapViewActive(false)
      setSelectedProduct(null)
      setSelectedProtein(null)
    };

    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", () => handleBackButton);
    }

  }, [])

  useEffect(() => {
    if((location.pathname === '/product/pathways/ssb' || location.pathname === '/product/pathways/dsb-hr' || location.pathname === '/product/pathways/dsb-nh')) {
    }else{
      setMapViewActive(false)
      setSelectedProduct(null)
      setSelectedProtein(null)
    }
  }, [location])



  return (
    <>
      {<Helmet meta={meta} />}
      {notification && <Notification message={notification.message} subMessage={notification.subMessage} type={notification.type} onClick={() => setNotification(null)} />}
      <Navbar />
      <main>
        {children}
      </main>
      {/* <Footer/> */}
      {signInModal && <SignIn />}
      {signUpModal && <SignUp />}
      {completeModal && <CompleteModal />}
      {restoreModal && <RestoreModal />}
      {changePasswordRestoreModal && <ChangePasswordRestoreModal />}
      {changePasswordModal && <ChangePasswordModal />}
      {teamModal && <TeamModal />}
      {changeEmailModal && <ChangeEmailModal />}
      {logOutModal && <LogOutModal />}
      {deleteProfileModal && <DeleteProfileModal />}
      {shippingAddressModal && <ShippingAddressModal />}
      {billingAddressModal && <BillingAddressModal />}
      {completeOrderModal && <OrderCompleteModal />}
      {!tempDisable && <ThankYouModal />}
    </>
  )
}

export default Index