import React, { useContext, useEffect, useState } from 'react'
import "./style.scss"
import { toAbsoluteUrl } from "../../utils/assetHelper.js"
import Input from "../../components/Input/index.js"
import { AuthContext } from "../../context/auth.js"
import { CountryDropdown, StateDropdown } from '../../components/DropDowns'
import { Checkbox } from '../../components/Checkbox'
import { checkEmailExist, getCustomerGroupsApi, createCustomerApi, createCustomerGroupApi, verifyCustomerApi, updateCustomerApi, addCustomerAddressesApi, loginApi } from '../../api/index.js'
import { countryCode } from '../../utils/country'
import { setLocalStorage } from '../../helpers/localStorage.js'

const Index = () => {
  const { signUpModal, setSignUpModal, setSignInModal, setCompleteModal, setIsLoading, setNotification, login, user, isLoggedIn } = useContext(AuthContext)
  const [current, setCurrent] = useState(0)
  const [types, setTypes] = useState([])
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [position, setPosition] = useState('')
  const [email, setEmail] = useState('')
  const [country, setCountry] = useState('United States');
  const [academic, setAcademic] = useState()
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [validPassword, setValidPassword] = useState(false)
  const [error, setError] = useState(false)
  const [code, setCode] = useState('')
  const [taxExempt, setTaxExempt] = useState(false)
  const [taxExemptNumber, setTaxExemptNumber] = useState('')
  const [shippingAddress, setShippingAddress] = useState({
    first_name: '',
    last_name: "",
    company: '',
    address_1: '',
    city: '',
    province: '',
    country_code: '',
    postal_code: '',
    metadata: {
      department: '',
      building: '',
      lab: '',
      attention_to: '',
      type: "shipping",
      is_primary: true
    }
  })
  const [asShipping, setAsShipping] = useState(false)
  const [billingAddress, setBillingAddress] = useState({
    first_name: '',
    last_name: "",
    company: '',
    address_1: '',
    city: '',
    province: '',
    country_code: '',
    postal_code: '',
    metadata: {
      department: '',
      building: '',
      lab: '',
      attention_to: '',
      type: "billing",
      is_primary: true
    }
  })



  useEffect(() => {
    if (signUpModal && !user) {
      setCurrent(0)
      fetchCustomerGroups()
    }else if (user && signUpModal){
      if(!user?.customer?.metadata?.is_email_verified){
        setCurrent(2)
        setEmail(user?.customer?.email)
        resendEmail()
      }
      else if(user?.customer?.is_tax_exempt === null ){
        setCurrent(3)
      }
      else if (!user?.customer?.shipping_addresses?.length){
        setCurrent(4)
      }else if (!user?.customer?.billing_address){
        setCurrent(5)
      }
    }
  }, [signUpModal, user])

  // useEffect(() => {
  //   const hasMinLength = password.length >= 8;
  //   const hasUppercase = /[A-Z]/.test(password);
  //   const hasNumber = /\d/.test(password);
  //   if (password !== confirmPassword) {
  //     setValidPassword(false)
  //     setError(true)
  //   } else if (password === confirmPassword && hasMinLength && hasUppercase && hasNumber) {
  //     setValidPassword(true)
  //     setError(false)
  //   } else {
  //     setValidPassword(false)
  //   }
  // }, [password])


  const fetchCustomerGroups = () => {
    setIsLoading(true)
    getCustomerGroupsApi().then((res) => {
      if (res?.data?.customerGroups) {
        setTypes(res?.data?.customerGroups)
        setIsLoading(false)
      }
    })
  }

  const signUp = () => {
    setIsLoading(true)
    if (password.length < 8 || !/[A-Z]/.test(password) || !/\d/.test(password) || !confirmPassword) {
      setError('Password must be at least 8 characters long, contain at least one uppercase letter, and one number')
      setIsLoading(false)
    }
    else if (password !== confirmPassword) {
      setError('Password does not match')
      setIsLoading(false)
    } else {
      if(types.find((group) => group.name === academic)){
        createCustomerApi({
          email,
          isSignUp: true,
          firstName,
          lastName,
          password,
          group_id: types.find((group) => group.name === academic)?.id,
          position,
          country
        }).then((res) => {
          setIsLoading(false)
          setNotification({
            type: 'success',
            message: 'Verification code has been sent to your email address!'
          })
          setError('')
          setCurrent(2)
          loginApi({
            email,
            password
          }).then((res) => {
            if (res?.data?.access_token) {
              setLocalStorage('identifyntoken', res?.data?.access_token)
              // login(res.data)
            }
          }) 
        }).catch(() => {
          setIsLoading(false)
          setNotification({
            type: 'red',
            message: 'Something went wrong, please try again!'
          })
          setError('')
        }) 
      }else{
        createCustomerGroupApi({
          name: academic
        }).then((res) => {
          if(res && res.data){
            createCustomerApi({
              email,
              isSignUp: true,
              firstName,
              lastName,
              password,
              group_id: res?.data?.id,
              position,
              country
            }).then((res) => {
              setIsLoading(false)
              setNotification({
                type: 'success',
                message: 'Verification code has been sent to your email address!'
              })
              setError('')
              setCurrent(2)
              loginApi({
                email,
                password
              }).then((res) => {
                if (res?.data?.access_token) {
                  setLocalStorage('identifyntoken', res?.data?.access_token)
                  // login(res.data)
                }
              }) 
            }).catch(() => {
              setIsLoading(false)
              setNotification({
                type: 'red',
                message: 'Something went wrong, please try again!'
              })
              setError('')
            }) 
          }else{
            setIsLoading(false)
            setNotification({
              type: 'red',
              message: 'Something went wrong, please try again!'
            })
            setError('')
          }
        })
      }

    }
  }

  const verify = () => {
    setIsLoading(true)
    verifyCustomerApi(email, code).then((res) => {
      if(res.message){
        setNotification({
          type: 'red',
          message: res.message.message
        })

      }else{
        setNotification({
          type: 'email',
          message: 'Email verified!'
        })
        setCurrent(3)
        login(res.data)
      }
      setIsLoading(false)
    }).catch((err) => {
      setIsLoading(false)
      setNotification({
        type: 'red',
        message: 'Invalid verification code!'
      })
    })
  }

  const updateTaxExempt = () => {
    setIsLoading(true)
    updateCustomerApi({
      is_tax_exempt: taxExempt,
      tax_number: taxExemptNumber
    }).then((res) => {
      if(res.data){
        setIsLoading(false)
        setNotification({
          type: 'success',
          message: 'Tax exempt updated!'
        })
        setCurrent(4)
        login(res.data)
      }else{
        setIsLoading(false)
        setNotification({
          type: 'red',
          message: 'Something went wrong, please try again!'
        })
      }
    }).catch((err) => {
      setIsLoading(false)
      setNotification({
        type: 'red',
        message: 'Something went wrong, please try again!'
      })
    })
  }

  const addShippingAddress = () => {
    const data = {
      ...shippingAddress,
      country_code: countryCode[shippingAddress.country_code]
    }
    setIsLoading(true)
    addCustomerAddressesApi({
      address: data
    }).then((res) => {
      if (res.data) {
        setNotification({
          type: 'success',
          message: 'Address updated successfully'
        })
        setIsLoading(false)
        setCurrent(5)
      } else {
        setNotification({
          type: 'red',
          message: 'Something went wrong'
        })
        setIsLoading(false)
      }
    }).catch((err) => {
      setNotification({
        type: 'red',
        message: 'Something went wrong'
      })
      setIsLoading(false)
    })
  }

  const addBillingAddress = () => {
    const data = asShipping ? {
      ...shippingAddress,
      country_code: countryCode[shippingAddress.country_code],
      metadata:{
        ...shippingAddress.metadata,
        type:"billing"
      }
    }
      : {
        ...billingAddress,
        country_code: countryCode[billingAddress.country_code]
      }
    setIsLoading(true)
    addCustomerAddressesApi({
      address: data
    }).then((res) => {
      if (res.data) {
        updateCustomerApi({
          billing_address: data
        }).then((res) => {
          if (res.data) {
            setNotification({
              type: 'success',
              message: 'Address updated successfully'
            })
            setSignUpModal(false)
            setCompleteModal(true)
            setIsLoading(false)
          } else {
            setNotification({
              type: 'red',
              message: 'Something went wrong'
            })
            setIsLoading(false)
          }
        })
      } else {
        setNotification({
          type: 'red',
          message: 'Something went wrong'
        })
        setIsLoading(false)
      }
    }).catch((err) => {
      setNotification({
        type: 'red',
        message: 'Something went wrong'
      })
      setIsLoading(false)
    })
  }


  const resendEmail = () => {
    setIsLoading(true)
    createCustomerApi({
      email:user?.customer?.email ? user?.customer?.email : email,
      isSignUp: true
    }).then((res) => {
      setIsLoading(false)
      setNotification({
        type: 'success',
        message: 'Verification code has been sent to your email address!'
      })
    }).catch(() => {
      setIsLoading(false)
      setNotification({
        type: 'red',
        message: 'Something went wrong, please try again!'
      })
    })
  }

  return (
    <div className="modal-wrapper">
      <div className="modal-content">
        <i className='fal fa-times close' style={{ color: 'white', position: 'absolute', right: '32px', top: '32px', cursor: 'pointer', zIndex: 9, fontSize: '20px' }} onClick={() => {
          setSignUpModal(false)
        }}></i>
        <div className="left-container">
          {current <= 2 && <div className="steps">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "19px",
                marginTop: "20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#3f1bd0",
                  color: "#ffffff",
                  width: "20px",
                  height: "20px",
                  borderRadius: "50%",
                  border: "1px solid transparent",
                }}
              >
                <span
                  style={{
                    background: current > 0 ? "#3f1bd0" : current === 0 ? "#fcfcfc" : "transparent",
                    width: "8px",
                    height: "8px",
                    borderRadius: "50%",
                  }}
                ></span>
              </div>
              <div
                style={{
                  height: "1px",
                  width: "200px",
                  margin:' 0 5px',
                  backgroundColor: current >= 1 ? "#3f1bd0" : "rgba(154, 154, 154, 0.2)",
                }}
                className="progress-connector-line"
              ></div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: current >= 1 ? "#3f1bd0" : "rgba(154, 154, 154, 0.2)",
                  color: "#ffffff",
                  width: "20px",
                  height: "20px",
                  borderRadius: "50%",
                  border: "1px solid transparent",
                }}
              >
                <span
                  style={{
                    background: current > 1 ? "#3f1bd0" : current === 1 ? "#fcfcfc" : "transparent",
                    width: "8px",
                    height: "8px",
                    borderRadius: "50%",
                  }}
                ></span>
              </div>
              <div
                style={{
                  height: "1px",
                  width: "200px",
                  margin:' 0 5px',
                  backgroundColor: current >= 2 ? "#3f1bd0" : "rgba(154, 154, 154, 0.2)",
                }}
                className="progress-connector-line"
              ></div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: current >= 2 ? "#3f1bd0" : "rgba(154, 154, 154, 0.2)",
                  color: "#ffffff",
                  width: "20px",
                  height: "20px",
                  borderRadius: "50%",
                  border: "1px solid transparent",
                }}
              >
                <span
                  style={{
                    background: current >= 2 ? "#fcfcfc" : "transparent",
                    width: "8px",
                    height: "8px",
                    borderRadius: "50%",
                  }}
                ></span>
              </div>
              {/* <div
                style={{
                  height: "2px",
                  width: "100px",
                  backgroundColor: current >= 3 ? "#3f1bd0" : "#9a9a9a",
                }}
                className="progress-connector-line"
              ></div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: current >= 3 ? "#3f1bd0" : "#9a9a9a",
                  color: "#ffffff",
                  width: "20px",
                  height: "20px",
                  borderRadius: "50%",
                  border: "1px solid transparent",
                }}
              >
                <span
                  style={{
                    background: current >= 3 ? "#fff" : "inherit",
                    width: "8px",
                    height: "8px",
                    borderRadius: "50%",
                  }}
                ></span>
              </div>
              <div
                style={{
                  height: "2px",
                  width: "100px",
                  backgroundColor: current >= 4 ? "#3f1bd0" : "#9a9a9a",
                }}
                className="progress-connector-line"
              ></div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: current >= 4 ? "#3f1bd0" : "#9a9a9a",
                  color: "#ffffff",
                  width: "20px",
                  height: "20px",
                  borderRadius: "50%",
                  border: "1px solid transparent",
                }}
              >
                <span
                  style={{
                    background: current >= 4 ? "#fff" : "inherit",
                    width: "8px",
                    height: "8px",
                    borderRadius: "50%",
                  }}
                ></span>
              </div> */}
            </div>
          </div>}
          {current >= 3 && <div className="steps">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "19px",
                marginTop: "20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#3f1bd0",
                  color: "#ffffff",
                  width: "20px",
                  height: "20px",
                  borderRadius: "50%",
                  border: "1px solid transparent",
                }}
              >
                <span
                  style={{
                    background: current > 3 ? "#3f1bd0" : current === 3 ? "#fcfcfc" : "transparent",
                    width: "8px",
                    height: "8px",
                    borderRadius: "50%",
                  }}
                ></span>
              </div>
              <div
                style={{
                  height: "1px",
                  width: "200px",
                  margin:' 0 5px',
                  backgroundColor: current >= 4 ? "#3f1bd0" : "rgba(154, 154, 154, 0.2)",
                }}
                className="progress-connector-line"
              ></div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: current >= 4 ? "#3f1bd0" : "#rgba(154, 154, 154, 0.2)",
                  color: "#ffffff",
                  width: "20px",
                  height: "20px",
                  borderRadius: "50%",
                  border: "1px solid transparent",
                }}
              >
                <span
                  style={{
                    background: current > 4 ? "#3f1bd0" : current === 4 ? "#fcfcfc" : "transparent",
                    width: "8px",
                    height: "8px",
                    borderRadius: "50%",
                  }}
                ></span>
              </div>
              <div
                style={{
                  height: "1px",
                  width: "200px",
                  margin:' 0 5px',
                  backgroundColor: current >= 5 ? "#3f1bd0" : "rgba(154, 154, 154, 0.2)",
                }}
                className="progress-connector-line"
              ></div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: current >= 5 ? "#3f1bd0" : "rgba(154, 154, 154, 0.2)",
                  color: "#ffffff",
                  width: "20px",
                  height: "20px",
                  borderRadius: "50%",
                  border: "1px solid transparent",
                }}
              >
                <span
                  style={{
                    background: current >= 5 ? "#fcfcfc" : "transparent",
                    width: "8px",
                    height: "8px",
                    borderRadius: "50%",
                  }}
                ></span>
              </div>
            </div>
          </div>}
          {current === 0 && <div className="first-step">
            <div>
              <h1>Welcome to IDENTiFYN™</h1>
              <p>Please complete account sign up.</p>
            </div>
            <div className="form-container">
              <div className="two-column">
                <Input className={"First"} required label={'First Name'} value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                <Input required label={"Last Name"} className={"Last"} value={lastName} onChange={(e) => setLastName(e.target.value)} />
              </div>
              <div className="two-column">
                <Input placeholder="Position *" value={position} onChange={(e) => setPosition(e.target.value)} />
                <Input placeholder="Email *" value={email} onChange={(e) => setEmail(e.target.value)} />
              </div>
              <CountryDropdown value={country} onChange={(e) => setCountry(e)} />
              <div className="two-column" style={{ marginTop: '32px' }}>
                <div className="select-option" style={{ border: academic === 'Academic' ? '1px solid var(--primary-color)' : '1px solid #F1EEFC', width:'50%', background: academic === 'Academic' ? '#F8F7FE' : 'inherit' }}>
                  <label class="containers" >
                    <input type="radio" name="academic" value="Academic" onChange={(e) => setAcademic(e.target.value)} checked={academic === 'Academic'} />
                    <span class="checkmark"></span>
                    <span>Academic</span>
                  </label>
                </div>
                <div className="select-option" style={{ border: academic === 'Industry' ? '1px solid var(--primary-color)' : '1px solid #F1EEFC', width:'50%', background: academic === 'Industry' ? '#F8F7FE' : 'inherit' }}>
                  <label class="containers" >
                    <input type="radio" name="academic" value="Industry" onChange={(e) => setAcademic(e.target.value)} checked={academic === 'Industry'} />
                    <span class="checkmark"></span>
                    <span>Industry</span>
                  </label>
                </div>
              </div>

            </div>
            <button className="btn" disabled={!firstName || !lastName || !position || !email || !country || !academic} onClick={() => {
             if (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)){
               setCurrent(1)
             }else{
              setNotification({
                type:'red',
                message:'Please enter a valid Email Address',
              })
             }
            }}>Create Account</button>
            <p style={{ textAlign: 'center' }}>Already have an Account? <span onClick={() => {
              setSignUpModal(false)
              setSignInModal(true)
            }}>Sign In</span></p>
            <small style={{ textAlign: 'center', fontSize: '10px', color:'#6c6a73' }}>By clicking the “Create Account” button, by using the website, or by proceeding with the purchase of products or services, you accept and agree to be bound by: (1) the identifyn.com Terms of Use ; and (2) the applicable terms and conditions of sale for the products and services you purchase on identifyn.com</small>
          </div>
          }
          {current === 1 && <div className="second-step">
            <div>
              <h1>Create Password</h1>
              <p style={{ width: '90%' }}>Please create a password, it will be used for the next sign-ins.</p>
            </div>
            <div className="form-container">
              <div style={{ marginTop: '32px' }}>
                <p style={{ marginBottom: '10px', color: '#9a9a9a', opacity: '1', fontSize: '14px' }}>Create Password <span style={{ color: 'rgba(220, 30, 0, 1)' }}>*</span></p>
                <Input type='password' placeholder={"Password"} value={password} onChange={(e) => setPassword(e.target.value)} className={error ? 'error' : ''} />
              </div>
              <div style={{ marginTop: '22px', marginBottom: '22px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <p style={{ marginBottom: '8px', color: '#9a9a9a', opacity: '1', fontSize: '14px', textAlign: 'left' }}>Confirm Password</p>
                  {error && error === 'Password does not match' && <p style={{ marginBottom: '8px', color: '#DC1E00', opacity: '1', fontSize: '14px', textAlign: 'left' }}>Passwords must match</p>}
                </div>
                <Input type='password' placeholder={"Confirm Password"} value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} className={error ? 'error' : ''} />
              </div>
            </div>
            <div className="password-validations">
              <span style={{ color: '#6C6A73' }}>{password.length >= 8 ? <i className="fas fa-check" style={{ color: '#33C25E', marginRight: '8px' }}></i> : <i className="far fa-circle" style={{ color: '#9a9a9a', marginRight: '8px' }}></i>}At least 8 characters</span>
              <span style={{ color: '#6C6A73' }}>{/[A-Z]/.test(password) ? <i className="fas fa-check" style={{ color: '#33C25E', marginRight: '8px' }}></i> : <i className="far fa-circle" style={{ color: '#9a9a9a', marginRight: '8px' }}></i>}At least 1 upper case letter (A-Z).</span>
              <span style={{ color: '#6C6A73' }}>{/\d/.test(password) ? <i className="fas fa-check" style={{ color: '#33C25E', marginRight: '8px' }}></i> : <i className="far fa-circle" style={{ color: '#9a9a9a', marginRight: '8px' }}></i>}At least 1 number (0-9).</span>
            </div>
            <div className="button-container">
              {error && error !== 'Password does not match' && <p style={{ color: '#DC1E00', opacity: '1', fontSize: '16px', textAlign: 'center' }}>{error}</p>}
              <button className="btn" disabled={!password || !confirmPassword} onClick={() => signUp()}>Continue</button>
              <p style={{ textAlign: 'center' }}>Aready have an Account? <span onClick={() => {
                setSignUpModal(false)
                setSignInModal(true)
              }}>Sign In</span></p>
            </div>
          </div>
          }
          {current === 2 && <div className="second-step">
            <div>
              <h1>Verify Your Email Address</h1>
              <p style={{ width: '70%' }}>A verification code was sent to <span style={{color: 'var(--primary-color)'}}>{user?.customer?.email ? user?.customer?.email : email}</span>. This code will be valid for 10 minutes</p>
            </div>
            <div className="form-container">
              <div style={{ marginTop: '32px', marginBottom: '32px' }}>
                <h4 style={{ marginBottom: '12px' }}>Enter Code Below</h4>
                <Input placeholder="XXXX" value={code} onChange={(e) => setCode(e.target.value)} />
              </div>
            </div>
            <p>If you dont see the email in a few minutes, please try the following:</p>
            <ul style={{marginLeft:'25px'}}>
              <li style={{color:'#6c6a73'}}>Add no-reply@identifyn.com to your Whitelist or Safe Sender List</li>
              <li style={{color:'#6c6a73'}}>Check your junk mail or spam folder</li>
              {/* <li>If this email address is not valid please <span>provide one here</span></li> */}
            </ul>
            <div className="button-container">
              <button className="btn" disabled={!code} onClick={() => {
                verify()
              }}>Verify</button>
              <p style={{ textAlign: 'center', marginTop:'8px' }}>Didn't get a code? <span onClick={() => resendEmail()}>Resend</span></p>
            {!isLoggedIn &&  <p style={{ textAlign: 'center' }}>Aready have an Account? <span onClick={() => {
                setSignUpModal(false)
                setSignInModal(true)
              }}>Sign In</span></p>
            }
            </div>
          </div>
          }
          {current === 3 && <div className="third-step">
            <div>
              <h1>Finish Registering Your Account</h1>
              <p style={{ width: '70%' }}>Please complete account registration</p>
            </div>
            <div className="form-container">
              <div style={{ marginTop: '32px', marginBottom: '32px' }}>
                <h4 style={{ marginBottom: '12px' }}>Is Your Organization Tax Exempt?</h4>
                <div className="two-column" style={{ marginTop: '32px' }}>
                  <div className={`select-option ${taxExempt  ? 'select-active' : ''}`}>
                    <label class="containers">
                      <input type="radio" name="tax" value={true} onChange={(e) => setTaxExempt(true)} checked={taxExempt} />
                      <span class="checkmark"></span>
                      <span>Yes</span>
                    </label>
                  </div>
                  <div className={`select-option ${!taxExempt && taxExempt !== null ? 'select-active' : ''}`}>
                    <label class="containers">
                      <input type="radio" name="tax" value={false} onChange={(e) => setTaxExempt(false)} checked={!taxExempt} />
                      <span class="checkmark"></span>
                      <span>No</span>
                    </label>
                  </div>
                </div>
                {taxExempt && <Input placeholder="Tax Exempt Number" value={taxExemptNumber} onChange={(e) => setTaxExemptNumber(e.target.value)} />}
              </div>
            </div>
            <div className="button-container">
              <button className="btn" disabled={taxExempt && !taxExemptNumber} onClick={() => {
                updateTaxExempt()
              }}>Continue Shipping</button>
              <p style={{ textAlign: 'center', marginTop:'8px' }} onClick={() => setSignUpModal(false)}><span>Finish Later</span></p>
            </div>
          </div>
          }
          {current === 4 && <div className="fourth-step">
            <div>
              <h1>Complete Your Account</h1>
            </div>
            <div className="form-container">
              <div style={{ marginTop: '32px', marginBottom: '32px' }}>
                <h3 style={{ marginBottom: '24px' }}>Shipping Address</h3>
                <div className="two-column">
                  <Input placeholder="First and Last Name *" value={shippingAddress.first_name} onChange={(e) => setShippingAddress({ ...shippingAddress, first_name: e.target.value })} />
                  <Input placeholder="Your Company/Institution *" value={shippingAddress.company} onChange={(e) => setShippingAddress({ ...shippingAddress, company: e.target.value })} />
                </div>
                <div className="two-column">
                  <Input placeholder="Department *" value={shippingAddress.metadata?.department} onChange={(e) => setShippingAddress({ ...shippingAddress, metadata: { ...shippingAddress.metadata, department: e.target.value } })} />
                  <Input placeholder="Street Address *" value={shippingAddress.address_1} onChange={(e) => setShippingAddress({ ...shippingAddress, address_1: e.target.value })} />
                </div>
                <div className="three-column">
                  <Input placeholder="Building" value={shippingAddress.metadata?.building} onChange={(e) => setShippingAddress({ ...shippingAddress, metadata: { ...shippingAddress.metadata, building: e.target.value } })} />
                  <Input placeholder="Lab" value={shippingAddress.metadata?.lab} onChange={(e) => setShippingAddress({ ...shippingAddress, metadata: { ...shippingAddress.metadata, lab: e.target.value } })} />
                  <Input placeholder="Attention To" value={shippingAddress.metadata?.attention_to} onChange={(e) => setShippingAddress({ ...shippingAddress, metadata: { ...shippingAddress.metadata, attention_to: e.target.value } })} />
                </div>
                <div className="two-column">
                  <CountryDropdown value={shippingAddress.country_code} onChange={(e) => setShippingAddress({ ...shippingAddress, country_code: e })} />
                  <StateDropdown country={shippingAddress.country_code} placeholder={"Select State"} value={shippingAddress.province} onChange={(e) => setShippingAddress({ ...shippingAddress, province: e })} />
                </div>
                <div className="two-column">
                  <Input placeholder="Select City *" value={shippingAddress.city} onChange={(e) => setShippingAddress({ ...shippingAddress, city: e.target.value })} />
                  <Input placeholder="Zip Code *" value={shippingAddress.postal_code} onChange={(e) => setShippingAddress({ ...shippingAddress, postal_code: e.target.value })} />
                </div>
              </div>
            </div>
            <div className="button-container">
              <button className="btn" 
               disabled={!shippingAddress.first_name || !shippingAddress.company || !shippingAddress.address_1 || !shippingAddress.city || !shippingAddress.postal_code || !shippingAddress.country_code || !shippingAddress.province || !shippingAddress.metadata.department}
              onClick={() => addShippingAddress()}>Continue to Billing</button>
              <p style={{ textAlign: 'center', marginTop:'8px' }}><span onClick={() => setCurrent(3)}>Back to Tax Exempt</span></p>
            </div>
          </div>
          }
          {current === 5 && <div className="fourth-step">
            <div>
              <h1>Complete Your Account</h1>
            </div>
            <div className="form-container">
              <div style={{ marginTop: '32px', marginBottom: '32px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: '12px'}}>
                  <h3>Billing Address</h3>
                  {/* <div class="checkbox-container">
                    <input type="checkbox" id="myCheckbox" class="checkbox" />
                    <label for="myCheckbox" class="checkbox-label">Same as Shipping</label>
                  </div> */}
                  <Checkbox label="Same as Shipping" checked={asShipping} onChange={() => setAsShipping(!asShipping)} />

                </div>
                {!asShipping && <>
                  <div className="two-column">
                    <Input placeholder="First and Last Name *" value={billingAddress.first_name} onChange={(e) => setBillingAddress({ ...billingAddress, first_name: e.target.value })} />
                    <Input placeholder="Your Company/Institution *" value={billingAddress.company} onChange={(e) => setBillingAddress({ ...billingAddress, company: e.target.value })} />
                  </div>
                  <div className="two-column">
                    <Input placeholder="Department *" value={billingAddress.metadata?.department} onChange={(e) => setBillingAddress({ ...billingAddress, metadata: { ...billingAddress.metadata, department: e.target.value } })} />
                    <Input placeholder="Street Address *" value={billingAddress.address_1} onChange={(e) => setBillingAddress({ ...billingAddress, address_1: e.target.value })} />
                  </div>
                  <div className="three-column">
                    <Input placeholder="Building" value={billingAddress.metadata?.building} onChange={(e) => setBillingAddress({ ...billingAddress, metadata: { ...billingAddress.metadata, building: e.target.value } })} />
                    <Input placeholder="Lab" value={billingAddress.metadata?.lab} onChange={(e) => setBillingAddress({ ...billingAddress, metadata: { ...billingAddress.metadata, lab: e.target.value } })} />
                    <Input placeholder="Attention To *" value={billingAddress.metadata?.attention_to} onChange={(e) => setBillingAddress({ ...billingAddress, metadata: { ...billingAddress.metadata, attention_to: e.target.value } })} />
                  </div>
                  <div className="two-column">
                    <CountryDropdown value={billingAddress.country_code} onChange={(e) => setBillingAddress({ ...billingAddress, country_code: e })} />
                    <StateDropdown country={billingAddress.country_code} placeholder={"Select State"} value={billingAddress.province} onChange={(e) => setBillingAddress({ ...billingAddress, province: e })} />
                  </div>
                  <div className="two-column">
                    <Input placeholder="Select City *" value={billingAddress.city} onChange={(e) => setBillingAddress({ ...billingAddress, city: e.target.value })} />
                    <Input placeholder="Zip Code *" value={billingAddress.postal_code} onChange={(e) => setBillingAddress({ ...billingAddress, postal_code: e.target.value })} />
                  </div>
                </>
                }
              </div>
            </div>
            <div className="button-container">
              <button className="btn"
                disabled={!asShipping && (!billingAddress.first_name || !billingAddress.company || !billingAddress.address_1 || !billingAddress.city || !billingAddress.postal_code || !billingAddress.country_code || !billingAddress.province || !billingAddress.metadata.department)}
                onClick={() => {
                  addBillingAddress()
                }}>Complete Registration</button>
              <p style={{ textAlign: 'center', marginTop:'8px' }}><span onClick={() => setCurrent(4)}>Back to Shipping</span></p>
            </div>
          </div>
          }
        </div>
        <div className="right-container">
          <img src={toAbsoluteUrl('/media/logowhite.png')} alt="" />
        </div>
      </div>
    </div>
  )
}

export default Index