import { useEffect} from "react";
import { useLocation } from "react-router-dom";
import { AppRouter } from "./routes/AppRouter";
import { AuthProvider } from './context/auth'
// import {Elements} from "@stripe/react-stripe-js";
// import { loadStripe } from "@stripe/stripe-js";
function App() {
// const { width } = useContext(AuthContext)
// const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
// const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);
const {pathname} = useLocation()

  useEffect(() => {
    document.body.className = ''
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
}, [pathname])


  return (
    <AuthProvider>
      {/* <Elements stripe={stripePromise}> */}
      <AppRouter />
      {/* </Elements> */}
    </AuthProvider>
  );
}

export default App;
