import React from 'react'
import "./style.scss"
import { toAbsoluteUrl } from '../../../utils/assetHelper';


const Index = ({setOpenFunctionModal, selectedProtein, proteinFunctions}) => {
  return (
    <div className="small-modal-wrapper">
      <div className="small-function-modal-content large-function-modal-content" style={{ padding: '20px' }}>
        <div style={{ position: 'relative', justifyContent: 'center', display: 'flex', width: '100%' }}>
          <i className='fas fa-times close' style={{ color: '#9a9a9a', position: 'absolute', right: '0px', top: '0px', cursor: 'pointer', zIndex: 9, fontSize: '20px' }} onClick={() => {
            setOpenFunctionModal(false)
          }}></i>
          <span style={{ width: '44px', height: '44px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '12px', background: '#FFF2E5', color: 'var(--white)' }}>
            <img src={toAbsoluteUrl('/media/identifynsmalllogo.png')} style={{ width: '20px', height: '20px' }} alt="" />
          </span>
        </div>
        <h2>{selectedProtein}</h2>
        <div className="mobile-overflow">
          <p className="function-text" dangerouslySetInnerHTML={{ __html: proteinFunctions.find((item) => item.name === selectedProtein)?.function || '-' }}  />
          <div className="references">
            <h4>References:</h4>
            <div className="references-list">
              {proteinFunctions?.find((item) => item.name === selectedProtein)?.reference.filter((item) => item !== "").map((item, index) => {
                return <a href={item.includes("https") ? item : `https://${item}`} target="_blank" key={index}>[{index + 1}]</a>
              })}
            </div>
          </div>
        </div>
          <p style={{ fontSize: '12px', marginTop:'20px', alignSelf:'flex-end'}}>© Copyright 2023-2024 Identifyn™ LLC & GMD12™ LLC, All rights reserved</p>
      </div>
    </div>
  )
}

export default Index