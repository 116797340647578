import { apiService } from './apiService'
import { getSessionStorage } from '../../helpers/localStorage.js'

export const apiCall = (endpoint, method, data, type = 'application/json', responseType = 'json') => {
    const headers = {
        'Content-Type': type,
        'Access-Control-Allow-Origin': '*'
    }

    let url = endpoint

    const config = data ? { url, headers, data, method } : { url, headers, method, responseType }

    return apiService.request(config)
}
