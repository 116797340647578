import React, { useContext } from 'react'
import Layout from '../../../components/layout'
import { AboutHeader, AboutContent } from './style'
import { toAbsoluteUrl } from '../../../utils/assetHelper'
import { Footer } from '../../../components/Footer'
import { AuthContext } from '../../../context/auth'
import { downloadPdf } from '../../../utils/downloader'
import microscopyMethods from "./mircroscopyMethods.pdf"

const Index = () => {
  const { width } = useContext(AuthContext)
  return (
    <Layout>
      <div className="container title-container bio-container">
        <span style={{ width: '44px', height: '44px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '12px', background: '#fff2e5', color: 'var(--primary-color)' }}>
          <i class="fal fa-microscope"></i>
        </span>
        <AboutHeader>
          <h1>Identifyn™ Microscopy Methods</h1>
        </AboutHeader>
      </div>
      <AboutContent>
        <div className="container azure-container" style={{ marginBottom: '32px' }}>
          <div className="left-container">
            <div className="solutions-text">
              <h2>Identifyn™ Microscopy Immunostaining Methodology</h2>
              <p>Identifyn™ is committed to using the best products and instrumentation in vetting our de novo antibody products. The following method is used at Identifyn™ to validate antibody specificity. In sharing our methodology, we encourage the implementation of our method in your own research.
              </p>
              <p style={{ fontWeight: 700 }}>Due to the high variability in microscopy equipment and applications the following method serves as a general guideline and is not representative of the totality of Identifyn’s™ microscopy methods.</p>
            </div>
          </div>
          {width > 1100 &&  <div className="right-container">
            <figure>
              <img src={toAbsoluteUrl("/media/intellectual/logo.png")} alt="" width={'100%'} style={{ height: '350px' }} />
            </figure>
          </div>
          }
        </div>

        <div className="container services-section-two-container">
          <div className="left-container">
            <div className="card2">
              <h3>Reagents</h3>
              <p style={{ marginBottom: '16px', color: '#6c6a73', fontSize: '16px' }}>Click on a reagent to visit our partner website</p>
              <div className="points-container">
                <div className="points">
                  <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
                  <p>Cells – Application Specific</p>
                </div>
                <div className="points">
                  <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
                  <p>100% Methanol</p>
                </div>
                <div className="points">
                  <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
                  <p>PBS pH 7.4</p>
                </div>
                <div className="points">
                  <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
                  <a href="https://www.thermofisher.com/order/catalog/product/37527" target='_blank'><p>Fish Serum Buffer <i className='fal fa-eye' style={{ fontSize: '16px', color: '#DC4405' }} /></p></a>
                </div>
                <div className="points">
                  <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
                  <a href="https://www.thermofisher.com/order/catalog/product/P36962" target='_blank'><p>ProLong™ Diamond Antifade Mountant with
                    DAPI <i className='fal fa-eye' style={{ fontSize: '16px', color: '#DC4405' }} /></p></a>
                </div>
                <div className="points">
                  <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
                  <p>Primary Antibody</p>
                </div>
                <div className="points">
                  <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
                  <p>Secondary Antibody</p>
                </div>
              </div>
            </div>
          </div>
          <div className="right-container">
            <div className="card2">
              <h3>Consumables</h3>
              <p style={{ marginBottom: '16px', color: '#6c6a73', fontSize: '16px' }}>Click on a consumable to visit our partner website</p>
              <div className="points-container">
                <div className="points">
                  <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
                  <a href="https://www.fishersci.com/shop/products/costar-cell-culture-plates-1/07-200-83?searchHijack=true&searchTerm=costar-cell-culture-plates-1&searchType=Rapid&matchedCatNo=07-200-83" target='_blank'><p>6-well Tissue Culture Treated Plates <i className='fal fa-eye' style={{ fontSize: '16px', color: '#DC4405' }} /></p></a>
                </div>
                <div className="points">
                  <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
                  <a href="https://www.fishersci.com/shop/products/microslide-8-well-glass-bottom/NC0704855" target='_blank'><p>iBidi 8 Well Chambered µ-Slides <i className='fal fa-eye' style={{ fontSize: '16px', color: '#DC4405' }} /></p></a>
                </div>
                <div className="points">
                  <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
                  <a href="https://www.fishersci.com/shop/products/precision-coverglass-1000-pk/NC1415511" target='_blank'><p>#1.5H Coverslips <i className='fal fa-eye' style={{ fontSize: '16px', color: '#DC4405' }} /></p></a>
                </div>
                <div className="points">
                  <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
                  <a href="https://www.fishersci.com/shop/products/fisherbrand-superfrost-disposable-microscope-slides-5/12-550-123?searchHijack=true&searchTerm=fisherbrand-superfrost-disposable-microscope-slides-5&searchType=Rapid&matchedCatNo=12-550-123" target='_blank'><p>Glass Slides <i className='fal fa-eye' style={{ fontSize: '16px', color: '#DC4405' }} /></p></a>
                </div>
                <div className="points">
                  <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
                  <a href="https://www.thermofisher.com/order/catalog/product/170355N" target='_blank'><p>5mL Serological Pipette <i className='fal fa-eye' style={{ fontSize: '16px', color: '#DC4405' }} /></p></a>
                </div>
                <div className="points">
                  <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
                  <a href="https://www.thermofisher.com/order/catalog/product/170356N" target='_blank'><p>10mL Serological Pipette <i className='fal fa-eye' style={{ fontSize: '16px', color: '#DC4405' }} /></p></a>
                </div>
                <div className="points">
                  <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
                  <a href="https://www.fishersci.com/shop/products/fisherbrand-disposable-borosilicate-glass-pasteur-pipets-4/13-678-20B" target='_blank'><p>Glass Aspirator Tips <i className='fal fa-eye' style={{ fontSize: '16px', color: '#DC4405' }} /></p></a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container services-section-two-container" style={{gridTemplateColumns:'1fr'}}>
            <div className="card1">
              <h3>Equipment</h3>
              <p style={{ marginBottom: '16px', color: '#6c6a73', fontSize: '16px' }}>Click on a reagent to visit our partner website</p>
              <div className="points-container">
                <div className="points">
                  <img src={toAbsoluteUrl("/media/pointbullet.svg")} alt="" />
                  <p>Zeiss Axioscope Widefield Microscope</p>
                </div>
                <div className="points">
                  <img src={toAbsoluteUrl("/media/pointbullet.svg")} alt="" />
                  <p>Zeiss Apotome Structured Illumination (SIM) Microscope</p>
                </div>
                <div className="points">
                  <img src={toAbsoluteUrl("/media/pointbullet.svg")} alt="" />
                  <p>Zeiss LSM, 900 Confocal with Super Resolution AiryScan Microscope</p>
                </div>
                <div className="points">
                  <img src={toAbsoluteUrl("/media/pointbullet.svg")} alt="" />
                  <p>Zeiss LSM, 980 Confocal with Super Resolution AiryScan Microscope</p>
                </div>
                <div className="points">
                  <img src={toAbsoluteUrl("/media/pointbullet.svg")} alt="" />
                  <p>Zeiss Elyra 7 with Lattice-Structured Illumination (SIM) and SIM² Microscope</p>
                </div>
                <div className="points">
                  <img src={toAbsoluteUrl("/media/pointbullet.svg")} alt="" />
                  <p>Bruker Vutara VXL</p>
                </div>
              </div>
            </div>
  
        </div>
        <div className="container azure-section-four-container" style={{ background: 'rgba(237, 237, 237, 0.20)', marginBottom: '0px', alignItems: 'start', gap: '40px' }}>
          <div className="methods-header">
            <h5>Section</h5>
            <div className='inner-header'>
              <h5>Method Steps</h5>
              {width > 700 && <button className='btn' style={{background:'#FFF2E5', color:'#DC4405'}}
                onClick={() => downloadPdf(microscopyMethods, 'microscopyMethods.pdf')}
             ><i className='fal fa-arrow-to-bottom' /> Download PDF</button>}
            </div>
          </div>
          <div className="methods-header">
            <p>Coverslip Preparation</p>
            <p><span>1.</span> Coverslip preparation may include acid etching of the surface or a substrate to promote adhesion, e.g., Poly-D-Lysine</p>
          </div>
          <div className="methods-header">
            <p>Seeding</p>
            <p><span>2.</span> Seed the cells per requirement following your cell culture SOP and visually check confluency. Identifyn™ uses the Zeiss Primovert with Labscope software to obtain confluency cell counts</p>
          </div>
          <div className="methods-header">
            <p>Fixation</p>
            <p><span>3.</span>
              Fixation of previously seeded cells on coverslip / cover glass uses conventional fixation agents, such as 4% (v/v) PFA with 0.1% Triton X-100 (v/v) for 10 min
              <br /><br />
              Alternatively, Methanol (-20 °C) for 8 – 10 minutes may be used
              <br /><br />
              Both protocols suffice to fix and permeabilize cells with no apparent distortion of cellular ultrastructure
            </p>
          </div>
          <div className="methods-header">
            <p>Blocking</p>
            <p><span>4.</span> Sample preparation should include a blocking step to reduce non-specific binding and background fluorescence
              <br /> <br />
              Conventional blocking agents are BSA, Goat Serum, or Fetal Calf Serum in varying concentrations in PBS
              <br /> <br />
              However, the aforementioned agents provide varying levels of cross-reactivity with mammalian reagents
              <br /> <br />
              Identifyn™ finds that background fluorescence and non-specific binding are virtually eliminated using a marine blocking agent as it has essentially no antigens that can potentially crossreact</p>
          </div>
          <div className="methods-header">
            <p>Primary Antibody Staining </p>
            <p><span>5a.</span> Precaution - When staining against multiple primary targets, it is essential not to create pre-mixed cocktails of primary or secondary antibodies. It is preferable to perform those sequentially with intermittent washing and blocking. Cocktails may create non-specific colocalization
              <br /> <br />
              The primary antibody is typically used at a concentration between 1:100 and 1:1000 per the requirements of the end-user's application in a buffer containing the block media in PBS
              <br /> <br />
              Incubation is typically for 60 min with continuous rocking or overnight at 4 °C
              <br /> <br />
              Wash thoroughly using PBS following primary antibody incubation</p>
          </div>
          <div className="methods-header">
            <p>Secondary Antibody Staining</p>
            <p><span>5b.</span> Secondary antibody is typically used at a concentration between 1:1000 to 1:5000 per the requirements of end-user’s application in a buffer containing the block media in PBS
            <br /> <br />
              Incubation is typically for 60 minutes with continuous rocking
              <br /> <br />
              Wash thoroughly using PBS following secondary antibody incubation
              <br /> <br />
              If you wish to stain against additional targets, the protocol can be performed by repeating steps 5a and 5b
              <br /> <br /> <br/>
              or
            </p>
          </div>
          <div className="methods-header">
            <p>Direct Conjugate Staining </p>
            <p><span>5c.</span> Identifyn™ SRM Alexa Fluor™ Primary Antibody conjugates can be used following step 5a of this protocol. A secondary antibody will not be required if these primary antibody dye conjugates are used. For multiple targets, sequential staining with intermittent washing and blocking is preferable</p>
          </div>
          <div className="methods-header">
            <p>Coverslip Mounting</p>
            <p><span>6.</span> Coverslips are mounted using appropriate mounting media per application. In our applications, we use ProLong™ Diamond Antifade Mountant with DAPI</p>
          </div>
          {width < 700 && <button className='btn' style={{background:'#FFF2E5', color:'#DC4405', width:'100%'}}
                onClick={() => downloadPdf(microscopyMethods, 'microscopyMethods.pdf')}
             ><i className='fal fa-arrow-to-bottom' /> Download PDF</button>}
        </div>
        <div className="container azure-section-four-container" style={{ background: 'rgba(237, 237, 237, 0.20)', marginBottom: '0px', alignItems: 'start', gap: '10px' }}>
          <div className="methods-header">
            <h5>Section</h5>
            <div className='inner-header'>
              <h5>Note</h5>
            </div>
          </div>
          <div className="methods-header">
            <p>DNA Damage</p>
            <p>Identifyn’s™ de novo antibody validation within our five oncological pathways may require DNA damage to illicit specific protein response. Identifyn™ uses pharmaceuticals, such as Etoposide, Cisplatin, Mitomycin, Taxol, and others, at optimized dosimetry to induce damage and verify protein activity in the pathways.</p>
          </div>
        </div>
      </AboutContent>
      <Footer />
    </Layout>
  )
}

export default Index