import React, { useState, useContext } from 'react'
import "./style.scss"
import { AuthContext } from "../../../context/auth";
import Input from "../../Input"
import { deleteCustomerApi, checkCurrentUserApi } from '../../../api';

const Index = () => {
  const { setDeleteProfileModal, setRestoreModal, user, logout, setNotification, setIsLoading } = useContext(AuthContext)
  const [type, setType] = useState('step1')
  const [currentPassword, setCurrentPassword] = useState('')


  const onDelete = () => {
    setIsLoading(true)
    checkCurrentUserApi({email: user?.customer?.email, password: currentPassword }).then((res) => {
      if(res && res?.data?.customer){
        deleteCustomerApi(user.customer?.id).then((res) => {
          if(res){
            setIsLoading(false)
            setType('step3')
            logout()
          }else{
            setIsLoading(false)
            setNotification({ message: 'Something went wrong', type: 'red' })
          }
        })
      }else{
        setIsLoading(false)
        setNotification({ message: 'Current password is incorrect', type: 'red' })
      }
    })

  }

  return (
    <div className="small-modal-wrapper">
      <div className="small-modal-content">
      <div style={{position:'relative',justifyContent:'center', display:'flex', width:'100%'}}>
        <i className='fal fa-times close' style={{ color: '#9a9a9a', position: 'absolute', right: '0px', top: '0px', cursor: 'pointer', zIndex: 9, fontSize: '20px' }} onClick={() => {
          setDeleteProfileModal(false)
        }}></i>
        <span style={{ width: '44px', height: '44px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '12px', background: '#DC1E00', color: 'var(--white)' }}>
          <i class="fal fa-trash-alt"></i>
        </span>
        </div>
        {type === "step1" ? <>
          <h2>Delete Profile </h2>
          <p style={{ marginBottom: '20px' }}>Your profile and history will be permanently <span>deleted.</span></p>
          <button className='btn' onClick={() => {
            setDeleteProfileModal(false)
          }}>Keep Profile</button>
          <button className='btn transparent' onClick={() => {
            setType('step2')
          }}>Delete Profile</button>
        </>
          : type === 'step2' ? <>
            <h2>Delete Profile</h2>
            <p  style={{ marginBottom: '20px' }}>Enter your password for confirm profile deleting.</p>
            <Input type='password' placeholder='Password' value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)} />
            <p style={{ marginBottom: '28px', marginTop:'36px' }}>Forgot? <span style={{ color: 'var(--primary-color)', cursor: 'pointer', fontWeight:500 }}
              onClick={() => {
                setRestoreModal(true)
                setDeleteProfileModal(false)
              }}
            >Restore Password</span></p>
            <button className='btn' disabled={!currentPassword} onClick={() => {
              onDelete()
            }}>Delete Profile</button>
            <button className='btn transparent' onClick={() => {
              setDeleteProfileModal(false)
            }}>Cancel</button>
          </>
            : type === 'step3' && <>
              <h2>Profile was deleted successfully </h2>
              <p  style={{ marginBottom: '20px' }}>We're sorry to see you go but we hope to see you again soon.</p>
              <button className='btn' onClick={() => {
                setDeleteProfileModal(false)
              }}>Go to Home</button>
            </>
        }
      </div>
    </div>
  )
}

export default Index