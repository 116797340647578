import styled from 'styled-components';


export const LayoutWrapper = styled.div`
padding: 90px 0 0 0;
background-color: #FCFCFC;

.layout-container{
    flex-direction: column !important;
    padding-top:0;
.topbar{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFF;
    margin-bottom: 20px;
    border-radius: 20px;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.05);
    padding: 10px 0;
    .links-wrapper{
    display: flex;
    flex-wrap: wrap;
    gap: 5px; 

i{
    font-size: 20px;
}


.sub-link{
padding-left: 15px;
height: 0;
opacity: 0;
visibility: hidden;
transition: all 0.7ms cubic-bezier(0.075, 0.82, 0.165, 1);
}

.sub-link-active{
    height: max-content;
    opacity: 1;
    visibility: visible;
}

.link{
color:  #6c6a73;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px;   
display: flex;
gap: 5px;
align-items: center;
padding: 12px 16px;
position: relative;

&:hover{
    color: var(--primary-color);
    font-weight: 700;
    background-color: #fff2e5;
    border-radius: 10px;
}

i{
   font-size: 20px;
   /* width: 30px; */
   height: 20px;
}

.angle{
    position: absolute;
    right: 10px;
}


}

.active{
    color: var(--primary-color);
    font-weight: 700;
    background-color: #fff2e5;
    border-radius: 10px;
}

}

.signout{
color:#6c6a73;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px;
display: flex;
    gap: 15px;
    align-items: center;
    cursor: pointer;
padding: 0 16px;

span{
    width: 28px;
    height: 28px;
    border-radius: 50%;
background:  #28272E;
backdrop-filter: blur(19px);
color: #fcfcfc;
display: flex;
align-items: center;
}
    }
}

}


.sidebar{
width: 280px;
position: relative;
top: 0;
left: 0;
bottom: 0;
height: 100%;
background: #fff;
box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.05);
padding: 20px 12px;
display: flex;
justify-content: space-between;
flex-direction: column;
border-radius: 20px;


.profile-wrapper{
    display: flex;
    flex-direction: column;
    gap:20px;
    justify-content: center;
    align-items: center;

.custom-profile{
background: linear-gradient(227.46deg, #DC4405 14.94%, #FF8D24 85.6%);
backdrop-filter: blur(19px);
border-radius: 12px;
width: 72px;
height: 72px;
color: white;
font-size: 42px;
font-weight: 700;
display: flex;
justify-content: center;
align-items: center;
}

    .profile-img{
        width: 72px;
        height: 72px;
        border-radius: 12px;
        object-fit: cover;
    }

.profile-details{
    h5,h6, p{
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 24px;
color:#1E1E1E;
text-align: center;
    }

h6{
    font-size: 14px;
    color:#6c6a73;
    font-weight:600
}

p{
    font-size: 12px;
    font-weight: 400;
    color:#6c6a73
}

}

hr{
    width: 100%;
  color:#EDEDED;
  /* background-color: #EDEDED; */
  /* height: 1px; */
  border:1px solid #EDEDED;
}


}

.links-wrapper{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 5px; 

i{
    font-size: 20px;
}


.sub-link{
padding-left: 15px;
height: 0;
opacity: 0;
visibility: hidden;
transition: all 0.7ms cubic-bezier(0.075, 0.82, 0.165, 1);
}

.sub-link-active{
    height: max-content;
    opacity: 1;
    visibility: visible;
}

.link{
color:  #6c6a73;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px;   
display: flex;
gap: 5px;
align-items: center;
padding: 12px 16px;
position: relative;

&:hover{
    color: var(--primary-color);
    font-weight: 700;
    background-color: #fff2e5;
    border-radius: 10px;
}

i{
   font-size: 20px;
   width: 30px;
   height: 20px;
}

.angle{
    position: absolute;
    right: 10px;
}


}

.active{
    color: var(--primary-color);
    font-weight: 700;
    background-color: #fff2e5;
    border-radius: 10px;
}

}

.signout{
margin-top: 10rem;
color:#6c6a73;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px;
display: flex;
    gap: 15px;
    align-items: center;
    cursor: pointer;
padding: 0 16px;

span{
    width: 28px;
    height: 28px;
    border-radius: 50%;
background:  #28272E;
backdrop-filter: blur(19px);
color: #fcfcfc;
display: flex;
align-items: center;
}
    }

}

.finish-registration{
border-radius: 16px;
background:#FFF2E5;
padding: 12px;
margin-bottom: 20px;
margin-left: 44px;
display: flex;
justify-content: space-between;
}


`