import styled from "styled-components";

export const AboutHeader = styled.div`
margin-top: 20px;
h1{
    font-size: 48px;
    font-weight: 800;
    margin-bottom: 16px;
    text-align: center;

    span{
        color: var(--primary-color);
    }
}
`

export const AboutContent = styled.div`
    margin-top: 32px;
    margin-bottom: 32px;

.azure-section-four-container{
background: linear-gradient(102deg, rgba(63, 27, 208, 0.05) 4.47%, rgba(215, 76, 16, 0.05) 54.56%);

h2{
font-size: 48px;
font-style: normal;
font-weight: 800;
line-height: 60px;
text-align: center;
}

p{
margin-top: 16px;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 28px;
text-align: center;

span{
    color: var(--primary-color);
    font-weight: 700;
}

}

}


.left-container > .solutions-text > p{
margin-top: 16px;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 28px;
width: 100%;

span{
    color: var(--primary-color);
    font-weight: 700;
}
}

.card1,.card2 > p{
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 28px;

span{
    font-weight: 700;
    color: var(--primary-color);
}

.purple{
    color: #3F1BD0;
}

}

@media screen and (min-width:2160px) {
    .background-image{
        height: 800px !important;
    }
}


`