export const isVideoFile = (item) => {
    const videoExtensions = ['mp4', 'avi', 'mov', 'wmv', 'flv', 'webm'];
    if (item?.url) {
      const fileExtension = item.url.split('.').pop().toLowerCase();
      return videoExtensions.includes(fileExtension);
    }
    return false;
  };
  
  export const getMp4Url = (url) => {
    if (url.endsWith('.mp4')) {
      return url;
    }  
    const baseReplacementUrl = 'https://video.identifyn.com/';
    const lastDotIndex = url.lastIndexOf('.');
    const lastSlashIndex = url.lastIndexOf('/');
    const baseUrl = url.substring(0, lastSlashIndex + 1);
    const fileNameWithExt = url.substring(lastSlashIndex + 1);

    const newUrl = `${baseReplacementUrl}${fileNameWithExt.substring(0, fileNameWithExt.lastIndexOf('.'))}.mp4`;
    
    return newUrl;
  };

  
export const isGif = (item) => {
  if (item?.url) {
    const fileExtension = item.url.split('.').pop().toLowerCase();
    return fileExtension === 'gif';
  }
  return false;
};