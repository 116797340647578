import React, { useContext, useState } from 'react'
import "./style.scss"
import { AuthContext } from "../../../context/auth";
import Input from "../../Input Old";
import { changeEmailApi, verifyCustomerApi } from '../../../api';

const Index = () => {
  const { setChangeEmailModal, setNotification, user, setUser, setIsLoading, login } = useContext(AuthContext)
  const [type, setType] = useState('change-email')
  const [email, setEmail] = useState('')
  const [code, setCode] = useState('')


const changeEmail = () => {
  setIsLoading(true)
  if (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)) {
    if(email !== user?.customer?.email){
    changeEmailApi({email:email, firstName:user?.customer?.first_name, lastName:user?.customer?.last_name, id:user?.customer?.id, isSignUp: true}).then((res) => {
      if(res && res.data && res.data.customer){
        setUser({
          ...user,
          customer: {
            ...user.customer,
            email: email,
            metadata: {
              ...user.customer.metadata,
              is_email_verified: false
            }
          }
        })
        setType('verify-email')
        setIsLoading(false)
      }else if (res.error.type === 'duplicate_error'){
        setNotification({
          type: 'red',
          message: 'Email already exists!'
        })
        setIsLoading(false)
      }else{
        setNotification({
          type: 'red',
          message: 'Something went wrong, please try again!'
        })
        setIsLoading(false)
      }
    }).catch((err) => {
      setNotification({
        type: 'red',
        message: 'Something went wrong, please try again!'
      })
      setIsLoading(false)
    })
  }else{
    setNotification({
      type: 'red',
      message: 'Entered email is same as current email'
    })
    setIsLoading(false)
  }
  }else{
    setNotification({
      type: 'red',
      message: 'Please enter a valid Email Address'
    })
    setIsLoading(false)
  }
}

const verify = () => {
  setIsLoading(true)
  verifyCustomerApi(user?.customer?.email, code).then((res) => {
    if(res.message){
      setNotification({
        type: 'red',
        message: res.message.message
      })

    }else{
      setNotification({
        type: 'email',
        message: 'Email Address Verified!'
      })
     setUser(res.data)
     setChangeEmailModal(false)
    }
    setIsLoading(false)
  }).catch((err) => {
    setIsLoading(false)
    setNotification({
      type: 'red',
      message: 'Invalid verification code!'
    })
  })
}



  return (
    <div className="small-modal-wrapper">
      <div className={`small-modal-content ${type === 'verify-email' && 'verify-modal-content'}`} style={{padding:'20px'}}>
      <div style={{ position: 'relative', justifyContent: 'center', display: 'flex', width: '100%' }}>
        <span style={{ width: '44px', height: '44px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '12px', background: '#fff2e5', color: 'var(--primary-color)' }}>
          <i class="fal fa-envelope"></i>
        </span>
        <span className='close' style={{color:'#9a9a9a', top:0, right:0}} onClick={() => setChangeEmailModal(false)}><i className="fal fa-times"></i></span>
        </div>
    {type === 'change-email' ?      <>
            <h2>Change Email </h2>
            <h4 style={{marginBottom:'12px'}}>Enter new email below</h4>
            <Input placeholder="Enter new email here" type='email' value={email} onChange={(e) => setEmail(e.target.value)} style={{marginBottom:'20px', textAlign:'center'}}  />
            <button className='btn' disabled={!email} onClick={() => {
              changeEmail()
            }}>Continue</button>
             <button className='btn transparent' onClick={() => {
              setChangeEmailModal(false)
            }}>Cancel</button>
          </>
          :
          <>
           <h2>Verify Your Email Address </h2>
           <p style={{width:'80%', margin:'0 auto'}}>A verification code was set to {user?.customer?.email}.This code will be valid for 10 minutes</p>
           <h4 style={{marginTop:'20px'}}>Enter the code Below</h4>
           <Input placeholder="XXXX" type='text' style={{marginBottom:'20px', textAlign:'center'}} 
           onChange={(e) => setCode(e.target.value)}
           value={code}
           />
           <p style={{textAlign:'left', width:'100%'}}>If you dont see the email in a few minutes, please try the following:</p>
           <div style={{textAlign:'left'}} className='details'>
            <ul>
              <li>Add no-reply@identifyn.com to your Whitelist or Safe Sender List</li>
              <li>Check your junk mail or spam folder</li>
              <li>If this email address is not valid please <span onClick={() => {
                setType('change-email')
              }}>provide one here</span></li>
            </ul>
           </div>
            <p style={{ textAlign: 'center', marginBottom:'20px' }}>Didn't get a code? <span style={{color:'var(--primary-color)', cursor:'pointer', fontWeight:500}}
            onClick={() => {
              changeEmail()
            }}
            >Resend</span></p>
           <button className='btn' disabled={!code} onClick={() => {
            verify()
              //  setNotification({ status: true, message: 'Email Address Verified!', subMessage: '', type: 'email' })
                // setChangeEmailModal(false)
            }}>Verify</button>
             <button className='btn transparent' onClick={() => {
              setChangeEmailModal(false)
            }}>Cancel</button>
          </>
}
      </div>
    </div>
  )
}

export default Index