import React from 'react'
import Layout from '../../../components/layout'
import { AboutHeader, Content } from './style'
import {Footer} from '../../../components/Footer'

const Index = () => {
    return (
        <Layout>
            <AboutHeader>
                <h1>Terms and Condition <span>of Use</span></h1>
            </AboutHeader>
            <Content>
                <p style={{ color: '#6c6a73' }}>Effective: December 6, 2023</p>
                <p>Thank you for downloading the Identifyn™ mobile software application (“<span>Mobile Application</span>”) or visiting the Identifyn™ website (collectively the “<span>Platform</span>”). This screen contains terms and conditions that apply to your activities in using or accessing the Platform. These “Terms and Conditions of Use” constitute a valid and binding legal agreement (“<span>Terms and Conditions of Use</span>” or “<span>Terms</span>”) between you as a customer, purchaser, or user of the Platform (“<span>you</span>,” “<span>your</span>,” or “<span>user</span>”) and Identifyn™ (“<span>we</span>,” “<span>our</span>,” “<span>us</span>,” or “<span>Identifyn™</span>”). By downloading or visiting the Platform you acknowledge, accept, and agree to be bound by these Terms and Conditions of Use.</p>
                <p>Both you and Identifyn™ are referenced herein individually, as a “<span>party</span>” and collectively, as the “<span>parties</span>.”</p>
                <p>As used in these Terms, Identifyn™ refers to GMD12, LLC and Identifyn™, LLC, and its owners, parent companies, subsidiaries, affiliated companies, officers, directors, suppliers, partners, sponsors, and advertisers, and includes (without limitation) all parties involved in creating, producing, maintaining, or delivering the Platform.</p>
                <h5 style={{ textTransform: 'uppercase' }}>Please read these Terms carefully before ACCESSING, ENGAGING WITH, OR OTHERWISE USING THE PLATFORM. THESE TERMS INCLUDE: (A) LIABILITY DISCLAIMERS; (B) LIMITATIONS OF LIABILITY; (C) AN INDEMNIFICATION PROVISION; (d) an arbitration provision; (e) A CLAUSE THAT GOVERNS THE JURISDICTION AND VENUE FOR ANY DISPUTES; (f) A JURY TRIAL WAIVER; AND (g) A CLASS ACTION WAIVER.</h5>
                <p>By accessing or using the Platform, you acknowledge, accept, and agree to be bound by these Terms. If you do not agree, please stop viewing or using the Platform. Your remedy for dissatisfaction with the Platform, or any products, services, content, or other information available on or through the Platform is to stop using the Platform.</p>
                <p>Subject to these Terms, Identifyn™ grants you a limited, non-exclusive, non-sublicensable, revocable, non-transferrable license to: (i) access and use the Platform solely in connection with your purchase of products and use of services available through the Platform; and (ii) access and use of any content, information and related materials that may be made available through the Platform. Any rights not expressly granted herein are reserved by Identifyn™.</p>
                <p>Identifyn™ reserves the right, at its discretion, to update or revise these Terms at any time. All changes to these Terms are effective and binding on you immediately either upon posting the revised Terms to Identifyn’s™ website, making the Terms available through the Mobile Application, posting a notice regarding changes to the Terms, or making the Terms otherwise accessible through Identifyn’s™ Platform. You acknowledge and agree that you are responsible for checking Identifyn’s™ Website periodically for changes.</p>
                <h4>Liability and Warranty Disclaimers</h4>
                <h5 style={{ textTransform: 'uppercase' }}>Identifyn™ disclaims all warranties, express or implied, written or oral, including, without limitation, any warranty or condition of merchantability, fitness for a particular purpose, noninfringement of INTELLECTUAL property, satisfactory quality, nonINTERFERENCE, or accuracy of informational content; whether such warranties arise from a course of dealing, law, usage, or trade practice, such warranties are hereby excluded to the extent allowed by applicable law and are expressly disclaimed by Identifyn™.</h5>
                <h5 style={{ textTransform: 'uppercase' }}>You understand and agree that the PLATFORM AND THE PRODUCTS AND services available through the Platform are provided “AS IS.” EXCEPT AS OTHERWISE PROVIDED IN THESE TERMS, Identifyn™ MAKES NO WARRANTY THAT THE PRODUCTS, THE SERVICES, OR THE PLATFORM WILL MEET CUSTOMER REQUIREMENTS OR WILL BE ERROR-FREE OR EFFECTIVE. YOU assume full responsibility for any liability arising out of the use OF THE PLATFORM.</h5>
                <h5>IN ADDITION TO THE OTHER DISCLAIMERS AND LIABILITY LIMITATIONS SET FORTH HEREIN, Identifyn™ SPECIFICALLY DISCLAIMS LIABILITY FOR THE FOLLOWING:</h5>
                <ul style={{ listStyleType: 'auto', fontWeight: 700, textTransform: 'uppercase' }}>
                    <li>the suitability, quality, condition, TIMELINESS, or delivery of the MATERIALS, PRODUCTS OR SERVICES RENDERED BY Identifyn™.</li>
                    <li>ANY DEATH, INJURY, ILLNESS, OR OTHER BODILY HARM TO PERSONS CAUSED BY THE PRODUCTS OR SERVICES.</li>
                    <li>ANY DAMAGES, LOSS, OR HARM CAUSED BY VIRUSES, MALICIOUS CODE, BUGS, TROJAN HORSES, OR SIMILAR VULNERABILITIES ARISING THROUGH USING OR ACCESSING THE PLATFORM.</li>
                    <li>ANY DAMAGES, LOSS, OR HARM CAUSED BY ANY ERRORS, MISTAKES, INACCURACIES, LOSS OF, OR UNAVAILABILITY OF DATA AND INFORMATION available through the platform or ACCOMPANYING THE PRODUCTS and SERVICES.</li>
                    <li>any DAMAGE, HARM, OR claims related to Identifyn’s™ cooperation with law enforcement or regulatory authorities.</li>
                    <li>ANY DAMAGES, HARM, OR CLAIMS RELATING TO THE PROCESSING OF PAYMENTS BY A THIRD PARTY SERVICE PROVIDER.</li>
                    <li>ANY DAMAGES, HARM, OR CLAIMS RELATING TO THE use of third party products sold throuhg the platform.</li>
                    <li>ANY DAMAGE OR HARM CAUSED BY UNAVAILABILITY OF THE PLATFORM IN WHOLE OR IN PART.</li>
                </ul>
                <h5 style={{ textTransform: 'uppercase' }}>TO THE MAXIMUM EXTENT PERMITTED BY LAW, In no event shall Identifyn™ be liable for any direct, indirect, actual, consequential, special, incidental, EXEMPLARY, PUNITIVE, EMOTIONAL DISTRESS, LOSS OF CONSORTIUM, or contingent damages OF ANY KIND, or ANY costs of litigation, LOST PROFITS, LOST INCOME, OR LOST BUSINESS OPPORTUNITY, arising out of any claim of any nature, whether in contract, tort, strict liability, infringement, or otherwise, nor shall Identifyn™ have a duty to defend such claims on YOUR behalf. to the extent any damages are deemed recoverable against Identifyn™, IN NO EVENT WILL Identifyn’s™ TOTAL CUMULATIVE LIABILITY TO YOU OR ANY THIRD PARTY, ARISING FROM ANY CAUSES OF ACTION OR THEORY OF LIABILITY, EXCEED AMOUNTS PAID BY YOU TO Identifyn™.</h5>
                <h5 style={{ textTransform: 'uppercase' }}>You understand and agree that temporary interruptions of the services available through the platform may occur as normal events.</h5>
                <h4>Identifyn™ Products and Services</h4>
                <p>Identifyn™ provides customers with the ability to purchase primary antibodies, secondary antibodies, direct conjugates, glass slides, cover slips, and cell chambers, buffers, and kits for life science internal research use only, biological collection, drug development, and predictive medicine research, and reagents for internal research use only (collectively, the “<span>Products</span>”).</p>
                <p>Identifyn™ makes third party products available for purchase. Such products may be subject to restrictions or license conditions imposed by the manufacturer. You are responsible for complying with such restrictions or conditions.</p>
                <p>Identifyn™ also makes available to customers microscopy imaging services for use in the study and analysis of biological tissue samples, biopsy samples, tissue cultures, cell cultures, antibodies, antigens, and internal cellular components, as well as validation services, and in vitro diagnostic services (collectively, the “<span>Services</span>”). Customers will be able to view microscopy images generated as part of the Services (“<span>Images</span>”) through the Identifyn™ Platform and through HIVE, a third-party provider.</p>
                <p>Identifyn™ shall hold and maintain all right, title, and interest in and to the Images, including all copyrights on the Images, along with any methods, techniques, processes, know-how, and experimental designs used or maintained by Identifyn™ outside the scope of the Terms of Sale.</p>
                <p>The Images are provided for reference use only, and no specific application or use of the Products can be inferred or assumed based on the Images.</p>
                <p><span>MICROSCOPY IMAGES PROVIDED BY Identifyn™ SHALL, UNDER NO CIRCUMSTANCES, BE INCLUDED IN ANY PUBLICATION WITHOUT Identifyn’s™ EXPRESS, WRITTEN PRIOR AUTHORIZATION</span>. Any Images that are authorized for publication shall bear a conspicuous copyright notice either on the image itself or proximal to the image, which attribute copyrights to Identifyn™, such as “Copyrights Identifyn™ 2023, All Rights Reserved” or “© Identifyn™ 2023—All Rights Reserved.”</p>
                <h4>Kit Building</h4>
                <p>Identifyn™ has developed interactive kit building services to assist in the creation of research kits (“Kit Building Services”). The Kit Building Services provide information concerning interactivity of primary and secondary antibody pairs, buffers, fluorophores, slides, and other glassware that may be used together. The Kit Building Services are intended to assist customers in creating a kit with the goal of having the proper Products for the needs of the customer’s experiment. The Kit Building Services do not predict or model cell function or predict protein interactions. In making such related information available to customers, Identifyn™ has relied on third party sources of which the accuracy cannot be guaranteed.</p>
                <p>Identifyn™ expressly disclaims any representations and warranties that the Kit Building Services will meet your requirements or desired results, will be error-free or effective. Identifyn™ does not represent or warrant that selected antibodies will exhibit interactivity with specific proteins. Further, Identifyn™ does not warrant that its Kit Building Services accurately predict antibody, antigen, or fluorophore interactions.</p>
                <h4>Registration</h4>
                <p>In order to purchase Products or Services, you must register for an online account. In connection with registering for an account, we will collect (i) your first and last name; (ii) organization name (i.e., your company, employer, university, or other institution); (iii) mailing address, city, state, zip code; (iv) telephone number; (v) email address; (vi) end user name and address, if applicable; and (vii) a username and password for the account holder (“<span>Registration Information</span>”).</p>
                <p>By registering and creating an account, you agree that all information provided in the Registration Information and in connection with using the Platform is true and accurate and that you will maintain and update this information as required to keep it current, complete, and accurate.</p>
                <p>You also grant Identifyn™ the right to disclose to third parties certain Registration Information about you in connection with Identifyn’s™ facilitation of the services through the Platform. The information obtained through your participation in the Platform, including your Registration Information, is subject to our Privacy Policy, which is specifically incorporated by reference into these Terms and available through Identifyn’s™ website. Furthermore, Identifyn™ may share data with Thermo Fisher. Customer information shared with Thermo Fisher may include the names of specific organizations, sales volume numbers, shipping location and end user information.</p>
                <p>You are solely responsible for maintaining the confidentiality of your password and account and for any and all statements made and acts or omissions that occur through the use of your password and account, including any mail sent and any charges incurred. Therefore, you must take steps to ensure that others do not gain access to your password and account. You may not transfer or share your account with anyone, and Identifyn™ reserves the right to immediately terminate your account in the event of any unauthorized transfer or sharing thereof.</p>
                <h4>Reliance on Content and Information Posted to the Platform</h4>
                <p>The materials, data, information, documents, and content (“Materials”) available through the Platform is provided for general information purposes. Identifyn™ does not warrant the accuracy, completeness or usefulness of any such Materials. Any reliance you place on such Materials and information is strictly at your own risk, whether generated by Identifyn™ or a third party. Identifyn™ disclaims all liability and responsibility arising from any reliance placed on such Materials by you or any other users of the Platform.</p>
                <h4>Restrictions on Use</h4>
                <p>Your use of the Platform is subject to all applicable laws, rules, and regulations, and you are solely responsible for the contents of your communications through the Platform. You acknowledge and agree that you will not engage in conduct that does the following or upload or otherwise submit or facilitate submission of any content, including text, communications, software, images, sounds, data, or other information, that:</p>
                <ul>
                    <li className='orange'>Is unlawful, threatening, abusive, harassing, defamatory, libelous, deceptive, fraudulent, invasive of another’s privacy, tortious, or that otherwise violates Identifyn’s™ rules or policies;</li>
                    <li className='orange'>Victimizes, harasses, degrades, or intimidates an individual or group of individuals on the basis of religion, gender, sexual orientation, race, ethnicity, age, or disability;</li>
                    <li className='orange'>Infringes on any patent, trademark, trade secret, copyright, right of publicity, or other proprietary right of any party;</li>
                    <li className='orange'>Constitutes unauthorized or unsolicited advertising, junk or bulk e-mail (also known as “spamming”), chain letters, any other form of unauthorized solicitation, or any form of lottery or gambling;</li>
                    <li className='orange'>Contains software, viruses, malware, or any other computer code, files, or programs that are designed or intended to disrupt, damage, or limit the functioning of any software, hardware, or telecommunications equipment or to damage or obtain unauthorized access to any data or other information of Identifyn™, any customer, or third party;</li>
                    <li className='orange'>Implements any means to circumvent the Platform or avoid payment obligations;</li>
                    <li className='orange'>Impersonates any person or entity, including any of Identifyn’s™ employees or representatives;</li>
                    <li className='orange'>Contains personally identifying information, except when we expressly ask you to provide such information;</li>
                    <li className='orange'>Provides Identifyn™ with false information, including false Registration Information;</li>
                    <li className='orange'>Restricts or inhibits another user’s use of the Platform;</li>
                    <li className='orange'>Deploys, initiates, or use, any manual process to monitor or copy any of the Materials on the Platform, or for any other purpose not expressly authorized in these Terms;</li>
                    <li className='orange'>Attempts to accessing data not intended for you, such as logging into a server or an account which you are not authorized to access; or</li>
                    <li className='orange'>Scans or monitors the Platform for data gathering purposes in an effort to track sales, usage, aggregate offering information, pricing information, or similar data.</li>
                </ul>
                <p>In addition, you may not use your account to breach security of another account or attempt to gain unauthorized access to another network or server. You shall not interfere with anyone else’s use and enjoyment of the Platform. Users who violate systems or network security may incur criminal or civil liability.</p>
                <p>You agree that Identifyn™ may, at any time and at its sole discretion, terminate your account without prior notice to you for violating any of the above provisions. In addition, you acknowledge that Identifyn™ will cooperate fully with investigations of violations of systems or network security, including cooperating with law enforcement authorities in investigating suspected criminal violations.</p>
                <h4>Intellectual Property Information</h4>
                <p>All right, title, and interest in and to the Platform, any content or Materials provided by Identifyn™ within the Platform, the Images, and the infrastructure used to provide the Platform are owned by or proprietary to Identifyn™.</p>
                <p>Any printing, copying, modifying, downloading, or otherwise engaging in the use of any part of the Platform in breach of these Terms shall result in the immediate termination of your rights to access or use the Platform and under such circumstances, you must, at our option, return or destroy any copies of the materials that you have in violation of these Terms. No right, title, or interest in or to the Platform or any content is transferred to you. All rights not expressly granted herein are expressly reserved to Identifyn™. The Platform is provided to you under a license and not sold to you, and you acknowledge that you do not acquire any ownership interest in the Platform under these Terms or any rights thereto other than to use the Platform in accordance with the license granted and subject to these Terms.</p>
                <p>By using the Platform and accepting these Terms, Identifyn™ grants you a limited, personal, nontransferable, nonexclusive, revocable license to use the Platform pursuant to these Terms, the Identifyn™ Privacy Policy, and to any additional terms and policies set forth by Identifyn™. You shall not:</p>
                <ul>
                    <li className="orange">Decompile, reverse engineer, disassemble, attempt to derive the source code of, or decrypt any software or other technical feature of the Platform;</li>
                    <li className="orange">Copy the software application or Mobile Application following your initial download;</li>
                    <li className="orange">Make any modification, adaptation, improvement, enhancement, translation, or derivative work from the Platform or Materials;</li>
                    <li className="orange">Violate any applicable laws, rules, or regulations in connection with your access or use of the Platform;</li>
                    <li className="orange">Remove, alter, or obscure any proprietary notice (including any notice of copyright or trademark);</li>
                    <li className="orange">Use the Platform for any revenue generating endeavor, commercial enterprise, or other purpose for which it is not designed or intended;</li>
                    <li className="orange">Use the Platform for creating a product, service, or software that is, directly or indirectly, competitive with or in any way a substitute for any aspect or feature of the Platform or a Product or Service offered by Identifyn™;</li>
                    <li className="orange">Rent, lease, lend, sell, sublicense, assign, distribute, publish, transfer or otherwise make available the Platform, or any features or functionality of the Platform, to any third party for any reason;</li>
                    <li className="orange">Remove, disable, circumvent, or otherwise create or implement any workaround to any copy protection, rights management, or security features in or protecting the Platform; or</li>
                    <li className="orange">Use the Platform to send automated queries to any website or to send any unsolicited commercial e-mail.</li>
                </ul>
                <p>Materials made available by Identifyn™ is protected by copyrights, trademarks, service marks, patents, or other proprietary rights and laws, and is the sole property of Identifyn™. You are only permitted to use the Materials as expressly authorized by Identifyn™. You may not copy, reproduce, modify, republish, upload, post, transmit, or distribute any Materials from the Platform in any form or by any means without prior written permission from Identifyn™. Any unauthorized use of the Materials available through the Platform may violate copyright, trademark, and other applicable laws and could result in criminal or civil penalties.</p>
                <p>Neither Identifyn™ nor its affiliates warrant or represent that your use of Materials or content displayed within, or obtained through, the Platform will not infringe the rights of third parties.</p>
                <p>Identifyn™ marks and certain other marks within the Platform are registered or unregistered trademarks or service marks of Identifyn™. All custom graphics, icons, logos, and service names are registered or unregistered intellectual property of Identifyn™. Nothing in these Terms grants you any right to use any trademark, service mark, logo, copyrighted material, and/or the name of Identifyn™.</p>
                <h4>User’s Content</h4>
                <p>Subject to Identifyn’s™ Privacy Policy, any communication or material that you transmit through the Platform or to Identifyn™, whether by electronic mail or other means, for any reason, will be treated as nonconfidential and nonproprietary. Please do not submit confidential or proprietary information to Identifyn™ unless we have mutually agreed in writing otherwise.</p>
                <p>You grant Identifyn™ and its designated licensees a nonexclusive, royalty-free, fully paid, perpetual, and worldwide right to copy, distribute, display, perform, publish, translate, adapt, modify, and otherwise use such material transmitted through the Platform; except that any feedback on the Platform itself (including the Mobile Application) as well as improvements, modifications, or changes arising from or in connection with your feedback, are Identifyn™ property.</p>
                <h4>Third-Party Websites and Information</h4>
                <p>The Platform may link you to third-party websites or otherwise include references to services, materials, content, products, data, information, results, documents, or software provided by third parties. These websites and third parties are not under Identifyn’s™ control, and you acknowledge that Identifyn™ is not responsible for the accuracy, copyright compliance, legality, decency, or any other aspect of the content of such websites, nor is Identifyn™ responsible for errors or omissions in any references to other parties or their products and services.</p>
                <p>The inclusion of a link or reference to third-party materials or content is provided merely as a convenience and does not imply endorsement of, or association with Identifyn™, or any warranty of any kind, either express or implied. All statements and/or opinions expressed in third-party materials and content are solely the opinions and the responsibility of the person or entity providing such materials and content and do not necessarily reflect the opinion of Identifyn™. We are not responsible or liable to you or any third party for the content or accuracy of any materials and content provided by any third parties.</p>
                <h4>Termination of Use</h4>
                <p>You agree that Identifyn™ may, in its sole discretion, terminate or suspend your access to all or part of the Platform with or without notice and for any reason, including, without limitation, breach of these Terms. Any suspected fraudulent, abusive, or illegal activity may be grounds for terminating your access and may be referred to appropriate law enforcement authorities.</p>
                <p>Upon termination or suspension, regardless of the reasons therefore, your right to use the Platform immediately ceases, and you acknowledge and agree that Identifyn™ may immediately deactivate or delete your account and all related information and files in your account and/or bar any further access to such files or the Platform. Identifyn™ shall not be liable to you or any third-party for any claims or damages arising out of any termination or suspension or any other actions taken by Identifyn™ in connection therewith.</p>
                <p>Your obligations to Identifyn™ will continue upon termination of your rights under these Terms, including restrictions regarding the content, disclaimers, indemnification obligations and liability limitations under the Terms. Upon termination of your rights, you must promptly destroy all Materials downloaded or obtained from the Platform.</p>
                <h4>Modification, Updates, or Termination of the Platform</h4>
                <p>Identifyn™ also reserves the right to terminate, alter, modify, update, or discontinue the Platform, including any features therein, at any time with or without notice to you. Identifyn™ is not liable to you or any third-party should Identifyn™ exercise such right.</p>
                <p>You agree to promptly download and install all updates to the Mobile Application or other software that become available, and you acknowledge and agree that the Platform or portions thereof may not properly operate should you fail to do so. You further acknowledge and agree that all updates will be deemed part of the Platform subject to these Terms.</p>
                <h4>Geographic Restrictions</h4>
                <p>Although the Platform may be accessible worldwide, Identifyn™ makes no representation that the Materials, content, Products, Services, data, information, documents, or software available through the Platform are appropriate or available for use in locations outside the United States, and accessing them, or offering or procuring products and service, from territories where illegal is prohibited. Those who choose to access and use the Platform from other locations do so on their own initiative and are responsible for compliance with local laws. Offers for any Product, Service, or Materials made in connection with the Platform is void where prohibited.</p>
                <h4>Export Controls</h4>
                <p>Products and Services available on or through the Platform, and any related software and technology, including technical information we supply you, including those contained in any documentation (collectively “<span>Items</span>”), is subject to U.S., EU and local government export controls.</p>
                <p>The export controls may include, among others, those of the Export Administration Regulations of the U.S. Department of Commerce (the “<span>EAR</span>”), which may restrict or require licenses for the export of Items from the U.S. and their re-export to or from other countries.</p>
                <p>No materials from the Platform may be downloaded or exported (a) into (or to a resident of) any country which the United States has embargoed goods; or (b) anyone on the United States Treasury Department’s list of Specially Designated Nationals or the United States Commerce Department’s Table of Deny Orders. By downloading or using the materials, you represent and warrant that you are not located in, under the control of, or a national or resident of any such country or on any such list.</p>
                <p>You must comply with the EAR and all other applicable laws, regulations, laws, treaties, and agreements relating to the export, re-export, and import of any Item. You must not, directly or indirectly, without first obtaining the required license to do so from the appropriate U.S. government agency, export, re-export, distribute or supply any Item to (a) any restricted or embargoed country or to a person or entity whose privilege to participate in exports has been denied or restricted by the U.S. government; or (b) any person or entity who is involved in improper development or use of nuclear weapons or of chemical/biological weapons, or missiles, or in terrorist activities. You will, if we request, provide information on the end user and end use of any Item you export or plan to export, as well as written assurance(s) of compliance with EAR in the form we specify prior to shipment/delivery of Items and/or upon request.</p>
                <p>You will cooperate fully with us in any official or unofficial audit or inspection related to applicable export or import control laws or regulations, and will indemnify and hold us harmless from, or in connection with, your or your consultants’, agents’ or employees’ violation of this section.</p>
                <h4>Indemnification</h4>
                <p>You assume full responsibility for any use of any services or content available through the Platform. You understand and agree that Identifyn™ will not be responsible or liable for any claim, loss or damage arising from the use of any such services and content.</p>
                <p>You agree to defend, indemnify, and hold harmless Identifyn™ and its affiliates harmless from all liabilities, losses, damages, and expenses, including attorneys’ fees and costs, arising out of, related to, or in connection with any of the following, whether related to a third-party claim or a first-party claim between you and Identifyn™: (a) any use of the Platform in violation of these Terms; (b) fraud you commit or your intentional misconduct or gross negligence; (c) your violation of any applicable law or rights of a third-party; or (d) your use or misuse of the Platform. Identifyn™ reserves the right, at its own expense, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will cooperate with Identifyn™ in asserting any available defenses.</p>
                <h4>Legal Matters for Disputes Not Subject to Arbitration</h4>
                <p>With respect to any dispute not covered by the Arbitration provisions of these Terms, in the event any litigation commences between the parties, exclusive, mandatory jurisdiction and venue shall properly lie only in the state and federal courts of Hillsborough County, Florida. The validity, construction, enforcement, and interpretation of these Terms and Conditions of Use are governed by the laws of the State of Florida.</p>
                <h4>Class Action Waiver</h4>
                <h5 style={{ textTransform: 'uppercase' }}>You agree that any disputes shall be asserted in your individual capacity and not as a plaintiff or class member in any purported class, representative proceeding or as an association. In addition, we each agree that any disputes shall be litigated only on an individual basis and not in a class, consolidated, or representative action and that a court or arbitrator(s) may award relief (including injunctive relief) only on an individual basis.</h5>
                <h4>Jury Trial Waiver</h4>
                <h5>YOU HEREBY ACKNOWLEDGE AND AGREE TO WAIVE ANY CONSTITUTIONAL AND STATUTORY RIGHT YOU MAY HAVE UNDER ANY APPLICABLE LAW TO MAINTAIN A LAWSUIT IN A COURT AND BEFORE A JUDGE AND JURY. Identifyn™ ALSO WAIVES THIS RIGHT.</h5>
                <h4>Limitation on Time to File Claims</h4>
                <h5>TO THE EXTENT PERMITTED BY EXISTING LAW, ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF THESE TERMS OR THE PROVISION OF THE PLATFORM, PRODUCTS, OR SERVICES, WHETHER OR NOT SUCH SERVICES WERE PROVIDED THROUGH THE PLATFORM, MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION GIVING RISE TO THE CLAIM ACCRUES OR OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM SHALL BE PERMANENTLY AND FOREVER BARRED.</h5>
                <h4>Arbitration</h4>
                <p>Any controversy or claim between the parties shall be subject to binding arbitration administered by the JAMS arbitration and mediation organization in accordance with its Comprehensive Rules. The arbitration proceeding shall be presided over by a single arbitrator who shall have exclusive jurisdiction to determine matters of arbitrability of any claims presented. The mandatory, exclusive location for the arbitration shall be Hillsborough County, Florida. The arbitrator will have the exclusive authority to make determination as to the applicability, scope and enforceability of these terms and to resolve disputes in connection with these Terms. The federal rules of evidence shall apply to arbitration proceedings between the parties, and the arbitration shall be governed by the laws of the State of Florida. The arbitrator’s decision shall be final, binding, and non-appealable, and judgment on the decision shall be entered thereon only in the U.S. District Court for the Middle District of Florida. The prevailing party in an arbitration proceeding shall be entitled to an award of all attorneys’ fees and any and all costs and expenses incurred in any way relating to the resolution of any dispute between the parties, including fees incurred in determining entitlement to and the amount of attorneys’ fees and costs.</p>
                <p>Unless otherwise agreed between the parties, arbitration or state or federal court litigation on an individual basis shall be the exclusive remedy for any claim that might otherwise be brought on a class, collective, or representative action basis. The parties must bring any claim on an individual basis, such that any arbitration shall consider only the claim or claims asserted by a single plaintiff individually and solely on his or her own behalf. You waive any right to submit a class, collective, or representative action for arbitration under these Terms and Conditions of Use, and you shall not request, nor shall the arbitrator allow, the joinder of any other claimant in an arbitration, except upon express written consent signed Identifyn™.</p>
                <h4>Severability</h4>
                <p>If any part of these Terms is held invalid or unenforceable, that portion shall be construed in a manner consistent with applicable law to reflect, as nearly as possible, the original intentions of the parties, and the remaining portions shall remain in full force and effect.</p>
                <h4>Waiver</h4>
                <p>Any failure by Identifyn™ to enforce or exercise any provision of these Terms or related rights shall not constitute a waiver of that right or provision.</p>
                <h4>Miscellaneous</h4>
                <p>You may not assign your rights or obligations under these Terms to any third party, and any purported attempt to do so shall be null and void. Identifyn™ may freely assign its rights and obligations under these Terms.</p>
                <p>You agree not to sell, resell, reproduce, duplicate, copy, or use for any commercial purposes any portion of the Platform.</p>
                <p>These Terms shall not be construed more strictly against any party regardless of who is responsible for their drafting. Unless the context of these Terms otherwise clearly requires, references to the plural include the singular and the singular include the plural.</p>
                <p>The captions of these Terms are for convenience and ease of reference only and in no way define, describe, extend, or limit the scope or intent of these Terms.</p>
                <h4>Entire Agreement</h4>
                <p>These Terms, together with any invoice issued by Identifyn™, Privacy Policy and the Terms and Conditions of Sale, constitute the entire agreement between the parties relating to the subject matter hereof. All prior understandings and agreements between the parties relating to the subject matter hereof are merged into these Terms, which alone and completely express their understanding. The Terms may not be altered, amended, or changed except by written instrument signed on behalf of each of the parties.</p>
                <h4>Contact Us</h4>
                <p>The Identifyn™ Platform is operated by GMD12, LLC and Identifyn™, LLC.</p>
                <p>Feedback, comments, requests for technical support, and other communications relating to the Identifyn™ website, Mobile Application, or the services available on or through the Platform should be directed to:</p>
                <a href="mailto:Contact@Identifyn™.com" style={{ color: 'var(--primary-color)' }}>Contact@Identifyn™.com</a>
            </Content>
            <Footer />
        </Layout>
    )
}

export default Index