import axios from 'axios'
import { getLocalStorage, removeLocalStorage} from '../../helpers/localStorage.js'


const BASE_URL = process.env.REACT_APP_BASE_URL

export const apiService = axios.create({
    baseURL: BASE_URL,
    withCredentials: true
})

apiService.interceptors.request.use(
    (config) => {
        let jwt = ''

        const identifynToken = getLocalStorage('identifyntoken')
            jwt = identifynToken

        if (jwt) {
            config.headers['Authorization'] = `Bearer ${jwt}`
        }
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

apiService.interceptors.response.use(
    response => {
        return {
            ok: true,
            status: response.status,
            data: (response || {}).data,
            errMessage: '',
          };
    },
    error => {
        if (error.response) {
            return {
              ok: false,
              status: error.response.data.status,
            //   data: { data: { success: false } },
              errMessage: error.response.data.message,
              error: error.response.data,
            };
          } else {
            return {
              ok: false,
              status: error.message,
            //   data: { data: { success: false } },
              errMessage: error.message,
            };
          }
    }
)
