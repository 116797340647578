import React, { useContext, useState } from 'react'
import "./style.scss"
import { AuthContext } from "../../../context/auth";
import Input from "../../Input Old";
import { updateCustomerApi, changePasswordApi, checkCurrentUserApi } from '../../../api';

const Index = () => {
  const { setChangePasswordModal, setNotification, setRestoreModal, setIsLoading, user} = useContext(AuthContext)
  const [currentPassword, setCurrentPassword] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [error, setError] = useState(false)


  const changePassword = () => {
    setIsLoading(true)
    if (password !== confirmPassword) {
      setError('Password does not match')
      setIsLoading(false)
    }else{
      checkCurrentUserApi({email: user?.customer?.email, password: currentPassword }).then((res) => {
        if(res && res?.data?.customer){
          changePasswordApi({id: user?.customer?.id, password: password }).then((res) => {
            if (res.status === 200) {
              setNotification({ message: 'Password changed successfully', type: 'success' })
              setChangePasswordModal(false)
              setIsLoading(false)
            }else{
              setNotification({ message: 'Something went wrong', type: 'red' })
              setIsLoading(false)
            }
          }).catch(() => {
            setNotification({ message: 'Something went wrong', type: 'red' })
            setIsLoading(false)
          })
        }else{
          setNotification({ message: 'Current password is incorrect', type: 'red' })
          setIsLoading(false)
        }
      })
  }
  }

  return (
    <div className="small-modal-wrapper">
      <div className="small-modal-content" style={{padding:'20px'}}>
      <div style={{ position: 'relative', justifyContent: 'center', display: 'flex', width: '100%' }}>
        <span style={{ width: '44px', height: '44px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '12px', background: '#fff2e5', color: 'var(--primary-color)' }}>
          <i class="fal fa-lock"></i>
        </span>
        <span className='close' style={{ color: '#9a9a9a', top:0, right:0 }} onClick={() => setChangePasswordModal(false)}><i className="fal fa-times"></i></span>
        </div>
        <h2>Change Password </h2>
        <div className="form-container" style={{ width: '100%' }}>
        <div style={{ marginTop: '12px' }}>
            <p style={{ marginBottom: '4px', color: '#9a9a9a', opacity: '1', fontSize: '14px', textAlign: 'left' }}>Current Password</p>
            <Input type='password' placeholder={"Current Password"} value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)} />
          </div>
          <div style={{ marginTop: '12px' }}>
            <p style={{ marginBottom: '4px', color: '#9a9a9a', opacity: '1', fontSize: '14px', textAlign: 'left' }}>New Password</p>
            <Input type='password' placeholder={"Enter Password"} value={password} onChange={(e) => setPassword(e.target.value)} className={error ? 'error' : ''} />
          </div>
          <div style={{ marginTop: '12px', marginBottom: '22px' }}>
            <div style={{display:'flex', justifyContent:'space-between'}}>
            <p style={{ marginBottom: '4px', color: '#9a9a9a', opacity: '1', fontSize: '14px', textAlign: 'left' }}>Confirm New Password</p>
           {error && <p style={{ marginBottom: '8px', color: '#DC1E00', opacity: '1', fontSize: '14px', textAlign: 'left' }}>Passwords must match</p>}
            </div>
            <Input type='password' placeholder={"Confirm Password"} value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} className={error ? 'error' : ''} 
            />
          </div>
        </div>
        <div className="password-validations" style={{ width: '100%', marginBottom: '30px' }}>
          <span style={{ color: '#6C6A73' }}>{password.length >= 8 ? <i className="fas fa-check" style={{ color: '#33C25E', marginRight: '8px' }}></i> : <i className="far fa-circle" style={{ color: '#9a9a9a', marginRight: '8px' }}></i>}At least 8 characters</span>
          <span style={{ color: '#6C6A73' }}>{/[A-Z]/.test(password) ? <i className="fas fa-check" style={{ color: '#33C25E', marginRight: '8px' }}></i> : <i className="far fa-circle" style={{ color: '#9a9a9a', marginRight: '8px' }}></i>}At least 1 upper case letter (A-Z).</span>
          <span style={{ color: '#6C6A73' }}>{/\d/.test(password) ? <i className="fas fa-check" style={{ color: '#33C25E', marginRight: '8px' }}></i> : <i className="far fa-circle" style={{ color: '#9a9a9a', marginRight: '8px' }}></i>}At least 1 number (0-9).</span>
        </div>

        <p style={{marginBottom:'28px'}}>Forgot? <span style={{ color: 'var(--primary-color)', cursor: 'pointer', fontWeight:500 }}
        onClick={() => {
          setRestoreModal(true)
          setChangePasswordModal(false)
        }}
        >Restore Password</span></p>

        <button className='btn'
        disabled={password.length < 8 || !/[A-Z]/.test(password) || !/\d/.test(password) || !confirmPassword}
        onClick={() => {
          changePassword()
        }}>Save</button>
        <button className='btn transparent' style={{margin:0}} onClick={() => {
          setChangePasswordModal(false)
        }}>Cancel</button>
      </div>
    </div>
  )
}

export default Index