import React, {useState, useEffect, useContext} from 'react'
import Layout from '../../../components/layout'
import { AboutHeader, AboutContent } from './style'
import { toAbsoluteUrl } from '../../../utils/assetHelper'
import { AuthContext } from '../../../context/auth'
import { contactApi } from '../../../api'
import Input from "../../../components/Input"
import { CountryDropdown, StateDropdown } from '../../../components/DropDowns'
import axios from 'axios'
import Dropdown from "../../../components/Dropdown"
import { Footer } from '../../../components/Footer'

const Index = () => {
  const {setIsLoading, setNotification } = useContext(AuthContext)
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [country, setCountry] = useState('United States');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [topic, setTopic] = useState('Identifyn™ Assay Development');
  const [description, setDescription] = useState('');
  const [cities, setCities] = useState([]);


  useEffect(() => {
    if (state === undefined) return
    if (state) {
      axios.post('https://countriesnow.space/api/v0.1/countries/state/cities', {
        country: country,
        state: state
      }).then((res) => {
        setCities(res.data.data)
      }).catch((err) => {
        console.error(err)
      })
    }
  }, [state])



  const sendContact = async () => {
    setIsLoading(true)
if(email && RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(email)){
    const data = {
        first_name:firstName,
         last_name:lastName,
         email,
         company,
         country,
         state,
         city,
         topic,
         message:description,
         email_to:'services@identifyn.com'
     }
     const response = await contactApi(data)
     if(response?.status === 200) {
         setFirstName('')
         setLastName('')
         setEmail('')
         setCompany('')
         setCountry('United States')
         setState('')
         setCity('')
         setTopic('')
         setDescription('')
         setIsLoading(false)
         setNotification({
             type: 'success',
             message: 'Message sent successfully!'
         })      
     }else{
         setIsLoading(false)
         setNotification({
             type: 'red',
             message: 'Something went wrong, please try again!'
         })
     }
}else{
    setIsLoading(false)
    setNotification({
        type: 'red',
        message: 'Please enter a valid Email Address'
    })
}
}






  return (
    <Layout>
      <div className="container title-container bio-container">
        <span style={{ width: '44px', height: '44px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '12px', background: '#fff2e5', color: 'var(--primary-color)' }}>
          <i class="fal fa-users"></i>
        </span>
        <AboutHeader>
          <h1>Identifyn™ Assay Development</h1>
        </AboutHeader>
      </div>
      <AboutContent>
        <div className="container azure-section-four-container">
          <p>Identifyn™ <span>possesses rich experience</span> in all aspects of assay development in <span>Cell-based ELISA, Multiplex Immunofluorescence, and Multi-label In-Cell Western</span>, with core expertise in Immunocytochemistry deploying <span>Super Resolution Microscopy at Single Molecule Resolution</span>.</p>
          <p><span>Identifyn™ offers consultation and contract services for developing Immunocytochemistry assays</span>, including dose-directed response testing for anti-cancer drugs and biotherapeutic agents.</p>
          <p><span>Reach out to inquire about Identifyn™ Assay Development Program</span>.</p>
        </div>
        <div className="container contact-container" id='contact'>
          <div className="left-container">
            <div className="solutions-text">
              <h2>Contact <span>Us</span></h2>
              {/* <p>Please contact IDENTiFYN™ using this from. Select topic from dropdown and briefly describe how we can help.
              </p> */}
            </div>
          </div>
          <div className="right-container">
            <div className="form-container">
              <div className="form-wrapper">
                <div
                  style={{ display: "block", width: "100%", marginTop: "20px", marginBottom: '20px' }}
                >
                  <div className="two-column">
                    <Input placeholder="Your First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                    <Input placeholder="Your Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                  </div>
                  <Input placeholder="Your Email" type='email' style={{ marginBottom: '20px' }} value={email} onChange={(e) => setEmail(e.target.value)} />
                  <Input placeholder="Your Company/Institution" style={{ marginBottom: '20px' }} optional value={company} onChange={(e) => setCompany(e.target.value)} />
                  <div className="three-column">
                    <CountryDropdown value={country} onChange={(value) => {
                      setCountry(value)
                    }} />
                    <StateDropdown value={state} country={country} placeholder={"State"} onChange={(value) => {
                      setState(value)
                    }} />
                    <select
                      data-kt-select2='true'
                      data-placeholder='City'
                      data-allow-clear='true'
                      data-kt-user-table-filter='two-step'
                      data-hide-search='true'
                      value={city}
                      onChange={(e) => {
                        setCity(e.target.value)
                      }}
                      placeholder='City'
                    >
                      <option disabled selected>City </option>
                      {cities && cities.length && cities?.map((city) => {
                        return <option key={city} value={city}>{city}</option>
                      })}
                    </select>
                  </div>
                  <Input style={{ marginBottom: '20px', pointerEvents:'none' }} value={topic} onChange={(e) => setTopic(e.target.value)} />
                  {/* <Dropdown
                    value={topic}
                    onChange={(e) => {
                      setTopic(e)
                    }}
                    style={{ marginBottom: '20px', width: '100%' }}
                    label={'Select Topic'}
                    options={
                      [
                        "Microscopy Support",
                        "Protein Chemistry Support",
                        "Primary Antibody",
                        "Primary Fluorescent Conjugates",
                        "Secondary Fluorescent Conjugates",
                        "Reagents & Glass",
                        "STORM Buffers",
                        "Kits",
                        "Account Support",
                        "Order Support",
                        "General Inquiry"
                      ]
                    }
                  /> */}
                  <textarea
                    style={{ resize: "none", borderRadius: "8px" }}
                    placeholder='Write your message here!'
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  ></textarea>
                </div>
                <button class="btn" style={{ float: 'right' }}
                  disabled={!firstName || !lastName || !email || !country || !state || !city || !topic || !description}
                  onClick={sendContact}
                >Get In Touch!</button>
              </div>
            </div>
          </div>
        </div>
      </AboutContent>
      <Footer />
    </Layout>
  )
}

export default Index