import React, {useState,useContext, useEffect} from 'react'
import { Wrapper } from "./style"
import Input from "../../Input"
import { CountryDropdown, StateDropdown } from '../../DropDowns'
import { AuthContext } from '../../../context/auth'
import { addCustomerAddressesApi, updateCustomerAddressesApi,currentUserApi} from '../../../api'
import {countryCode} from '../../../utils/country'


const Index = () => {
const {setShippingAddressModal, selectedAddress, setNotification, user, setUser, setIsLoading} = useContext(AuthContext)
const [name, setName] = useState('');
const [company, setCompany] = useState('');
const [department, setDepartment] = useState('');
const [streetAddress, setStreetAddress] = useState('');
const [building, setBuilding] = useState('');
const [lab, setLab] = useState('');
const [attentionTo, setAttentionTo] = useState('');
const [country, setCountry] = useState('United States');
const [state, setState] = useState('');
const [city, setCity] = useState('');
const [zipCode, setZipCode] = useState('');


const fetchCurrentUser = () => {
  currentUserApi()
  .then(res => {
      if (res.data) {
         setUser(res.data)
          setIsLoading(false)
      }
  })
  .catch(() => {
      setIsLoading(false)
  })
}


function getKeyByValue(object, value) {
  for (const [key, val] of Object.entries(object)) {
    if (val === value) {
      return key;
    }
  }
  return null; 
}

useEffect(() => {
if(selectedAddress){
  setName(selectedAddress?.first_name)
  setCompany(selectedAddress?.company)
  setDepartment(selectedAddress?.metadata?.department)
  setStreetAddress(selectedAddress?.address_1)
  setBuilding(selectedAddress?.metadata?.building)
  setLab(selectedAddress?.metadata?.lab)
  setAttentionTo(selectedAddress?.metadata?.attention_to)
  setCountry(getKeyByValue(countryCode, selectedAddress?.country_code?.toUpperCase()))
  setState(selectedAddress?.province)
  setCity(selectedAddress?.city)
  setZipCode(selectedAddress?.postal_code)
}
},[selectedAddress])






const addAddress = () => {
  setIsLoading(true)
const is_primaryAddress = user?.customer?.shipping_addresses?.filter((item) => item.metadata?.type === 'shipping' && item.is_primary).length === 0 ? true : false
  const data = {
    first_name: name,
    last_name:"",
    company: company,
    address_1: streetAddress,
    city: city,
    province: state,
    country_code: countryCode[country],
    postal_code: zipCode,
    metadata: {
      department: department,
      building: building,
      lab: lab,
      attention_to: attentionTo,
      type:"shipping",
      is_primary: is_primaryAddress ? false : true
    }
  }
  addCustomerAddressesApi({
    address:data}).then((res) => {
     if(res.data){
       setNotification({
         type: 'success',
         message: 'Address updated successfully'
       })
       fetchCurrentUser()
       setIsLoading(false)
       setShippingAddressModal(false)
     }else{
       setNotification({
         type: 'red',
         message: 'Something went wrong'
       })
       setIsLoading(false)
     }
  }).catch((err) => {
    setNotification({
      type: 'red',
      message: 'Something went wrong'
    })
    setShippingAddressModal(false)
    setIsLoading(false)
  })
}

const updateAddress = () => {
    setIsLoading(true)
    const data = {
      first_name: name,
      last_name:"",
      company: company,
      address_1: streetAddress,
      city: city,
      province: state,
      country_code: countryCode[country],
      postal_code: zipCode,
      metadata: {
        department: department,
        building: building,
        lab: lab,
        attention_to: attentionTo,
        type:"shipping",
        is_primary: selectedAddress?.metadata?.is_primary
      }
    }
    updateCustomerAddressesApi(selectedAddress.id, data).then((res) => {
      if(res.data){
        setNotification({
          type: 'success',
          message: 'Address updated successfully'
        })
        fetchCurrentUser()
        setShippingAddressModal(false)
        setIsLoading(false)
      }else{
        setNotification({
          type: 'red',
          message: 'Something went wrong'
        })
        setIsLoading(false)
      }
    }).catch((err) => {
      setNotification({
        type: 'red',
        message: 'Something went wrong'
      })
      setShippingAddressModal(false)
      setIsLoading(false)
    })
  }







  return (
    <Wrapper>
      <div className="small-modal-content">
        <span style={{ color: '#9a9a9a' }} className='close' onClick={() => {
          setShippingAddressModal(false)
         }}><i className="fas fa-times"></i></span>
        <h2>{selectedAddress ? 'Edit Shipping Address' : 'New Shipping Address'}</h2>
        <div className="two-column">
          <div className='input-container'>
            <label style={{ color: '#9a9a9a' }} className='label'>First and Last Name <span style={{color:'var(--primary-color)'}}>*</span></label>
          <Input placeholder="First and Last Name" value={name} onChange={(e) => setName(e.target.value)} />
          </div>
          <div className="input-container">
          <label style={{ color: '#9a9a9a' }} className='label'>Your Company/Institution <span style={{color:'var(--primary-color)'}}>*</span></label>
          <Input placeholder="Your Company/Institution" value={company} onChange={(e) => setCompany(e.target.value)} />
          </div>
          </div>
        <div className="two-column">
          <div className="input-container">
          <label style={{ color: '#9a9a9a' }} className='label'>Department <span style={{color:'var(--primary-color)'}}>*</span></label>
          <Input placeholder="Department" value={department} onChange={(e) => setDepartment(e.target.value)} />
          </div>
          <div className="input-container">
          <label style={{ color: '#9a9a9a' }} className='label'>Street Address <span style={{color:'var(--primary-color)'}}>*</span></label>
          <Input placeholder="Street Address" value={streetAddress} onChange={(e) => setStreetAddress(e.target.value)} />
          </div>
        </div>
        <div className="three-column">
          <div className="input-container">
          <label style={{ color: '#9a9a9a' }} className='label'>Building</label>
          <Input placeholder="Building" value={building} onChange={(e) => setBuilding(e.target.value)} />
          </div>
          <div className="input-container">
          <label style={{ color: '#9a9a9a' }} className='label'>Lab</label>
          <Input placeholder="Lab" value={lab} onChange={(e) => setLab(e.target.value)} />
          </div>     
          <div className="input-container">
          <label style={{ color: '#9a9a9a' }} className='label'>Attention To</label>
          <Input placeholder="Attention To" value={attentionTo} onChange={(e) => setAttentionTo(e.target.value)} />
          </div>    
        </div>
        <div className="two-column">
        <div className="input-container">
          <label style={{ color: '#9a9a9a' }} className='label'>Country</label>
          <CountryDropdown value={country} onChange={(e) => setCountry(e)} />
          </div>
          <div className="input-container">
          <label style={{ color: '#9a9a9a' }} className='label'>State <span style={{color:'var(--primary-color)'}}>*</span></label>
          <StateDropdown country={country} placeholder={"Select State"} value={state} onChange={(e) => setState(e)} />
          </div>
        </div>
        <div className="two-column">
        <div className="input-container">
          <label style={{ color: '#9a9a9a' }} className='label'>City <span style={{color:'var(--primary-color)'}}>*</span></label>
          <Input placeholder="Select City" value={city} onChange={(e) => setCity(e.target.value)} />
          </div>
          <div className="input-container">
          <label style={{ color: '#9a9a9a' }} className='label'>Zip Code <span style={{color:'var(--primary-color)'}}>*</span></label>
          <Input placeholder="Zip Code" value={zipCode} onChange={(e) => setZipCode(e.target.value)} />
          </div>
        </div>
        <button className='btn'
        disabled={!name || !company || !department || !streetAddress || !country || !state || !city || !zipCode}
        onClick={() => {
          if (selectedAddress) {
            updateAddress()
          }else{
            addAddress()
          }
        }}
        
        >{selectedAddress ? 'Update Shipping Address' : 'Add Shipping Address'}</button>
      </div>
    </Wrapper>
  )
}

export default Index