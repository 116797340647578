import React from 'react'
import "./style.scss"
import { toAbsoluteUrl } from '../../../utils/assetHelper';



const Index = ({setOpenFeedBackModal}) => {
  return (
    <div className="small-modal-wrapper">
        <div className="small-modal-content large-modal-content" style={{padding:'20px'}}>
        <div style={{ position: 'relative', justifyContent: 'center', display: 'flex', width: '100%' }}>
        <i className='fas fa-times close' style={{ color: '#9a9a9a', position: 'absolute', right: '0px', top: '0px', cursor: 'pointer', zIndex: 9, fontSize: '20px' }} onClick={() => {
          setOpenFeedBackModal(false)
        }}></i>
        <span style={{ width: '44px', height: '44px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '12px', background: '#FFF2E5', color: 'var(--white)' }}>
           <img src={toAbsoluteUrl('/media/identifynsmalllogo.png')} style={{ width: '20px', height: '20px' }} alt="" />
            </span>
            </div>
            <div className="mobile-overflow">
            <p style={{marginBottom:'10px', marginTop:'20px'}}>Identifyn™ Pathways represent our current knowledge and interpretation, a dynamic process ever-evolving with new publications. If you would like to contribute corrections or additions to our maps, please send your comments and supporting literature to <a href="mailto:contact@identifyn.com">contact@identifyn.com</a>.</p>
            <p style={{marginTop:'24px', padding:'12px 10px', background:'#FFF2E5', borderRadius:'12px', color:'#DC4405', fontWeight:700}}>If Identifyn™ incorporates your research into our maps after peer review, we will cite your efforts and you will receive one free antibody product!</p>
            </div>
        </div>
    </div>
  )
}

export default Index