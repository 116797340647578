import React, { useState, useEffect } from 'react'
import Layout from '../../components/layout'
import { PathwayWrapper } from './style'
import { toAbsoluteUrl } from '../../utils/assetHelper'
import PathwayModal from '../../components/modals/pathwayModal'
import { useLocation } from 'react-router-dom'

const Index = () => {
  const [pathwayModal, setPathwayModal] = useState(false)
  const location = useLocation()


  useEffect(() => {
    if (pathwayModal) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [pathwayModal])


  useEffect(() => {
    if (location.state && (location.state.ssb || location.state.dsb)) {
      setPathwayModal(true)
    }
  }, [])


  const pathways = [
    {
      name: 'Cell Death',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor',
      img: `${toAbsoluteUrl('/media/pathways/celldeath.png')}`,
      coming_soon: true
    },
    {
      name: 'DNA Damage & Repair',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor',
      img: `${toAbsoluteUrl('/media/pathways/dna.png')}`,
      coming_soon: false,
    },
    {
      name: 'Mitochondria',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor',
      img: `${toAbsoluteUrl('/media/pathways/mitochondria.png')}`,
      coming_soon: true
    },
  ]


  const pathwaysSecond = [
    {
      name: 'Cell Cycle',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor',
      img: `${toAbsoluteUrl('/media/pathways/cellcycle.png')}`,
      coming_soon: true
    },
    {
      name: 'Cancer Growth & Spread',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor',
      img: `${toAbsoluteUrl('/media/pathways/cancer.png')}`,
      coming_soon: true
    }
  ]


  return (
    <Layout>
      <PathwayWrapper>
        <div className="container title-container">
        <div style={{width:'100%'}}>
        <h2><span>Identifyn™ SRM Vetted De Novo Primary Antibodies</span></h2>
        <h6>Please select a pathway</h6>
        </div>
          <div className="pathways">
            {pathways.map((item) => {
              return <div className="pathway" style={{ backgroundImage: `url(${item.img})`, backgroundSize: 'cover', cursor: item.coming_soon ? 'default' : 'pointer' }}
                onClick={() => item.coming_soon ? null : setPathwayModal(true)}
              >
                <div className="img-container" style={{ backgroundImage: `url(${item.img})`, backgroundPositionY: item.name === 'Cancer Growth & Spread' ? '30%' : '' }}>
                  {/* <img src={item.img} alt="" /> */}
                </div>
                <div className="text-content">
                  <h4>{item.name}</h4>
                  {/* <p>{item.description}</p> */}
                </div>
                {item.coming_soon && <span className="coming-soon">Coming Soon</span>}
              </div>
            })}
          </div>
          <div className="pathways">
            {pathwaysSecond.map((item) => {
              return <div className="pathway pathway2" style={{ backgroundImage: `url(${item.img})`, backgroundSize: 'cover', cursor: item.coming_soon ? 'default' : 'pointer' }}
                onClick={() => item.coming_soon ? null : setPathwayModal(true)}
              >
                <div className="img-container" style={{ backgroundImage: `url(${item.img})`, backgroundPositionY: item.name === 'Cancer Growth & Spread' ? '30%' : '' }}>
                  {/* <img src={item.img} alt="" /> */}
                </div>
                <div className="text-content">
                  <h4>{item.name}</h4>
                  {/* <p>{item.description}</p> */}
                </div>
                {item.coming_soon && <span className="coming-soon">Coming Soon</span>}
              </div>
            })}
          </div>
        </div>
      </PathwayWrapper>
      {pathwayModal && <PathwayModal setPathwayModal={setPathwayModal} pathwayModal={pathwayModal} />}
    </Layout>
  )
}

export default Index