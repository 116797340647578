import React, {useContext} from 'react'
import "./style.scss"
import {AuthContext} from "../../../context/auth";
import { useNavigate } from 'react-router-dom';
import { removeLocalStorage } from '../../../helpers/localStorage';
import { toAbsoluteUrl } from '../../../utils/assetHelper';



const Index = () => {
    const {setCompleteOrderModal, setCompletedOrderData, completedOrderData, setCartData, tempDisable, setTempDisable} = useContext(AuthContext)
    const navigate = useNavigate()

  return (
    <div className="small-modal-wrapper">
        <div className="small-modal-content large-modal-content" style={{padding:'20px'}}>
        <div style={{ position: 'relative', justifyContent: 'center', display: 'flex', width: '100%' }}>
        <i className='fas fa-times close' style={{ color: '#9a9a9a', position: 'absolute', right: '0px', top: '0px', cursor: 'pointer', zIndex: 9, fontSize: '20px' }} onClick={() => {
          setTempDisable(true)
          setCompletedOrderData(null)
          setCartData(null)
          removeLocalStorage('cartId')
          // navigate('/')
        }}></i>
        <span style={{ width: '44px', height: '44px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '12px', background: '#FFF2E5', color: 'var(--white)' }}>
           <img src={toAbsoluteUrl('/media/identifynsmalllogo.png')} style={{ width: '20px', height: '20px' }} alt="" />
            </span>
            </div>
            <div className="mobile-overflow">
            <h2 style={{marginBottom:'10px'}}>Thank you for visiting Identifyn™ and viewing our Super Resolution Vetted Product Offering!</h2>
            <p>We appreciate your valuable time; our checkout process will be live once we have completed our data collection.</p>
            <p style={{marginBottom:'20px', marginTop:'20px'}}>In the interim, <span>if you would like to obtain any of our Identifyn™ SRM Alexa Fluor™ Secondary or Primary Conjugates, as well as our De Novo Antibodies,</span> don't hesitate to get in touch with us: <a href="mailto:productsupport@identifyn.com">productsupport@identifyn.com</a>.</p>
            <p>For Contract Services:</p>
            <p><a href="mailto:contract@identifyn.com">contract@identifyn.com</a></p>
            <p style={{marginTop:'20px'}}>Identifyn™ Delivering Unprecedented Power, Speed, and Access to Multimodal Super Resolution Microscopy, Reagents, Applications, and Discovery.</p>
            </div>
        </div>
    </div>
  )
}

export default Index