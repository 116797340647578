import React from 'react'
import Layout from '../../../components/layout'
import { AboutHeader, Content } from './style'
import {Footer} from '../../../components/Footer'

const Index = () => {
    return (
        <Layout>
            <AboutHeader>
                <h1>Terms and Condition <span>of Sale</span></h1>
            </AboutHeader>
            <Content>
                <p style={{ color: '#6c6a73' }}>Effective: December 6, 2023</p>
                <p>These “Terms and Conditions of Sale” constitute a valid and binding legal agreement (“<span>Terms of Sale</span>” or “<span>Terms</span>”) between you as a customer, purchaser, or user of the Identifyn™ Products and Services (“<span>you</span>,” “<span>your</span>,” or “<span>user</span>”) and Identifyn™ (“<span>we</span>,” “<span>our</span>,” “<span>us</span>,” or “<span>Identifyn™</span>”). By purchasing Identifyn™ Products or Services (collectively “<span>Products</span>”), you acknowledge, accept, and agree to be bound by these Terms of Sale.</p>
                <p>Unless otherwise defined in these Terms of Sale, terms used in these Terms of Sale have the same meanings as in our Platform Terms and Conditions of Use available through the Website.</p>
                <p>Both you and Identifyn™ are referenced herein individually as a “party,” and collectively as the “parties.”</p>
                <h5 style={{textTransform:'uppercase'}}>Please read these Terms carefully before ACCESSING, ENGAGING WITH, OR OTHERWISE USING THE PLATFORM. THESE TERMS INCLUDE: (A) LIABILITY DISCLAIMERS; (B) LIMITATIONS OF LIABILITY; (C) AN INDEMNIFICATION PROVISION; (d) an arbitration provision; (e) A CLAUSE THAT GOVERNS THE JURISDICTION AND VENUE FOR ANY DISPUTES; (f) A JURY TRIAL WAIVER; AND (g) A CLASS ACTION WAIVER.</h5>
                <p>Identifyn™ reserves the right, at its discretion, to update or revise these Terms of Sale at any time. All changes to these Terms of Sale are effective and binding on you immediately either upon posting the revised Terms of Sale to Identifyn’s™ website, making the Terms of Sale available through Identifyn’s™ mobile software application, posting a notice regarding changes to the Terms of Sale, or making the Terms of Sale otherwise accessible through Identifyn’s™ Platform, as that term is defined in Identifyn’s™ Platform Terms and Conditions of Use. You acknowledge and agree that you are responsible for checking Identifyn’s™ Website periodically for changes. Your purchase of Products or Services following any such updates or revisions constitutes your acknowledgement and acceptance of the changes and agreement to abide and be bound by the updated or revised Terms.</p>
                <p>As used in these Terms of Sale, Identifyn™ refers to GMD12, LLC and Identifyn™, LLC, and its owners, parent companies, subsidiaries, affiliated companies, officers, directors, suppliers, partners, sponsors, and advertisers, and includes (without limitation) all parties involved in creating, producing, maintaining, or delivering the Platform.</p>
                <h4>Liability and Warranty Disclaimers</h4>
                <h5 style={{textTransform:'uppercase'}}>Identifyn™ disclaims all warranties, express or implied, written or oral, including, without limitation, any warranty or condition of merchantability, fitness for a particular purpose, noninfringement of INTELLECTUAL property, satisfactory quality, nonINTERFERENCE, or accuracy of informational content; whether such warranties arise from a course of dealing, law, usage, or trade practice, such warranties are hereby excluded to the extent allowed by applicable law and are expressly disclaimed by Identifyn™.</h5>
                <h5 style={{textTransform:'uppercase'}}>You understand and agree that the PRODUCTS AND services available through the Platform are provided “AS IS.” EXCEPT AS OTHERWISE PROVIDED IN THESE TERMS, Identifyn™ MAKES NO WARRANTY THAT THE PRODUCTS OR THE SERVICES WILL MEET CUSTOMER REQUIREMENTS OR WILL BE ERROR-FREE OR EFFECTIVE. YOU assume full responsibility for any liability arising out of the use, transportation, storage, HANDLING, DISPOSAL, OR DISCHARGE of any PRODUCTS.</h5>
                <h5 style={{textTransform:'uppercase'}} >IN ADDITION TO THE OTHER DISCLAIMERS AND LIABILITY LIMITATIONS SET FORTH HEREIN, Identifyn™ SPECIFICALLY DISCLAIMS LIABILITY FOR THE FOLLOWING:</h5>
                <ul style={{listStyle:'auto', textTransform:'uppercase', fontWeight:'700'}}>
                    <li>the suitability, quality, condition, TIMELINESS, or delivery of the PRODUCTS OR SERVICES RENDERED BY Identifyn™.</li>
                    <li>ANY DEATH, INJURY, ILLNESS, OR OTHER BODILY HARM TO PERSONS CAUSED BY THE PRODUCTS OR SERVICES.</li>
                    <li>ANY DAMAGES, LOSS, OR HARM CAUSED BY ANY ERRORS, MISTAKES, INACCURACIES, LOSS OF, OR UNAVAILABILITY OF DATA AND INFORMATION ACCOMPANYING THE PRODUCTS OR SERVICES.</li>
                    <li>any DAMAGE, HARM, OR claims related to Identifyn’s™ cooperation with law enforcement or regulatory authorities.</li>
                    <li>ANY DAMAGES, HARM, OR CLAIMS RELATING TO THE PROCESSING OF PAYMENTS BY A THIRD PARTY SERVICE PROVIDER.</li>
                    <li>any damages, harm, or claims relating to any third party products sold through the platform, including, but not limited to, PROTEINS, ANTIBODIES, fluorophores, OR DYES.</li>
                    <li>tHE FAILURE OF AN Identifyn™ PRODUCT TO DETECT OR BIND TO AN ANTIGEN, A PRIMARY OR SECONDARY ANTIBODY, PROTEIN, OR OTHER BIOMOLECULE, OR THE FAILURE OF AN Identifyn™ PRODUCT TO FLUORESCE, exhibit Chemiluminescence, or function for a particular labeling technique or other purpose.</li>
                    <li>any damages, harm, or claims relating to use of microscopy images.</li>
                </ul>
                <h5 style={{textTransform:'uppercase'}}>TO THE MAXIMUM EXTENT PERMITTED BY LAW, In no event shall Identifyn™ be liable for any direct, indirect, actual, consequential, special, incidental, EXEMPLARY, PUNITIVE, EMOTIONAL DISTRESS, LOSS OF CONSORTIUM, or contingent damages OF ANY KIND, or ANY costs of litigation, LOST PROFITS, LOST INCOME, OR LOST BUSINESS OPPORTUNITY, arising out of any claim of any nature, whether in contract, tort, strict liability, infringement, or otherwise, nor shall Identifyn™ have a duty to defend such claims on YOUR behalf. to the extent any damages are deemed recoverable against Identifyn™, IN NO EVENT WILL Identifyn’s™ TOTAL CUMULATIVE LIABILITY TO YOU OR ANY THIRD PARTY, ARISING FROM ANY CAUSES OF ACTION OR THEORY OF LIABILITY, EXCEED AMOUNTS PAID BY YOU TO Identifyn™.</h5>
                <p>Product information is subject to change. Before ordering, please review the product specifications as published on the Platform or call and request product specifications from Identifyn™. Product information on the Website reflects, in general, the applications and conditions for use. Products should be used in accordance with the documentation shipped with the Product. Identifyn™ reserves the right to change Product specifications without prior notification.</p>
                <p>Services are priced and provided on a time-and-materials basis, and Identifyn™ agrees only that the contracted tasks and experiments will be carried out in accordance with prevailing industry practices and any acceptance criteria, if expressly set forth in the relevant quotation or statement of work or purchase order document signed by Identifyn™. Services are experimental in nature, and, therefore, no warranties regarding services are made, express or implied. Specifically, no particular timeline, outcome, results or performance of deliverable(s) or work product is promised or guaranteed.</p>
                <h4>Identifyn™ Products and Services</h4>
                <p>Identifyn™ provides customers with the ability to purchase primary antibodies, secondary antibodies, direct conjugates, glass slides, cover slips, and cell chambers, buffers, and kits for life science internal research use only, biological collection, drug development, and predictive medicine research, and reagents for internal research use only (collectively, the “<span>Products</span>”).</p>
                <p>Identifyn™ makes third party products available for purchase. Such products may be subject to restrictions or license conditions imposed by the manufacturer. You are responsible for complying with such restrictions or conditions.</p>
                <p>Identifyn™ also makes available to customers microscopy imaging services for use in the study and analysis of biological tissue samples, biopsy samples, tissue cultures, cell cultures, antibodies, antigens, and internal cellular components, as well as validation services, and in vitro diagnostic services (collectively, the “<span>Services</span>”). Customers will be able to view microscopy images generated as part of the Services (“<span>Images</span>”) through the Identifyn™ Platform and through HIVE, a third-party provider.</p>
                <h4>Limited Use License and Use Restrictions</h4>
                <p>Products are sold, transferred and licensed for use by you for in vitro activities, animal studies in laboratory settings and INTERNAL RESEARCH USE ONLY. No other right or license is granted to you, explicitly, by implication, by estoppel or otherwise. Products are not submitted for regulatory review, validated for diagnostic, prophylactic, therapeutic, or clinical use, safety and effectiveness, or any another other specific use or application unless expressly stated. You are solely responsible for proper selection, application, processing and use of Products (including incorporation of Products into other product or modification of Products), and for adopting safety precautions as may be necessary. You are solely responsible for complying with, and shall handle and use Products and the results of using Products in conformity with (1) good laboratory practice, (2) all applicable laws, regulations and governmental policies, and (3) any necessary approvals, permissions, authorizations and/or licenses as may be required for your research and other intended uses, including any rights to use intellectual property rights of a third party.</p>
                <p>As a material condition to Identifyn™ providing its Products to you, you shall not, directly or indirectly, use the Products or its components (1) to modify Products or its components except for non-commercial in vitro INTERNAL RESEARCH USE ONLY (including animal studies in laboratory settings); (2) to provide a commercial service, or to provide commercially relevant information or data to a third party; (3) for diagnostic, prophylactic or therapeutic purposes; (4) for use on animals or humans for clinical diagnostic or drug purposes; (5) to attempt to reverse engineer, disassemble, or otherwise perform any compositional, structural, functional or other analyses directed to learning the methodology, components, formulae, sequence, processes, make-up, or production of any Products or any portion thereof; (6) for manufacturing; (7) for resale or transfer in any form to a third party; or (8) for any other commercial purpose.</p>
                <h4>Payments</h4>
                <p>You may purchase Products and Services either by purchase order or through the Platform. We will invoice you for the Product price and/or Service price and all other charges due (i) when we ship you the Products, or (ii) upon the completion of the Services. Unless we have agreed otherwise in writing, you will pay us within 30 days from your receipt of invoice. Interest will accrue on such overdue amount at the lesser of (a) eighteen percent (18%) per annum, or (b) the maximum rate permitted by law. Identifyn™ may rely on third-party financial service providers and technology to process payments.</p>
                <p>Identifyn’s™ Products and/or Services prices do not include any taxes (including VAT), surcharges, duties, levies or other government fees that may apply to your order. If they apply, it will be your responsibility to pay them. If we pay them, we will add them to your invoice.</p>
                <h5 style={{textTransform: 'uppercase'}}>YOU acknowledge and agree that Identifyn™ is not liable for any damages, harm, or claim resulting from the actions of a third party financial service provider, including any loss of data or funds.</h5>
                <h5 style={{textTransform: 'uppercase'}}>Payments processed by a financial service provider may be subject to fees charges by such financial service provider as well as the financial service provider’s own terms and conditions separate from THESE TERMS.</h5>
                <p>Identifyn™ establishes the price for Products and Services. Identifyn™ reserves the right to establish, remove and/or revise the price for any or all Products of Services at any time in Identifyn’s™ sole discretion. Identifyn™ further reserves the right, in its sole discretion, to not process or to cancel any orders.</p>
                <p>Payment will be processed using the preferred payment method(s) designated in each user’s account or designated at the time of purchase. You consent to receiving electronic receipts for amounts paid.</p>
                <p>Once you have placed your order for Products or Services, you cannot cancel or change it without Identifyn’s™ prior, written consent. In the event that we provide such prior written consent, we may impose cancellation and/or change fees.</p>
                <h4>Delivery of Products and Freight Policy</h4>
                <p>Identifyn™ shall deliver to you the Products to the destination you specify in your order by a mutually agreed upon date. You acknowledge and accept that you shall be responsible for all cost and expenses associated with the transportation of Products supplied by Identifyn™ to you. Furthermore, you acknowledge and accept that you shall be responsible for all cost and expenses associated with the transportation of biological tissue samples, biopsy samples, tissue cultures, cell cultures, antibodies, antigens, and internal cellular components (collectively, the “<span>Samples</span>”) to Identifyn™. You (a) give your consent for us to arrange for carriage and packaging for all Products supplied hereunder on your behalf; and (b) waive your right to arrange carriage or to give us any specific instructions regarding carriage. We may, in our discretion, make partial shipments and invoice each shipment separately. Our shipping or performance dates are approximate only. Regardless of anything stated in our pre-printed quotation, confirmation or invoice documentation we may ship on, prior to, or after the confirmed estimated date of shipment.</p>
                <p>All Products shall be shipped to you EXW (Identifyn™ Facility) INCOTERMS 2020, unless otherwise agreed in a writing signed by Identifyn™. All risk of loss, damage, or destruction of the Products shall pass to you on delivery of Products by Identifyn™ to the carrier at Identifyn’s™ facility. Identifyn™ will select the mode of shipment and the carrier. We will not be liable for any loss or damages resulting from any delay in delivery or failure to deliver. You may not refuse delivery or otherwise be relieved of any obligations as the result of such delay. We will not be liable for any loss or damages resulting from your refusal of delivery.</p>
                <p>You shall comply with all laws, regulations and requirements that apply to shipping biological materials.</p>
                <p>Domestic freight and/or handling is charged according to the total dollar value of your order and the shipping conditions required to safely transport the Products to your order destination. Additional shipping condition fees may apply, but are not limited to:</p>
                <ul style={{listStyle:'auto', textTransform:'uppercase', fontWeight:'700'}}>
                    <li>Products shipping on dry/wet/gel ice.</li>
                    <li>Products classified as hazardous materials that require special handling.</li>
                    <li>Unusually heavy products.</li>
                    <li>Expedited delivery.</li>
                </ul>
                <p>Shipping conditions and recommended storage conditions may not be identical.</p>
                <p>If you fail to take delivery of the Products pursuant to your order, we will ship the raw materials and/or finished Products to you and invoice you for them.</p>
                <h4>Product Handling and Storage</h4>
                <p>Certain Products may be required to be handled or stored under specific conditions, such as being kept within a specified temperature range. Identifyn™ is not responsible for your non-compliance with handling or storage requirements, regardless of whether such requirements were specified by Identifyn™. Handling and storage of Products is at your own risk and expense.</p>
                <h4>Performance of Services</h4>
                <p>Your submission of a purchase order or other similar document to indicate payment for the Services (“<span>PO</span>”) shall indicate acceptance of the Terms of Sale to the exclusion of any other terms or conditions appearing or referenced in such PO, which are hereby deemed to be material alterations and notice of objection to which is hereby given, notwithstanding anything contained to the contrary in a PO. Any additional terms or conditions stated in any PO (including but not limited to the “reverse side” or boilerplate terms of any purchase order), invoices or other directions or instructions shall not be binding upon the parties unless separately agreed to by Identifyn™ in writing. In the event of any inconsistency or conflict between these Terms and Conditions of Sale and any other document or agreement, these Terms of Sale shall control.</p>
                <p>Following the submission of the PO indicating acceptance of the Terms of Sale, we may schedule a consultation with you. Following the consultation, we will perform the Services in accordance with the consultation. We will provide a summary report along with any agreed work product, microscopy Images, or experimental data (“<span>Deliverables</span>”). Upon providing the Deliverables and summary report, the Services shall be deemed completed, and you will receive an invoice for services rendered.</p>
                <p>We will perform the Services as an independent contractor, using proprietary data, methods, materials, equipment, and/or related intellectual property owned, licensed or controlled by us or our affiliates to provide you with Deliverables and a summary report as a direct result of the Services. We will use commercially reasonable efforts to timely start and complete the Services. Identifyn™ does not guarantee that Deliverables will be produced in accordance with United States Food and Drug Administration (“<span>FDA</span>”) good manufacturing practices or good laboratory practices or in accordance with any other similar laws or regulations in other jurisdictions.</p>
                <p>All Deliverables are for reference only and are not meant to be used to make any scientific inferences. Furthermore, we cannot guarantee the quality of the Deliverables. The quality of Deliverables, including imaging and work product, may be impacted by the quality of the biological materials sent to Identifyn™ and the adherence to any storage, shipping, packaging, sample preparation, preservation instructions, methods, and experimental design, procedures or other information provided by you. Furthermore, Identifyn™ will not independently verify experimental procedures provided by a customer.</p>
                <p>During and/or following the performance of the Services, Identifyn™ may make the Images remotely available through the HIVE platform. You will receive a link to access the platform and any Images associated with the Service.</p>
                <p>You shall hold all ownership rights, title and interest in and to experimental data, and Identifyn™ agrees to assign, and hereby does assign, all right title and interest in and to the experimental data following completion of the Services.</p>
                <p>Identifyn™ shall hold and maintain all right, title, and interest in and to the Images, including any copyrights to the Images, along with any methods, techniques, processes, know-how, and experimental designs used or maintained by Identifyn™ outside the scope of these Terms of Sale.</p>
                <h4>Material Transfer</h4>
                <p>With respect to the Products to be delivered, you agree to receive and Identifyn™ agrees to transfer to you, antibodies, secondary antibodies, direct conjugates, or other biological materials (“<span>Research Materials</span>”) to enable you to perform certain testing or studies of <span>bona fide</span> scientific and/or educational merit.</p>
                <p>Identifyn™ agrees to permit you to use the Research Materials or Products solely for the purposes of internal scientific research, and you agree not to transfer the Research Materials or Products to any entity or person who is not employed at your facilities without the prior written consent of Identifyn™.</p>
                <p>The original Research Materials, any biological Products, and any progeny, unmodified derivatives, any part of the foregoing incorporated in modifications and in other substances created by you through use of the Research Materials, and any information related thereto (e.g., nucleic acid sequences, amino acid sequences, protein structure information, etc.) are and shall remain the property of Identifyn™ along with all intellectual property rights in the foregoing. You shall maintain ownership rights in all experimental data and results created by you through the use of the Research Materials. Each party shall retain all right, title and interest in any patent, patent application, trade secret, know-how and other intellectual property that was owned by such party prior to the Effective Date of these Terms of Sale. No other right or license to the Research Materials is granted or implied as a result of the transfer of the Research Materials to you.</p>
                <p>Any Research Materials delivered pursuant to these Terms of Sale are understood to be experimental in nature and may have hazardous properties. Identifyn™ MAKES NO REPRESENTATIONS AND EXTENDS NO WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. THERE ARE NO EXPRESS OR IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, NOR DOES Identifyn™ WARRANT THAT THE USE OF THE RESEARCH MATERIALS WILL NOT INFRINGE ANY PATENT, COPYRIGHT, TRADEMARK, OR OTHER INTELLECTUAL PROPERTY RIGHTS.</p>
                <h4>Publication</h4>
                <p>Any manuscripts or publications prepared by you and relating to the Research Materials shall be provided to Identifyn™ at least thirty (30) days prior to submission for publication for the purpose of protecting the intellectual property or confidential information of Identifyn™ that may be disclosed in such publication or presentation. You further agree that Identifyn™ and your investigator will be the collaborator in any publication in which there is a significant intellectual contribution by Identifyn™. You agree that you will provide appropriate acknowledgment to Identifyn™ as the source of the Research Materials and any images in all publications.</p>
                <p><span style={{textTransform:'uppercase'}}>MICROSCOPY IMAGES PROVIDED BY Identifyn™ SHALL, UNDER NO CIRCUMSTANCES, BE INCLUDED IN ANY PUBLICATION WITHOUT Identifyn’s™ EXPRESS, WRITTEN PRIOR AUTHORIZATION</span>. Any Images that are authorized for publication shall bear a conspicuous copyright notice either on the image itself or proximal to the image, which attribute copyrights to Identifyn™, such as “Copyrights Identifyn™ 2023, All Rights Reserved” or “© Identifyn™ 2023—All Rights Reserved.”</p>
                <p>You expressly agree that your use of Research Materials shall be in compliance with all applicable federal, state and local laws and regulations, including those relating to ethical treatment of animals. No animals receiving the Research Materials in the research project (nor animal products derived there from) will be used for food purposes. The Research Materials will not be used in human subjects, in clinical trials, or for diagnostic purposes involving human subjects without the written consent of Identifyn™.</p>
                <p>Rights under this section may not be assigned or otherwise transferred to any third party without the prior written consent of Identifyn™.</p>
                <h4>Product Returns</h4>
                <p>Identifyn™ must pre-authorize all Product returns. Any customized Products are not eligible for return. Identifyn™ will, at its discretion, approve returns or credit of any Product that is damaged, defective, or non-confirming to the specifications on receipt, provided you contact Identifyn™ in writing within five (5) days after receiving the Product, and provided such damage or defect has not been caused by any failure by you or the carrier to handle or store the Product. No returns are accepted and no credit will be issued for any Product returned without prior written authorization from Identifyn™. If you do not contact us within this 5-day period, we will deem the Product accepted. You may be required to provide a sample of any Product that you claim is damaged, defective or non-conforming to the specifications for us to consider authorizing a Product return. If an error by Identifyn™ results in shipment of an incorrect order, Identifyn™ will, at its option, either ship a replacement order at no charge or credit the customer’s account for the original product shipped in error. Any returns or refunds are expressly conditioned on customer cooperating with Identifyn™ by providing requested information or, if requested, a sample of any Product claimed to be damaged, defective, or non-conforming.</p>
                <p>Product credit may not be used if you have a net balance due on your account.</p>
                <p>If Identifyn™ pre-authorizes a Product for return, then the Product must arrive at our facilities in a condition satisfactory for resale and in compliance with any return instructions we provide. We do not credit shipping charges.</p>
                <h4>Non-Conforming Services</h4>
                <p>For Services not meeting our warranty(ies) as stated in these Terms of Sale, we agree, in our sole discretion, to re-perform that portion of the Services so that they conform with our quotation or initial consultation, but you must first promptly notify us in writing within 5 days of when you discover any defect or nonconformance, and include in the notice (a) a description of which tasks were not performed properly by Identifyn™, i.e. other than as agreed in the quotation, and (b) evidence of the non-conformity. In no event will Identifyn™ be responsible for replacing any customer materials. This shall be your sole and exclusive remedy.</p>
                <h4>Registration</h4>
                <p>In order to purchase Products or Services, you must register for an online account. In connection with registering for an account, we will collect (i) your first and last name; (ii) organization name (i.e., your company, employer, university, or other institution); (iii) mailing address, city, state, zip code; (iv) telephone number; (v) email address; (vi) end user name and address, if applicable; and (vii) a username and password for the account holder (“<span>Registration Information</span>”).</p>
                <p>By registering and creating an account, you agree that all information provided in the Registration Information and in connection with using the Platform is true and accurate and that you will maintain and update this information as required to keep it current, complete, and accurate.</p>
                <p>You also grant Identifyn™ the right to disclose to third parties certain Registration Information about you in connection with Identifyn’s™ facilitation of the services through the Platform. The information obtained through your participation in the Platform, including your Registration Information, is subject to our Privacy Policy, which is specifically incorporated by reference into these Terms of Sale and available through Identifyn’s™ website. Furthermore, Identifyn™ may share data with Thermo Fisher. Customer information shared with Thermo Fisher may include the names of specific organizations, sales volume numbers, shipping location and end user information.</p>
                <p>You are solely responsible for maintaining the confidentiality of your password and account and for any and all statements made and acts or omissions that occur through the use of your password and account, including any mail sent and any charges incurred. Therefore, you must take steps to ensure that others do not gain access to your password and account. You may not transfer or share your account with anyone, and Identifyn™ reserves the right to immediately terminate your account in the event of any unauthorized transfer or sharing thereof.</p>
                <h4>Confidentiality</h4>
                <p>“Confidential Information” means (i) the Identifyn™ Mobile Software Application; (ii) any documentation relating to the Mobile Software Application, Products, and Services; (iii) any of Identifyn’s™ business information; and (iv) Identifyn™ testing, diagnostic, and experimental procedures, techniques, methods, know-how, Product and Service specifications and all related writings, drawings, designs and similar works, or any other information which are disclosed by Identifyn™ to you whether orally or in writing, or to which you are exposed in any form. Confidential Information shall not include information which: (i) was in the public domain at the time it was disclosed by Identifyn™ to you, other than as a result of your (or any Representative’s) violation of any confidentiality obligation to Identifyn™; or (ii) enters the public domain through sources independent of you and through no breach of this provision by you or any of your Representatives.</p>
                <p>All Confidential Information is the exclusive property of Identifyn™, and Identifyn™ retains all of its rights, title and interests. You agree to use Confidential Information only to the extent necessary to perform under these Terms of Sale. You shall not disclose or provide any Confidential Information to any third party and shall take all necessary measures to prevent any such disclosure or any unauthorized use by its employees, agents, contractors or consultants (collectively, “<span>Representatives</span>”). Upon request by Identifyn™, you shall return or destroy all Confidential Information provided by Identifyn™.</p>
                <p>All provisions relating to confidentiality shall survive the termination of a PO or this Agreement.</p>
                <h4>Intellectual Property</h4>
                <p>You acknowledge that all intellectual property rights (patent, trademark, copyright, trade secret or otherwise) relating to the Product, Services, and Images, as between you and Identifyn™, are solely and exclusively owned by Identifyn™. Identifyn’s™ sale of Products and Services or providing microcopy Images to you grants to you a limited non-transferable right (i) to use, as authorized by these Terms of Sale, the Images and quantity of Products purchased pursuant to these Terms of Sale, and (ii) to use the applicable technical data sheet and content contained therein (e.g., protocols, validation data and images) for your authorized use of the Products. Except as expressly permitted by these Terms of Sale, the sale or transfer of Products to you does not grant you any other license rights to Identifyn’s™ intellectual property, including, without limitation, no right to make or have made any Product or any portion thereof, and no right to reproduce, display, redistribute copies, create derivative works or otherwise use the technical data sheets and content thereof. Any use of Products for diagnostic, prophylactic or therapeutic purposes, or any purchase of Products for resale (alone or as a component) or other commercial purpose, is prohibited.</p>
                <h4>Export Controls</h4>
                <p>You acknowledge that each Product and any related software and technology, including technical information we supply you, including those contained in any documentation (collectively “<span>Items</span>”), is subject to U.S., EU and local government export controls.</p>
                <p>The export controls may include, among others, those of the Export Administration Regulations of the U.S. Department of Commerce (the “<span>EAR</span>”), which may restrict or require licenses for the export of Items from the U.S. and their re-export to or from other countries.</p>
                <p>No Products and Services may be exported (a) into (or to a resident of) any country which the United States has embargoed goods; or (b) anyone on the United States Treasury Department’s list of Specially Designated Nationals or the United States Commerce Department’s Table of Deny Orders. By Purchasing Products or Services, you represent and warrant that you are not located in, under the control of, or a national or resident of any such country or on any such list.</p>
                <p>You must comply with the EAR and all other applicable laws, regulations, laws, treaties, and agreements relating to the export, re-export, and import of any Item. You must not, directly or indirectly, without first obtaining the required license to do so from the appropriate U.S. government agency, export, re-export, distribute or supply any Item to (a) any restricted or embargoed country or to a person or entity whose privilege to participate in exports has been denied or restricted by the U.S. government; or (b) any person or entity who is involved in improper development or use of nuclear weapons or of chemical/biological weapons, or missiles, or in terrorist activities. You will, if we request, provide information on the end user and end use of any Item you export or plan to export, as well as written assurance(s) of compliance with EAR in the form we specify prior to shipment/delivery of Items and/or upon request.</p>
                <p>You will cooperate fully with us in any official or unofficial audit or inspection related to applicable export or import control laws or regulations, and will indemnify and hold us harmless from, or in connection with, your or your consultants’, agents’ or employees’ violation of this section.</p>
                <h4>Indemnification</h4>
                <p>You assume full responsibility for any use of any Products or Services purchased from Identifyn™. You understand and agree that Identifyn™ will not be responsible or liable for any claim, loss or damage arising from the use of any such Products and Services.</p>
                <p>You agree to defend, indemnify, and hold harmless Identifyn™ and its affiliates harmless from all liabilities, , losses, damages, and expenses, including attorneys’ fees and costs, arising out of, related to, or in connection with any of the following, whether related to a third-party claim or a first-party claim between you and Identifyn™: (a) any use of the Products and Services in violation of these Terms of Sale; (b) fraud you commit or your intentional misconduct or gross negligence; (c) your violation of any applicable law or rights of a third-party; or (d) your use or misuse of the Products and Services or Deliverables and work product resulting from the Services. Identifyn™ reserves the right, at its own expense, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will cooperate with Identifyn™ in asserting any available defenses.</p>
                <h4>Legal Matters for Disputes Not Subject to Arbitration</h4>
                <p>With respect to any dispute not covered by the Arbitration provisions of these Terms of Sale, in the event any litigation commences between the parties, exclusive, mandatory jurisdiction and venue shall properly lie only in the state and federal courts of Hillsborough County, Florida. The validity, construction, enforcement, and interpretation of these Terms of Sale are governed by the laws of the State of Florida.</p>
                <h4>Class Action Waiver</h4>
                <h5 style={{ textTransform:'uppercase' }}>You agree that any disputes shall be asserted in your individual capacity and not as a plaintiff or class member in any purported class, representative proceeding or as an association. In addition, we each agree that any disputes shall be litigated only on an individual basis and not in a class, consolidated, or representative action and that a court or arbitrator(s) may award relief (including injunctive relief) only on an individual basis.</h5>
                <h4>Jury Trial Waiver</h4>
                <h5>YOU HEREBY ACKNOWLEDGE AND AGREE TO WAIVE ANY CONSTITUTIONAL AND STATUTORY RIGHT YOU MAY HAVE UNDER ANY APPLICABLE LAW TO MAINTAIN A LAWSUIT IN A COURT AND BEFORE A JUDGE AND JURY. Identifyn™ ALSO WAIVES THIS RIGHT.</h5>
                <h4>Limitation on Time to File Claims</h4>
                <h5>TO THE EXTENT PERMITTED BY EXISTING LAW, ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF THESE TERMS OF SALE OR THE PROVISION OF PRODUCTS AND SERVICES, WHETHER OR NOT SUCH SERVICES WERE PROVIDED THROUGH THE PLATFORM, MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION GIVING RISE TO THE CLAIM ACCRUES OR OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM SHALL BE PERMANENTLY AND FOREVER BARRED.</h5>
                <h4>Arbitration</h4>
                <p>Any controversy or claim between the parties shall be subject to binding arbitration administered by the JAMS arbitration and mediation organization in accordance with its Comprehensive Rules. The arbitration proceeding shall be presided over by a single arbitrator who shall have exclusive jurisdiction to determine matters of arbitrability of any claims presented. The mandatory, exclusive location for the arbitration shall be Hillsborough County, Florida. The arbitrator will have the exclusive authority to make determination as to the applicability, scope and enforceability of these terms and to resolve disputes in connection with these Terms of Sale. The federal rules of evidence shall apply to arbitration proceedings between the parties, and the arbitration shall be governed by the laws of the State of Florida. The arbitrator’s decision shall be final, binding, and non-appealable, and judgment on the decision shall be entered thereon only in the U.S. District Court for the Middle District of Florida. The prevailing party in an arbitration proceeding shall be entitled to an award of all attorneys’ fees and any and all costs and expenses incurred in any way relating to the resolution of any dispute between the parties, including fees incurred in determining entitlement to and the amount of attorneys’ fees and costs.</p>
                <p>Unless otherwise agreed between the parties, arbitration or state or federal court litigation on an individual basis shall be the exclusive remedy for any claim that might otherwise be brought on a class, collective, or representative action basis. The parties must bring any claim on an individual basis, such that any arbitration shall consider only the claim or claims asserted by a single plaintiff individually and solely on his or her own behalf. You waive any right to submit a class, collective, or representative action for arbitration under these Terms of Sale, and you shall not request, nor shall the arbitrator allow, the joinder of any other claimant in an arbitration, except upon express written consent signed Identifyn™.</p>
                <h4>Severability</h4>
                <p>If any part of these Terms of Sale is held invalid or unenforceable, that portion shall be construed in a manner consistent with applicable law to reflect, as nearly as possible, the original intentions of the parties, and the remaining portions shall remain in full force and effect.</p>
                <h4>Waiver</h4>
                <p>Any failure by Identifyn™ to enforce or exercise any provision of these Terms of Sale or related rights shall not constitute a waiver of that right or provision.</p>
                <h4>Miscellaneous</h4>
                <p>You may not assign your rights or obligations under these Terms of Sale to any third party, and any purported attempt to do so shall be null and void. Identifyn™ may freely assign its rights and obligations under these Terms of Sale.</p>
                <p>You agree not to sell, resell, reproduce, duplicate, copy, or use for any commercial purposes any portion of the Platform.</p>
                <p>These Terms of Sale shall not be construed more strictly against any party regardless of who is responsible for their drafting. Unless the context of these Terms of Sale otherwise clearly requires, references to the plural include the singular and the singular include the plural.</p>
                <p>The captions of these Terms of Sale are for convenience and ease of reference only and in no way define, describe, extend, or limit the scope or intent of these Terms of Sale.</p>
                <h4>Entire Agreement</h4>
                <p>These Terms of Sale, together with any invoice issued by Identifyn™, the Privacy Policy, and the Platform Terms and Conditions of Use, constitute the entire agreement between the parties relating to the subject matter hereof. All prior understandings and agreements between the parties relating to the subject matter hereof are merged into these Terms of Sale, which alone and completely express their understanding. The Terms of Sale may not be altered, amended, or changed except by written instrument signed on behalf of each of the parties.</p>
            </Content>
            <Footer />
        </Layout>
    )
}

export default Index