import styled from "styled-components";

export const Wrapper = styled.div`
width: 100%;
height: 100vh;

p{
        font-size: 8px;
        font-weight: 400;
        color: #fff;
    }

.header{
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 6rem;
    background-color: var(--primary-color);

    img{
        width: 80px;
        height: 16px;
    }


}


.content{
    padding: 20px 6rem 6rem;

    @media screen and (max-width: 1100px){
        padding: 1.2rem;
    }

    @media screen and (min-width: 2160px){
        padding-left:25rem;
        padding-right: 25rem;
    }

    h5{
color: #1e1e1e;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 16px; /* 114.286% */
text-transform: uppercase;
margin-bottom: 12px;
    }


 .gray{
    color:#6c6a73;
    font-size: 9px;
    font-weight: 400;
 }

.dark{
    color: #1e1e1e;
    font-size: 9px;
    font-weight: 400;
}


.two-column{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}



.product-title-container{
border-radius: 6px;
background: rgba(237, 237, 237, 0.40);
display: flex;
padding: 8px 12px;
flex-direction: column;
align-items: flex-start;
gap: 6px;
align-self: stretch;
margin-bottom: 24px;

}


.gray-card{
padding:12px 12px;
border-radius: 6px;
background: rgba(237, 237, 237, 0.40);

}

.white-card{
    padding:12px 12px;
    border-radius: 6px;
background: rgba(237, 237, 237, 0.00);
}

.description-card{
border-radius: 6px;
background: rgba(237, 237, 237, 0.40);
padding: 12px 12px;
color: #1e1e1e;
font-size: 9px;
font-style: normal;
font-weight: 400;
line-height: 14px;
height: max-content;
}


}

.footer{
    margin-top: auto;
    width: 100%;
    height: max-content;
    border-bottom: 3px solid var(--primary-color);
    padding-left:6rem;
    padding-right: 6rem;

    @media screen and (max-width: 1100px){
        padding: 1.2rem;
    }

    @media screen and (min-width: 2160px){
        padding-left:25rem;
        padding-right: 25rem;
    }

    .two-column{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 8px;
}

}


`