import React, { useContext, useState } from 'react'
import "./style.scss"
import { AuthContext } from "../../../context/auth";
import Input from "../../Input Old";
import { resetPasswordApi } from '../../../api';

const Index = () => {
  const { setRestoreModal, setChangePasswordRestoreModal, setIsLoading, setNotification } = useContext(AuthContext)
  const [type, setType] = useState('restore')
  const [email, setEmail] = useState('')


  const resetPassword = () => {
    setIsLoading(true)
    if (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)) {
    resetPasswordApi({email:email}).then((res) => {
      setIsLoading(false)
      setType('check-email')
    }).catch((err) => {
      setIsLoading(false)
      setNotification({
        type: 'red',
        message: 'Something went wrong, please try again!'
      })
    })
  }else{
    setIsLoading(false)
    setNotification({
      type: 'red',
      message: 'Please enter a valid Email Address'
    })
  }
  }

  const resend = () => {
    setIsLoading(true)
    if (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)) {
    resetPasswordApi({email:email}).then((res) => {
      setIsLoading(false)
    }).catch((err) => {
      setIsLoading(false)
      setNotification({
        type: 'red',
        message: 'Something went wrong, please try again!'
      })
    })
  }else{
    setIsLoading(false)
    setNotification({
      type: 'red',
      message: 'Please enter a valid Email Address'
    })
  }
  }



  return (
    <div className="small-modal-wrapper">
      <div className="small-modal-content" style={{padding:'20px'}}>
      <div style={{ position: 'relative', justifyContent: 'center', display: 'flex', width: '100%' }}>
        <span style={{ width: '44px', height: '44px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '12px', background: '#fff2e5', color: 'var(--primary-color)' }}>
          <i class="fal fa-envelope"></i>
        </span>
        <span className='close' style={{color:'#9a9a9a', top:0, right:0}} onClick={() => setRestoreModal(false)}><i className="fal fa-times"></i></span>
        </div>
        {type === 'restore' ? <>
          <h2>Restore Password </h2>
          <p>Don’t worry, we’ll send you a restore link
            to your email address </p>
          <div style={{ position: 'relative', width: '100%', display: 'flex', flexDirection: 'column', gap: '12px', marginBottom: '20px', marginTop: '20px' }}>
            <h4>Enter your email address below</h4>
            <Input placeholder="Enter Email Address" value={email} onChange={(e) => setEmail(e.target.value)} style={{textAlign:'center'}} />
          </div>
          <button className='btn' disabled={!email} onClick={() => {
            resetPassword()
            // setType('check-email')
          }}>Continue</button>
          <button className='btn transparent' onClick={() => {
            setRestoreModal(false)
          }}>Cancel</button>
        </>
          : type === 'check-email' &&
          <>
            <h2>Check Email </h2>
            <p>We sent a link to reset your password to <span style={{fontWeight:500}}>{email}</span>.</p>
            <div style={{ position: 'relative', width: '100%', display: 'flex', flexDirection: 'column', gap: '12px', marginBottom: '20px', marginTop: '20px' }}>
              <p>Didn't get it? <span style={{ color: 'var(--primary-color)', cursor: 'pointer' }}
              onClick={() => {
                resend()
              }}
              >Resend</span></p>
            </div>
            <button className='btn' onClick={() => {
              setRestoreModal(false)
              setType('resetore')
              // setChangePasswordRestoreModal(true)
            }}>Okay</button>
          </>
        }
      </div>
    </div>
  )
}

export default Index