import styled from "styled-components";


export const PathwayWrapper = styled.div`
width: 100%;

.title-container{
    height: calc(100% - 150px);
    padding-left: 6rem;
    padding-right: 6rem;
    justify-content: start;
    @media screen and (min-width:2160px) {
        padding-left: 25rem !important;
        padding-right: 25rem !important;
        height: 100vh;
    }

    @media screen and (max-width:1100px) {
        padding-left: 3rem;
        padding-right: 3rem;
    }
}

h2{
   font-size:20px;
   font-weight: 700;
   line-height: 28px;
   text-align: left;

   span{
    color:var(--primary-color)
   }
}

h6{
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color:#1e1e1e;
}

.pathways{
    display: flex;
    flex-wrap: wrap;
    gap:32px;
    justify-content: center;
    margin-top: 32px;
    width: 100%;
    /* height: calc(100vh - 100px); */

     
.pathway{
position:relative;
border-radius: 20px;
background-size: 30%;
background-repeat: no-repeat;
background-position: center;
background: #FFF;
box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.08);
width: 384px;
height: 327px;

&:nth-child(4), &:nth-child(5){
    width: 592px;
}

@media screen and (max-width:1376px) {
    width: 280px
}


@media screen and (max-width:900px) {
    width: 100%;
}




.coming-soon{
position: absolute;
top: 20px;
right: 24px;
display: flex;
padding: 4px 8px;
justify-content: center;
align-items: center;
border-radius: 8px;
background: linear-gradient(0deg, rgba(255, 141, 36, 0.12) 0%, rgba(255, 141, 36, 0.12) 100%), #FFF;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 24px;
color: #FF8D24;
}

.text-content{
position: relative;
border-radius: 0px 0px 20px 20px;
background: rgba(91, 91, 91, 0.76);
backdrop-filter: blur(18px);
width: 100%;
height: 60px;
text-align: center;
padding: 16px;


h4{
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 28px; 
color: #fcfcfc;
}

p{
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px;
color: #fcfcfc;
}

}

.img-container{
    height: 267px;
    width: 100%;
    background: #fcfcfc;
    background-size: 90%;
    background-repeat: no-repeat;
    background-position: center 35%;
    border-radius: 20px 20px 0px 0px;
    

    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        background-repeat: no-repeat;
        border-radius: 20px 20px 0px 0px;
    }
}


}

.pathway2{
    width: 592px;


@media screen and (max-width:1376px) {
    width: 420px
}
@media screen and (max-width:900px) {
    width: 100%;
}

}  

}

`