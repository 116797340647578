import styled from "styled-components";


export const Wrapper = styled.div`
margin: 0px 0px 0 44px;
overflow-y: auto;
height: 100%;
border-radius: 20px;
background: #fff;
box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.05);
padding: 20px 20px;
display: flex;
flex-direction: column;
gap: 20px;

@media screen and (max-width: 1100px) {
    margin: 0;
}

.header{
    h4{
        font-size: 20px;
        line-height: 28px;
        font-weight: 700;
    }
.header-btn-container{
    display: flex;
    align-items: center;
    gap: 20px;
}


}

.address-container{
    display: flex;
    flex-direction: column;
    gap: 16px;

.address-card{
border-radius: 12px;
border: 1px solid #F1EEFC;
background:#FCFCFC;
padding: 16px;
display: flex;
justify-content: space-between;
gap: 10px;

.left-container{
    display: flex;
    flex-direction: column;
    gap: 8px;
    h5,p{
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        color: #6c6a73;
    }

h5{
    font-weight: 500;
    color:#1E1E1E;
    display: flex;
    align-items: center;
    gap: 8px;
    span{
padding: 4px 8px;
display: flex;
align-items: center;
justify-content: center;
border-radius: 8px;
background: linear-gradient(0deg, rgba(63, 27, 208, 0.12) 0%, rgba(63, 27, 208, 0.12) 100%), #FFF;
color: #3f1bd0;
    }
}

}

.right-container{
    align-self: flex-start;

    .transparent{
        padding:0;
    }
}

}


}


`