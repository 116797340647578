import React from 'react'
import Layout from '../../../components/layout'
import { AboutHeader, Content } from './style'
import {Footer} from '../../../components/Footer'

const Index = () => {
    return (
        <Layout>
            <AboutHeader>
                <h1>Privacy <span>Policy</span></h1>
            </AboutHeader>
            <Content>
                <p style={{ color: '#6c6a73' }}>Last Modified Date: December 6, 2023</p>
                <p>This Privacy Policy (“<span>Privacy Policy</span>”) addresses how Identifyn™ collects, uses, and discloses the information it collects through the <a href='www.Identifyn.com'>www.Identifyn.com</a> (“<span>Website</span>”) and the Identifyn™ mobile application (“<span>Mobile Application</span>”). Both the Website and Mobile Application are referenced collectively as the “<span>Platform</span>.” References in this Privacy Policy to “<span>we</span>,” “<span>our</span>,” or “<span>us</span>” means Identifyn™. When we refer to “<span>you</span>,” “<span>your</span>,” or “<span>user</span>,” we mean the individual user of the Mobile Application or visitor to the Website.</p>
                <p>Please be advised that your use of our technology platform and the services, which are made available through our Mobile Application and Website, are subject to our Terms and Conditions, which incorporates this Privacy Policy and our Cookie Policy.</p>
                <p>We use your data to provide and improve our products and services. By purchasing our products or using our services (collectively “Products”), you agree to the collection and use of information in accordance with this Privacy Policy. Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the same meanings as in our Platform Terms and Conditions available through the Website.</p>
                <h4>What Information Does Identifyn™ Collect?</h4>
                <p>In order to utilize the services available through our Platform, you must register for an online account. In connection with registering for an account, we will collect (i) your first and last name; (ii) organization name (i.e., your company, employer, university, or other institution); (iii) mailing address, city, state, zip code; (iv) telephone number; (v) email address; (vi) end user name and address, if applicable; and (vii) a username and password for the account holder (“<span>Registration Information</span>”). We collect this Registration Information directly from you..</p>
                <p>We collect information related to your purchase history, including what products or services were purchased, the date, the price paid, the name of the person who made the purchase, the institution, and in some cases, the name of a specific individual who will be using the product or service (“<span>Purchasing History</span>”).</p>
                <p>To facilitate payment between the user and Identifyn™, our Platform utilizes a third-party financial service provider, who will collect your first name, last name, payment card information, banking information, billing address, telephone number, email address, and any other pertinent information in connection with the transaction (“<span>Payment Information</span>”).</p>
                <p>Following the completion of a purchase or after rendering a service, we may collect feedback from the user, including a rating, the products or services you purchased, and any comments (“<span>Feedback Information</span>”).</p>
                <p>If you choose to utilize the “Contact Us” functionality, we will collect your first and last name, username, email address, and any other pertinent details you provide us in connection with your inquiry.</p>
                <p>When using our Platform, we employ technological means to collect certain usage and machine information (“<span>Usage Data</span>”). Our Platform utilizes internet cookies, pixel tags, and web beacons, which enable us to automatically collect Usage Information (“<span>Technologies</span>”). Using these Technologies, we will automatically collect Usage Data from your computing device, including your internet protocol address, your geographical location, browser name, browser version, computing device type (e.g., manufacturer or model), language preferences, internet service provider, operating system type, downloads, advertising IDs associated with your device (e.g., Apple’s Identifier for Advertising (IDFA) or Android’s Advertising ID (AAID)), communications received and sent through the Platform, and statistics concerning the time and duration of your use of the Platform.</p>
                <p>When visiting our Website, we may also collect Usage Information that includes web browsing history (i.e., website addresses visited prior to arriving and after leaving our Website), whether you have ever visited the website before, the time and date of your arrival on the website, and the duration of time spent on each web page of the Website.</p>
                <p>We may make interactive tools available through the website, such as forms and graphical user interfaces that permit users to input information about intended Product purchases and uses. We may collect information that users input into such tools as well as the resulting output by the Platform. As an example, a user may input information concerning a primary antibody the user intends to use or purchase as well as a labeling technique the user intends to employ. In response, the Platform may recommend a secondary antibody, fluorophore, or glassware that pairs with the identified primary antibody and labeling technique. The Platform may collect and store both the information input by the user and the resulting Platform recommendations.</p>
                <h4>How Does Identifyn™ Use the Information it Collects?</h4>
                <p>We use the Registration Information, Purchasing History, Usage Data, and Feedback Information for purposes that include:</p>
                <ul>
                    <li>To provide and maintain our Platform and Products.</li>
                    <li>To identify and authenticate your identity.</li>
                    <li>To allow you to participate in interactive features of our Platform.</li>
                    <li>To detect, prevent, troubleshoot, and address technical issues with our Platform and Products.</li>
                    <li>To improve our Products and Platform.</li>
                    <li>To notify you about changes to our Products or Platform.</li>
                    <li>To communicate with you in connection with a purchase or respond to an inquiry.</li>
                    <li>To provide customer support.</li>
                    <li>To monitor usage of our Products and Platform.</li>
                    <li>To detect security incidents, such as potential cyber-attacks.</li>
                    <li>To administer our relationship with your organization.</li>
                    <li>To provide quality assurance and assist in training and development of our representatives.</li>
                    <li>For marketing, internal research, and development of our Products and Platform.</li>
                </ul>
                <p>We may use information you provide to contact you with news, special offers, and promotional materials, unless you have opted not to receive such information.</p>
                <p>The Payment Information collected by our third-party payment card processor is utilized by that third-party service provider to complete the financial transaction and facilitate payment between the user and Identifyn™. This information is also used to verify a person’s identity, facilitate payment, and combat fraud in connection with processing a particular payment method in connection with the Products.</p>
                <p>We use the information we collect through Technologies for purposes of detecting and troubleshooting problems with our Platform. We use internet protocol addresses to identify the regions where visitors to our Platform originate, which helps us to understand where there is interest in our Platform and the services rendered by Identifyn™. We also use internet protocol addresses to identify and respond to malicious, fraudulent, and other harmful actions taken against our Platform. We use information about computing device and internet browser types, operating systems, language preferences, and page visits, in the aggregate, to compile usage statistics and to optimize your experience browsing our Website or using our Mobile Application.</p>
                <p>The Technologies also enable us to remember your preferences, save passwords in restricted areas of the Platform (where an account registration is required), so you don’t have to login to restricted areas each time you use the Platform, and to provide you with media content. We use the aggregated technical and usage information that we collect to tailor the display of our Website or the Mobile Application to the device, browser, and operating system types that are most popular among our visitors and to identify the content and functionality that is most often utilized, which we consider in connection with future updates and improvements to the Platform.</p>
                <h4>Does Identifyn™ Disclose the Information it Collects to Third Parties?</h4>
                <h5>Generally</h5>
                <p>In connection with offering users access to our Platform, we utilize third-party service providers, including web and data hosting, software and mobile application development, marketing services, and financial service providers that process payments. Your information may be provided to such third-party service providers to enable the providers to render services to us.
                    We may share your Purchasing History with Thermo Fisher. Customer information shared with Thermo Fisher may include the names of specific institutions, sales volume numbers, shipping location and end user information.</p>
                <p>We may utilize a third-party website analytics service provider, who collects information directly from you through the use of third-party cookies. This information includes your internet protocol address, any unique identifier, and other usage and technical information.</p>
                <h5>To law enforcement or in response to legal process</h5>
                <p>If required by law or subpoena, warrant, court order, or another legal demand by a court, law enforcement, or government authority, we will disclose your information to the extent necessary to comply with such law or legal process, as determined in our sole discretion. We will also disclose your information if necessary to protect our legal rights or prevent harm to another, as determined at our sole discretion.</p>
                <h5>Sale or acquisition of Identifyn™</h5>
                <p>In the event that Identifyn™ is sold to, or substantially all of its assets are acquired by a third party, the assets will include the information that we have collected and maintain about users and will be disclosed pursuant to any such sale or acquisition to the acquiring entity.</p>
                <h4>What is Identifyn’s™ Policy Regarding Information from Children?</h4>
                <p>You must be at least 18 years of age to access or otherwise use the Platform. If you are not at least 18 years of age, you must immediately terminate your usage of the Platform. We do not knowingly solicit information from or otherwise market to children under the age of 18. If a minor child has provided us with any personal information, the child’s parent or legal guardian should immediately contact us to have us delete such information. If we discover that a user is under 18, we reserve the right to terminate the violating user account.</p>
                <h4>Security of Information</h4>
                <p>We value the security of your information and have implemented commercially reasonable practices to help keep your personal information secure. User accounts are protected by a username and password. Please exercise care to ensure the safety and security of your username and password. Our Terms and Conditions of Use specifically prohibit the sharing of your username and password with another party. Should you contact us via the Mobile Application, Website, or by email, please do not include any sensitive information, such as credit card, medical or other information of a sensitive nature as the security of email and other communications cannot be guaranteed. Please be advised that no mobile application, website or system is impenetrable. As a result, we cannot warrant the security or confidentiality of your information on the Website or Mobile Application.</p>
                <h4>Hyperlinks to Third Party Sites</h4>
                <p>Our Website and Mobile Application contain hyperlinks that if clicked, will redirect you to a website that is controlled, owned, and operated by a third party (each, a “<span>Third Party Site</span>”). Please be advised that we have no control over the content, information, business, or privacy practices of any Third Party Site or service that is owned or operated by a third party. We strongly encourage you to carefully review the privacy policies and terms of service for any Third Party Site or service. Under no circumstances will we be responsible for the content or practices of such Third Party Site.</p>
                <h4>Your Options for Viewing, Updating or Deleting Information</h4>
                <p>If you are a user of the Platform, you have the ability to view, update, or remove your personal information from the Platform within your profile settings. You can delete your account any time.</p>
                <p>Depending upon your internet browser and computer device settings, you may have the option to reject or block internet cookies while browsing our website, but please be advised that doing so will cause some functions on our Website not to work for you.</p>
                <h4>Do Not Track Signals</h4>
                <p>We currently do not share personal data with third parties for direct marketing purposes without your permission. The Platform does not recognize or otherwise respond to Do-Not-Track signals.</p>
                <h4>Modifications</h4>
                <p>We may make changes to this Privacy Policy. We reserve the right to change this Privacy Policy at any time by electronic notice posted on our website or sent to your email address. Your continued use of the Platform after we post a revised Privacy Policy means you have accepted the Privacy Policy.</p>
                <h4>European Union (“EU”) General Data Protection Regulation (“GDPR”) Disclosures.</h4>
                <p>The EU’s GDPR requires that we provide EU data subjects with disclosures regarding our data collection, use, and sharing practices as well as information about EU data rights. References included in this section of the Policy to terms such as “controller,” “data subject,” “personal data,” “processor,” and “supervisory authority” shall have the meanings set forth in Regulation (EU) 2016/679 of the European Parliament and of the European Council, dated 27 April 2016, on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC.</p>
                <h5>Identity of the Controller</h5>
                <p>GMD12, LLC & Identifyn™ LLC<br />
                    2055 Wood Street<br />
                    Suite 206<br />
                    Sarasota, FL 34237</p>
                <h5>Purposes of Processing</h5>
                <ul>
                    <li>The personal data that we collect from you is processed for the purposes set forth in the foregoing sections of this Privacy Policy. To the extent that you register for an account for services and provide us with Registration Information, Usage Data, Payment Information, or Feedback Information, the legal basis for our processing activities, as detailed in this Policy, is both your consent and by contract pursuant to our Platform Terms and Conditions of Use, which are incorporated herein by reference.</li>
                    <li>If you contact us using the Mobile Application or Website and provide inquiry information, the legal basis for our processing is both your consent and our legitimate interest in servicing current and prospective customers.</li>
                    <li>The legal basis for our processing utilizing the Technologies is your consent.</li>
                    <li>If you choose to register for our newsletter or other promotional materials, the legal basis for processing is your consent.</li>
                </ul>
                <h5>Recipients of your Personal Data</h5>
                <p>We provide your personal data to third parties, including service providers, law enforcement (where legally required) and in connection with the sale of our business as more fully described in this Privacy Policy.</p>
                <h5>Data Transfers</h5>
                <p>Identifyn™ is located within the United States of America. When you provide us with personal data, this information is stored and processed within the United States. While we have no intention of transferring your personal data outside of the United States, if we should have to do so, we will comply with all applicable data privacy laws and utilize an approved mechanism for transfer, including the Standard Contractual Clauses approved by the EU Commission.</p>
                <h5>Data Retention Policy</h5>
                <p>At this time, we retain your personal data until you contact us to request deletion of such personal data.</p>
                <h5>EU Data Rights</h5>
                <p>Pursuant to EU law, EU data subjects are afforded specific data rights under the GDPR, including the right to request access to and rectification of the personal data that we have collected and maintain about you. An EU data subject has the right to request deletion of your personal data from us. Data subjects are afforded the right to object to certain processing activities and request data portability with respect to the personal data that we maintain about you.</p>
                <p>To the extent the legal basis for our processing of your personal data is based solely on your consent, data subjects have the right to withdraw their consent at any time Where we fall short of our applicable legal requirements under the GDPR, data subjects have a right to lodge a complaint against us with a supervisory authority. Data subjects may exercise their data rights by contact us using one of the methods described below.</p>
                <h5>Statutory or Contractual Requirement for the Collection of Personal Data</h5>
                <p>The personal data that we collect from you in connection with your use of the Platform, including the Mobile Application and Website, is not required by any statute or legal authority. Instead, the personal data that we collect from you in connection with your registration for services is required, to the extent indicated in the specific form, under the Platform Terms and Conditions that govern the services. To the extent that you choose not to provide us with any required information, we will be unable to complete your particular request.</p>
                <h4>Contact Us</h4>
                <p>The Identifyn™ Platform is operated by GMD12, LLC and Identifyn™, LLC.</p>
                <p>Feedback, comments, requests for technical support, and other communications relating to the Platform, or the services available on or through the Platform should be directed to:</p>
                <a href="mailto:Contact@Identifyn™.com" style={{ color: 'var(--primary-color)', opacity: 0.8}}>Contact@Identifyn™.com</a>
                <p style={{marginTop:'12px'}}>or</p>
                <p>Identifyn™ LLC <br/>
                    2055 Wood Street <br/>
                    Suite 206 <br/>
                    Sarasota, FL 34237</p>
            </Content>
            <Footer />
        </Layout>
    )
}

export default Index