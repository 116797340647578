import React from 'react'
import Layout from "../../../components/layout"
import "./style.scss"
import { toAbsoluteUrl } from "../../../utils/assetHelper"
import { Footer } from '../../../components/Footer'

const Index = () => {
    return (
        <Layout>
            <div className="container title-container">
                <img src={toAbsoluteUrl("/media/partners/azure.png")} alt="" />
            </div>
            <div className="container azure-container">
                <div className="left-container">
                    <div className="solutions-text">
                        <h2>Azure Biosystems – Sapphire Biomolecular Imaging </h2>
                        <p>Identifyn™ currently deploys <span>two custom-designed Azure Biosystems Sapphire FL Biomolecular Imaging Platforms</span>. The two Sapphires allow immunocytochemistry <span>imaging from ~300 nm to 900 nm across a broad spectrum</span>. Additionally, the Identifyn™ <span>Sapphires provide 5 μm – 1000 μm resolution scans, a Z-plane range from -1.0 to +6 mm</span>, chemiluminescence detection through the Chemiluminescence Module, <span>and Multiplex</span>.
                        </p>
                    </div>
                </div>
                <div className="right-container" style={{position:'relative'}}>
                    <img src={toAbsoluteUrl("/media/azure/azure1.png")} alt="" className='bruker-hero-img' />
                    <small style={{ font:'Instrument Sans', fontWeight: 400, color: '#1e1e1e', fontSize:'10px', position:'absolute', bottom:'-50px', right:'5%' }}>Image Courtesy Azure Biosystems</small>
                </div>
            </div>
            <div className="container azure-section-second-container">
                <h3>Application Flexibility & Sensitive Fluorescent Detection </h3>
                <p>The flexibility of the <span>Sapphire FL enables Identifyn™ to validate fluorescent affinity reagents and multiplex assays</span> for any biological investigation. Identifyn's™ custom optical configurations provide for the excitation of any fluorescent dye, <span>allowing for accurate quantification and sensitive detection within the femtogram range of biological molecules</span> across various applications.</p>
            </div>
            <div className="container azure-section-third-container">
                <div className="card1">
                    <h3>Sapphire FL Imager Technical Capabilities</h3>
                    <div className="points-container">
                        <div className="points">
                            <img src={toAbsoluteUrl("/media/pointbullet.svg")} alt="" />
                            <p>Detection Modes: <span>Fluorescence</span>, Chemiluminescence, Phosphor Imaging, and Densitometry.</p>
                        </div>
                        <div className="points">
                            <img src={toAbsoluteUrl("/media/pointbullet.svg")} alt="" />
                            <p>Detectors: <span>PMT and APD</span> detection built for confocal imaging using Solid State Laser Diodes.</p>
                        </div>
                        <div className="points">
                            <img src={toAbsoluteUrl("/media/pointbullet.svg")} alt="" />
                            <p>Laser Wavelength (range): <span>375 – 900 nm</span> having</p>
                        </div>
                        <div className="points">
                            <img src={toAbsoluteUrl("/media/pointbullet.svg")} alt="" />
                            <p>Excitation (range): <span>375 – 850 nm</span>, and</p>
                        </div>
                        <div className="points">
                            <img src={toAbsoluteUrl("/media/pointbullet.svg")} alt="" />
                            <p>Emission (range): <span>380 – 900 nm</span> in</p>
                        </div>
                        <div className="points">
                            <img src={toAbsoluteUrl("/media/pointbullet.svg")} alt="" />
                            <p>Z-plane (range): <span>7 mm </span>[-1 to 6 mm with 0.1 mm increments]</p>
                        </div>
                    </div>
                </div>
                <div className="card1">
                    <h3>Identifyn™ Sapphire Applications  </h3>
                    <div className="points-container">
                        <div className="points">
                            <img src={toAbsoluteUrl("/media/pointbullet.svg")} alt="" />
                            <p><span>Protein Chemistry Workflow</span> – Protein Expression, Post-Translational Modification, Total Protein Normalization</p>
                        </div>
                        <div className="points points-right">
                            <img src={toAbsoluteUrl("/media/pointbullet.svg")} alt="" />
                            <p>2D DIGE</p>
                        </div>
                        <div className="points points-right">
                            <img src={toAbsoluteUrl("/media/pointbullet.svg")} alt="" />
                            <p><span>In-Cell Western</span></p>
                        </div>
                        <div className="points points-right">
                            <img src={toAbsoluteUrl("/media/pointbullet.svg")} alt="" />
                            <p><span>Arrays</span>, Membrane Arrays, Microarrays</p>
                        </div>
                        <div className="points points-right">
                            <img src={toAbsoluteUrl("/media/pointbullet.svg")} alt="" />
                            <p><span>Multiplex</span> Western Blotting</p>
                        </div>
                        <div className="points">
                            <img src={toAbsoluteUrl("/media/pointbullet.svg")} alt="" />
                            <p> Tissue Section Imaging / <span>TissueFluorescence ImmunoHistoChemistry</span></p>
                        </div>
                        <div className="points">
                            <img src={toAbsoluteUrl("/media/pointbullet.svg")} alt="" />
                            <p>Extended Functionality –</p>
                        </div>
                        <div className="points points-right">
                            <img src={toAbsoluteUrl("/media/pointbullet.svg")} alt="" />
                            <p><span>DNA / RNA</span> workflow – Southern and Northern Blotting</p>
                        </div>
                        <div className="points points-right">
                            <img src={toAbsoluteUrl("/media/pointbullet.svg")} alt="" />
                            <p>Bacterial Plate Imaging</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container azure-images-container">
                <img src={toAbsoluteUrl('/media/azure/azure2.png')} alt="" loading='lazy' style={{height:'100%', width:'100%'}} />
                <img src={toAbsoluteUrl('/media/azure/azure3.png')} alt="" loading='lazy' style={{height:'100%', width:'100%'}} />
                <img src={toAbsoluteUrl('/media/azure/azure4.png')} alt="" loading='lazy' style={{height:'100%', width:'100%'}} />
                {/* <article>
                <div style={{position:'relative', height:'300px'}}>
                    <img src={toAbsoluteUrl('/media/azure/azure2.png')} alt="" loading='lazy' />
                    <small style={{ font:'Instrument Sans', fontWeight: 400, color: '#1e1e1e', fontSize:'10px', position:'absolute', bottom:'0px', right:'5%', color:'#9a9a9a' }}>Image Courtesy Azure Biosystems</small>
                    </div>
                    <div className="info-container">
                        <div className="top-part">
                            <h4>2D DIGE</h4>
                        </div>
                    </div>
                </article> */}
                {/* <article>
                <div style={{position:'relative', height:'300px'}}>
                    <img src={toAbsoluteUrl('/media/azure/azure3.png')} alt="" loading='lazy' />
                    <small style={{ font:'Instrument Sans', fontWeight: 400, color: '#1e1e1e', fontSize:'10px', position:'absolute', bottom:'0px', right:'5%', color:'#9a9a9a' }}>Image Courtesy Azure Biosystems</small>
                    </div>
                    <div className="info-container">
                        <div className="top-part">
                            <h4>2D DIGE</h4>
                        </div>
                    </div>
                </article>
                <article>
                    <div style={{position:'relative', height:'300px'}}>
                    <img src={toAbsoluteUrl('/media/azure/azure4.png')} alt="" loading='lazy' />
                    <small style={{ font:'Instrument Sans', fontWeight: 400, color: '#1e1e1e', fontSize:'10px', position:'absolute', bottom:'0px', right:'5%', color:'#9a9a9a' }}>Image Courtesy Azure Biosystems</small>
                    </div>
                    <div className="info-container">
                        <div className="top-part">
                            <h4>2D DIGE</h4>
                        </div>
                    </div>
                </article> */}
            </div>
            <div className="container azure-images-container azure-images-container-second">
            <img src={toAbsoluteUrl('/media/azure/azure5.png')} alt="" loading='lazy' style={{height:'100%', width:'100%'}} />
            <img src={toAbsoluteUrl('/media/azure/azure6.png')} alt="" loading='lazy' style={{height:'100%', width:'100%'}} />
                {/* <article>
                <div style={{position:'relative', height:'300px'}}>
                    <img src={toAbsoluteUrl('/media/azure/azure5.png')} alt="" loading='lazy' />
                    <small style={{ font:'Instrument Sans', fontWeight: 400, color: '#1e1e1e', fontSize:'10px', position:'absolute', bottom:'0px', right:'5%' }}>Image Courtesy Azure Biosystems</small>
                    </div>
                    <div className="info-container second">
                        <div className="top-part">
                            <h4>Arrays</h4>
                        </div>
                    </div>
                </article>
                <article>
                <div style={{position:'relative', height:'300px'}}>
                    <img src={toAbsoluteUrl('/media/azure/azure6.png')} alt="" loading='lazy' />
                    <small style={{ font:'Instrument Sans', fontWeight: 400, fontSize:'10px', position:'absolute', bottom:'10px', right:'0', color:'#9a9a9a' }}>Image Courtesy Azure Biosystems</small>
                    </div>
                    <div className="info-container">
                        <div className="top-part">
                            <h4>Tissue Section imaging / IHC</h4>
                        </div>
                    </div>
                </article> */}
            </div>
            <div className="container azure-section-four-container">
                <h3>Identifyn's™ ongoing commitment to fluorescence imaging combined with the power and speed of the Azure Sapphire FL imaging system and Azure Spot™ analysis ensures accuracy in multiplexed protein chemistry to our partners in academia and industry.</h3>
            </div>
            <div className="container azure-container" style={{ marginBottom: '32px' }}>
                <div className="left-container">
                    <div className="solutions-text">
                        <h2><span>Identifyn’s™ Azure Sapphire FL </span> </h2>
                            <h3>Biomolecular Imaging Systems </h3>
                    </div>
                </div>
                <div className="right-container">
                    <img src={toAbsoluteUrl("/media/azure/azure7.png")} alt="" className='bruker-hero-img' />
                </div>
            </div>
            <Footer />
        </Layout>
    )
}

export default Index